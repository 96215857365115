<div class="sidebar-new" [ngClass]="sideMenuColor()">
  <div>
    <app-header></app-header>
  </div>
  <div class="menu-list">
    <ul>
      <li *ngFor="let menu of menuItems"
      [class.hide-item]="!hasPermission(menu) || hideForCompany(menu)" [ngClass]="openMenuColor(menu.isOpen)"
      [class.menu-selected-bg]="!menu.hasSub && menu.isOpen">
        <div (click)="setMenu(menu.selectables)" routerLink=".{{ menu.path }}">
          <i *ngIf="menu.hasSub" class="angle fas" [class.fa-angle-right]="!menu.isOpen" [class.fa-angle-down]="menu.isOpen"
            ></i>
  
          <img *ngIf="!menu.isOpen" class="icon-image" src="assets/icons/{{ menu.iconDefault }}"
            [class.single-menu]="!menu.hasSub">
          <img *ngIf="menu.isOpen" class="icon-image selected" src="assets/icons/{{ menu.iconSelected }}"
            [class.single-menu]="!menu.hasSub">
  
          <span [class.selected]="menu.isOpen">
            <a [ngClass]="getTextColor(menu)" routerLink=".{{ menu.path }}" [queryParams]="menu.params">{{ menu.title }}</a>
          </span>
  
        </div>
        <ul *ngIf="menu.hasSub" class="sub-items hide-item" [class.show-item]="menu.isOpen">
          <li *ngFor="let sub of menu.sub" class="animated flipInX" (click)="setMenu(sub.selectables)" [class.sub-selected-bg]="sub.isOpen"
          [class.sub-selected-bg-channel-partner]="sub.isOpen && isChannelPartner"
            routerLink=".{{ sub.path }}" [queryParams]="sub.params"
            [class.hide-item]="!hasPermission(sub) || hideForCompany(sub)">
            <a [ngClass]="getTextColor()" [class.sub-selected-bg]="sub.isOpen"
              [class.sub-selected-bg-channel-partner]="sub.isOpen && isChannelPartner"
              [class.selected]="sub.isOpen" appCompanyDisplayName [changeTextAfterViewInit]="true">
              {{ sub.title }}
            </a>
          </li>
        </ul>
      </li>
    </ul>
    <div (click)="logoutUser()" class="bottom-fixed cursor-pointer" [ngClass]="openMenuColor()"><i class="logout-icon"><img src="assets/icons/Logout_Unselected_v2.svg"></i> 
      <span [ngClass]="getTextColor()" class="logout-text">Logout</span>
    </div>
  </div>
</div>
