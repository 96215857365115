import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { formatToInr } from 'src/app/utils';

@Component({
  selector: 'app-currency-cell',
  templateUrl: './currency-cell.component.html',
  styleUrls: ['./currency-cell.component.scss']
})

export class CurrencyCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {
  }

  transform(value: number): string {
    if (!value) {
      return;
    }
    return formatToInr(value);

  }
}
