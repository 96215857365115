import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { ActionCellService } from 'src/app/services/action-cell.service';

@Component({
  selector: 'app-actions-cell',
  templateUrl: './actions-cell.component.html',
  styleUrls: ['./actions-cell.component.scss']
})
export class ActionsCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor(private _actionCellService: ActionCellService) { }

  init(params: any) {
  }

  emitAction($event, buttonAction = null) {
    $event.stopPropagation();
    if (buttonAction) {
      this.element.rowAction = buttonAction;
    }
    this._actionCellService.registerEvent(this.element, this.columnDef);
  }

}
