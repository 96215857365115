import { StatusNotificationComponent } from './../../components/shared/modal/status-notification/status-notification.component';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { ErrorModalComponent } from '../../components/error-modal/error-modal.component';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {
  toasterConfig = {
    closeButton: true,
    progressBar: true,
    progressAnimation: 'increasing',
    tapToDismiss: true
  };
  modalRef: BsModalRef;
  constructor(private toastr: ToastrService, private modalService: BsModalService) { }

  successNotification(message, title,) {
    return this.toastr.success(message, title, { timeOut: 5000 });
  }

  errorNotification(message, title, route = null) {
    const initialState = {
      list: [
        message
      ],
      title: title,
      route,
    };
    this.modalService.show(ErrorModalComponent, { initialState, class: 'modal-dialog-centered modal-error-backdrop', backdrop: 'static', keyboard: false });
    // return this.toastr.error(message, title, { positionClass: 'toast-top-right', enableHtml: true, timeOut: 100000, tapToDismiss: false});
  }

  warningNotification(message, title) {
    return this.toastr.warning(message, title);
  }


  statusModalNotification({ message, successStatus }) {
    const initialState = {
      message,
      successStatus
    };
    return this.modalService.show(StatusNotificationComponent,
      { initialState, class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

}
