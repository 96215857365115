<div class="modal-header">
  <h4 class="modal-title pull-left">Error</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="onClickOk()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="error-msg" [innerHtml]="list[0]">
  </div>
  <div class="error-btn-div">
    <button  class="btn oms-btn-primary ok-btn" type="submit" (click)="onClickOk()">Ok</button>
  </div>

</div>

