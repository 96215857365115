<div class="book-closure-list-card-container" [class.selected]="isSelected">

  <!-- Top Row -->
  <div class="column">
    <div class="row p-12 top-row space-between">
      <div class="row">
        <p class="text">{{ period?.periodNumber || "--" }}</p>
        <p class="text m-12">|</p>
        <p class="text">{{ (period?.startDate | date:'MMM dd, YYYY':'UTC') || "--" }} to {{ (period?.endDate | date:'MMM dd, YYYY':'UTC') || "--" }}</p>
      </div>

      <!-- Type Tag -->
      <div class="row" *ngIf="type">
        <span class="tag" [ngClass]="type?.class" *ngIf="true">
          <img class="tag-icon" src="/assets/icons/book-closure/{{ type?.icon + '.svg'}}" />
          {{ type?.text }}
        </span>
      </div>
    </div>

    <!-- Bottom Row -->
    <div class="row bottom-row space-between">
      <div class="row">
        <img class="mr-4" src="/assets/icons/book-closure/bu.svg" />
        <p class="text text-sm">{{ selectedBusinessUnitsLength || "--" }}</p>
        <div class="vertical-divider"></div>
        <img class="mr-4" src="/assets/icons/book-closure/timer.svg" />
        <p class="text text-sm">{{ (period?.validUntil | date:'MMM dd, YYYY':'UTC') || "--" }}</p>
        <div class="vertical-divider"></div>
        <img class="mr-4" src="/assets/icons/book-closure/refresh.svg" />
        <p class="text text-sm">{{ (period?.validUntil | date:'hh:mm a':'UTC') || "--" }}</p>
        <div class="vertical-divider"></div>
      </div>

      <div class="row">
        <div class="vertical-divider"></div>
        <img class="mr-4" src="/assets/images/kam-user.svg" />
        <p class="text text-sm">{{ userName }}</p>
      </div>
    </div>
  </div>
</div>
