import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs';

const API_URL = environment.apiEndPoint;
const USER_URL = environment.userEndPoint;
const USER_URL_V2 = environment.userEndPointV2
@Injectable({
  providedIn: 'root'
})
export class UsersService {

  companySubject = new Subject<any>();

  constructor(private http: HttpClient) { }

  private handleSuccess(response: HttpResponse<any>) {
    if (response.status === 200) {
      return { 'body': response.body, 'headers': response.headers };
    }
    return JSON.parse(response.body);
  }

  public getRoles() {
    return this.http.get(API_URL + 'roles');
  }

  public createUser(data) {
    return this.http.post(USER_URL + 'users', data);
  }

  public getUsers(urlParams) {
    return this.http.get(API_URL + 'users', {
      params: {
        ...urlParams
      }
    });
  }

  public updateUser(userId, data) {
    return this.http.put(USER_URL + 'users/' + userId, data);
  }

  public deleteUser(userId) {
    return this.http.delete(USER_URL + 'users/' + userId);
  }

  public getUserById(userId) {
    return this.http.get(API_URL + '/users/' + userId);
  }

  public getCompanyBySlug(slug) {
    return this.http.get(USER_URL + 'company-by-slug/' + slug, {});
  }

  public getAllCompanies() {
    return this.http.get(USER_URL + 'company', {});
  }

  public getCompaniesByProfile() {
    return this.http.get(USER_URL + 'company-by-tenant-profile', {});
  }

  public getAllCompaniesByTenant(tenantId) {
    return this.http.get(USER_URL + 'company', {
      params: {
        tenantId: tenantId
      }
    });
  }

  /**
   * List to fetch users from the user service
   * @param urlParams
   * @returns UserList[]
   */
  public getUserByRole(urlParams) {
    return this.http.get(USER_URL + 'get-user-by-role', {
      params:{
        ...urlParams
      }
    });
  }

  getUsersByNameAndRole({ params }: any) {
    return this.http.get(`${USER_URL}get-user-by-role`, { params });
  }

  public companySelection(slug) {
    this.companySubject.next(slug);
  }

  public getAllUsers(params: any = {}) {
    const url = `${USER_URL}users`
    return this.http.get(url, { params });
  }

  public getSubBuByBuId(buId) {
    const url = `${USER_URL}sub-bu/getSubBuByBuId/${buId}`;
    return this.http.get(url);
  }
  
  isChannelPartner(companyId) {
    return this.http.get(`${USER_URL_V2}company/${companyId}/is-channel-partner`);
  }
}
