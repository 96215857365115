import {
  Injectable
} from '@angular/core';
import {
  HttpClient,
  HttpResponse
} from '@angular/common/http';
import {
  environment
} from '../../environments/environment';
import { of, Observable } from 'rxjs';
import { OptionModel } from '../models/tab-model';

const API_URL = environment.apiEndPoint;

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  constructor(private http: HttpClient) { }

  private handleSuccess(response: HttpResponse<any>) {
    if (response.status === 200) {
      return {
        body: response.body,
        headers: response.headers
      };
    }
    return JSON.parse(response.body);
  }

  public submitPurchaseOrder(poDetails, orderId) {
    return this.http.post(
      API_URL + 'orders/' + orderId + '/purchase-orders',
      poDetails
    );
  }

  public updatePurchaseOrder(poDetails, orderId, poId) {
    return this.http.put(
      API_URL + 'orders/' + orderId + '/purchase-orders/' + poId,
      poDetails
    );
  }

  public getPurchaseOrderById(orderId, poId) {
    return this.http.get(
      API_URL + 'orders/' + orderId + '/purchase-orders/' + poId
    );
  }

  public getPurchaseOrderByIdForEdit(orderId, poId) {
    return this.http.get(
      API_URL + 'orders/' + orderId + '/purchase-orders/' + poId + '/draft'
    );
  }

  public revisePoById(poDetails, orderId, poId) {
    return this.http.put(
      API_URL + 'orders/' + orderId + '/purchase-orders/' + poId + '/revise',
      poDetails
    );
  }

  public getPaymentDetailsById(paymentId, params = {}) {
    return this.http.get(API_URL + 'payment-requests/' + paymentId, {
      params,
    });
  }


  public getPaymentsByStatus(paymentId, params) {
    if (paymentId) {
      return this.http.get(
        API_URL + 'payment-requests/' + paymentId + '/payments', {
        params: params
      }
      );
    }
  }

  public getPaymentRequestById(paymenRequesttId) {
    if (paymenRequesttId) {
      return this.http.get(
        API_URL + 'payment-requests/' + paymenRequesttId + '/payments');
    }
  }

  public getFinancePaymentsByPR(paymentRequestId, params) {
    return this.http.get(
      API_URL + 'payment-requests/' + paymentRequestId + '/finance-payments', {
      params: params
    }
    );
  }

  public makePaymentById(paymentId, body) {
    return this.http.put(
      API_URL + 'payments/' + paymentId + '/make-payment',
      body
    );
  }

  public getPayments(params) {
    return this.http.get(API_URL + 'payments/all', {
      params
    });
  }

  public getPaymentsById(params) {
    return this.http.get(API_URL + 'payment-requests', {
      params
    });
  }

  getAllMetricData(params) {
    return this.http.get(API_URL + 'customers/contract/getMetric', {
      params
    });
  }

  isNonWorkingHour(params) {
    return this.http.get(API_URL + 'is-non-working-hour', {
      params
    });
  }
  
  validateNonWorkingHourPaymentLimit(params) {
    return this.http.get(API_URL + 'validate-payment', {
      params
    });
  }

  generateOtp(params, paymentId) {
    return this.http.post(API_URL + 'generate-payment-otp', {
      ...params, paymentId
    });
  }
  // Cusotmer Credit Note payment API calls

  generateOtpForCustomerNote(params, creditNoteId) {
    return this.http.post(API_URL + `payments/customer-note/${creditNoteId}/generate-otp`, {
      ...params
    });
  }

  makeBankPaymentForCustomerNote(otp, creditNoteId, paymentBankName, remark?: null) {
    const data = {
      otp,
      paymentBankName,
      remark
    };
    return this.http.put(
      API_URL + `payments/customer-note/${creditNoteId}/make-bank-payment`, data
    );
  }

  registerBankPaymentForCustomerNote(otp, creditNoteId, paymentData) {
    const data = {
      otp,
      ...paymentData
    };
    return this.http.put(
      API_URL + `payments/customer-note/${creditNoteId}/register-bank-payment`, data
    );
  }

  // Customer Credit Note Payment API calls ends

  getPaymentDetails(paymentId) {
    return this.http.get(API_URL + 'payments/' + paymentId);
  }

  makeBankPayment(otp, payId, paymentBankName) {
    const data = {
      otp: otp,
      paymentBankName: paymentBankName
    };
    return this.http.put(
      API_URL + 'payments/' + payId + '/make-bank-payment', data
    );
  }

  initiatePayment(paymentId, otp, paymentData) {
    const data = {
      otp: otp,
      ...paymentData
    };
    return this.http.post(
      API_URL + 'payments/' + paymentId + '/validate-otp-initiate-payment', data
    );
  }

  getAllCategories() {
    return this.http.get(API_URL + 'categories', {});
  }

  getPaymentByLoanId(loanId) {
    return this.http.get(API_URL + 'loans/' + loanId + '/payments', {});
  }

  getAllPaymentRequest(params) {
    return this.http.get(API_URL + 'all-payment-requests', {
      params: {
        ...params
      }
    });
  }

  getAllPaymentRequestNew(params) {
    return this.http.get(API_URL + 'payment-request-list', {
      params: {
        ...params
      }
    });
  }

  getAllFactoryPaymentRequest(params) {
    return this.http.get(API_URL + 'all-factory-payment-requests', {
      params: {
        ...params
      }
    });
  }

  getLetterOfCreditListByOrderId(orderId): Observable<any> {
    return this.http.get(
      API_URL + 'letter-of-credit-by-order/' + orderId
    );
  }
  /**
   * Get Letter of credits for a factory PO
   * @param factoryPurchaseOrderId
   */
  getLCListByFactoryPurchaseOrderId(factoryPurchaseOrderId) {
    return this.http.get(
      API_URL + 'letter-of-credit-by-fpo/' + factoryPurchaseOrderId
    );
  }

  editPaymentRequestStatus(paymentRequestId, payload) {
    return this.http.put(`${API_URL}/payment-requests/${paymentRequestId}/edit-status`, payload);
  }
  
  getPaymentRequestList(params, filterQueryString = '') {
    return this.http.get(API_URL + `payment-requests/payment-request-list/list?${filterQueryString}`, {
      params: {
        ...params
      }
    });
  }

  getPaymentRequestFilters(params) {
    return this.http.get(API_URL + `payment-requests/payment-request-list/filter`, {
      params: {
        ...params
      }
    });
  }

  getPaymentRequestTabCounts(params) {
    return this.http.get(`${API_URL}payment-requests/payment-request-list/tab-counts`, {
      params: {
        ...params,
      }
    });
  }

  getAllCustomerTransactions(params, filterQueryString = '') {
    return this.http.get(`${API_URL}customer-finance-payments/transaction-list/list?${filterQueryString}`, {
      params: {
        ...params,
      }
    });
  }

  getAllCustomerTransactionFilters(params) {
    return this.http.get(`${API_URL}customer-finance-payments/transaction-list/filters`, {
      params
    });
  }

  getCustomerTransactionTabCount(params) {
    return this.http.get(`${API_URL}customer-finance-payments/transaction-list/tab-counts`, {
      params: {
        ...params,
      }
    });
  }

  generateOtpForBulkCustomerPayment(payload) {
    return this.http.post(`${API_URL}customer-finance-payments/payments-bulk/generate-otp`, payload);
  }

  customerTransactionPaymentApprove(payload) {
    const url = API_URL + 'customer-finance-payments/bulk/approve';
    return this.http.put(url, payload);
  }

  customerTransactionPaymentReject(payload) {
    const url = API_URL + 'customer-finance-payments/bulk/cancel';
    return this.http.put(url, payload);
  }
}
