
<div class="under-maintenance">
  <img src="/assets/logo_blue.png" class="login-logo container" />
  <div class="message container">
    <div class="title">
      We&rsquo;ll be back soon!
    </div>
    <div class="text">
        <p>Sorry for the inconvenience but we&rsquo;re performing some maintenance at the moment. If you need to, you can
          always contact us, otherwise we&rsquo;ll be back online shortly!</p>
        <p class="mt-3" appCompanyDisplayName>&mdash; &nbsp; Zetwerk</p>
    </div>
  </div>

</div>