export const environment = {
  production: true,
  mixpanelToken: "44ecf9c61a059e6e83ea574bbec5ca9e",
  googleClientId:
    "533773529183-97ufurmdfs5q12phba0ksssqt6g8maqa.apps.googleusercontent.com",
  apiEndPoint: "https://api.zetwerk.com/oms/v1/",
  authApiEndPoint: "https://api.zetwerk.com/v1/",
  zetProApiEndPoint: "https://api.zetwerk.com/v1/",
  socketEndPoint: "https://oms-api.zetwerk.com/",
  sosApiEndPoint: "https://api.zetwerk.com/sms/v1/",
  zisoApiEndPoint: "https://api.zetwerk.com/ziso/v2/",
  eqsEndPoint: "https://api.zetwerk.com/eqsv2/api/v1/",
  userEndPoint: "https://api.zetwerk.com/user/v1/",
  userEndPointV2: 'https://api.zetwerk.com/user/api/v2/',
  cmsApiEndPoint: "https://api.zetwerk.com/cms/api/v1/",
  inzeEndPoint: "https://api.zetwerk.com/catalogue/api/v1/",
  inzeUI: "https://inze.zetwerk.com/",
  supplierPortal: "https://supplier.zetwerk.com/",
  WREndPoint: "https://api.zetwerk.com/workflow/api/v1/",
  env: "production",
  maintenance: false,
  addressWidgetEndPoint: "https://api.zetwerk.com/",
  commonMastersAPIEndPoint: "https://api.zetwerk.com/common-masters/api/v1/",
  zetsupUrl: "https://zetsup.zetwerk.com/",
  createGRNEndPoint: 'https://zetwerkuat01.sandbox.operations.dynamics.com/',
  commonMastersAPIEndPointV2:"https://api.zetwerk.com/common-masters/api/v2/",
  zataBaseUrl: 'https://zata.zetwerk.com/question',
  bankMasterUrl: '/10736-bank-master-download-link',
  investorMasterUrl: '/10741-investor-master-download-link',
  freshDeskUrl: "https://newaccount1632390608123.freshdesk.com/a/tickets",
  cpHostUrl: 'oms-cp.zetwerk.com',
  supplierMicroserviceAPIEndPoint: "https://api.zetwerk.com/suppliers-v2/api/v2/",
  supplierMicroserviceBucketName: "zetwerk-smes",
  zetMSDUrl:"https://zetwerk.operations.dynamics.com",
  approvalZEndpoint: 'https://api.zetwerk.com/approvalz/api/v1/',
  bisApiEndPoint: "https://api.zetwerk.com/bis/api/v1/",
  shipmentBaseUrl: 'https://shipment.zetwerk.com/'
};
