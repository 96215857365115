import { Component, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-supplier-feedback-form',
  templateUrl: './supplier-feedback-form.component.html',
  styleUrls: ['./supplier-feedback-form.component.scss']
})
export class SupplierFeedbackFormComponent implements OnInit {
  @ViewChild('qualityWork') qualityWork: ElementRef;
  @ViewChild('dispatchTime', { static: true }) dispatchTime: ElementRef;
  @ViewChild('reasonOfDelay') reasonOfDelay: ElementRef;
  @ViewChild('paymentIssue') paymentIssue: ElementRef;
  @ViewChild('labour') labour: ElementRef;
  @ViewChild('planning') planning: ElementRef;
  @ViewChild('procureMent') procureMent: ElementRef;
  @ViewChild('response', { static: true }) response: ElementRef;
  showError: Boolean = false;
  feedbackDetails: any = {};
  public feedbackForm = {
    qualityOfWork: '',
    feedbackSubmitted: false,
    reasonForPoorQuality: {
      isPoorWorkManship: false,
      technicallyNotCapable: false,
      deliveryTimePressue: false,
      requiredMachinesAvailable: false
    },
    dispatchDoneOnTime: undefined,
    reasonForDispatchDelay: {
      isPaymentIssueWithLabour: false,
      isPaymentIssueWithZetwerk: false,
      truckAvailability: false,
      labourAvailability: false,
      planningError: false,
      delayInReceiveingDrawings: false,
      delayInDrawingClarification: false,
      delayInApprovalCustomer: false,
      delayInRawMaterialProcurement: false
    },
    frequencyOfPaymentIssueWithLabour: '',
    frequencyOfLabourUnavailability: '',
    planningDoneForProject: '',
    reasonForProcurementDelay: {
      isFinanceIssue: false,
      isRawMaterialAvailable: false,
      rawMaterialSupplierIssue: false,
      dispatchIssue: false
    },
    supplierResponsivenessToComplaints: '',
    wouldWorkWithSupplierInFuture: ''

  };

  showId = {
    qualityDispatch: false,
    qualityWork: true,
    dispatchTime: true,
    reasonOfDelay: true,
    paymentIssue: true,
    labour: true,
    planning: true,
    procureMent: true,
    response: true,
    nps: true,
    submit: true
  };
  form: any;
  constructor(private route: ActivatedRoute, private router: Router,
    private generalService: GeneralService) {
    console.log('this', this.feedbackForm);
   }
  ngOnInit() {
    this.getSupplierFeedback();
    // check is form submitted or not api
  }

  checkIsDissatisfied() {
    const quality = ['Very dissatisfied', 'Dissatisfied', 'Neither'];
    return quality.indexOf(this.feedbackForm.qualityOfWork) >= 0;
  }

  select(childKey, value) {
    this.feedbackForm[childKey] = value;
  }

  isDispatchDone() {
    return this.feedbackForm.dispatchDoneOnTime === 'No';
  }

  scrollTo(event, type?) {
    let nextClass = {};
    if (type === 'isDivClick' || event.keyCode === 13) {
      nextClass = event.target.parentElement;
    } else  {
      nextClass = event.target.parentNode.parentElement.nextElementSibling;
    }
    if (!nextClass) {
      window.scrollTo(0, document.body.scrollHeight);
    }
    const id = nextClass['id'];
    if (!id) {
      return;
    }
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center'});
    this.makeOtherDivFalse(id);
  }

  makeOtherDivFalse(id) {
    for (const key in this.showId) {
      if (key === id) {
        this.showId[key] = false;
      } else {
        this.showId[key] = true;
      }
    }
  }

  @HostListener('window:scroll', ['$event']) scrollHandler($event) {

    const windowTop = window.pageYOffset,
      feedbackIds = Array.from(document.querySelectorAll('.dispatch'));
    const feedbackId = feedbackIds.filter(function (e) {
      return e['offsetTop'] < windowTop + 350;
    });
    const id = feedbackId[feedbackId.length - 1].id;
    if (id) {
      this.makeOtherDivFalse(id);
    }
  }

  checkIsDisabled(key) {
    const obj = this.feedbackForm[key];
    const data = Object.keys(obj).some(function (t) {
      if (typeof obj[t] === 'boolean') {
        return obj[t];
      }
    });
    return !data;
  }

  submit(form) {
    const data = form.value['feedbackForm'];
    data.qualityOfWork = this.feedbackForm.qualityOfWork;
    data.wouldWorkWithSupplierInFuture = this.feedbackForm.wouldWorkWithSupplierInFuture;
    const feedbackId = this.route.snapshot.params['feedbackId'];
    this.generalService.submitSupplierFeedback(data, feedbackId).subscribe(() => {
      this.router.navigate(['thankyou']);
    });
  }

  checkIsRequired(form, key) {
    const obj = form.value['feedbackForm'];
    if (!obj || !obj[key] || typeof obj !== 'object') {
      return false;
    }
    return Object.keys(obj[key]).every((k) => !obj[key][k]);
  }

  getSupplierFeedback() {
    const feedbackId = this.route.snapshot.params['feedbackId'];
    this.generalService.getSupplierFeedback(feedbackId).subscribe(data => {
      if (data['data'].feedbackSubmitted) {
        this.router.navigate(['thankyou'], { queryParams: { submitted: true } });
      }
      this.feedbackDetails = data['data'];
      const qualityOfWork = this.route.snapshot.queryParams['qualityOfWork'];
      this.feedbackForm.qualityOfWork = qualityOfWork ? qualityOfWork.replace(/_/g, ' ') : '';
    });
  }

  checkIsDispatchDone() {
    if (this.feedbackForm.dispatchDoneOnTime) {
      return Object.keys(this.feedbackForm.reasonForDispatchDelay).forEach(v => this.feedbackForm.reasonForDispatchDelay[v] = false);
    }
  }
}
