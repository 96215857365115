export const ENTITY_TYPES = {
  BILL: "BILL",
};

export const DOCUMENT_NAMES = {
  BILL_DETAILS: "BILL_DETAILS",
  LR_DOC: "LR_DOC",
  E_WAY_BILL_DOC: "E_WAY_BILL_DOC",
  BILL_ITEMS: "BILL_ITEMS",
};

export const DOCUMENT_TYPES_FOR_NEW_VERIFY_FLOW = [
  'LR_DOC',
  'E_WAY_BILL_DOC'
];

export const DOCUMENT_TYPES_FOR_ACTIVE_DOCUMENT = [
  'LR No / Docket No',
  'E-Way Bill No'
];

export const DOCUMENT_TYPE_TO_NAME_MAP = {
  "E-Way Bill No": DOCUMENT_NAMES.E_WAY_BILL_DOC,
  "LR No / Docket No": DOCUMENT_NAMES.LR_DOC,
};

export const ENTITY_REFS = {
  BILL: "bill",
};

export const STATUSES = {
  APPROVED: "APPROVED",
  REJECTED: "REJECTED",
};

export const DISPLAY_TYPES = {
  TEXT: 'TEXT',
  NUMBER: 'NUMBER',
  CURRENCY: 'CURRENCY',
  DATE: 'DATE',
  ADDRESS: 'ADDRESS'
};

export const PRIORITY = {
  MANUAL: {
    HIGH: 'HIGH',
    LOW: 'LOW'
  },
  QR: {
    HIGH: 'HIGH',
    LOW: 'LOW'
  },
  IRN: {
    HIGH: 'HIGH',
    LOW: 'LOW'
  }
};


export const VALIDATION_TYPES = {
  MANUAL: 'MANUAL',
  AUTO: 'AUTO'
};

export const QUESTION_TYPES = {
  QUESTION: 'QUESTION',
  ADDITIONAL_INFO: 'ADDITIONAL_INFO'
};
