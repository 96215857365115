import { Component } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { formatToInr } from 'src/app/utils';

@Component({
  selector: 'app-date-sub-currency-cell',
  templateUrl: './date-sub-currency-cell.component.html',
  styleUrls: ['./date-sub-currency-cell.component.scss']
})
export class DateSubCurrencyCellComponent implements ICellCmp {
  
  params;
  columnDef;
  element;

  constructor() { }

  init(): void {
  }

  transform(value: number): string {
    if (!value) {
      return;
    }
    return formatToInr(value);
  }

}
