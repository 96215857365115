export const COA_TYPE = {
  CONTROL_ACCOUNT : 'CONTROL_ACCOUNT' ,
  CLEARING_ACCOUNT : 'CLEARING_ACCOUNT', 
  PARKING_ACCOUNT : 'PARKING_ACCOUNT' ,
  COLLECTION_CONTROL_ACCOUNT : 'COLLECTION_CONTROL_ACCOUNT' ,
  COLLECTION_PARKING_ACCOUNT : 'COLLECTION_PARKING_ACCOUNT'
};

export const COA_ACCOUNT_TYPE = {
  BANK : 'Bank' ,
  LEDGER : 'Ledger'
};
