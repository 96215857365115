import { OptionModel } from 'src/app/models/tab-model';

// ZW GST constants

export const COMPANY_TYPES = [
    { name: 'Partnership', id: 'Partnership' },
    { name: 'Public', id: 'Public' },
    { name: 'Private', id: 'Private' },
    { name: 'Proprietorship', id: 'Proprietorship' },
  ];

  export const GST_MENU_ALL: OptionModel = {
    label: 'All GST',
    value: 'allGST'
  };


// Supplier GST constants
export const SUPPLIER_COMPANY_TYPES = [
  { name: 'Private Limited', id: 'Private Limited' },
  { name: 'Sole proprietorship', id: 'Sole proprietorship' },
  { name: 'Partnership', id: 'Partnership' },
  { name: 'Limited Liability Partnership', id: 'Limited Liability Partnership' },
  { name: 'One Person Company', id: 'One Person Company' },
  { name: 'HUF', id: 'HUF' },
  { name: 'Public Limited', id: 'Public Limited' }
];

