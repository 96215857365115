export const CHARGE_TYPES = [
  { chargeType: "LC", name: "LC Charges" },
  { chargeType: "TDS", name: "TDS Charges" },
  { chargeType: "VFS", name: "VFS/SID Discounting Charges" },
  { chargeType: "FOREX", name: "FOREX Charges" },
  { chargeType: "UDAAN COMMISSION", name: "Udaan Commission" },
  { chargeType: "GST COMMISSION", name: "GST on Commission" },
  { chargeType: "TCS UDAAN COMMISSION", name: "TCS on Udaan Commission" },
  { chargeType: "TDS 194 UDAAN", name: "TDS u/s 194 O (Udaan)" },
  { chargeType: "LC SUBTRACT", name: "LC SUBTRACT Charges" },
  { chargeType: "AGGREGATOR FEE", name: "Aggregator Fee" }
];
