import { environment } from "../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

const API_ENDPOINT = environment.apiEndPoint;

@Injectable({
  providedIn: "root",
})
export class InvoiceDiscountingService {
  constructor(private _http: HttpClient) {}

  getInvoiceDiscountingList(queryParams, filterParams = "") {
    return this._http.get(
      `${API_ENDPOINT}invoice-discounting?${filterParams}`,
      { params: queryParams }
    );
  }

  public getInvoiceDiscountingFilters(params) {
    return this._http.get(
      `${API_ENDPOINT}invoice-discounting/invoice-discounting-filters`,
      { params }
    );
  }

  rejectInvoiceDiscount({ payload, invoiceDiscountingId }) {
    return this._http.put(
      `${API_ENDPOINT}invoice-discounting/${invoiceDiscountingId}/reject-sid`,
      payload
    );
  }

  updatePaymentReceipt({ invoiceDiscountingId, transactionId, payload }) {
    return this._http.put(
      `${API_ENDPOINT}invoice-discounting/${invoiceDiscountingId}/update-payment-receipt/${transactionId}`,
      payload
    );
  }

  // getInvoiceDiscounting() {
  //   return this._http.get('http://localhost:3000/v1/invoice-discounting/60a4f4942ec87b0d59d803b4');
  // }
  getInvoiceDiscountingDetailsById({ invoiceDiscountingId }) {
    return this._http.get(
      `${API_ENDPOINT}invoice-discounting/${invoiceDiscountingId}`
    );
  }

  public deleteInvoiceDiscountingPaymentReceipts(
    invoiceDiscountingId,
    payload
  ) {
    return this._http.put(
      `${API_ENDPOINT}invoice-discounting/${invoiceDiscountingId}/delete-payment-receipt`,
      payload
    );
  }

  public getInvoiceDiscountingByInvoiceId(invoiceId) {
    return this._http.get(
      `${API_ENDPOINT}invoice-discounting/${invoiceId}/all-sids`
    );
  }

  createSID(data) {
    return this._http.post(`${API_ENDPOINT}/invoice-discounting`, data);
  }

  public uploadExcelFile(fileId) {
    const file = { discountingFileId: fileId };
    return this._http.post(
      `${API_ENDPOINT}invoice-discounting/upload-discounting-excel`,
      file
    );
  }

  uploadReceiptExcelFile(receiptExcelFileId, financierName,interestModality) {
    const queryBody = { excelFileId: receiptExcelFileId, financierName, interestModality };
    return this._http.put(
      `${API_ENDPOINT}invoice-discounting/payment-receipt/upload-via-excel`,
      queryBody
    );
  }

  getInvoiceDiscounting(queryParams, filterParams = "") {
    return this._http.get(`${API_ENDPOINT}invoice-discounting`, {
      params: queryParams,
    });
  }

  markPaymentReceipt(receiptData) {
    return this._http.post(
      `${API_ENDPOINT}invoice-discounting/mark-payment-receipt`,
      receiptData
    );
  }

  public setReceiptData(data: any) {
    localStorage.setItem("receiptData", JSON.stringify(data));
  }

  public get getReceiptData(): any {
    return JSON.parse(localStorage.getItem("receiptData"));
  }

  public getContractsFromSID(queryParams) {
    return this._http.get(
      `${API_ENDPOINT}invoice-discounting/get-contracts-by-financier`,
      { params: queryParams }
    );
  }
}
