import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActionCellService {

  private eventObserver = new Subject();
  constructor() {
  }

  getActionCellEventObserver() {
    return this.eventObserver.asObservable();
  }

  registerEvent(row, columnDef?:any) {
    // this.eventObserver.next();
    this.eventObserver.next({row, columnDef });
  }

}
