<div class="main-text">
  <div class="row">
    <div *ngIf="params?.value?.text?.currencySymbol" [innerHTML]="params?.value?.text?.currencySymbol" class="currency mr-1">
    </div>
    <div>
      {{ params?.value?.textHead ? params?.value?.textHead : params?.value?.text?.amount && transform(params?.value?.text?.amount) }}
    </div>
  </div>
</div>
<div class="subtext" appEllipsifyMe>
  <div class="row">
    <div *ngIf="params?.value?.text?.currencySymbol" [innerHTML]="params?.value?.subText?.currencySymbol"
      class="currency mr-1">
    </div>
    <div>
      {{ params?.value?.subTextPara ? params?.value?.subTextPara : params?.value?.subText?.amount && transform(params?.value?.subText?.amount) }}
    </div>
  </div>
</div>
