import {
  Directive,
  Input,
  OnInit,
  ElementRef,
  Renderer2,
  ChangeDetectorRef
} from '@angular/core';
import { TabDirective, TabsetComponent, TabsModule } from 'ngx-bootstrap/tabs';
import { PermissionsService } from '../services/permissions.service';
@Directive({
  selector: '[appPermissions]',
  providers: [TabDirective, TabsetComponent]
})
export class PermissionsDirective implements OnInit {
  /**
   * Accept the allowed permissions list
   */
  @Input() appPermissions: string[] = [];

  /**
   * Accept the logical operations to be performed
   * i.e AND, OR
   */
  // tslint:disable-next-line:no-input-rename
  @Input() appPermissionsOp: string;

  /**
   * Declare the type of element/ tag
   */
  public tagType: String;

  /**
   * Define all the available tag types
   */
  public TYPES = {
    BUTTON: 'BUTTON',
    TAB: 'TAB',
    MENU: 'LI',
    DIV: 'DIV',
    NGSELECT: 'NG-SELECT',
    SPAN: 'SPAN',
    IMG: 'IMG'
  };

  /**
   * Declare the opertor
   */
  public operator: any;

  /**
   * Define all the available opertors
   */
  public OP: {
    AND: (p: Array<string>) => boolean;
    OR: (p: Array<string>) => boolean;
  };

  /**
   * Define the permissions available for user
   */
  public userPermissions: string[] = [];

  constructor(
    /* Reference to the native HTML element */
    private _el: ElementRef<HTMLElement>,
    /* Reference to the bootsrap TAB directive */
    private _tab: TabDirective,
    /* Reference to the permision service */
    private _ps: PermissionsService,
    private _renderer: Renderer2,
    private _cd: ChangeDetectorRef
  ) {
    this.OP = this._ps.getOP();
  }

  ngOnInit() {
    /**
     * Set default operator to AND
     */
    !this.appPermissionsOp
      ? (this.operator = this.OP.AND)
      : (this.operator = this.OP[this.appPermissionsOp]);
    /**
     * Handle undefined appPermissions
     */
    if (!this.appPermissions) {
      this.appPermissions = [];
    }
    /**
     * Get all the permissions for requsting user
     */
    this.loadUserPermissions();
    /**
     * Identify the type of tag of incoming element and assign to tagType
     */
    this.tagType = this._el.nativeElement.tagName;
    /**
     * If user does not have permission, handle behaviour different elements
     */
    if (!this.userHasPermission()) {
      this.handleElementBehaviour();
    }
  }

  /**
   * Get all the permission of user from permission service
   */
  loadUserPermissions() {
    this.userPermissions = this._ps.getPermissions();
  }

  /**
   * Check whether specific permission available for user
   */
  hasPermission(permission: string) {
    return this.userPermissions.indexOf(permission) > -1;
  }

  /**
   * Check whether user has permission as per logical operator
   */
  userHasPermission() {
    return this.operator(this.appPermissions);
  }

  /**
   * Handle the behaviour of different HTML elements
   */
  handleElementBehaviour() {
    switch (this.tagType) {
      case this.TYPES.BUTTON:
        /**
         * Handle buttons
         */
        this.handleButton();
        break;
      case this.TYPES.TAB:
        /**
         * Handle tabs
         */
        this.handleTab();
        break;
      case this.TYPES.MENU:
        /**
         * Handle main menu
         */
        this.handleMainMenu();
        break;
        case this.TYPES.DIV:
        /**
         * Handle any div
         */
        this.handleDiv();
        break;
        case this.TYPES.NGSELECT:
        /**
         * Handle any ng select
         */
        this.handleNgSelect();
        break;
        case this.TYPES.SPAN:
        /**
         * Handle any span
         */
        this.handleSpan();
        break;
        case this.TYPES.IMG:
        /**
         * Handle any image
         */
        this.handleImg()
    }
  }

  /**
   * If the tag is a BUTTON, then
   * 1. Apply disabled = true attribute
   * 2. Add not-allowed cusrsor for this element
   */
  handleButton() {
    this._renderer.setAttribute(this._el.nativeElement, 'disabled', 'true');
    this._renderer.setStyle(this._el.nativeElement, 'cursor', 'not-allowed');
  }

  /**
   * If the tag is a TAB, then
   * Disable the tab by setting up disabled = true
   */
  handleTab() {
    this._tab.disabled = true;
  }

  /**
   * If the tag is a MENU/ LI, then
   * Hide the menu
   * NOTE: Not using now, please refer sidebar.component.ts for implementation
   */
  handleMainMenu() {
    this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
  }

  /**
   * If the tag is a BUTTON, then
   * 1. Apply disabled = true attribute
   * 2. Add not-allowed cusrsor for this element
   */
  handleDiv() {
    this._renderer.addClass(this._el.nativeElement, 'disabled');
  }

  /**
   * If the tag is a NG-SELECT, then
   * 1. Add class 'disabled-ng-select-input'
   */
  handleNgSelect() {
    this._renderer.addClass(this._el.nativeElement, 'disabled-ng-select-input');
  }

  /**
   * If the tag is a SPAN, then
   * Hide it
   */
   handleSpan() {
    this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
  }

  /**
   * If the tag is a IMG, then
   * Hide it
   */
   handleImg() {
    this._renderer.setStyle(this._el.nativeElement, 'display', 'none');
  }
}
