import { Injectable } from '@angular/core';
import { OmsService } from './oms.service';

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagService {

  /**
   * Store the feature flags
   */
  public featureFlags = [];

  constructor(private omsService: OmsService) { }

  /**
   * Store the feature flags in the service variable
   */
  setFeatureFlags(featureFlags) {
    this.featureFlags = featureFlags || [];
  }

  /**
   * Get the feature flags data
   */
  getFeatureFlags(): string[] {
    return this.featureFlags;
  }

  /**
   * Get feature flag's value
   */
  getFeatureFlagValue(name) {
    const flag = this.featureFlags.find(flag => flag.name === name);
    return flag?.isActive || false;
  }
}
