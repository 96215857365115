import { Component, Input, OnInit } from "@angular/core";
import { BOOK_CLOSURE_TYPE_MAPPER, BUSINESS_UNIT } from "src/app/constants/book-closure";

@Component({
  selector: "app-book-closure-list-card",
  templateUrl: "./book-closure-list-card.component.html",
  styleUrls: ["./book-closure-list-card.component.scss"],
})
export class BookClosureListCardComponent implements OnInit {
  @Input() period: any = {};
  @Input() isSelected = false;
  type: any = {};

  constructor() {}

  ngOnInit(): void {
    this.setType();
  }

  /**
   * Concats first and last name and return full user name
   */
  get userName() {
    return `${this.period?.createdByDetails?.firstName || "--"} ${
      this.period?.createdByDetails?.lastName || ""
    }`;
  }

  /**
   * Get selected business units length
   */
  get selectedBusinessUnitsLength() {
    const selectedBusinessUnits = this.period?.levels?.filter(
      (level: any) => level?.level === BUSINESS_UNIT && level?.scopes?.length
    );
    return selectedBusinessUnits?.length;
  }

  /**
   * Set type
   */
  setType() {
    this.type = BOOK_CLOSURE_TYPE_MAPPER.find(
      (mapper: any) => mapper?.type === this.period?.type
    );
  }
}
