import { Component, HostListener, isDevMode, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { OmsService } from 'src/app/services/oms.service';
import { SocketService } from 'src/app/services/socket/socket.service';
import mixpanel from "mixpanel-browser";
import {
  environment
} from 'src/environments/environment';
// declare let gtag: Function;
declare var heap: any;
declare var pendo: any;
declare var posthog: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'oms-ui';
  byPassUrl: any = ['login', '/login', '/under-maintenance', 'under-maintenance'];
  previousUrl: string = ''; // URL prior to mixpanel URL
  eventUrl: string = ''; // URL on which mixpanel event is captured

  constructor(
    private socketService: SocketService,
    private router: Router,
    private _oms: OmsService
  ) { }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.keyCode === 91) {
      const company = this._oms.getSelectedCompany();
      this._oms.setSelectedCompanySlug(company);
    }
  }
  @HostListener('document:keyup', ['$event'])
  handleKeyboard(event: KeyboardEvent) {
    if (event.keyCode === 91) {
      const company = this._oms.getSelectedCompany();
      this._oms.setSelectedCompanySlug(company);
    }
  }
  ngOnInit() {
    console.log('inside app component------');
    mixpanel.init(environment.mixpanelToken, { debug: isDevMode(),persistence:'localStorage' })
    if (this._oms.getUserEmail() && this._oms.getUserName()) {
      mixpanel.identify(this._oms.getUserEmail());
      mixpanel.people.set({
        '$email': this._oms.getUserEmail(),
        '$name': this._oms.getUserName()
      });
      // Attach a unique identifier to a heap profile
      heap.identify(this._oms.getUserEmail());

      // Attach a unique identifier to a Pendo profile
      pendo.initialize({
        visitor: {
          id: this._oms.getUserEmail()
        }
      });

      // Attach a unique identifier to a postHog profile
      posthog.identify(
        this._oms.getUserEmail(),  // Replace 'distinct_id' with your user's unique identifier
      );
    }
    window.oncontextmenu = () => {
      const company = this._oms.getSelectedCompany();
      this._oms.setSelectedCompanySlug(company);
    };
    if (!this._oms.getSelectedCompany()) {
      this.router.navigate(['login']);
    }
    this.router.events.subscribe((event) => {
      const slug = this._oms.getSelectedCompany();
      // if (event['url'] && !this.byPassUrl.includes(event['url'])) {
      //   if (event['url'].includes('/company') && !event['url'].includes(slug)) {
      //     slug = event['url'].split('/')[2];
      //     if (slug) {
      //       this._oms.setSelectedCompany(slug);
      //     }
      //   }
      // }

      const routePrefix = `company/${slug}`;
      if (event instanceof NavigationStart) {
        if (!this.byPassUrl.includes(event['url']) && slug) {
          if (!(new RegExp(routePrefix)).test(event['url'])) {
            let eventUrl = String(event['url']);
            if (event['url'] === '' || event['url'] === '/') {
              eventUrl = `${eventUrl}contract/view/OPEN`;
            }
            this.router.navigateByUrl(routePrefix + eventUrl);
          }
        }
      }
    });
    if (isDevMode()) {
      console.log('👋 Development!');
    } else {
      console.log('💪 Production!');
    }

    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      this.trackPageViewsMixpanel(evt);
      window.scrollTo(0, 0);  // scroll to page top on route change.
    });

    this.initializeSocketConnection();
    this.checkForLocalStorage();
  }


  initializeSocketConnection() {
    this.socketService.initializeSocket();
  }

  checkForLocalStorage() {
    if (!this._oms.getUserId() || !this._oms.getAuthToken()) {
      if (localStorage) {
        localStorage.clear();
      }
      this.router.navigate(['login']);
    }
  }

  trackPageViewsMixpanel(event) {
    this.previousUrl = this.eventUrl;
    this.eventUrl = event.url;
    let slug = this._oms.getSelectedCompanyIdAndSlug()?.slug || '';
    let companyId = this._oms.getSelectedCompanyIdAndSlug()?.companyId || '';
    let userRoles = this._oms.getUserRole() ? this._oms.getUserRole().split(',') : [];
    let { url: previousUrl } = this.getUrlForMixpanel(this.previousUrl.split('/'), slug);
    let { url: eventUrl, zUUID } = this.getUrlForMixpanel(this.eventUrl.split('/'), slug);
    mixpanel.track('Page View', {
      ...(previousUrl !== '' ? { zPreviousUrl: previousUrl } : {}),
      ...(eventUrl !== '' ? { zEventUrl: eventUrl } : {}),
      ...(slug !== '' ? { zCompanySlug: slug } : {}),
      ...(companyId !== '' ? { zCompanyId: companyId } : {}),
      ...(Array.isArray(userRoles) && userRoles.length > 0 ? { zUserRoles: userRoles } : {}),
      ...(zUUID !== '' ? { zUUID } : {})
    });
  }

  getUrlForMixpanel(url: string[], slug: string) {
    let zUUID = '';
    let urlToArray = [];
    let alphabetRegex = new RegExp('^[a-zA-Z-]+$');
    let alphanumericRegex = new RegExp('^[a-zA-Z0-9]+$');
    for (let i = 0; i < url.length; i++) {
      let partial = url[i]
      if (partial === "company" || partial === slug) continue; // Ignore "company" and slug when creating the url.
      if (i === url.length - 1) partial = url[i].split('?')[0]; // Remove query params
      if (alphabetRegex.test(partial)) {
        urlToArray.push(partial);
      } else if (alphanumericRegex.test(partial) && zUUID === '') { // Assign to zUUID only if its empty string. For now we are sending only 1 zUUID.
        zUUID = partial;
      }
    }
    return {
      url: urlToArray.join('/'),
      zUUID
    };
  }

  // buildPageViewEvent(user, path) {
  //   let userId = '';
  //   if (user) {
  //     userId = user._id;
  //   }
  //   this.sendGAPageViewEvent(userId, path);
  // }

  // sendGAPageViewEvent(userId, path) {
  //   gtag('event', 'page_view', {
  //     page_path: path,
  //     'User Identifier': userId,
  //     user_id: userId
  //   });
  // }
}
