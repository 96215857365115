
import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { OmsService } from './services/oms.service';
import { environment } from '../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private _authService: OmsService,
  ) {}

  canActivate(): boolean {
    if (environment.maintenance) {
      this.router.navigate(['under-maintenance']);
      return false;
    }
    if (!this._authService.getAuthToken() || !this._authService.getSelectedCompany()) {
      this.router.navigate(['login']);
      return false;
    }
    // force logout if currency not found for company
    if (!this._authService.isCurrencyDetailsAvailabel()) {
      localStorage.clear();
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
