export const CONTRACT_DOCUMENT_TYPE = [{
  name: 'LOI',
  value: 'loiDetails',
  key: 'LOI',
  id: 1,
  labelName: {
    number: 'LOI Number',
    date: 'Date of LOI',
    value: 'LOI Value',
    totalValue: 'Total Value of LOI',
    revisedDate: 'Revised Date of LOI',
  },
  valueName: {
    number: 'loiNumber',
    date: 'loiDate',
    value: 'value',
    totalValue: 'totalValue'
  }
}, {
  name: 'Draft PO',
  key: 'DRAFT_PO',
  id: 2,
  value: 'draftPODetails',
  labelName: {
    number: 'Draft PO No.',
    date: 'Date of Draft PO',
    value: 'Draft PO Value',
    totalValue: 'Total Value of Draft PO',
    revisedDate: 'Revised Date of Draft PO',
  },
  valueName: {
    number: 'draftNumber',
    date: 'draftDate',
    value: 'value',
    totalValue: 'totalValue'
  }
}, {
  name: 'PO',
  value: 'poDetails',
  id: 3,
  key: 'PO',
  labelName: {
    number: 'PO Number',
    date: 'Date of PO',
    value: 'PO Value',
    totalValue: 'Total Value of  PO',
    dateOfDelivery: 'Expected delivery Date',
    revisedDate: 'Revised Date of PO',
  },
  valueName: {
    number: 'poNumber',
    date: 'poDate',
    value: 'value',
    totalValue: 'totalValue',
    dateOfDelivery: 'dateOfDelivery'
  }
}];

export const INVOICE_TYPE = {
  'TAX_INVOICE': 'tax-invoice',
  'PROFORMA_INVOICE': 'proforma-invoice'
};

export const CONTRACT_TABS = [
  {
    title: 'Open',
    status: 'OPEN',
    countKey: 'totalOpenContractCount',
    active: true
  },
  {
    title: 'In Process',
    status: 'INPROCESS',
    countKey: 'totalInProcessContractCount',
  },
  {
    title: 'Active',
    status: 'ACTIVE',
    countKey: 'totalActiveContractCount',
  },
  {
    title: 'Under Revision',
    status: 'REVISED',
    countKey: 'totalRevisedContractCount',
  },
  {
    title: 'Cancelled',
    status: 'CANCELLED',
    countKey: 'totalCancelledContractCount',
  }
];

export const STATUS_MAP = {
  CREATED: 'Contract Created',
  CLOSED: 'Contract Closed',
  CANCELLED: 'Contract Cancelled',
  LOI_CREATED: 'LOI Received',
  LOI_REVISED: 'LOI Revised',
  DRAFT_PO_CREATED: 'Draft PO Received',
  DRAFT_PO_REVISED: 'Draft PO Revised',
  PO_CREATED: 'PO Received',
  PO_REVISED: 'PO Revised',
  PO_ACKNOWLEDGED: 'PO Acknowledged',
  BILLING_STARTED: 'Billing Started',
  ORDER_RELEASED: 'Order Released',
  BILLING_STOPPED: 'Billing Stopped',
  REVISION_REQUESTED: 'CN Under Revision'
};

export const BANK_RECEIPT_TABS = [
  { name:'Receipts',title: 'Receipts', status: 'CREATED', active: true, countKey:'totalReceipts' }
];

export const CUSTOMER_INVOICE_DECLARATIONS = [
  {
    name: 'General Declaration',
    descriptions: [
      'We declare that this Invoice shows the actual price of the goods described and that all particulars are true and correct.'
    ]
  },
  {
    name: 'RoDTEP Declaration for Invoice',
    descriptions: [
      'I/We, in regard to my/our claim under RoDTEP scheme made in this Shipping Bill or Bill of Export, hereby declare that:',
      '1. I/ We undertake to abide by the provisions, including conditions, restrictions, exclusions and time-limits as provided under RoDTEP scheme, and  relevant notifications, regulations, etc., as amended from time to time.',
      '2   Any claim made in this shipping bill or bill of export is not with respect to any duties or taxes or levies which are exempted or remitted or credited under any other mechanism outside RoDTEP.',
      '3. I/We undertake to preserve and make available relevant documents relating to the exported goods for the purposes of audit in the manner and for the time period prescribed in the Customs Audit Regulations, 2018.'
    ]
  }
];

export const  COLUMN_DEFENITIONS =
[{
  'headerName': 'No.',
  'field': 'contractNumber',
  'widthInPercentage': 15,
  'filter': 'ColumnFilter',
  'columnType': 'subfield-tooltip',
  'subField': 'contractName',
  'subType': 'tooltip'
},
{
  'headerName': 'BU',
  'field': 'businessUnit',
  'widthInPercentage': 15,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
},
{
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
},
{
  'headerName': 'Customer',
  'field': 'customerName',
  'widthInPercentage': 17,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
},
{
  'headerName': 'PO No',
  'field': 'poNumber',
  'widthInPercentage': 20,
  'filter': 'ColumnFilter',
},
{
  'headerName': 'Taxed PO Value',
  'field': 'taxedPOValue',
  'widthInPercentage': 12,
  'columnType': 'currency',
  'symbol': 'currencySymbol'
},
{
  'headerName': 'Contract Status',
  'field': 'status',
  'widthInPercentage': 11,
  'columnType': 'status',
  'statusMap': STATUS_MAP,
  'filter': 'ColumnFilter',
}];

export const generalFabrication = ['Shop Fabrication', 'On Site Fabrication', 'On Site Erection', 'Raw Material/Bought Out', 'Drawing Services', 'Other Processes', 'Machining', 'Transport'];
export const water = ['Turnkey', 'Other Processes'];
export const railways = ['Shop Fabrication', 'On Site Fabrication', 'On Site Erection', 'Raw Material/Bought Out', 'Drawing Services', 'Other Processes', 'Machining', 'Transport'];
export const materialHandlingDevices = ['Turnkey', 'Shop Fabrication', 'On Site Fabrication', 'On Site Erection', 'Raw Material/Bought Out', 'Drawing Services', 'Other Processes', 'Machining', 'Transport'];
export const boiler = ['Shop Fabrication', 'On Site Erection'];
export const usMachining = ['Machining', 'Shop Fabrication', 'Casting', 'Injection Moulding', 'Sheet Metal Fabrication', 'Forging', 'Die Casting', 'PPE', 'Other Processes', 'Extrusion'];
export const others = ['Others', 'Raw Material/Bought Out', 'Turnkey', 'Other Processes'];
export const scaffolding = ['Shop Fabrication', 'Raw Material/Bought Out', 'Machining'];
export const healthCare = ['PPE', 'Ventilator', 'Sanitizer', 'Other Processes'];
export const oilAndGas = ['Turnkey', 'Shop Fabrication', 'On Site Fabrication', 'On Site Erection', 'Raw Material/Bought Out', 'Drawing Services', 'Other Processes', 'Machining', 'Transport', 'Casting', 'Injection Moulding', 'Sheet Metal Fabrication', 'Forging', 'Die Casting', 'PPE', 'Others', 'Ventilator', 'Sanitizer'];
export const onSite = ['On Site Fabrication', 'On Site Erection', 'Raw Material/Bought Out', 'Drawing Services', 'Other Processes', 'Machining', 'Transport'];
export const ecoSystem = ['Raw Material/Bought Out'];
export const TD = ['Shop Fabrication', 'On Site Fabrication', 'On Site Erection', 'Raw Material/Bought Out', 'Drawing Services', 'Other Processes', 'Machining', 'Transport'];
export const consumerGoods = ['Assembling', 'Manufacturing'];
export const apparel = ['Trading', 'Manufacturing'];

export enum CONTRACT_STATUS {
  CREATED = 'CREATED',
  CLOSED = 'CLOSED',
  CANCELLED = 'CANCELLED',
  LOI_CREATED = 'LOI_CREATED',
  LOI_REVISED = 'LOI_REVISED',
  DRAFT_PO_CREATED = 'DRAFT_PO_CREATED',
  DRAFT_PO_REVISED = 'DRAFT_PO_REVISED',
  PO_CREATED = 'PO_CREATED',
  PO_ACKNOWLEDGED = 'PO_ACKNOWLEDGED',
  PO_REVISED = 'PO_REVISED',
  BILLING_STARTED = 'BILLING_STARTED',
  ORDER_RELEASED = 'ORDER_RELEASED',
  BILLING_STOPPED = 'BILLING_STOPPED',
  REVISION_REQUESTED = 'REVISION_REQUESTED',
}

export const PAYMENT_RETENTION_MILESTONES = [
  'Completion of Order',
  'Completion of Supplies',
  'Receipt of material at site',
  'After DLP (Defect Liability Period)'
];

export const E_INVOICING_SUBTYPES = [
  {value: 'B2B', label: 'B2B-Business to Business'},
  {value: 'SEZWP', label: 'SEZWP-SEZ with payment'},
  {value: 'SEZWOP', label: 'SEZWOP-SEZ without payment'},
  {value: 'EXPWP', label: 'EXPWP-Export with Payment'},
  {value: 'EXPWOP', label: 'EXPWOP-Export without payment'},
  {value: 'DEXP', label: 'DEXP-Direct Export'}
];

export const CUSTOMER_TYPE = {
  'DOMESTIC': 'Domestic',
  'INTERNATIONAL': 'International',
  'RETAIL': 'Retail'
};


export const INVOICE_APPROVAL_STATUS = {
  PENDING: 'Under Review',
  REJECTED: 'Rejected',
  APPROVED: 'Approved'
};

export const VALID_CONTRACT_STATUS_FOR_CREATE_LC = [
  'PO_ACKNOWLEDGED',
  'BILLING_STARTED',
  'ORDER_RELEASED'
];

export const BUSINESS_TYPES = ['Managed Supply Chain', 'Upstream', 'Downstream', 'None'];

export const VALID_CONTRACT_STATUS_TO_FETCH_FOR_DC_RETURN_DISPATCH = [
    'CLOSED',
    'PO_ACKNOWLEDGED',
    'PO_REVISED',
    'BILLING_STARTED',
    'ORDER_RELEASED',
    'BILLING_STOPPED'
];


export const INVOICE_CONTRACT_FIELDS_TO_POPULATE = [
  {
    path: 'customerDetails',
    populate: [
      {
        path: 'countryDetails',
        select: 'name alpha2 region subRegion'
      },
      {
        path: 'billingAddresses.countryDetails',
        select: 'name alpha2 region subRegion'
      },
      {
        path: 'shippingAddresses.countryDetails',
        select: 'name alpha2 region subRegion'
      }
    ]
  },
  {
    path: 'orderDetails',
    populate: [{
      path: 'orderPODetails',
      select: 'items isDirectDelivery'
    }],
    select: 'orderPODetails',
    match: { status: { $ne: 'ORDER_CANCELLED' } }
  },
  'loiDetails.currencyDetails',
  'draftPODetails.currencyDetails',
  'poDetails.currencyDetails',
  'exchangeRateDetails',
  'createdByDetails',
  'updatedByDetails',
  'loiDetails.fileDetails',
  'draftPODetails.fileDetails',
  'poDetails.fileDetails',
  'poDetails.poAcknowledgedFileDetails',
  'loiDetails.history.fileDetails',
  'draftPODetails.history.fileDetails',
  'poDetails.history.fileDetails',
  'loiDetails.history.poAcknowledgedFileDetails',
  'draftPODetails.history.poAcknowledgedFileDetails',
  'poDetails.history.poAcknowledgedFileDetails',
  'advancePayments',
  'purchaseManagerDetails',
  'projectManagerDetails',
  'keyAccountManagerDetails',
  'shippingAddresses.countryDetails',
  'billingAddress.countryDetails',
  'salesPointOfContactDetails',
  'planningSpocDetails',
  'zetwerkDetail',
  'timeline.updatedByDetails',
  'scopeMatrixDetails',
  {
    path: 'projectTypeDetails',
    populate: [
      {
        path: 'milestoneDetails',
        select: 'name'
      },
      {
        path: 'paymentMethodDetails',
        select: 'name'
      },
      {
        path: 'documentRequiredDetails',
        select: 'name'
      },
    ]
  },
  'businessUnitDetails',
  {
    path: 'boqDetails',
    match: { status: 'APPROVED' }
  },
  {
    path: 'parentOrderDetails',
    select: '_id contractId supplierId orderNumber status',
    populate: [
      {
        path: 'contractDetails',
        select: '_id contractNumber customerId customerGroupId',
        populate: [
          {
            path: 'customerDetails',
            select: '_id name'
          }
        ]
      },
    ]
  },
  {
    path: 'parentContractDetails',
    select: 'contractNumber'
  },
  'siteDetails',
  'warehouseDetails'
]

export const DUMMY_ITEM_CODE = 'SO_Migrated';
