export const REQ_STATUS_MAPPER = {
  CREATED: {
    background: '#F0E4D9',
    color: '#B47944',
    label: 'CREATED',
    title: 'Created'
  },
  PENDING: {
    background: '#CACDD2',
    color: '#7D7D7D',
    label: 'PENDING',
    title: 'Pending'
  },
  SENT_FOR_APPROVAL: {
    background: '#D2DFE1',
    color: '#2B6667',
    label: 'SENT FOR APPROVAL',
    title: 'Sent For Approval'
  },
  APPROVED: {
    background: '#CDE3CD',
    color: '#368F36',
    label: 'APPROVED',
    title: 'Approved'
  },
  REJECTED: {
    background: '#F2D2C8',
    color: '#CC4B24',
    label: 'REJECTED',
    title: 'Rejected'
  },
  ON_HOLD: {
    background: '#FBE3C5',
    color: '#ED8D17',
    label: 'ON HOLD',
    title: 'On Hold'
  },
  OVERDUE: {
    background: '#F2D2C8',
    color: '#CC4B24',
    label: 'OVERDUE',
    title: 'Overdue'
  },
    WAITING: {
    background: '#CACDD2',
    color: '#7D7D7D',
    label: 'WAITING FOR APPROVAL',
    title: 'Waiting for Approval'
  },
};

export const PRIORITY_MAPPER = {
  LOW: {
    background: '#CDE3CD',
    color: '#368F36',
    label: 'LOW'
  },
  MEDIUM: {
    background: '#FAF0C2',
    color: '#8E7507',
    label: 'MEDIUM'
  },
  HIGH: {
    background: '#F2D2C8',
    color: '#CC4B24',
    label: 'HIGH'
  }
};

export const STATUS_MAPPER = {
  CREATED: 'CREATED',
  ON_HOLD: 'ON_HOLD',
  REJECTED: 'REJECTED',
  APPROVED: 'APPROVED',
  PENDING: 'PENDING'
};
