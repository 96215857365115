import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  HostListener
} from '@angular/core';
import {
  PlatformLocation
} from '@angular/common';
import {
  Route,
  ActivatedRoute
} from '@angular/router';
import {
  BsModalRef
} from 'ngx-bootstrap/modal';
import {
  Subscription
} from 'rxjs';

@Component({
  selector: 'app-pdf-file-preview',
  templateUrl: './pdf-file-preview.component.html',
  styleUrls: ['./pdf-file-preview.component.scss']
})
export class PdfFilePreviewComponent implements OnInit {

  @Input() list: any = [];
  @Output() action = new EventEmitter();
  fileUrl;
  fileMimeType;
  currentIndex;
  showNavigation;
  fileName;
  public routeSubsription: Subscription;

  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    switch (event.keyCode) {
      case 37:
        // left
        this.handleNavigation('previous');
        break;
      case 39:
        // right
        this.handleNavigation('next');
        break;
    }
  }

  constructor(
    public modalRef: BsModalRef,
    public router: ActivatedRoute,
    public location: PlatformLocation) {

    this.routeSubsription = this.router.params.subscribe(params => {
      location.onPopState(() => {
        this.modalRef.hide();
      });
    });

  }

  ngDestroy() {
      this.routeSubsription.unsubscribe();
  }

  ngOnInit() {
    this.fileUrl = this.list[0];
    this.fileMimeType = this.list[1];
    this.currentIndex = this.list[2];
    this.showNavigation = this.list[3];
    this.fileName = this.list[4];
  }

  handleNavigation(direction: string) {
    const data = {
      direction: direction,
      currentIndex: this.currentIndex
    };
    this.action.emit(data);
  }

}
