import { IFilterOptions, ITableOptions, ListType } from "@zetwerk/zet-list";
import { DatePipe } from "@angular/common";

const PAGINATION_SIZE = 10;

export const OMS_RECEIPTS_TABS = [
  {
    title: "Direct Receipts",
    status: "OMS_ALLOCATED",
    active: true,
  },
  {
    title: "Under Verification",
    status: "UNDER_VERIFICATION",
    active: false,
  },
  {
    title: "Draft",
    status: "DRAFT",
    active: false,
  },
  {
    title: "Rejected",
    status: "REJECTED",
    active: false,
  },
  {
    title: "Cancelled",
    status: "CANCELLED",
    active: false,
  },
  {
    title: "Reversed",
    status: "REVERSED",
    active: false,
  },
];


export const OMS_RECEIPTS_TABLE_OPTIONS: ITableOptions = {
  type: ListType.CARD,
  rowHeight: 50,
  paginationSize: PAGINATION_SIZE,
  syncURL: true,
  isRowSelectable: false,
  enableFilterBtn: true,
  clearSelection: true,
};


export const OMS_RECEIPTS_FILTER_OPTIONS: Array<IFilterOptions> = [
  {
    id: "transactionNumber",
    title: "Transaction Number",
    type: "multiselect",
    bindLabel: "transactionNumber",
    bindValue: "transactionNumber",
    filterModelType: 'serverSide'
  },
  {
    id: "bank",
    title: "Select Bank",
    type: "multiselect",
    bindLabel: "bankName",
    bindValue: "bankName",
  },
  {
    id: "bankAccount",
    title: "Select Bank Account",
    type: "multiselect",
    bindLabel: "accountNo",
    bindValue: "accountNo",
  },
  {
    id: 'customer',
    title: 'Customer',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
  {
    id: 'supplier',
    title: 'Supplier',
    type: 'multiselect',
    bindLabel: 'supplierName',
    bindValue: '_id',
    filterModelType: 'serverSide'
  },
  {
    id: 'orderNumber',
    title: 'Order No.',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide',
  },
  {
    id: 'contractNumber',
    title: 'Contract No.',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
    filterModelType: 'serverSide',
  },
  {
    id: 'paymentMode',
    title: 'Payment Mode',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
  },
  {
    id: 'unallocated',
    title: 'Contains Unallocated',
    type: 'multiselect',
    bindLabel: 'name',
    bindValue: '_id',
  },
];

export const OMS_ALLOCATED_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Yesterday",
    status: "YESTERDAY",
    active: false,
  },
];

export const CANCELLED_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Yesterday",
    status: "YESTERDAY",
    active: false,
  },
];

export const UNDER_VERIFICATION_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Yesterday",
    status: "YESTERDAY",
    active: false,
  },
];

export const DRAFT_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Yesterday",
    status: "YESTERDAY",
    active: false,
  },
];

export const REJECTED_QUICK_FILTERS = [
  {
    title: "All",
    status: "ALL",
    active: true,
  },
  {
    title: "Yesterday",
    status: "YESTERDAY",
    active: false,
  },
];

export const REVERSED_QUICK_FILTERS = [
  {
    title: 'Direct',
    status: 'OMS',
    active: true
  },
  {
    title: 'Indirect',
    status: 'NON_OMS',
    active: false
  }
];

export const PAYMENT_MODE_MAPPER: any[] = [
  {
    name: "LC",
    _id: "LC",
  },
  {
    name: "Bank",
    _id: "BANK",
  },
  {
    name: "AR & AP Adjustment",
    _id: "AR & AP adjustment",
  },
  {
    name: "AR & AP Adjustment (CN/DN)",
    _id: "AR & AP adjustment (CN/DN)",
  },
  {
    name: "Cheque",
    _id: "CHEQUE",
  },
  {
    name: "DD",
    _id: "DD",
  },
  {
    name: "BG",
    _id: "BG",
  },
  {
    name: "Invoice Discounting",
    _id: "INVOICE_DISCOUNTING",
  },
  // {
  //   name: "JV Entry",
  //   _id: "JV_ENTRY",
  // },
  {
    name: "TDS Receivable",
    _id: "TDS receivable",
  },
];

export const STATUS_MAPPER: any = [
  {
    status: "OMS_ALLOCATED",
    text: "Direct Receipt",
    class: "oms-allocated",
    icon: "oms-allocated",
  },
  {
    status: "UNDER_VERIFICATION",
    text: "Under Verification",
    class: "under-verification",
    icon: "under-verification",
  },
  {
    status: "DRAFT",
    text: "Draft",
    class: "draft",
    icon: "draft",
  },
  {
    status: "REJECTED",
    text: "Rejected",
    class: "rejected",
    icon: "rejected",
  },
  {
    status: "CANCELLED",
    text: "Cancelled",
    class: "cancelled",
    icon: "cancelled",
  }
];

export const PAYMENT_MODE_ICON_MAPPER: any = [
  {
    paymentMode: "BANK",
    text: "Bank",
    class: "bank",
    icon: "bank",
  },
  {
    paymentMode: "LC",
    text: "LC",
    class: "lc",
    icon: "lc",
  },
  {
    paymentMode: "TDS receivable",
    text: "TDS",
    class: "tds",
    icon: "tds",
  },
  {
    paymentMode: "AR & AP adjustment",
    text: "AR & AP adjustment",
    class: "ar-ap",
    icon: "ar-ap",
  },
  {
    paymentMode: "CHEQUE",
    text: "Cheque",
    class: "cheque",
    icon: "cheque",
  },
  {
    paymentMode: "DD",
    text: "DD",
    class: "dd",
    icon: "dd",
  },
  {
    paymentMode: "JV_ENTRY",
    text: "JV Entry",
    class: "jv-entry",
    icon: "jv-entry",
  },
  {
    paymentMode: "BG",
    text: "BG",
    class: "bg",
    icon: "bg",
  },
  {
    paymentMode: "INVOICE_DISCOUNTING",
    text: "Invoice Discounting",
    class: "invoice-discounting",
    icon: "invoice-discounting",
  },
];

export const ACTION_LIST: any[] = [
  {
    label: "Create AR/AP (CN/DN)",
    value: "ADJUSTMENTS_NEW",
    showNewLabel: true
  },
  {
    label: "Create AR/AP",
    value: "ADJUSTMENTS",
  },
  {
    label: "Create Draft Receipt",
    value: "DRAFT_RECEIPT",
  },
];

export const PAYABLE_TYPE = {
  CREDIT_NOTE: 'CREDIT_NOTE',
  BILL: 'BILL',
  TAX_INVOICE: 'TAX_INVOICE'
}

export const RECEIVABLE_TYPE = {
  DEBIT_NOTE: 'DEBIT_NOTE',
  TAX_INVOICE: 'TAX_INVOICE',
  BILL: 'BILL'
}

export const AR_AP_REASONS = [
    'Customer Refund',
    'Wrong Receipt Marking',
    'Advance/AP Knockoff',
    'Advance/AR Knockoff',
    'Multi-customer marking',
    'Buy/Sell Motilal (Brokerage)',
    'Van',
    'Unclaimed',
];

export enum RECEIPTS_STATUS_ENUM {
  OMS_ALLOCATED = 'OMS_ALLOCATED',
  DRAFT = 'DRAFT',
  UNDER_VERIFICATION = 'UNDER_VERIFICATION',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  REVERSED = 'REVERSED',
};

export enum TRANSACTION_TYPES_IN_INVOICE {
  AR_AP_ADJUSTMENTS = 'AR_AP_ADJUSTMENTS',
  PAYMENT_RECEIVED = 'PAYMENT_RECEIVED'
}

export enum PAYMENT_MODES {
  TDS_RECEIVABLE = 'TDS receivable',
  AR_AP_ADJUSTMENTS = 'AR & AP adjustment',
}

export const AR_AP_REASONS_META_MAPPING = {
  'Customer Refund': {
    allowSameCustomer: true
  }
}
