<div class="thanks-form">
<div class="thankyou-feedback" *ngIf="!isSubmitted">
  <div class="logo">
    <img src="/assets/zetwerk-logo.svg" alt="">
  </div>
  <div class="thankyou">
    <div>
      <img src="/assets/emojis/Thankyou.svg" width="40" height="40">
    </div>
    <div class="text">
      Thank You
    </div>
    <div class="text-desc">
      We are always looking for ways to improve our service and we truely value your honest feedback.
      <br />
      Looking forward to your continued support.
    </div>
  </div>
</div>

<div class="thankyou-repeat-feedback" *ngIf="isSubmitted">
  <div class="logo">
    <img src="/assets/zetwerk-logo.svg" alt="">
  </div>
  <div class="thankyou">
    <div class="check-circle" *ngIf="isSubmitted">
      <i class="fa fa-check-circle" aria-hidden="true"></i>
    </div>
    <div class="text">
      You have already submitted the feedback.
    </div>
    <div class="text-desc">
     We are hard at work implementing insights we received from you feedback.
    </div>
    <div class="text-desc">
     Keep an eye out for awesome updates to our service.
    </div>
  </div>
</div>
</div>

