// Book Closure constants
import { ITableOptions, ListType } from "@zetwerk/zet-list";

export const BU_SCOPES_MAPPER = {
  SUPPLIER_BILL: "SUPPLIER_BILL",
  PAYMENTS_VFS: "PAYMENTS_VFS",
  PAYMENTS_LETTER_OF_CREDIT: "PAYMENTS_LETTER_OF_CREDIT",
  PAYMENTS_PDC: "PAYMENTS_PDC",
  PAYMENTS_OLD: "PAYMENTS_OLD",
  SUPPLIER_CREDIT_NOTES: "SUPPLIER_CREDIT_NOTES",
  SUPPLIER_DEBIT_NOTES: "SUPPLIER_DEBIT_NOTES",
  CUSTOMER_INVOICE: "CUSTOMER_INVOICE",
  CUSTOMER_CREDIT_NOTES: "CUSTOMER_CREDIT_NOTES",
  CUSTOMER_DEBIT_NOTES: "CUSTOMER_DEBIT_NOTES",
  LC_REPAYMENT: "LC_REPAYMENT"
};

export const ENTITY = "ENTITY";
export const BUSINESS_UNIT = "BUSINESS_UNIT";

export const AUTO = "AUTO";
export const MANUAL = "MANUAL";

export const OPEN = "OPEN";
export const CLOSED = "CLOSED";

export const PAGINATION_SIZE = 10;

export const BOOK_CLOSURE_TABS = [
  {
    title: "Open",
    status: "OPEN",
    active: true,
  },
  {
    title: "Closed",
    status: "CLOSED",
    active: false,
  },
];

export const BU_SCOPES = {
  SUPPLIER_BILL: "Supplier Bill",
  PAYMENTS_VFS: "Payments - VFS",
  PAYMENTS_LETTER_OF_CREDIT: "Payments - Letter of Credit",
  PAYMENTS_PDC: "Payments - PDC",
  PAYMENTS_OLD: "Payments - Old",
  SUPPLIER_CREDIT_NOTES: "Supplier Credit Notes",
  SUPPLIER_DEBIT_NOTES: "Supplier Debit Notes",
  CUSTOMER_INVOICE: "Customer Invoice",
  CUSTOMER_CREDIT_NOTES: "Customer Credit Notes",
  CUSTOMER_DEBIT_NOTES: "Customer Debit Notes",
  LC_REPAYMENT: 'LC Repayment'
};

export const ENTITY_SCOPES = {
  COLLECTION: "Collection",
  VFS_REPAYMENT: "VFS - Repayment",
};

export const ENTITY_SCOPES_MAPPER = {
  COLLECTION: "COLLECTION",
  VFS_REPAYMENT: "VFS_REPAYMENT",
};

export const BOOK_CLOSURE_TABLE_OPTIONS: ITableOptions = {
  type: ListType.CARD,
  rowHeight: 50,
  paginationSize: PAGINATION_SIZE,
  syncURL: true,
  isRowSelectable: false,
  enableFilterBtn: false,
  clearSelection: true,
  rowModelType: "infinite",
};

export const BOOK_CLOSURE_TYPE_MAPPER: any = [
  {
    type: "AUTO",
    text: "Auto",
    class: "auto",
    icon: "auto",
  },
  {
    type: "MANUAL",
    text: "Manual",
    class: "manual",
    icon: "manual",
  },
];

export const BACKDATING_ENABLED_ARTEFACT = ['CUSTOMER_INVOICE', 'CUSTOMER_CREDIT_NOTES', 'CUSTOMER_DEBIT_NOTES'];
