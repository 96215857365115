export class TabModel {
    public title: string;
    public name: string;
    public active: boolean;
}

export class OptionModel {
    constructor(public label: string, public value: any) {
    }
}
