import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_URL = environment.apiEndPoint;

@Injectable({
  providedIn: 'root'
})
export class NewPaymentService {

  constructor(private http: HttpClient) { }
  prefix = 'payment-requests/';
  private handleSuccess(response: HttpResponse<any>) {
    if (response.status === 200) {
      return { 'body': response.body, 'headers': response.headers };
    }
    return JSON.parse(response.body);
  }

  createPaymentRequest(data) {
    return this.http.post(API_URL + this.prefix, data);
  }

  resubmitPaymentRequest(paymentRequestId, data) {
    return this.http.put(API_URL + this.prefix + paymentRequestId + '/resubmit', data);
  }

  getPaymentRequestById(paymentRequestId, urlParams = {}) {
    return this.http.get(API_URL + this.prefix + paymentRequestId, {
      params: urlParams
    });
  }

  verifyDocument(paymentId, documentId, activeDoc = '') {
    return this.http.put(API_URL + this.prefix + paymentId + '/documents/' + documentId + '/verify', {});
  }

  rejectDocument(data, paymentId, documentId) {
    return this.http.put(API_URL + this.prefix + paymentId + '/documents/' + documentId + '/reject', data);
  }

  sendForPayment(paymentId) {
    return this.http.put(API_URL + this.prefix + paymentId + '/send-payment', {});
  }

  rejectPayment(paymentId) {
    return this.http.put(API_URL + this.prefix + paymentId + '/reject', {});
  }
  uploadFile(data) {
    return this.http.post(API_URL + 'files', data);
  }

  updateDocument(data, paymentId, documentId) {
    return this.http.put(API_URL + this.prefix + paymentId + '/documents/' + documentId, data);
  }

  createPaymentScheduleForPR(paymentRequestId,data){
    return this.http.post(API_URL + this.prefix + paymentRequestId +'/payment-schedule',data);
  }

  editPaymentScheduleForPR(paymentRequestId,data){
    return this.http.put(API_URL + this.prefix + paymentRequestId +'/payment-schedule',data);
  }

  holdPaymentRequest(paymentRequestId){
    return this.http.put(API_URL + this.prefix + paymentRequestId +'/hold-payment-request',{});
  }

  deletePR(paymentRequestId){
    return this.http.put(
      API_URL + this.prefix + paymentRequestId + '/delete', {} );
  }

  prValueForPaymentRequest(data) {
    return this.http.post(API_URL + this.prefix + "/pr-value", data);
  }

}
