import {Component, Input, OnDestroy, OnInit,  ChangeDetectorRef,} from '@angular/core';
import {Subscription} from 'rxjs';
import {LoaderService} from '../../../services/loader/loader.service';
import {LoaderState} from '../../../services/loader/loader';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  @Input() loaded: any = false;
  private subscription: Subscription;
  constructor(private loaderService: LoaderService,   private changeDetector: ChangeDetectorRef) { }

  ngOnInit() {
    this.subscription = this.loaderService.loaderState
      .subscribe((state: LoaderState) => {
        this.loaded = state.loaded;
        this.changeDetector.detectChanges();
      });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
