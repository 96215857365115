import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-status-notification',
  templateUrl: './status-notification.component.html',
  styleUrls: ['./status-notification.component.scss']
})
export class StatusNotificationComponent implements OnInit {

  @Input() successStatus: boolean;
  @Input() message: any = '';
  @Output() action = new EventEmitter();

  constructor(public modalRef: BsModalRef) { }

  ngOnInit() {
  }

  public close() {
    this.modalRef.hide();
    this.action.emit('closed');
  }

}
