import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { timer } from 'rxjs';
import { PreviewFile } from './../../../models/file-preview';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit {

  previewFile: PreviewFile;
  msg: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.activatedRoute.queryParams.forEach(qParams => {
      let { path, fileName, fileType } = qParams;
      fileName = ['', null, 'undefined', undefined].includes(fileName) ? (path ? path.split('/').pop() : null) : fileName;
      if (path) {
        if (fileType === 'application/pdf') {
          this.previewFile = { path, fileName, fileType };
        } else {
          window.open(path, '_self');
          const timerSubs = timer(2000).subscribe(_ => {
            window.close();
            timerSubs.unsubscribe();
          });
        }
      } else {
        this.msg = 'No path or Invalid file path!';
      }

    });
  }

}

