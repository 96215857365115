import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class FilterService {

  private _filter: Map<string, any> = new Map();
  selectedTransactionData =  new BehaviorSubject<any>(null);


  constructor() { }

  getFilter(key: string) {
    return this._filter[key];
  }

  setFilter(key: string, filter: any) {
    this._filter[key] = filter;
  }

  getTransactionDetails(data) {
    this.selectedTransactionData.next(data);
  }
}
