import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';

@Component({
  selector: 'app-link-cell',
  templateUrl: './link-cell.component.html',
  styleUrls: ['./link-cell.component.scss']
})
export class LinkCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {
  }
}
