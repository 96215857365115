import { Routes } from '@angular/router';
import { AuthGuard } from '../app/auth.guard';
import { ContractComponent } from './components/contract/contract.component';
import { CustomerFeedbackFormComponent } from './components/customer-feedback-form/customer-feedback-form.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { SupplierFeedbackFormComponent } from './components/supplier-feedback-form/supplier-feedback-form.component';
import { ThankyouFormComponent } from './components/thankyou-form/thankyou-form.component';
import { UnderMaintainenceComponent } from './components/under-maintainence/under-maintainence.component';
import { PermissionsGuard } from './permissions.guard';
import { FileViewComponent } from './components/general/file-view/file-view.component';
import { RedirectPathComponent } from './components/shared/redirect-path/redirect-path.component';
import { ItemsComponent } from './components/items/items.component';
import { BookClosureComponent } from './components/settings/book-closure/book-closure.component';


export const APP_ROUTES: Routes = [{
  path: '',
  redirectTo: 'contract/view/OPEN',
  pathMatch: 'full'
},

{
  path: 'login',
  component: LoginComponent,
},
{
  path: 'under-maintenance',
  component: UnderMaintainenceComponent
},
{
  path: 'redirect-path',
  component: RedirectPathComponent
},
{
  path: 'file-view',
  component: FileViewComponent
},
{
  path: 'company/:slug/supplier-feedback/:feedbackId',
  component: SupplierFeedbackFormComponent,
},
{
  path: 'company/:slug/customer-feedback/:feedbackId',
  component: CustomerFeedbackFormComponent,
},
{
  path: 'thankyou',
  component: ThankyouFormComponent,
},
{
  path: 'company/:slug',
  component: DashboardComponent,
  canActivate: [AuthGuard],
  children: [{
    path: '',
    component: ContractComponent,
  },
  {
    path: 'contract',
    loadChildren: () => import('./components/contract/contract.module').then(m => m.ContractModule)
  },
  {
    path: 'order',
    loadChildren: () => import('./components/order/order.module').then(m => m.OrderModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./components/payment/payment.module').then(m => m.PaymentModule)
  },
  {
    path: 'customer-groups',
    loadChildren: () => import('./components/customer-groups/customer-groups.module').then(m => m.CustomerGroupsModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_CUSTOMERS']
    }
  },
  {
    path: 'customers',
    loadChildren: () => import('./components/customers/customer.module').then(m => m.CustomerModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_CUSTOMERS']
    }
  },
  {
    path:'product-master',
    loadChildren: () => import('./components/items/items.module').then(m => m.ItemsModule),
  },
  {
    path: 'users',
    loadChildren: () => import('./components/users/user.module').then(m => m.UserModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_USERS_MAIN']
    }
  },
  {
    path: 'rate',
    loadChildren: () => import('./components/currency-exchange-rate/currency.module').then(m => m.CurrencyModule)
  },
  {
    path: 'metrics',
    loadChildren: () => import('./components/metrics/metric.module').then(m => m.MetricModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_REPORTS_MAIN']
    }
  },
  {
    path: 'supplier-groups',
    loadChildren: () => import('./components/supplier-groups/supplier-groups.module').then(m => m.SupplierGroupsModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_SUPPLIERS_MAIN']
    }
  },
  {
    path: 'suppliers',
    loadChildren: () => import('./components/suppliers/supplier.module').then(m => m.SupplierModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_SUPPLIERS_MAIN']
    }
  },
  {
    path: 'loan',
    loadChildren: () => import('./components/loan/loan.module').then(m => m.LoanModule)
  },
  {
    path: 'finance',
    loadChildren: () => import('./components/finance/finance.module').then(m => m.FinanceModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['VIEW_FINANCE_MAIN']
    }
  },
  {
    path: 'bills',
    loadChildren: () => import('./components/bills/bills.module').then(m => m.BillsModule)
  },
  {
    path: 'gst',
    loadChildren: () => import('./components/gst/gst.module').then(m => m.GSTModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./components/settings/settings.module').then(m => m.SettingsModule),
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['MANAGE_OMS_SETTINGS']
    }
  },
  {
    path: 'workflow',
    loadChildren: () => import('./components/workflow/workflow.module').then(m => m.WorkflowModule)
  },
  {
    path: 'boq',
    loadChildren: () => import('./components/boq/boq.module').then(m => m.BoqModule)
  },
  {
    path: 'vfs',
    loadChildren: () => import('./components/vfs/vfs.module').then(m => m.VfsModule)
  },
  {
    path: 'payment-ticket',
    loadChildren: () => import('./components/payment-ticket/payment-ticket.module').then(m => m.PaymentTicketModule)
  },
  {
    path: 'collections',
    loadChildren: () => import('./components/collections/collections.module').then(m => m.CollectionsModule)
  },
  {
    path: 'approvals',
    loadChildren: () => import('./components/approvals/approvals.module').then(m => m.ApprovalsModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('./components/inventory/inventory.module').then(m => m.InventoryModule)
  },
  {
    path: 'book-closure/:status',
    canActivate: [PermissionsGuard],
    data: {
      permissions: ['FINANCE_BOOK_CLOSURE'],
    },
    component: BookClosureComponent
  }
  ]
},

];
