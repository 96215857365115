import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

const API_URL = environment.apiEndPoint;

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  /**
   * Declare operator
   */
  public OP: any;
  /**
   * Store the user permissions
   */
  public permissions: string[] = [];

  constructor(private _http: HttpClient) {
    this.OP = {
      /**
       * If op = AND
       * check user has all of the permissions in required permissions
       */
      AND: (p: Array<string>) => {
        return p.every(permission => this.hasPermission(permission));
      },
      /**
       * If op = OR
       * check user has any of the permissions in required permissions
       */
      OR: (p: Array<string>) => {
        return p.some(permission => this.hasPermission(permission));
      }
    };
  }

  /**
   * Return the operator defenition
   */
  getOP() {
    return this.OP;
  }

  /**
   * Check whether specific permission available for user
   */
  hasPermission(permission: string) {
    return this.getPermissions().indexOf(permission) > -1;
  }

  /**
   * Check whether user has permission as per logical operator
   */
  userHasPermission(data) {
    let op = 'AND';
    if (data.operator) {
      op = data.operator;
    }
    return this.OP[op](data.permissions);
  }

  /**
   * Store the user permissions in the service variable
   */
  setPermissions(permissions) {
    this.permissions = permissions.map(permission => permission.name);
  }

  /**
   * Get the user permissions from the localStorage
   */
  getPermissions(): string[] {
    return this.permissions;
  }

  /**
   * Get user permissions from API
   */
  public getUserPermissions() {
    return this._http.get(`${API_URL}permissions`);
  }
}
