import { IColumnDef } from "@zetwerk/zet-list";

export const STATUS_MAP = {
  ORDER_CREATED: 'Order Created',
  ORDER_CANCELLED: 'Order Cancelled',
  PO_CREATED: 'PO Created',
  PO_DRAFT_CREATED: 'PO Draft Created',
  PO_ACKNOWLEDGED: 'PO Acknowledged',
  PO_CANCELLED: 'PO Cancelled',
  PO_REVISED: 'PO Revised',
  PM_ASSIGNED: 'PM Assigned',
  BILLING_STARTED: 'Billing Started',
  BILLING_STOPPED: 'Billing Stopped',
  ORDER_CLOSED: 'Order Closed',
  PO_APPROVED: 'PO Acknowledge Pending',
  PO_PENDING_APPROVAL: 'PO Pending Approval',
  PO_REJECTED: 'PO Rejected'
};

export const STATUS_MAP_PRODUCTION_ORDER = {
  ORDER_CREATED: 'Order Created',
  ORDER_CANCELLED: 'Order Cancelled',
  PO_CREATED: 'PO Created',
  PO_DRAFT_CREATED: 'PO Draft Created',
  PO_ACKNOWLEDGED: 'PO Acknowledged',
  PO_CANCELLED: 'PO Cancelled',
  PO_REVISED: 'PO Revised',
  PM_ASSIGNED: 'PM Assigned',
  BILLING_STARTED: 'Billing Started',
  BILLING_STOPPED: 'Billing Stopped',
  ORDER_CLOSED: 'Order Closed',
  PO_APPROVED: 'PO Acknowledge Pending',
  PO_PENDING_APPROVAL: 'PO Pending Approval',
  PO_REJECTED: 'PO Rejected',
  ACKNOWLEDGED: 'Acknowledged',
  IN_PROGRESS: 'PO Created'
};

export const noteShowStatus = ['PM_ASSIGNED', 'BILLING_STARTED', 'BILLING_STOPPED', 'PO_ACKNOWLEDGED'];
export const noteShowStatusForCreditDebitNoteButtons = ['PM_ASSIGNED', 'BILLING_STARTED', 'BILLING_STOPPED'];

export const ORDER_STATUS = {
  CREATED: 'ORDER_CREATED',
  CANCELLED:'ORDER_CANCELLED',
  PO_PENDING_APPROVAL: 'PO_PENDING_APPROVAL',
  PO_APPROVED: 'PO_APPROVED',
  PO_DRAFT_CREATED: 'PO_DRAFT_CREATED',
  PO_ACKNOWLEDGED: 'PO_ACKNOWLEDGED',
  PO_REJECTED: 'PO_REJECTED',
  PM_ASSIGNED: 'PM_ASSIGNED',
  BILLING_STARTED: 'BILLING_STARTED',
  BILLING_STOPPED: 'BILLING_STOPPED',
  CLOSED: 'ORDER_CLOSED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const COLUMN_DEFENITIONS = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Order Type',
  'field': 'orderType',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'BU',
  'field': 'businessUnit',
  'widthInPercentage': 12,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 16,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
}, {
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
}, {
  'headerName': 'Contract/Customer Name',
  'field': 'contractNumber',
  'widthInPercentage': 18,
  'filter': 'ColumnFilter',
  'columnType': 'subfield-tooltip',
  'subField': 'customerName'
}, {
  'headerName': 'PO Ack. Date',
  'field': 'poAcknowledgedDate',
  'widthInPercentage': 12,
  'columnType': 'subDate',
  'subField': 'taxedPOValue',
  'subType': 'currency'
}, {
  'headerName': 'Status',
  'field': 'status',
  'widthInPercentage': 12,
  'columnType': 'status',
  'statusMap': STATUS_MAP,
  'filter': 'ColumnFilter',
}];

export const COLUMN_DEFENITIONS2: Array<IColumnDef> = [
  {
    field: 'orderNumber',
    headerName: 'Order No.',
    cellClass: 'cell-center',
    width: 120,
    valueGetter: (data) => {
      return (
        (data.orderNumber) || ''
      );
    }
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    cellClass: 'cell-center',
    width: 150,
    valueGetter: (data) => {
      return (
        (data.orderType) || ''
      );
    }
  },
  {
    field: 'businessUnit',
    headerName: 'BU',
    cellClass: 'cell-center',
    width: 130,
    valueGetter: (data) => {
      return (
        (data.businessUnit) || ''
      )
    }
  },
  {
    field: 'supplierName',
    headerName: 'Supplier Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const res = {
        text: data.supplierName || '',
        subText: data.supplierLocation || '',
      }
      return res;
    }
  },
  {
    field: 'gst',
    headerName: 'GST',
    cellClass: 'cell-center',
    width: 120,
    valueGetter: (data) => {
      return (
        (data.zetwerkGst) || ''
      )
    }
  },
  {
    field: 'contractNumber',
    headerName: 'Contract/Customer Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 180,
    valueGetter: (data) => {
      const res = {
        text: data.contractNumber,
        subText: data.customerName,
      }
      return res;
    }
  },
  {
    field: 'poAcknowledgedDate',
    headerName: 'PO Ack. Date',
    cellClass: 'cell-center',
    cellRenderer: 'dateSubCurrencyCellRenderer',
    width: 130,
    valueGetter: (data) => {
      const res = {
        text: data.poAcknowledgedDate || '',
        subText: data.taxedPOValue || '',
        currencySymbol: (data.symbol || '&#8377;')
      }
      return res;
    }
  },
  {
    field: 'status',
    headerName: 'Status',
    cellClass: 'cell-center',
    cellRenderer: 'statusCellRenderer',
    width: 140,
    valueGetter: (data) => {
      const res = {
        status: '',
        statusMap: {}
      };
      res['status'] = data.status;
      res['statusMap'] = STATUS_MAP
      return res;
    }
  },
  // {
  //   field: 'taxedPOValue',
  //   headerName: 'Taxed PO Value',
  //   cellClass: 'cell-center',
  //   cellRenderer: 'currencyCellRenderer',
  //   width: 140,
  //   valueGetter: (data) => {
  //     const res = {
  //       currencySymbol: '',
  //       amount: '',
  //     }
  //     res['currencySymbol'] = (data.symbol || '&#8377;');
  //     res['amount'] = (data.taxedPOValue);
  //     return res;
  //   }
  // },
];

export const ORDER_TABS = [{
  name: 'pendingpo',
  title: 'Pending PO',
  countKey: 'totalPendingPOCount',
  active: true
},
{
  name: 'pendingapproval',
  title: 'Pending Approval',
  countKey: 'totalPendingApprovalCount',
  // active: true
},
{
  name: 'pendingacknowledgement',
  title: 'Pending Acknowledgement',
  countKey: 'totalPendingAcknowledgementCount',
  // active: true
},
{
  name: 'inprocess',
  title: 'In-Process',
  countKey: 'totalInProcessOrdersCount'
},
{
  name: 'active',
  title: 'Active',
  countKey: 'totalActiveOrdersCount',
},
{
  name: 'cancelled',
  title: 'Cancelled',
  countKey: 'totalCancelledOrdersCount',
},
{
  name: 'closed',
  title: 'Closed',
  countKey: 'totalClosedOrdersCount',
}
];

export const PO_TERMS = [
  {
    name: 'Add Payment Terms',
    include: false,
    basedOnMilestone: false,
  },
  // {
  //   name: 'Add Measurement Terms',
  //   include: false
  // },
  {
    name: 'Add Transportation Terms',
    include: false
  },
  {
    name: 'Add Delivery Terms',
    include: false
  },
  {
    name: 'Add Warranty Terms',
    include: false
  },
  {
    name: 'Add Tax Details',
    include: false
  },
  {
    name: 'Add Late Delivery Terms',
    include: false
  }
];


export const PO_TERMS_METADATA = {
  'Add Payment Terms': {
    hasTermTypes: true,
    isBasedOnMilestone: true,
    isRetentionApplicable: true,
    isLcApplicable: true,
    isDiscountApplicable: true,
    multipleTerms: true,
    calculateSum: true,
    fieldsToCalculateSum: ['poBasicValuePercentage', 'poBasicValueAgainstDocPercentage', 'poValuePercentage', 'paymentRetentionPercentage'],
    nonMilestoneFields: [
      {
        key: 'description',
        label: 'Remarks',
        controlType: 'textarea',
        placeholder: 'Add Payment Terms',
        required: true,
        requiredMessage: 'Payment terms is required'
      }
    ],
    lcApplicableFields: [
      {
        key: 'lcUsanceDays',
        label: 'LC Usance Days',
        controlType: 'number',
        placeholder: '0',
        required: true,
        requiredMessage: 'LC Usance Days is required'
      },
      {
        key: 'zetwerkUsanceDays',
        label: 'Zetwerk Usance Days',
        controlType: 'number',
        placeholder: '0',
        required: true,
        requiredMessage: 'Zetwerk Usance Days is required'
      },
      {
        key: 'supplierUsanceDays',
        label: 'Supplier Usance Days',
        controlType: 'number',
        placeholder: '0',
        required: true,
        requiredMessage: 'Supplier Usance Days is required'
      },
    ],
    discoutApplicableFields: [
      {
        key: 'discountCreditDays',
        label: 'Credit Days',
        controlType: 'number',
        placeholder: 0,
        required: true,
        requiredMessage: 'Credit Days is required'
      },
    ],
    retentionFields: [{
      key: 'paymentRetentionPercentage',
      controlType: 'customPercentage',
      controlValue: 'number',
      placeholder: '0',
      required: true,
      requiredMessage: 'Percentage of PO Basic value is required',
      controlClassName: 'width-102px',
      labelClassName: 'd-inline-block',
      formGroupClassName: 'd-block mt-3 fixHeight',
      fieldsToCopy: ['poBasicValueUptoWarrantPeriodPercentage'],
      spanShow: true
    },
    {
      key: 'paymentRetentionAmount',
      label: 'Retention Value of PO Value',
      controlType: 'number',
      placeholder: 'Amount',
      controlClassName: 'width-90px',
      labelClassName: 'side-label',
      formGroupClassName: 'd-block mt-3 paymentRetentionAmount hide'
    },
    {
      key: 'pbgPercentage',
      label: 'PBG ( If Applicable)',
      controlType: 'customPercentage',
      controlValue: 'number',
      placeholder: 'PBG',
      controlClassName: 'width-102px',
      labelClassName: 'side-label',
      formGroupClassName: 'd-block mt-3 pbgPercentage',
      dayShow: true,
      appendClass: true,
      spanShow: true
    },
    {
      key: 'paymentRetentionDays',
      controlType: 'customPercentage',
      placeholder: '0',
      controlClassName: 'width-102px',
      formGroupClassName: 'd-block mt-3 fixHeight',
      appendClass: true
    }, {
      key: 'milestone',
      controlType: 'customSelect',
      placeholder: 'milestone',
      options: [{
        value: 'Completion of order',
        label: 'Completion of order'
      },
      {
        value: 'Complete material receipt at site',
        label: 'Complete material receipt at site'
      },
      {
        value: 'Till defect liability period from the date of last supply',
        label: 'Till defect liability period from the date of last supply'
      }, {
        value: 'Till the erection completion + DLP period whichever is earlier/later',
        label: 'Till the erection completion + DLP period whichever is earlier/later'
      },
      {
        value: 'Till mechanical completion/successful handing over the project',
        label: 'Till mechanical completion/successful handing over the project'
      },
      {
        value: 'Others',
        label: 'Others'
      }],
      controlClassName: 'width-270px',
      labelClassName: 'd-inline-block',
      labelAfter: true,
      formGroupClassName: 'milestone',
      required: true,
      requiredMessage: 'Milestone is required',
    }, {
      key: 'otherMilestoneCondition',
      controlType: 'customOtherText',
      placeholder: 'Comment',
      controlClassName: 'width-102px',
      formGroupClassName: 'd-block mt-3 others',
      // required: true,
      // requiredMessage: 'Comment is required',
    }],
    termTypeField: {
      key: 'typeName',
      label: '',
      controlType: 'select',
      placeholder: 'Select type of term',
      required: true,
      requiredMessage: 'Type of term is required',
      options: [{
        value: 'Advance',
        label: 'Advance'
      },
      {
        value: 'Against RM Receipt',
        label: 'Against RM Receipt'
      },
      {
        value: 'Before Dispatch',
        label: 'Before Dispatch'
      }, {
        value: 'On Dispatch',
        label: 'On Dispatch'
      }, {
        value: 'After Dispatch',
        label: 'After Dispatch'
      }, {
        value: 'Performance Related',
        label: 'Performance Related'
      }, {
        value: 'Retention',
        label: 'Retention'
      }],
      controlClassName: 'width-270px mr-3'
    },
    fieldsByTermType: {
      'Advance': [{
        key: 'subTypeName',
        label: '',
        controlType: 'select',
        placeholder: 'Select sub type of term',
        required: true,
        requiredMessage: 'Sub Type of term is required',
        options: [{
          value: 'Advance',
          label: 'Advance'
        },
        {
          value: 'Against ABG',
          label: 'Against ABG'
        },
        {
          value: 'Against ACG+PDC',
          label: 'Against ACG+PDC'
        }],
        controlClassName: 'width-270px'
      }],
      'Against RM Receipt': [{
        key: 'subTypeName',
        label: '',
        controlType: 'select',
        placeholder: 'Select sub type of term',
        required: true,
        requiredMessage: 'Sub Type of term is required',
        options: [{
          value: 'Advance',
          label: 'Advance'
        },
        {
          value: 'Advance against PDC and ACG',
          label: 'Advance against PDC and ACG'
        },
        {
          value: 'Interest Bearing Advance',
          label: 'IBA'
        },
        {
          value: 'Interest Bearing Advance against PDC and ACG',
          label: 'IBA against PDC and ACG'
        }],
        controlClassName: 'width-270px'
      }],
      'After Inspection': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO Basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3'
      }],
      'Before Dispatch': [{
        key: 'subTypeName',
        label: '',
        controlType: 'select',
        placeholder: 'Select sub type of term',
        options: [{
          value: 'Before dispatch on PO basic Value',
          label: 'Before dispatch on PO basic Value'
        },
        {
          value: 'Before dispatch on PO Value',
          label: 'Before dispatch on PO Value'
        }],
        controlClassName: 'width-270px'
      }],
      'On Dispatch': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO Basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3'
      }],
      'After Dispatch': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO Basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3'
      },
      {
        key: 'creditDays',
        label: 'Credit Days',
        controlType: 'number',
        placeholder: '0',
        required: true,
        requiredMessage: 'Credit Days is required',
        controlClassName: 'width-90px mr-2',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3'
      }],
      'Performance Related': [{
        key: 'poBasicValueAgainstDocPercentage',
        label: 'of PO basic value against PBG',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO Basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        fieldsToCopy: ['poBasicValueUptoWarrantPeriodPercentage'],
      },
      {
        key: 'poBasicValueUptoWarrantPeriodPercentage',
        label: 'PBG of PO basic value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        disabled: true,
        requiredMessage: 'PBG of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3'
      }],
      'Retention': [{
        key: 'paymentRetentionPercentage',
        controlType: 'customPercentage',
        controlValue: 'number',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO Basic value is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 fixHeight',
        fieldsToCopy: ['poBasicValueUptoWarrantPeriodPercentage'],
        spanShow: true
      },
      {
        key: 'paymentRetentionAmount',
        label: 'Retention Value of PO Value',
        controlType: 'number',
        placeholder: 'Amount',
        controlClassName: 'width-90px',
        labelClassName: 'side-label',
        formGroupClassName: 'd-block mt-3 paymentRetentionAmount hide'
      },
      {
        key: 'pbgPercentage',
        label: 'PBG ( If Applicable)',
        controlType: 'customPercentage',
        controlValue: 'number',
        placeholder: 'PBG',
        controlClassName: 'width-102px',
        labelClassName: 'side-label',
        formGroupClassName: 'd-block mt-3 pbgPercentage',
        dayShow: true,
        appendClass: true,
        spanShow: true
      },
      {
        key: 'paymentRetentionDays',
        controlType: 'customPercentage',
        placeholder: '0',
        controlClassName: 'width-102px',
        formGroupClassName: 'd-block mt-3 fixHeight',
        appendClass: true
      }, {
        key: 'milestone',
        controlType: 'customSelect',
        placeholder: 'milestone',
        options: [{
          value: 'Completion of order',
          label: 'Completion of order'
        },
        {
          value: 'Complete material receipt at site',
          label: 'Complete material receipt at site'
        },
        {
          value: 'Till defect liability period from the date of last supply',
          label: 'Till defect liability period from the date of last supply'
        }, {
          value: 'Till the erection completion + DLP period whichever is earlier/later',
          label: 'Till the erection completion + DLP period whichever is earlier/later'
        },
        {
          value: 'Till mechanical completion/successful handing over the project',
          label: 'Till mechanical completion/successful handing over the project'
        },
        {
          value: 'Others',
          label: 'Others'
        }],
        controlClassName: 'width-270px',
        labelClassName: 'd-inline-block',
        labelAfter: true,
        formGroupClassName: 'milestone',
        required: true,
        requiredMessage: 'Milestone is required',
      }, {
        key: 'otherMilestoneCondition',
        controlType: 'customOtherText',
        placeholder: 'Comment',
        controlClassName: 'width-102px',
        formGroupClassName: 'd-block mt-3 others',
        // required: true,
        // requiredMessage: 'Comment is required',
      }]
    },
    fieldsBySubTermType: {
      'Advance': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic value as advance',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3'
      }],
      'Against ABG': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic value as advance',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        fieldsToCopy: ['abgPercentage']
      },
      {
        key: 'abgPercentage',
        label: 'ABG of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'ABG of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        disabled: true
      }],
      'Against ACG+PDC': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic value as advance',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        fieldsToCopy: ['acgPercentage', 'pdcPercentage']
      },
      {
        key: 'acgPercentage',
        label: 'ACG of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'ACG of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        disabled: true
      },
      {
        key: 'pdcPercentage',
        label: 'PDC of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'PDC of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        disabled: true
      }],
      'Advance against PDC and ACG': [{
        key: 'poBasicValuePercentage',
        label: 'of PO basic value as advance',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'Percentage of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        fieldsToCopy: ['acgPercentage', 'pdcPercentage']
      },
      {
        key: 'pdcPercentage',
        label: 'PDC of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'PDC of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        disabled: true
      },
      {
        key: 'acgPercentage',
        label: 'ACG of PO basic Value',
        controlType: 'percentage',
        placeholder: '0',
        required: true,
        requiredMessage: 'ACG of PO basic value is required',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3',
        disabled: true
      }],
      'Interest Bearing Advance': [{
        key: 'poBasicValuePercentage',
        label: 'Advance',
        controlType: 'customPercentage',
        controlValue: 'number',
        placeholder: 'PO Basic',
        required: true,
        requiredMessage: 'Advance is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 fixHeight',
        spanShow: true
      },
      {
        key: 'interestPercentage',
        label: 'Interest Rate',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'IR',
        required: true,
        requiredMessage: 'Interest is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 interestRate',
        spanShow: true,
        appendClass: true
      },
      {
        key: 'processingFee',
        label: 'Processing Fee',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'PF',
        required: true,
        requiredMessage: 'PF is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 fixHeight',
        spanShow: true
      },
      {
        key: 'loanDuration',
        label: 'Loan Duration',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'Dur',
        required: true,
        requiredMessage: 'Loan Duration is required',
        controlClassName: 'width-62px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 loanDuration'
      },
      {
        key: 'loanDurationUnit',
        controlType: 'customSelect',
        placeholder: 'Period',
        options: [{
          value: 'Day',
          label: 'Day'
        },
        {
          value: 'Week',
          label: 'Week'
        },
        {
          value: 'Month',
          label: 'Month'
        }],
        controlClassName: 'width-89px',
        labelClassName: 'd-inline-block',
        labelAfter: true,
        requiredMessage: 'unit is required',
        formGroupClassName: 'loanDurationPeriod'
      },
      {
        key: 'gracePeriod',
        label: 'Grace Period',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'GP',
        required: true,
        requiredMessage: 'Grace Period is required',
        controlClassName: 'width-62px',
        labelClassName: 'd-inline-block',
        mainLabel: 'Late fee Terms',
        mainLabelClassName: 'main italic',
        formGroupClassName: 'd-block mt-3 fixHeight-107'
      },
      {
        key: 'gracePeriodUnit',
        controlType: 'customSelect',
        placeholder: 'Period',
        options: [{
          value: 'Day',
          label: 'Day'
        },
        {
          value: 'Week',
          label: 'Week'
        },
        {
          value: 'Month',
          label: 'Month'
        }],
        controlClassName: 'width-89px',
        labelClassName: 'd-inline-block',
        labelAfter: true,
        requiredMessage: 'Grace Unit is required',
        formGroupClassName: 'gracePeriodPeriod'
      },
      {
        key: 'lateFeeChargers',
        label: 'Late fee charges',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'LFC',
        required: true,
        requiredMessage: 'Late fee is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 lateFeeChargers',
        sideLabel: 'Per Annum (compounded)',
        sideLabelClass: 'side',
        spanShow: true,
        appendClass: true
      },
      {
        key: 'name',
        label: 'Name of Managing/Whole-Time Director',
        controlType: 'customPercentage',
        controlValue: 'text',
        placeholder: 'Name',
        required: true,
        requiredMessage: 'Name is required',
        controlClassName: 'width-370px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 name',
        mainLabel: 'Point of Contact',
        mainLabelClassName: 'main',
      },
      {
        key: 'title',
        label: 'Title',
        controlType: 'customPercentage',
        controlValue: 'text',
        placeholder: 'Title',
        required: true,
        requiredMessage: 'Title is required',
        controlClassName: 'width-370px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 title'
      },
      {
        key: 'email',
        label: 'Email',
        controlValue: 'text',
        controlType: 'customPercentage',
        placeholder: 'Email',
        required: true,
        requiredMessage: 'Email is required',
        controlClassName: 'width-175px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 email'
      },
      {
        key: 'phone',
        label: 'Phone No.',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'Number',
        required: true,
        requiredMessage: 'Phone No. is required',
        controlClassName: 'width-175px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 phone'
      }],
      'Interest Bearing Advance against PDC and ACG': [{
        key: 'poBasicValuePercentage',
        label: 'Advance',
        controlType: 'customPercentage',
        controlValue: 'number',
        placeholder: 'PO Basic',
        required: true,
        requiredMessage: 'Advance is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 fixHeight',
        spanShow: true,
        fieldsToCopy: ['acgPercentage', 'pdcPercentage']
      },
      {
        key: 'interestPercentage',
        label: 'Interest Rate',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'IR',
        required: true,
        requiredMessage: 'Interest is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 interestRate',
        spanShow: true,
        appendClass: true
      },
      {
        key: 'processingFee',
        label: 'Processing Fee',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'PF',
        required: true,
        requiredMessage: 'PF is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 fixHeight',
        spanShow: true
      },
      {
        key: 'loanDuration',
        label: 'Loan Duration',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'Dur',
        required: true,
        requiredMessage: 'Loan Duration is required',
        controlClassName: 'width-62px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 loanDuration'
      },
      {
        key: 'loanDurationUnit',
        controlType: 'customSelect',
        placeholder: 'Period',
        options: [{
          value: 'Day',
          label: 'Day'
        },
        {
          value: 'Week',
          label: 'Week'
        },
        {
          value: 'Month',
          label: 'Month'
        }],
        controlClassName: 'width-89px',
        labelClassName: 'd-inline-block',
        labelAfter: true,
        requiredMessage: 'unit is required',
        formGroupClassName: 'loanDurationPeriod'
      },
      {
        key: 'gracePeriod',
        label: 'Grace Period',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'GP',
        required: true,
        requiredMessage: 'Grace Period is required',
        controlClassName: 'width-62px',
        labelClassName: 'd-inline-block',
        mainLabel: 'Late fee Terms',
        mainLabelClassName: 'main italic',
        formGroupClassName: 'd-block mt-3 fixHeight-107'
      },
      {
        key: 'gracePeriodUnit',
        controlType: 'customSelect',
        placeholder: 'Period',
        options: [{
          value: 'Day',
          label: 'Day'
        },
        {
          value: 'Week',
          label: 'Week'
        },
        {
          value: 'Month',
          label: 'Month'
        }],
        controlClassName: 'width-89px',
        labelClassName: 'd-inline-block',
        labelAfter: true,
        requiredMessage: 'Grace Unit is required',
        formGroupClassName: 'gracePeriodPeriod'
      },
      {
        key: 'lateFeeChargers',
        label: 'Late fee charges',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'LFC',
        required: true,
        requiredMessage: 'Late fee is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 lateFeeChargers',
        sideLabel: 'Per Annum (compounded)',
        sideLabelClass: 'side',
        spanShow: true,
        appendClass: true
      },
      {
        key: 'name',
        label: 'Name of Managing/Whole-Time Director',
        controlType: 'customPercentage',
        controlValue: 'text',
        placeholder: 'Name',
        required: true,
        requiredMessage: 'Name is required',
        controlClassName: 'width-370px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 name',
        mainLabel: 'Point of Contact',
        mainLabelClassName: 'main',
      },
      {
        key: 'title',
        label: 'Title',
        controlType: 'customPercentage',
        controlValue: 'text',
        placeholder: 'Title',
        required: true,
        requiredMessage: 'Title is required',
        controlClassName: 'width-370px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 title'
      },
      {
        key: 'email',
        label: 'Email',
        controlValue: 'text',
        controlType: 'customPercentage',
        placeholder: 'Email',
        required: true,
        requiredMessage: 'Email is required',
        controlClassName: 'width-175px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 email'
      },
      {
        key: 'phone',
        label: 'Phone No.',
        controlValue: 'number',
        controlType: 'customPercentage',
        placeholder: 'Number',
        required: true,
        requiredMessage: 'Phone No. is required',
        controlClassName: 'width-175px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 phone'
      },
      {
        key: 'pdcPercentage',
        label: 'PDC',
        controlType: 'customPercentage',
        placeholder: 'PDC',
        spanShow: true,
        required: true,
        disabled: true,
        requiredMessage: 'PDC is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 pdc',
        appendClass: true
      },
      {
        key: 'acgPercentage',
        label: 'ACG',
        placeholder: 'ACG',
        spanShow: true,
        required: true,
        disabled: true,
        controlType: 'customPercentage',
        requiredMessage: 'ACG is required',
        controlClassName: 'width-102px',
        labelClassName: 'd-inline-block',
        formGroupClassName: 'd-block mt-3 acg',
        appendClass: true
      }],
      'Before dispatch on PO basic Value': [
        {
          key: 'poBasicValuePercentage',
          label: 'of PO basic Value',
          controlType: 'percentage',
          placeholder: '0',
          required: true,
          requiredMessage: 'Percentage of PO Basic value is required',
          controlClassName: 'width-90px',
          labelClassName: 'd-inline-block',
          formGroupClassName: 'd-block mt-3'
        }
      ],
      'Before dispatch on PO Value': [
        {
          key: 'poValuePercentage',
          label: 'of PO Value',
          controlType: 'percentage',
          placeholder: '0',
          required: true,
          requiredMessage: 'Percentage of PO value is required',
          controlClassName: 'width-90px',
          labelClassName: 'd-inline-block',
          formGroupClassName: 'd-block mt-3'
        }
      ]
    }
  },
  'Add Measurement Terms': {
    hasTermTypes: true,
    nonMilestoneFields: [],
    termTypeField: {
      key: 'typeName',
      label: '',
      controlType: 'select',
      placeholder: 'Select Measurement term',
      options: [{
        value: 'As per drawing weight',
        label: 'As per drawing weight'
      },
      {
        value: 'As per dharam Kata weight',
        label: 'As per dharam Kata weight'
      },
      {
        value: 'As per drawing weight or dharam kata weight whichever is lesser',
        label: 'As per drawing weight or dharam kata weight whichever is lesser'
      }],
      controlClassName: 'mb-3 pr-32 width-auto',
      required: true,
      requiredMessage: 'Measurement term is required'
    }
  },
  'Add Transportation Terms': {
    hasTermTypes: true,
    nonMilestoneFields: [
      {
        key: 'description',
        label: 'Remarks',
        controlType: 'textarea',
        placeholder: 'Remarks'
      }
    ],
    termTypeField: {
      key: 'typeName',
      label: 'Select type',
      controlType: 'select',
      placeholder: 'Select type of term',
      options: [{
        value: 'Not applicable',
        label: 'Not applicable'
      },
      {
        value: 'Ex-Works',
        label: 'Ex-Works'
      },
      {
        value: 'FOR',
        label: 'FOR'
      }, {
        value: 'CIF',
        label: 'CIF'
      }, {
        value: 'DDP',
        label: 'DDP'
      }, {
        value: 'FOB',
        label: 'FOB'
      }],
      controlClassName: 'width-270px mb-3',
      required: true,
      requiredMessage: 'Term type is required'
    }
  },
  'Add Delivery Terms': {
    nonMilestoneFields: [
      {
        key: 'deliveryDate',
        label: 'Select Delivery Date',
        controlType: 'customDate',
        placeholder: 'Delivery Date',
        controlClassName: 'mb-3',
        required: true,
        requiredMessage: 'Delivery date is required',
      },
      {
        key: 'description',
        label: 'Remarks',
        controlType: 'textarea',
        placeholder: 'Remarks'
      }
    ]
  },
  'Add Warranty Terms': {
    nonMilestoneFields: [
      {
        key: 'description',
        label: 'Remarks',
        controlType: 'textarea',
        placeholder: 'Remarks',
        required: true,
        requiredMessage: 'Warranty terms is required'
      }
    ]
  },
  'Add Tax Details': {
    nonMilestoneFields: [
      {
        key: 'description',
        label: 'Remarks',
        controlType: 'textarea',
        placeholder: 'Remarks',
        required: true,
        requiredMessage: 'Tax details is required'
      }
    ]
  },
  'Add Late Delivery Terms': {
    nonMilestoneFields: [
      {
        key: 'poValuePercentage',
        label: 'of PO basic value per every',
        controlType: 'percentage',
        placeholder: '0',
        controlClassName: 'width-90px',
        labelClassName: 'd-inline-block',
        errorClassName: 'validation-message-absolute'
      },
      {
        key: 'delayPeriodUnit',
        label: 'of delay upto a maximum of',
        controlType: 'select',
        placeholder: 'Period',
        options: [{
          value: 'Day',
          label: 'Day'
        },
        {
          value: 'Week',
          label: 'Week'
        },
        {
          value: 'Month',
          label: 'Month'
        }],
        controlClassName: 'width-100px ml-2 mr-2',
        labelClassName: 'd-inline-block',
        labelAfter: true
      },
      {
        key: 'maximumDelayPeriod',
        label: '',
        controlType: 'percentage',
        placeholder: '0',
        controlClassName: 'width-90px ml-2',
        labelClassName: 'd-inline-block'
      },
      {
        key: 'description',
        label: 'Remarks',
        controlType: 'textarea',
        placeholder: 'Remarks',
        labelClassName: 'mt-3'
      }
    ]
  },
};

export const COLUMN_DEFENITIONS_PROCESSING = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 11,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Contract No.',
  'field': 'contractNumber',
  'widthInPercentage': 13,
  'filter': 'ColumnFilter'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 20,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
},
{
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
},
{
  'headerName': 'Customer Name',
  'field': 'customerName',
  'widthInPercentage': 20,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
},
{
  'headerName': 'PO Date',
  'field': 'poDate',
  'widthInPercentage': 10,
  'columnType': 'subDate',
  'subField': 'taxedPOValue',
  'subType': 'currency',
  'symbol': 'symbol'
},
{
  'headerName': 'Status',
  'field': 'status',
  'widthInPercentage': 16,
  'columnType': 'status',
  'statusMap': STATUS_MAP,
  'filter': 'ColumnFilter',
}];

export const COLUMN_DEFENITIONS_REVISED = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Contract No.',
  'field': 'contractNumber',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 16,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
},
{
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
},
{
  'headerName': 'Customer Name',
  'field': 'customerName',
  'widthInPercentage': 16,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
},
{
  'headerName': 'PO Date',
  'field': 'poDate',
  'widthInPercentage': 10,
  'columnType': 'subDate',
  'subField': 'taxedPOValue',
  'subType': 'currency'
},
{
  'headerName': 'Project Manager',
  'field': 'projectManagerName',
  'widthInPercentage': 14,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
},
{
  'headerName': 'Status',
  'field': 'status',
  'widthInPercentage': 14,
  'columnType': 'status',
  'statusMap': STATUS_MAP,
  'filter': 'ColumnFilter',
}];

export const COLUMN_DEFENITIONS_PENDING_PO = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Order Type',
  'field': 'orderType',
  'widthInPercentage': 17,
  'filter': 'ColumnFilter'
}, {
  'headerName': 'BU',
  'field': 'businessUnit',
  'widthInPercentage': 15,
  'filter': 'ColumnFilter'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 15,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
},
{
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
},
{
  'headerName': 'Contract/Customer Name',
  'field': 'contractNumber',
  'widthInPercentage': 20,
  'filter': 'ColumnFilter',
  'columnType': 'subfield-tooltip',
  'subField': 'customerName'
},
{
  'headerName': 'Pending Since',
  'field': 'orderCreatedSince',
  'widthInPercentage': 12,
  'columnType': 'subDays',
  'subField': 'createdAt',
  'subType': 'date'
}];

export const COLUMN_DEFENITIONS_PENDING_PO2:Array<IColumnDef> = [
  {
    field: 'orderNumber',
    headerName: 'Order No.',
    cellClass: 'cell-center',
    width: 120,
    valueGetter: (data) => {
      return (
        (data.orderNumber) || ''
      );
    }
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    cellClass: 'cell-center',
    width: 150,
    valueGetter: (data) => {
      return (
        (data.orderType) || ''
      );
    }
  },
  {
    field: 'businessUnit',
    headerName: 'BU',
    cellClass: 'cell-center',
    width: 140,
    valueGetter: (data) => {
      return (
        (data.businessUnit) || ''
      )
    }
  },
  {
    field: 'supplierName',
    headerName: 'Supplier Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: data.supplierName || '',
        subText: data.supplierLocation || '',
      }
      return res;
    }
  },
  {
    field: 'gst',
    headerName: 'GST',
    cellClass: 'cell-center',
    width: 150,
    valueGetter: (data) => {
      return (
        (data.zetwerkGst) || ''
      )
    }
  },
  {
    field: 'contractNumber',
    headerName: 'Contract/Customer Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 170,
    valueGetter: (data) => {
      const res = {
        text: data.contractNumber,
        subText: data.customerName,
      }
      return res;
    }
  },
  {
    field: 'orderCreatedSince',
    headerName: 'Pending Since',
    cellClass: 'cell-center',
    cellRenderer: 'subtextDateCellRenderer',
    width: 120,
    valueGetter: (data) => {
      const res = {
        text: `${data.orderCreatedSince} Days` || '',
        subText: data.createdAt
      }
      return res;
    }
  }
];

export const COLUMN_DEFENITIONS_PENDING_APPROVAL = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Order Type',
  'field': 'orderType',
  'widthInPercentage': 13,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'BU',
  'field': 'businessUnit',
  'widthInPercentage': 12,
  'filter': 'ColumnFilter'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 12,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
},
{
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
},
{
  'headerName': 'Contract/Customer Name',
  'field': 'contractNumber',
  'widthInPercentage': 18,
  'filter': 'ColumnFilter',
  'columnType': 'subfield-tooltip',
  'subField': 'customerName'
},
{
  'headerName': 'Taxed PO Value',
  'field': 'taxedPOValue',
  'widthInPercentage': 15,
  'columnType': 'currency',
  'symbol': 'symbol'
},
{
  'headerName': 'Pending Since',
  'field': 'poCreatedSince',
  'widthInPercentage': 10,
  'columnType': 'subDays',
  'subField': 'poDate',
  'subType': 'date'
}];

export const COLUMN_DEFENITIONS_PENDING_APPROVAL2: Array<IColumnDef> = [
  {
    field: 'orderNumber',
    headerName: 'Order No.',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.orderNumber) || ''
      );
    }
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.orderType) || ''
      );
    }
  },
  {
    field: 'businessUnit',
    headerName: 'BU',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.businessUnit) || ''
      )
    }
  },
  {
    field: 'supplierName',
    headerName: 'Supplier Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: (data.supplierName) || '',
        subText: (data.supplierLocation) || '',
      }
      return res;
    }
  },
  {
    field: 'gst',
    headerName: 'GST',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.zetwerkGst) || ''
      )
    }
  },
  {
    field: 'contractNumber',
    headerName: 'Contract/Customer Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: (data.contractNumber) || '',
        subText: (data.customerName) || '',
      }
      return res;
    }
  },
  {
    field: 'taxedPOValue',
    headerName: 'Taxed PO Value',
    cellClass: 'cell-center',
    cellRenderer: 'currencyCellRenderer',
    width: 140,
    valueGetter: (data) => {
      const res = {
        currencySymbol: '',
        amount: '',
      }
      res['currencySymbol'] = (data.symbol || '&#8377;');
      res['amount'] = (data.taxedPOValue) || '';
      return res;
    }
  },
  {
    field: 'poCreatedSince',
    headerName: 'Pending Since',
    cellClass: 'cell-center',
    cellRenderer: 'subtextDateCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: `${data.poCreatedSince} Days` || '',
        subText: data.poDate || ''
      }
      return res;
    }
  }
];

export const COLUMN_DEFENITIONS_PENDING_ACKNOWLEDGEMENT = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Order Type',
  'field': 'orderType',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'BU',
  'field': 'businessUnit',
  'widthInPercentage': 12,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 15,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
},
{
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter'
},
{
  'headerName': 'Contract/Customer Name',
  'field': 'contractNumber',
  'widthInPercentage': 18,
  'filter': 'ColumnFilter',
  'columnType': 'subfield-tooltip',
  'subField': 'customerName'
},
{
  'headerName': 'Taxed PO Value',
  'field': 'taxedPOValue',
  'widthInPercentage': 14,
  'columnType': 'currency',
  'symbol': 'symbol'
},
{
  'headerName': 'Pending Since',
  'field': 'poApprovedSince',
  'widthInPercentage': 10,
  'columnType': 'subDays',
  'subField': 'approvedDate',
  'subType': 'date'
}];

export const COLUMN_DEFENITIONS_PENDING_ACKNOWLEDGEMENT2: Array<IColumnDef> = [
  {
    field: 'orderNumber',
    headerName: 'Order No.',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.orderNumber) || ''
      );
    }
  },
  {
    field: 'orderType',
    headerName: 'Order Type',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.orderType) || ''
      );
    }
  },
  {
    field: 'businessUnit',
    headerName: 'BU',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.businessUnit) || ''
      )
    }
  },
  {
    field: 'supplierName',
    headerName: 'Supplier Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: data.supplierName || '',
        subText: data.supplierLocation || '',
      }
      return res;
    }
  },
  {
    field: 'gst',
    headerName: 'GST',
    cellClass: 'cell-center',
    width: 160,
    valueGetter: (data) => {
      return (
        (data.zetwerkGst) || ''
      )
    }
  },
  {
    field: 'contractNumber',
    headerName: 'Contract/Customer Name',
    cellClass: 'cell-center',
    cellRenderer: 'subtextCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: data.contractNumber,
        subText: data.customerName,
      }
      return res;
    }
  },
  {
    field: 'taxedPOValue',
    headerName: 'Taxed PO Value',
    cellClass: 'cell-center',
    cellRenderer: 'currencyCellRenderer',
    width: 140,
    valueGetter: (data) => {
      const res = {
        currencySymbol: '',
        amount: '',
      }
      res['currencySymbol'] = (data.symbol || '&#8377;');
      res['amount'] = (data.taxedPOValue);
      return res;
    }
  },
  {
    field: 'poApprovedSince',
    headerName: 'Pending Since',
    cellClass: 'cell-center',
    cellRenderer: 'subtextDateCellRenderer',
    width: 150,
    valueGetter: (data) => {
      const res = {
        text: `${data.poApprovedSince} Days` || '',
        subText: data.approvedDate || ''
      }
      return res;
    }
  }
];

export const COLUMN_DEFENITIONS_ORDER_SEARCH = [{
  'headerName': 'Order No.',
  'field': 'orderNumber',
  'widthInPercentage': 10.28,
  'filter': 'ColumnFilter',
  'cssClass': 'bold',
}, {
  'headerName': 'Order Type',
  'field': 'orderType',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'BU',
  'field': 'businessUnit',
  'widthInPercentage': 10,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
}, {
  'headerName': 'Supplier Name',
  'field': 'supplierName',
  'widthInPercentage': 14.28,
  'filter': 'ColumnFilter',
  'columnType': 'sub',
  'subField': 'supplierLocation'
}, {
  'headerName': 'GST',
  'field': 'zetwerkGst',
  'widthInPercentage': 10.28,
  'filter': 'ColumnFilter',
  'columnType': 'tooltip'
},
{
  'headerName': 'Contract/Customer Name',
  'field': 'contractNumber',
  'widthInPercentage': 16,
  'filter': 'ColumnFilter',
  'columnType': 'subfield-tooltip',
  'subField': 'customerName'
},
{
  'headerName': 'Taxed PO Value',
  'field': 'taxedPOValue',
  'widthInPercentage': 14.28,
  'columnType': 'currency',
  'symbol': 'symbol'
},
{
  'headerName': 'Status',
  'field': 'status',
  'widthInPercentage': 12.28,
  'columnType': 'status',
  'statusMap': STATUS_MAP,
  'filter': 'ColumnFilter',
}];

export const SUPPLIER_TYPES = {
  OMS:'OMS',
  FACTORY:'FACTORY',
};

export const ORDER_ACTION_TYPES = {
  PROCESS_PAYMENT: 'PROCESS_PAYMENT'
};

export const DEFAULT_ORDER_ACTION_VALUES = {
  [ORDER_ACTION_TYPES.PROCESS_PAYMENT]: {
    isAllowed: false,
    reason: {
      order: { errors: "", meta: {} },
      supplier: { errors: "", meta: {} },
    },
  },
};

export const ACTION_TYPES = {
  CREATE_ORDER: 'CREATE_ORDER',
  CREATE_PURCHASE_ORDER: 'CREATE_PURCHASE_ORDER',
  REVISE_PURCHASE_ORDER: 'REVISE_PURCHASE_ORDER',
}

export const DEFAULT_ACTION_VALUES = {
  [ACTION_TYPES.CREATE_ORDER]: {
    isAllowed: true,
    reason: {},
  },
  [ACTION_TYPES.CREATE_PURCHASE_ORDER]: {
    isAllowed: true,
    reason: {},
  },
  [ACTION_TYPES.REVISE_PURCHASE_ORDER]: {
    isAllowed: true,
    reason: {},
  },
};

export const PURCHASE_ORDER_CATEGORY = {
  GOODS: 'GOODS',
  SERVICE: 'SERVICE',
  COMPOSITE: 'COMPOSITE'
}

export const ARTEFACT_TYPES = {
  BILL: 'bill',
  PAYMENT_REQUEST: 'paymentRequest',
  CREDIT_NOTE: 'creditNote'
}