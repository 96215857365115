const AGGREGATION_URLS = [
  {
    url: 'v1/customers/${customerId}/validate-customer-edit',
    test: (url) => {
      let regex = new RegExp('v1\/customers\/([0-9a-fA-F]+)\/validate-customer-edit$');
      return regex.test(url)
    }
  }
];

export function isURLAggregation(url) {
  for (const URLCONFIG of AGGREGATION_URLS) {
    if (URLCONFIG.test(url)) {
      return true;
    }
  }
  return false;
}