import {
  Injectable
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  environment
} from '../../environments/environment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { PdfFilePreviewComponent } from '../components/general/pdf-file-preview/pdf-file-preview.component';
import { getRequestParams } from '../utils/supplier';

const API_URL = environment.apiEndPoint;
const CMS_URL = environment.cmsApiEndPoint;
const SUPPLIER_MICROSERVICE_API_URL = environment.supplierMicroserviceAPIEndPoint;
@Injectable({
  providedIn: 'root'
})
export class GeneralService {
  public modalRef: BsModalRef;
  constructor(private http: HttpClient, private modalService: BsModalService) { }

  public getGeneralList(url, baseUrlType = 'oms', params = {}) {
    switch (baseUrlType) {
      case 'oms':
        return this.http.get(API_URL + url);
      case 'sms':
        const queryParams = getRequestParams(params)
        return this.http.get(SUPPLIER_MICROSERVICE_API_URL + url, { params: queryParams });
      case 'cms':
        return this.http.get(CMS_URL + url, { params });
    }
    return this.http.get(API_URL + url);
  }

  public downloadLedgerForLoan(loanId, params) {
    return this.http.post(API_URL + `ledger/loan-dump/${loanId}`, {}, { params });
  }

  public downloadLedgerForOrder(orderId, params) {
    return this.http.post(API_URL + `ledger/order-dump/${orderId}`, {}, { params });
  }

  public downloadLedgerForSupplier(supplierId, params) {
    return this.http.get(API_URL + `/ledger/supplier-ledger-dump/${supplierId}`, { params });
  }

  /**
   * Download Supplier Group Ledger Excel
   * @param supplierGroupId
   * @param params
   * @returns observable
   */
  public downloadLedgerForSupplierGroup(supplierGroupId, params) {
    return this.http.get(API_URL + `/supplier-groups/${supplierGroupId}/ledgers-dump`, { params });
  }

  public downloadLedgerForCustomer(customerId, params) {
    return this.http.get(API_URL + `customers/${customerId}/ledger-dump`, { params });
  }

  /**
   * Download Customer Group Ledger Excel
   * @param customerGroupId
   * @param params
   * @returns observable
   */
  public downloadLedgerForCustomerGroup(customerGroupId, params) {
    return this.http.get(API_URL + `customer-groups/${customerGroupId}/ledger-dump`, { params });
  }

  public submitSupplierFeedback(data, feedbackId) {
    return this.http.put(API_URL + `supplier-feedbacks/${feedbackId}`, data);
  }

  public getSupplierFeedback(feedbackId) {
    return this.http.get(API_URL + `supplier-feedbacks/${feedbackId}`);
  }

  public submitCustomerFeedback(data, feedbackId) {
    return this.http.put(API_URL + `customer-feedbacks/${feedbackId}`, data);
  }

  public getCustomerFeedback(feedbackId) {
    return this.http.get(API_URL + `customer-feedbacks/${feedbackId}`);
  }

  /**
   * @param {string} id supplier or supplierGroupId
   * @param {string} ledgerType PAN or GST
  */
  public getSupplierLedgerPdf(id, ledgerType, params) {
    const relativeEndpoint = ledgerType === 'PAN' ? `supplier-groups/${id}/ledger-pdf-dump` : `ledger/supplier-ledger-pdf-dump/${id}`;

    return this.http.get(API_URL + relativeEndpoint, { params });
  }

  /**
   * @param {string} id customer or customerGroupId
   * @param {string} ledgerType PAN or GST
  */
  public getCustomerLedgerPdf(id, ledgerType, params) {
    const relativeEndpoint = ledgerType === 'PAN' ? `customer-groups/${id}/ledger-pdf-dump` : `customers/${id}/ledger-pdf-dump`;

    return this.http.get(API_URL + relativeEndpoint, { params });
  }
  /**
   *
   * @param list - Send all file details in order first path
   * then fileType, no of files to view, boolean value and file name
   */
  public pdfFileViewer(list) {
    const initialState = {
      list: [
        list.path,
        list.fileType,
        list.value,
        list.isBoolean,
        list.fileName
      ]
    };
    this.modalRef = this.modalService.show(PdfFilePreviewComponent, {
      initialState,
      class: 'modal-lg'
    });
  }

  getNoteReason() {
    const reasonData = {};
    reasonData['reason'] = localStorage.getItem('reason');
    reasonData['referenceDocumentFileIds'] = JSON.parse(localStorage.getItem('refDocs'));
    reasonData['referenceDocumentNumber'] = localStorage.getItem('refDocumentNmb');
    reasonData['remarks'] = localStorage.getItem('remarks');
    return reasonData;
  }

  removeNoteReason() {
    localStorage.removeItem('reason');
    localStorage.removeItem('refDocs');
    localStorage.removeItem('refDocumentNmb');
    localStorage.removeItem('remarks');
  }

  public previewNote(noteId, data, noteType, params = {}) {
    const type = this.checkNoteType(noteType);
    let prefix = type === 'credit' ? 'supplier-credit-note' : 'supplier-debit-notes';
    return this.http.post(`${API_URL}${prefix}/${noteId}/preview`, data, {
      params: {
        ...params,
        noteType
      }
    });
  }

  /**
   *@desc get a specific note details
   *@params noteId
   */
  public getNoteById(noteId, noteType?, isEdit='false', populateBoqLineItemDetails='false') {
    const type = this.checkNoteType(noteType);
    const supplierNoteType = type === 'credit' ? 'supplier-credit-note' : 'supplier-debit-notes';
    const url = API_URL + `${supplierNoteType}/` + noteId;
    return this.http.get(url, {
      params: {
        isEdit,
        populateBoqLineItemDetails
      }
    });
  }

  /**
   *@desc create note
   *@params noteId
   *@data data
   */
  public createNoteById(noteId, data, noteType?, params = {}) {
    const type = this.checkNoteType(noteType);
    let prefix = type === 'credit' ? 'supplier-credit-note' : 'supplier-debit-notes';
    return this.http.put(`${API_URL}${prefix}/${noteId}/create`, data, {
      params
    });
  }

  /**
  *@desc update note
  *@params noteId
  *@data data
  */
  public updateNoteById(noteId, data, noteType?, params = {}) {
    const type = this.checkNoteType(noteType);
    let prefix = type === 'credit' ? 'supplier-credit-note' : 'supplier-debit-notes';
    return this.http.put(`${API_URL}${prefix}/${noteId}`, data, {
      params
    });
  }

  /**
   *@desc download draft note
   *@params noteId
   *@data data
   */
  public downloadDraftNotePdf(noteId, noteType?) {
    const type = this.checkNoteType(noteType);
    return this.http.get(`${API_URL}supplier-${type}-note/${noteId}/download`);
  }

  private checkNoteType(noteType) {
    return noteType.toLowerCase().includes('credit') ? 'credit' : 'debit';
  }

  /**
   *@desc get credit note advance payment details
   *@params noteId
   *@data data
   */
  public getAdvanceDetailsByBillId(noteId, urlParams) {
    const url = `${API_URL}supplier-credit-note/${noteId}/advance-details`;
    return this.http.get(url, {
      params: {
        ...urlParams
      }
    });
  }

  public createPaymentSchedule(noteId, data) {
    return this.http.post(API_URL + 'supplier-credit-note/' + noteId + '/payment-schedule', data);
  }

  public updatePaymentSchedule(noteId, data) {
    return this.http.put(API_URL + 'supplier-credit-note/' + noteId + '/payment-schedule', data);
  }

  public calculateDistanceFromPinCodes({ fromPinCode, toPinCode }) {
    return this.http.post(API_URL + 'get-distance', { fromPinCode, toPinCode });
  }

  fetchData({endpoint, queryParams}){
    const url = `${API_URL}${endpoint}`;
    return this.http.get(url, {params: queryParams});
  }
}
