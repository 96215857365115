<div class="dev-bar" *ngIf="env === 'development'">DEVELOPMENT</div>
<div class="staging-bar" *ngIf="env === 'staging'">STAGING</div>
<div class="uat-bar" *ngIf="env === 'uat'">UAT</div>

<div class="dashboard row" id="dashboard">
  <div class="col-md-2 sidebar left-side">
    <app-sidebar></app-sidebar>
  </div>
  <div class="col-md-10 offset-md-2 right-side">
    <div style="background-color: #fff;">
      <router-outlet> </router-outlet>
    </div>
  </div>
  <!-- <div class="footer-wrapper row">
    <div class="col-md-12">
      <p class="footer-poweredby">Powered By</p>
      <img src="assets/logo_blue.png" class="footer-logo" />
    </div>

  </div> -->
</div>
