<div (click)="$event.stopPropagation()">
  <span class="link-label" (click)="navigateToClickedLink(rejectInvoiceDiscounting)">
    Reject Invoice
  </span>
</div>



<ng-template #rejectInvoiceDiscounting>
  <div class="reject-invoice-discounting-container">
    <div class="invoice-discounting-info">
      <div class="details">
        <div class="heading">Invoice No.</div>
        <div class="value">{{ params?.value?.invoiceNumber }}</div>
      </div>
      <div class="details">
        <div class="heading">Financier</div>
        <div class="value">{{ params?.value?.financierName }}</div>
      </div>
      <div class="details">
        <div class="heading">Date of Submission</div>
        <div class="value">{{ params?.value?.submittedAt | date:'dd MMM yyy' }}</div>
      </div>
    </div>
    <div class="rejection-text">Do you want to mark this invoice for discounting as rejected?</div>
    <form [formGroup]="rejectInvoiceDiscountingForm" class="reject-id-form">
      <div class="reason-header">Reason for Rejection *</div>
      <div class="row">
        <div class="col-12">
          <textarea class="form-control oms-textarea" placeholder="Reason for Rejection" formControlName="rejectReason" required></textarea>
        </div>
        <div class="oms-input-validation-error" *ngIf="rejectInvoiceDiscountingForm.controls['rejectReason'].errors &&
                          (rejectInvoiceDiscountingForm.controls['rejectReason'].dirty || rejectInvoiceDiscountingForm.controls['rejectReason'].touched)">
          <p *ngIf="rejectInvoiceDiscountingForm.controls['rejectReason'].hasError('required')">
            Reject Reason is required.
          </p>
        </div>
      </div>
      <div class="btn-container">
        <div>
          <button class="btn zetwerk-blue-link-button" type="button" (click)="rejectInvoiceDiscountingModalRef.hide()">No</button>
        </div>
        <div>
          <button class="btn oms-btn-primary ok-btn" type="button" (click)="reject()">Yes, Reject</button>
        </div>
      </div>
    </form>
  </div>
</ng-template>
