import { Component, OnInit, HostListener } from '@angular/core';
import { WindowRef } from 'src/app/services/window.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GeneralService } from 'src/app/services/general.service';

@Component({
  selector: 'app-customer-feedback-form',
  templateUrl: './customer-feedback-form.component.html',
  styleUrls: ['./customer-feedback-form.component.scss']
})
export class CustomerFeedbackFormComponent implements OnInit {
  public feedbackForm = {
    qualityRatingsOfMaterials: '',
    pointsOfImprovement: {
      communication: false,
      deliveryTimeLines: false,
      qualityOfWork: false,
      progressUpdates: false
    },
    likenessToWorkWithCompanyInFuture: '',
    remarks: ''

  };
  feedbackDetails: any = {};


  showId = {
    qualityDispatch: false,
    improve: true,
    remarks: true,
    nps: true,
    submit: true
  };
  form: any;
  constructor(private winRef: WindowRef, private route: ActivatedRoute, private router: Router,
    private generalService: GeneralService) {
  }
  ngOnInit() {
    this.getCustomerFeedback();
  }



  checkIsDissatisfied() {
    const quality = ['Very dissatisfied', 'Dissatisfied', 'Neither'];
    return quality.indexOf(this.feedbackForm.qualityRatingsOfMaterials) >= 0;
  }

  isSelected() {

  }

  select(childKey, value) {
    this.feedbackForm[childKey] = value;
  }

  scrollTo(event, type?, end?) {
    let nextClass = {};
    if (type === 'isDivClick' || event.keyCode === 13) {
      nextClass = event.target.parentElement;
    } else if (end === 'end') {
      // it's a quick hack-- Need to remove
      nextClass = { id: 'submit' };
    } else {
      nextClass = event.target.parentNode.parentElement.nextElementSibling;
    }
    if (!nextClass) {
      return;
    }

    const id = nextClass['id'];
    if (!id) {
      return;
    }
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.makeOtherDivFalse(id);
  }




  makeOtherDivFalse(id) {
    for (const key in this.showId) {
      if (key === id) {
        this.showId[key] = false;
      } else {
        this.showId[key] = true;
      }
    }
  }

  @HostListener('window:scroll', ['$event']) scrollHandler($event) {
    // this.scrollTo($event);

    const windowTop = window.pageYOffset,
      feedbackIds = Array.from(document.querySelectorAll('.dispatch'));
    const feedbackId = feedbackIds.filter(function (e) {
      return e['offsetTop'] < windowTop + 400;
    });
    const id = feedbackId[feedbackId.length - 1].id;
    if (id) {
      this.makeOtherDivFalse(id);
    }
  }

  checkIsDisabled(key) {
    const obj = this.feedbackForm[key];
    const data = Object.keys(obj).some(function (t) {
      if (typeof obj[t] === 'boolean') {
        return obj[t];
      }
    });
    return !data;
  }

  submit(form) {
    const data = this.feedbackForm;
    const feedbackId = this.route.snapshot.params['feedbackId'];
    this.generalService.submitCustomerFeedback(data, feedbackId).subscribe(() => {
      this.router.navigate(['thankyou']);
    });
  }

  checkIsRequired(form, key) {
    const obj = form.value['feedbackForm'];
    if (!obj || !obj[key] || typeof obj !== 'object') {
      return false;
    }
    return Object.keys(obj[key]).every((k) => !obj[key][k]);
  }

  getCustomerFeedback() {
    const feedbackId = this.route.snapshot.params['feedbackId'];
    this.generalService.getCustomerFeedback(feedbackId).subscribe(data => {
      if (data['data'].feedbackSubmitted) {
        this.router.navigate(['thankyou'], { queryParams: { submitted: true } });
      }
      this.feedbackDetails = data['data'];
      // API not ready need to change
      const qualityRatingsOfMaterials = this.route.snapshot.queryParams['qualityRatingsOfMaterials'];
      this.feedbackForm.qualityRatingsOfMaterials = qualityRatingsOfMaterials ? qualityRatingsOfMaterials.replace(/_/g, ' ') : '';
    });
  }

}
