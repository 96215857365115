import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-under-maintainence',
  templateUrl: './under-maintainence.component.html',
  styleUrls: ['./under-maintainence.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnderMaintainenceComponent implements OnInit {
  @Input() title: any;
  @Input() desc: any;
  constructor() { }

  ngOnInit() {
  }

}
