import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  IFilterOptions,
  IGetRowsParams,
  ITableOptions,
} from "@zetwerk/zet-list";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap } from "rxjs/operators";
import {
  BOOK_CLOSURE_TABLE_OPTIONS,
  BOOK_CLOSURE_TABS,
  PAGINATION_SIZE,
} from "src/app/constants/book-closure";
import { BookClosureService } from "src/app/services/book-closure.service";
import { groupFilterQueryString } from "src/app/utils";
import * as moment from "moment";
import { BookClosureDetailsComponent } from "./book-closure-details/book-closure-details.component";

@Component({
  selector: "app-book-closure",
  templateUrl: "./book-closure.component.html",
  styleUrls: ["./book-closure.component.scss"],
})
export class BookClosureComponent implements OnInit {
  tabs: any[] = BOOK_CLOSURE_TABS;
  filterOptions: Array<IFilterOptions> = [];
  tableOptions: ITableOptions;
  dataProvider: DataProvider;
  zetListContext: any;
  activeTab: string;
  searchText$ = new Subject<string>();
  searchTextValue: string = "";
  bsConfig = { dateInputFormat: 'DD/MM/YYYY', containerClass: "theme-default", showWeekNumbers: false };
  startDate: any;
  endDate: any;
  selectedPeriod: any;
  showPeriodForm: boolean;

  @ViewChild(BookClosureDetailsComponent) bookClosureDetailsComponent: BookClosureDetailsComponent;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _bookClosureService: BookClosureService,
    private _changeDetectorRef: ChangeDetectorRef
  ) {
    this.tableOptions = BOOK_CLOSURE_TABLE_OPTIONS;
    this.zetListContext = {
      status: "OPEN",
    };
    this.dataProvider = new DataProvider(this._bookClosureService);
  }

  ngOnInit(): void {
    this.searchText$
      .pipe(debounceTime(400), distinctUntilChanged())
      .subscribe((searchText) => {
        this.searchTextValue = searchText;
        this.zetListContext = { ...this.zetListContext, searchText };
      });

    this._bookClosureService.$firstItem.subscribe((value: any) => {
      if (!this.selectedPeriod) {
        this.selectedPeriod = value;
      }
    });

    this._activatedRoute.params.subscribe(value => {
      this.activeTab = value.status;
      this.setActiveTab();
    });

    this._bookClosureService.$showPeriodDetails.subscribe((value: any) => {
      this.showPeriodForm = !this.selectedPeriod || value;
    });
  }

  ngAfterViewChecked() {
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Set the active tab and zetlist context
   */
  setActiveTab() {
    this.tabs.forEach(
      (tab: any) => (tab.active = tab.status === this.activeTab)
    );
  }

  /**
   * Handle tab select and updates the quick filters
   * @param tab
   */
  onTabSelect(tab: any) {
    tab.active = true;
    this.startDate = null;
    this.endDate = null;
    this.selectedPeriod = null;

    this.zetListContext = {
      ...this.zetListContext,
      startDate: null,
      endDate: null,
      status: tab?.status,
    };

    this._router.navigate(["book-closure", tab?.status], {
      queryParamsHandling: "preserve",
    });
  }

  /**
   * Handle period create
   */
  onPeriodCreate() {
    if (this.bookClosureDetailsComponent?.isEdit) return;
    this.selectedPeriod = null;
    this.bookClosureDetailsComponent.period = null;
  }

  /**
   * Handle row click
   * @param event
   */
  onRowClick(event: any) {
    this.selectedPeriod = event;
    this.bookClosureDetailsComponent.isEdit = false;
  }

  /**
   * Listen to start date change and update the zet list context
   * @param date
   */
  onStartDateChange(date: any) {
    if (date || this.startDate) {
      this.zetListContext = {
        ...this.zetListContext,
        startDate: date,
      };
    }
    this.startDate = date;
  }

  /**
   * Listen to end date change and update the zet list context
   * @param date
   */
  onEndDateChange(date: any) {
    if (date || this.endDate) {
      this.zetListContext = {
        ...this.zetListContext,
        endDate: date,
      };
    }
    this.endDate = date;
  }

  /**
   * Handle form action
   * @param event
   */
  onAction(event: any) {
    if (["SUCCESS", "CANCEL"].includes(event?.type)) {
      this.zetListContext = {
        ...this.zetListContext
      };
    }
  }
}

class DataProvider {
  constructor(private _bookClosureService: BookClosureService) {}

  /**
   * Get all transactions
   * @param params
   * @returns observable
   */
  getRows({
    pageNumber = "1",
    filterQueryString = "",
    context = {},
  }: IGetRowsParams) {
    const { searchText, sortBy } = context;

    const params = {
      pageNumber,
      recordsPerPage: PAGINATION_SIZE,
      sortBy: sortBy || "createdAt",
      searchText: searchText || "",
    };

    return this._bookClosureService.getAllPeriods(
      params,
      this.constructQueryString(filterQueryString, context)
    ).pipe(
      tap((response: any) => {
        if (response?.success) {
          const { allDocuments, meta } = response?.data || {};
          this._bookClosureService.firstItem = allDocuments[0];
          this._bookClosureService.showPeriodDetails = meta?.total > 0;
        }
      })
    );
  }

  /**
   * Construct query string from zet list context values
   * @param filterQueryString
   * @param context
   * @returns query string
   */
  constructQueryString(filterQueryString: string, context: any = {}) {
    const { status, startDate, endDate } = context || {};
    let queryString = groupFilterQueryString(filterQueryString);

    if (status) {
      queryString += `&status=${status}`;
    }

    if (startDate) {
      queryString += `&startDate=${this.formatDate(startDate)}`;
    }

    if (endDate) {
      queryString += `&endDate=${this.formatDate(endDate)}`;
    }

    return queryString;
  }

  /**
   * Formats the given date
   * @param date
   * @returns date string
   */
  formatDate(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }
}
