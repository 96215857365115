import { Component } from "@angular/core";
import { ICellCmp } from "@zetwerk/zet-list";

@Component({
  selector: "app-narration-cell",
  templateUrl: "./narration-cell.component.html",
  styleUrls: ["./narration-cell.component.scss"],
})
export class NarrationCellComponent implements ICellCmp {
  params: any;
  columnDef: any;
  element: any;

  constructor() {}

  init(params: any) {}
}
