import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OmsService } from '../oms.service';
import { environment } from '../../../environments/environment';

const SOCKET_URL =  environment.socketEndPoint;

@Injectable({
  providedIn: 'root'
})
export class SocketService {

  private socket: any;

  constructor(private omsService: OmsService) {
  }
  public initializeSocket(): void {
    //  this.socket = socketIo(SOCKET_URL);
  }

  public emit(event: String, data: any) {
    this.socket.emit(event, data);
  }

  public onEvent(event: String): Observable<any> {
    return new Observable((observer) => {
      this.socket.on(event, (response) => {
        observer.next(response);
      });
    });
  }
}
