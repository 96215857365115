<div [ngClass]="params?.value?.cellClass">

  <!-- Transaction Number -->
  <div class="title">{{ params?.value?.transactionNumber }}</div>

  <!-- Status -->
  <div class="row" *ngIf="status">
    <span class="tag" [ngClass]="status?.class" *ngIf="true">
      <img class="tag-icon" src="/assets/icons/bank-transaction/{{ status?.icon + '.svg'}}" />
      {{ status?.text }}
    </span>
  </div>
</div>
