<div class="book-closure-details-container" *ngIf="showSection" [formGroup]="periodForm">
  <div class="row space-between">
    <div class="row">
      <p class="title">{{ period?.periodNumber || 'Create Period' }}</p>
    </div>
    <div class="row" *ngIf="isEdit; else isView">
      <button class="btn oms-btn-default btn-black mr-2" (click)="onCancelPeriod()">Cancel</button>
      <button class="btn oms-btn-primary btn-blue mr-2"  (click)="onSavePeriod()">Save</button>
      <button class="btn oms-btn-primary btn-red" [appPermissions]="['AUTO_BOOK_CLOSURE']" *ngIf="period?.periodNumber" (click)="onClosePeriod()">Close</button>
    </div>
    <ng-template #isView>
      <button class="edit-icon" (click)="onEditPeriod()" *ngIf="this.activeTab === 'OPEN'"><img src="/assets/icons/book-closure/edit_button.svg" /></button>
    </ng-template>
  </div>

  <hr>

  <div class="table-content">
    <div class="row grid-container">
      <p class="table-main-header">Period Dates</p>
      <p class="table-sub-header one" [ngClass]="(isEdit ? 'zetwerk-required': '')">From Date</p>
      <p class="table-sub-header" [ngClass]="(isEdit ? 'zetwerk-required': '')">To Date</p>
    </div>

    <div class="row grid-container" *ngIf="!isEdit">
      <p class="table-data"></p>
      <p class="table-data">{{ periodForm.get('startDate').value | date : 'd MMM y'}}</p>
      <p class="table-data">{{ periodForm.get('endDate').value | date:'d MMM yyy' }}</p>
    </div>
    <div class="row grid-container" *ngIf="isEdit">
      <p class="table-data"></p>
      <div class="date-container"><input type="date" class="oms-input oms-date-input input-field mb-0" placeholder="Select From Date" [max]="today"
        formControlName="startDate"/>
        <div class="oms-input-validation-error" *ngIf="periodForm.controls['startDate'].errors && (periodForm.controls['startDate'].dirty
         || periodForm.controls['startDate'].touched)">
          <p *ngIf="periodForm.controls['startDate'].hasError('required')">
            From Date is required.
          </p>
        </div>
        <p class="oms-input-validation-error" *ngIf="startDateError"> {{ startDateError }} </p>
      </div>
      <div class="date-container"><input type="date" class="oms-input oms-date-input input-field mb-0" placeholder="Select To Date" [max]="today"
        formControlName="endDate"/>
        <div class="oms-input-validation-error" *ngIf="periodForm.controls['endDate'].errors && (periodForm.controls['endDate'].dirty
         || periodForm.controls['endDate'].touched)">
          <p *ngIf="periodForm.controls['endDate'].hasError('required')">
            End Date is required.
          </p>
        </div>
        <p class="oms-input-validation-error" *ngIf="endDateError"> {{ endDateError }} </p>
      </div>
    </div>
  </div>

  <hr>

  <div class="table-content">
    <div class="row grid-container">
      <p class="table-main-header">Period Validity</p>
      <p class="table-sub-header" [ngClass]="(isEdit ? 'zetwerk-required': '')">Date</p>
      <p class="table-sub-header" [ngClass]="(isEdit ? 'zetwerk-required': '')">Time</p>
    </div>

    <div class="row grid-container" *ngIf="!isEdit">
      <p class="table-data"></p>
      <p class="table-data">{{ periodForm.get('validTill').value | date:'d MMM yyy' }}</p>
      <p class="table-data">{{ periodForm.get('validTill').value | date:'hh:mm a' }}</p>
    </div>
    <div class="row grid-container" *ngIf="isEdit">
      <p class="table-data"></p>
      <div class="date-container"><input type="date" class="oms-input oms-date-input input-field mb-0" placeholder="Select Validity Date"
        formControlName="validityDate"/>
        <div class="oms-input-validation-error" *ngIf="periodForm.controls['validityDate'].errors && (periodForm.controls['validityDate'].dirty
         || periodForm.controls['validityDate'].touched)">
          <p *ngIf="periodForm.controls['validityDate'].hasError('required')">
            Validity Date is required.
          </p>
        </div>
        <p class="oms-input-validation-error" *ngIf="validityError"> {{ validityError }} </p>
      </div>
      <div class=""><input type="time" step="1" class="oms-input input-field mb-0" placeholder="Select Validity Time" [max]="today"
        formControlName="validityTime"/>
        <div class="oms-input-validation-error" *ngIf="periodForm.controls['validityTime'].errors && (periodForm.controls['validityTime'].dirty
         || periodForm.controls['validityTime'].touched)">
          <p *ngIf="periodForm.controls['validityTime'].hasError('required')">
            Validity Time is required.
          </p>
        </div>
      </div>
    </div>
  </div>

  <hr>

  <div class="row">
    <div class="col-3">
      <p class="table-main-header">Entity Level</p>
    </div>
    <div class="row table-data list-data check-list" *ngFor="let scope of entityScopes; let i = index;">
      <input type="checkbox" class="all-checkbox" (click)="toggleScope(scope, 'ENTITY')" [disabled]="!isEdit" [checked]="isScopeChecked(scope, 'ENTITY')">
      <span class="mr-60" [ngClass]="(!isScopeChecked(scope, 'ENTITY') && !isEdit ? 'disabled-text': '')">{{ scope }}</span>
    </div>
  </div>

  <hr>

  <div class="row space-between mb-0">
    <div class="row">
      <p class="table-main-header">Select BU</p>
    </div>
    <div class="row">
      <input type="checkbox" class="all-checkbox" (click)="selectAllScopesForAllBu()" [checked]="isAllScopesForAllBuSelected" [disabled]="!isEdit">
      <span class="label-st" [ngClass]="(!isEdit ? 'disabled-text': '')">Select All</span>
    </div>
  </div>

  <p class="oms-input-validation-error mb-1" *ngIf="scopesError">{{ scopesError }}</p>

  <div class="row">
    <div class="column col-5 bu-column">
      <div class="row table-data list-data bu-list" *ngFor="let bu of businessUnits; let i = index;"
      [ngClass]="(currentSelectedBuId === bu._id ? 'selected-item': '')" (click)="onBuSelect(bu)">
        {{ bu.name }} <span class="scopes-count ml-auto" *ngIf="bu?.scopesCount"> • {{ bu?.scopesCount }}</span>
      </div>
    </div>
    <div class="column scope-column col-7">
      <div class="row space-between">
        <div class="row table-main-header">
          Select Edit Scope
        </div>
        <div class="row">
          <input type="checkbox" class="all-checkbox" (click)="selectAllScopesForCurrentBu()" [checked]="isAllScopesForCurrentBuSelected" [disabled]="!isEdit">
          <span class="label-st" [ngClass]="(!isEdit ? 'disabled-text': '')">Select All</span>
        </div>
      </div>
      <div class="row table-data list-data" *ngFor="let scope of buScopes; let i = index;">
        <input type="checkbox" class="all-checkbox" (click)="toggleScope(scope, 'BU')" [checked]="isScopeChecked(scope, 'BU')" [disabled]="!isEdit">
        <span [ngClass]="(!isScopeChecked(scope, 'BU') && !isEdit ? 'disabled-text': '')">{{ scope }}</span>
      </div>
    </div>
  </div>
</div>
