import { Component, OnDestroy, OnInit } from '@angular/core';
import { OmsService } from '../../services/oms.service';

import { Router } from '@angular/router';
// import { setTime } from 'ngx-bootstrap/chronos/utils/date-setters';
import { PermissionsService } from '../../services/permissions.service';
import { GSTservice } from 'src/app/services/gst.service';
import { UsersService } from 'src/app/services/users.service';

import mixpanel from "mixpanel-browser";
import { LoginType } from '@zetwerk/zetui';
import { TenantService } from 'src/app/services/tenant.service';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';
import { AppInitService } from 'src/app/services/app-init.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

declare var heap: any;
declare var pendo: any;
declare var posthog: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  companyDetails: any;
  loginType = LoginType;
  private subscription: Subscription = new Subscription();

  constructor(
    private router: Router,
    private _omsService: OmsService,
    private _ps: PermissionsService,
    private _gst: GSTservice,
    private userService: UsersService,
    private tenantService: TenantService,
    private featureFlagService: FeatureFlagService,
    private appInitService: AppInitService

  ) {
  }

  title = 'proj';
  companySelect = false;
  isChannelPartner = false;
  ngOnInit() {
    this.isChannelPartner = this._omsService.getIsChannelPartner();
    if (this._omsService.getAuthToken()) {
      this.router.navigate(['/contract/view/OPEN']);
    }
    this.subscription.add(
      this.appInitService.companySelected$.subscribe(({ companySelected, companyDetails }) => {
        this.isChannelPartner = this._omsService.getIsChannelPartner();
        this.companySelect = companySelected;
        this.companyDetails = companyDetails;
      })
    );
  }

  extractUserRoles(roleDetails) {
    if (!roleDetails || roleDetails.length === 0) {
      return '';
    }

    return roleDetails.map(role => role['name']).join(',');
  }

  public onLogin(response: any) {

    this._omsService.setUserName(response.name);
    this._omsService.setUserImage(response['image']);
    this._omsService.setUserEmail(response.email);

    this._omsService.setToken(response['tokens']['zetAuthToken']);
    this._omsService.setUserRole(this.extractUserRoles(response['userObject']['roleDetails']));
    this.companyDetails = response['userObject']['companies'];
    this._omsService.setUserCompany(this.companyDetails);
    // this._omsService.setSOSToken(response['smesToken']);
    // this._omsService.setUserPermissions(response['permissions']);
    this.setPermissions();
    this._omsService.setUserId(response['userObject']['_id']);
    // Attach a unique identifier to a heap profile
    heap.identify(this._omsService.getUserEmail());

    // Attach a unique identifier to a Pendo profile
    pendo.initialize({
      visitor: {
        id: this._omsService.getUserEmail()
      }
    });

    // Attach a unique identifier to a postHog profile
    posthog.identify(
      this._omsService.getUserEmail(),  // Replace 'distinct_id' with your user's unique identifier
    );
    // Map device id to mixpanel user
    mixpanel.identify(this._omsService.getUserEmail());
    mixpanel.people.set({
      '$email': this._omsService.getUserEmail(),
      '$name': this._omsService.getUserName()
    });

  }

  /**
   * If the user has VIEW_CONTRACTS and VIEW_CONTRACTS_MAIN permissions
   * redirect him to /contract/view/OPEN
   * otherwise redirect to users/view
   */
  redirectUser() {
    const hasContractViewPermission = this._ps.userHasPermission({
      permissions: ['VIEW_CONTRACTS', 'VIEW_CONTRACTS_MAIN']
    });
    const hasCustomerViewPermission = this._ps.userHasPermission({
      permissions: ['VIEW_CUSTOMERS']
    });
    // if(this.companyDetails && this.companyDetails.length > 1) {
    //   console.log(this.companyDetails);
    //   this.companySelect = true
    // }else {
    //   this._omsService.setSelectedCompany(this.companyDetails[0].companyDetails.slug);
    //   if (hasContractViewPermission) {
    //     this.router.navigate([`/company/${this.companyDetails[0].companyDetails.slug}/contract/view/OPEN`]);
    //   } else {
    //     this.router.navigate([`/company/${this.companyDetails[0].companyDetails.slug}/users/view`]);
    //   }
    // }
    let companySlug = this._omsService.getSelectedCompany();
    if (hasContractViewPermission) {
      this.router.navigate([`/company/${companySlug}/contract/view/OPEN`]);
    } else if (hasCustomerViewPermission) {
      this.router.navigate([`/company/${companySlug}/customers/view`]);
    } else {
      this.router.navigate([`/company/${companySlug}/users/view`]);
    }
  }

  setPermissions() {
    if (this.companyDetails && this.companyDetails.length > 1) {
      this.companySelect = true
    } else {
      this._omsService.setSelectedCompany(this.companyDetails[0].companyDetails.slug);
      this.tenantService.setTenantId();
      this._omsService.setSelectedCompanySlug(this.companyDetails[0].companyDetails.slug);
      const gstData = this._gst.gstData.then(null);
      this._ps.getUserPermissions().subscribe(response => {
        if (response['success']) {
          this._omsService.setUserPermissions(response['data']['permissions']);
          this.featureFlagService.setFeatureFlags(response['data']['featureFlags']);
          this.redirectUser();
        }
      });
    }
  }


  onChangeCompany(companyName, company) {
      company.isSelected = true;
      // const hasContractViewPermission = this._ps.userHasPermission({
      //   permissions: ['VIEW_CONTRACTS', 'VIEW_CONTRACTS_MAIN']
      // });
      this._omsService.setSelectedCompany(companyName);
      this.tenantService.setTenantId();
      this._omsService.setSelectedCompanySlug(companyName);
      const gstData = this._gst.gstData.then(null);
      // if (hasContractViewPermission) {
      //   this.router.navigate([`/company/${companyName}/contract/view/OPEN`]);
      // } else {
      //   this.router.navigate([`/company/${companyName}/users/view`]);
      // }
      this._ps.getUserPermissions().subscribe(response => {
        if (response['success']) {
          this._omsService.setUserPermissions(response['data']['permissions']);
          this.featureFlagService.setFeatureFlags(response['data']['featureFlags']);
          this.redirectUser();
        }
      });
  }

  onChannelPartnerLogin(event) {
    this._omsService.setToken(event['data']['tokens']['zetAuthToken']);
    this._omsService.setUserRole(this.extractUserRoles(event['userObject']['roleDetails']));
    this.companyDetails = event['userObject']['companies'];
    this._omsService.setUserCompany(this.companyDetails);
    this.setPermissions();
    this._omsService.setUserId(event['userObject']['_id']);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
