import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-thankyou-form',
  templateUrl: './thankyou-form.component.html',
  styleUrls: ['./thankyou-form.component.scss']
})
export class ThankyouFormComponent implements OnInit {

  constructor(private route: ActivatedRoute) { }
  isSubmitted: Boolean = false;
  ngOnInit() {
    this.isSubmitted = this.route.snapshot.queryParams['submitted'];
  }

}
