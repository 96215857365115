// Supplier sort option interface
interface SUPPLIER_SORT {
  "status.sortOrder"?: number;
  createdAt?: number;
  _id?: number;
  "basicInformation.name"?: number;
  financialInfoRejectedDate?: number;
}

interface FINANCIER_SORT {
  financierName?: number;
}

// Request parameters interface
interface REQUEST_PARAMETERS {
  pageNumber?: string;
  perPage?: number;
  searchText?: string;
  query?: any;
  sort?: SUPPLIER_SORT | FINANCIER_SORT;
  projections?: string[];
  fieldsToPopulate?: string[];
  paginate?: boolean;
}

/**
 * Get request parameters for API
 * @param params - {
 *  pageNumber - Page number
 *  perPage - Number of records per page
 *  searchText - Search text
 *  query - Find conditions
 *  sort - Sort fields
 *  projections - Fields to populate
 * }
 * @returns object
 */
export function getRequestParams({
  pageNumber = "1",
  perPage = 10,
  searchText = "",
  query = {},
  sort = {},
  projections = [],
  fieldsToPopulate = [],
  paginate = true
}: REQUEST_PARAMETERS) {
  const params: any = {
    pagination: {
      pageNumber,
      perPage,
    },
    filter: {},
    projection: {},
    sort,
    fieldsToPopulate,
    paginate
  };

  if (searchText) {
    params.filter._search = searchText;
  }

  for (const key in query) {
    params.filter[key] = query[key];
  }

  for (const projection of projections) {
    params.projection[projection] = 1;
  }

  for (const key in params) {
    params[key] = JSON.stringify(params[key]);
  }

  return params;
}

/**
 * Get meta object from API response
 * @param params - {
 *  response - API response object
 *  pageNumber - Page number
 *  recordsPerPage - Number of records per page
 * }
 * @returns object
 */
export function getResponseMeta({
  response,
  pageNumber = "1",
  recordsPerPage = 10,
}) {
  return {
    total: response?.data?.totalCount,
    limit: recordsPerPage,
    offset: Number(pageNumber) - 1,
    nextStart: Number(pageNumber) * recordsPerPage + 1,
  };
}

/**
 * Map GET supplier API response
 * @param params - {
 *  records - Supliers data
 *  slug - Company slug
 * }
 * @returns object
 */
export function mapSupplierResponse({ records = [], slug = "" }) {
  if (!records?.length) return [];

  return records.map((record: any) => {
    const company = record?.companies?.find(
      (company: any) => company.slug === slug
    );
    const companyStatus = company ? "Active" : "Inactive";
    const location = [
      record?.basicInformation?.location?.city,
      record?.basicInformation?.location?.state,
      record?.basicInformation?.location?.country,
    ];

    const supplier = {
      _id: record?._id,
      supplierName: record?.basicInformation?.name,
      supplierLocation: location.filter(Boolean).join(', '),
      emailId: record?.basicInformation?.email,
      phoneNo: `${record?.basicInformation?.phone?.countryCode} ${record?.basicInformation?.phone?.number}`,
      financialInfoRequestedBy: record?.financialInfoRequestedBy,
      financialInfoVerifiedBy: record?.financialInfoVerifiedBy,
      financialInfoRejectedBy: record?.financialInfoRejectedBy,
      financeVerificationType: record?.financeVerificationType,
      financeVerificationStatus: record?.financeVerificationStatus,
      supplierGroup: record?.supplierGroup,
      supplierNationality: record?.supplierNationality,
      baseCurrencyDetails: record?.baseCurrencyDetails,
      ledgerLinkName: "View Ledger",
      companyStatus,
    };

    return supplier;
  });
}

