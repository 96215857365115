import { CommonSharedModule } from './../common-shared.module';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CommonCellComponent } from './components/common-cell/common-cell.component';
import { CurrencyCellComponent } from './components/currency-cell/currency-cell.component';
import { DateCellComponent } from './components/date-cell/date-cell.component';
import { SubtextCellComponent } from './components/subtext-cell/subtext-cell.component';
import { StatusCellComponent } from './components/status-cell/status-cell.component';
import { RejectCellComponent } from '../../finance/invoice-discounting/reject-cell/reject-cell.component';
import { ZetwerkDatatableModule } from '../data-table/data-table.module';
import { InterestCellComponent } from './components/interest-cell/interest-cell.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SubtextDateCellComponent } from './components/subtext-date-cell/subtext-date-cell.component';
import { DateSubtextCellComponent } from './components/date-subtext-cell/date-subtext-cell.component';
import { ActionsCellComponent } from './components/actions-cell/actions-cell.component';
import { DateSubCurrencyCellComponent } from './components/date-sub-currency-cell/date-sub-currency-cell.component';
import { LinkCellComponent } from './components/link-cell/link-cell.component';
import { SubtextCurrencyCellComponent } from './components/subtext-currency-cell/subtext-currency-cell.component';
import { NarrationCellComponent } from './components/narration-cell/narration-cell.component';
import { TransactionNumberCellComponent } from './components/transaction-number-cell/transaction-number-cell.component';

@NgModule({
  imports: [
    CommonModule,
    ZetwerkDatatableModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    CommonCellComponent,
    CurrencyCellComponent,
    DateCellComponent,
    SubtextCellComponent,
    SubtextDateCellComponent,
    StatusCellComponent,
    RejectCellComponent,
    InterestCellComponent,
    DateSubtextCellComponent,
    ActionsCellComponent,
    DateSubCurrencyCellComponent,
    LinkCellComponent,
    SubtextCurrencyCellComponent,
    NarrationCellComponent,
    TransactionNumberCellComponent,
  ],
  exports: [],
  entryComponents: [
    CommonCellComponent,
    DateCellComponent,
    CurrencyCellComponent,
    SubtextCellComponent,
    SubtextDateCellComponent,
    StatusCellComponent,
    RejectCellComponent,
    InterestCellComponent,
    LinkCellComponent,
  ]
})

export class ZetTableModule { }
