export const paymentType = [
  {
    name: 'TAX_INVOICE',
    value: 'Tax Invoice',
    isMandatory: true,
  },
  {
    name: 'LR',
    value: 'LR/Docket No.',
    isMandatory: true,
  },
  {
    name: 'EWAY_BILL',
    value: 'E-Way Bill',
    isMandatory: false,
  },
  {
    name: 'INSPECTION_LIST',
    value: 'Inspection List',
    isMandatory: false,
  },
  {
    name: 'PACKING_LIST',
    value: 'Packing List',
    isMandatory: false,
  },
  {
    name: 'WEIGH_SLIP',
    value: 'Weigh Slip',
  },
  {
    name: 'PROFORMA_INVOICE',
    value: 'Proforma Invoice',
    isMandatory: true,
  },
  {
    name: 'PDC',
    value: 'PDC',
    isMandatory: false,
  },
  {
    name: 'PROFORMA_INVOICE_ADVANCE',
    value: 'Proforma Invoice',
    isMandatory: false,
  },
];

export const paymentRequestTabs = [
  {
    title: 'Verification Pending',
    active: true,
    status: 'verification-pending',
    countKey: 'VERIFICATION_PENDING',
  },
  {
    title: 'Payment Pending',
    active: false,
    status: 'payment-pending',
    countKey: 'PAYMENT_PENDING',
  },
  {
    title: 'Payment Done',
    active: false,
    status: 'payment-done',
    countKey: 'PAYMENT_DONE',
  },
  {
    title: 'Document Rejected',
    active: false,
    status: 'pr-document-rejected',
    countKey: 'PR_DOCUMENT_REJECTED',
  },
  {
    title: 'Deleted PR',
    active: false,
    status: 'pr-deleted',
    countKey: 'PR_DELETED',
  },
];

export const COLUMN_DEFENITIONS = {
  VERIFICATION_PENDING: [
    {
      headerName: 'PR ID.',
      field: 'paymentRequestNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Order No',
      field: 'orderNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Supplier Name',
      field: 'supplierName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'sub',
      subField: 'supplierLocation',
    },
    {
      headerName: 'GST',
      field: 'zetwerkGst',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Contract No.',
      field: 'contractNumber',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
    {
      headerName: 'Business Unit',
      field: 'businessUnit',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
  ],
  PAYMENT_PENDING: [
    {
      headerName: 'PR ID.',
      field: 'paymentRequestNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Order No',
      field: 'orderNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Supplier Name',
      field: 'supplierName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'sub',
      subField: 'supplierLocation',
    },
    {
      headerName: 'GST',
      field: 'zetwerkGst',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Contract No.',
      field: 'contractNumber',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
    {
      headerName: 'Business Unit',
      field: 'businessUnit',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
  ],
  PAYMENT_DONE: [
    {
      headerName: 'PR ID.',
      field: 'paymentRequestNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Order No',
      field: 'orderNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Supplier Name',
      field: 'supplierName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'sub',
      subField: 'supplierLocation',
    },
    {
      headerName: 'GST',
      field: 'zetwerkGst',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Contract No.',
      field: 'contractNumber',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
    {
      headerName: 'Business Unit',
      field: 'businessUnit',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
  ],
  DOCUMENT_REJECTED: [
    {
      headerName: 'PR ID.',
      field: 'paymentRequestNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Order No',
      field: 'orderNumber',
      widthInPercentage: 15,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Supplier Name',
      field: 'supplierName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'sub',
      subField: 'supplierLocation',
    },
    {
      headerName: 'GST',
      field: 'zetwerkGst',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Contract No.',
      field: 'contractNumber',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
    },
    {
      headerName: 'Customer Name',
      field: 'customerName',
      widthInPercentage: 20,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
    {
      headerName: 'Business Unit',
      field: 'businessUnit',
      widthInPercentage: 10,
      filter: 'ColumnFilter',
      columnType: 'tooltip',
    },
  ],
};

export const PAYMENT_REQUEST_STATUS_MAP = {
  VERIFICATION_PENDING: 'Verification Pending',
  PAYMENT_DONE: 'Payment Done',
  PR_DOCUMENT_VERIFIED: 'PR Document Verified',
  PAYMENT_ON_HOLD: 'Payment On Hold',
  PR_DELETED: 'PR Deleted',
  PAYMENT_DUE: 'Payment Due',
  PR_DOCUMENT_REJECTED: 'PR Document Rejected',
  PAYMENT_PENDING: 'Payment Pending'
};

export const ADVANCE_DOCS = {
  PROFORMA_INVOICE: {
    name: 'PROFORMA_INVOICE',
    value: 'Proforma Invoice',
    isMandatory: true,
    controls: {
      invoiceNumber: {
        required: true
      },
      invoiceDate: {
        required: true
      },
      tcsRate: {
        required: false
      },
      totalValue: {
        required: true
      }
    },
    additionalControls: {
      gstTaxValue: {
        required: true
      },
      taxableValue: {
        required: true
      }
    }
  },
  INSPECTION_LIST: {
    name: 'INSPECTION_LIST',
    value: 'Inspection List',
    isMandatory: false,
    controls: {
      dateOfInspection: {
        required: true
      }
    }
  },
  PDC: {
    name: 'PDC',
    value: 'PDC',
    isMandatory: false,
    controls: {
      pdcAmount: {
        required: true
      },
      pdcDueDate: {
        required: true
      }
    }
  },
  PACKING_LIST: {
    name: 'PACKING_LIST',
    value: 'Packing List',
    isMandatory: false,
    controls: {}
  }
};

export const ADHOC_DOCS = {
  PROFORMA_INVOICE: {
    name: 'PROFORMA_INVOICE',
    value: 'Proforma Invoice',
    isMandatory: false,
    controls: {
      totalValue: {
        required: true
      }
    },
    additionalControls: {
      gstTaxValue: {
        required: true
      },
      taxableValue: {
        required: true
      }
    }
  }
};

export const DOCTYPE_MAPPER = {
  PROFORMA_INVOICE: 'Proforma Invoice',
  INSPECTION_LIST: 'Inspection List',
  PDC: 'PDC',
  PACKING_LIST: 'Packing List'
}

export const PAYMENT_REQUEST_TYPES = {
  AD_HOC: 'Adhoc',
  ADVANCE: 'Advance',
  AGAINST_INVOICE: 'Against Invoice'
};

export enum PAYMENT_REQUEST_STATUS_ENUM {
  VERIFICATION_PENDING = 'VERIFICATION_PENDING',
  PAYMENT_DONE = 'PAYMENT_DONE',
  PR_DOCUMENT_VERIFIED = 'PR_DOCUMENT_VERIFIED',
  PAYMENT_ON_HOLD = 'PAYMENT_ON_HOLD',
  PR_DELETED = 'PR_DELETED',
  PAYMENT_DUE = 'PAYMENT_DUE',
  PR_DOCUMENT_REJECTED = 'PR_DOCUMENT_REJECTED',
  PAYMENT_PENDING = 'PAYMENT_PENDING'
};

export const ACTION_TYPES = {
  CREATE_PAYMENT_REQUEST: 'CREATE_PAYMENT_REQUEST'
}

export const DEFAULT_TDS_RATES_BY_PO_CATEGORY = {
  GOODS: '194Q_0.1%',
  COMPOSITE: '0%-Threshold',
  SERVICE: '0%-Threshold'
}