import { Params } from '@angular/router';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { GSTservice } from './gst.service';
const API_URL = environment.apiEndPoint;
const COMMON_MASTERS_URL = environment.commonMastersAPIEndPoint;

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  public copiedAttributes: any;

  constructor(private http: HttpClient, private gstService: GSTservice) { }

  private handleSuccess(response: HttpResponse<any>) {
    if (response.status === 200) {
      return {
        'body': response.body,
        'headers': response.headers
      };
    }
    return JSON.parse(response.body);
  }

  public getContracts(urlParams) {
    const url = urlParams && urlParams.searchText && urlParams.searchText.length && 'contracts-with-search' || 'contracts'
    return this.http.get(API_URL + url, {
      params: {
        ...urlParams
      }
    });
  }

  /**
   * Get Contracts for customer groups
   * @param urlParams 
   * @returns 
   */
  public getContractsForBankReceipts(urlParams) {
    return this.http.get(API_URL + 'contracts', {
      params: {
        ...urlParams
      }
    });
  }

  public getContractsForInvoiceCreation(urlParams) {
    return this.http.get(API_URL + 'contracts/get-contracts-for-doc-creation', {
      params: {
        ...urlParams
      }
    });
  }

  public getContractsForRABillCreation(urlParams) {
    return this.http.get(API_URL + 'contracts/get-contracts-for-rabill-creation', {
      params: {
        ...urlParams,
      }
    })
  }

  /**
   *
   * @param urlParams
   * gets contracts BOQ to be assigned to
   */

  public getContractsForBOQcreation(urlParams) {
    return this.http.get(API_URL + 'contracts/get-contracts-for-boq-creation', {
      params: {
        ...urlParams
      }
    });
  }

  public getContractsReport(contractId, urlParams) {
    const url = API_URL + 'contracts/' + contractId + '/report';
    return this.http.get(url, {
      params: {
        ...urlParams
      }
    });
  }


  public createContract(data) {
    return this.http.post(API_URL + 'contracts', data);
  }

  public getContractById(contractId, params = {}) {
    return this.http.get(API_URL + 'contracts/' + contractId, {
      params: {
        ...params
      }
    });
  }

  public updateContractById(contractId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId, data);
  }

  public updateContractTermsById(contractId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/edit-addresses-and-terms', data);
  }

  public acknowledgePO(contractId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/acknowledge-po', data);
  }

  public revisePO(contractId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/revise-po', data);
  }

  public checkForInvoiceApproval(urlParams) {
    const url=API_URL + '/customer-invoices/creation-approval-check';
    return this.http.get(url, {
      params: {
        ...urlParams
      }
    });
  }

  public InitiateInvoiceApproval(payload) {
    return this.http.put(API_URL + '/customer-invoices/request-approval', payload);
  }

  public cancelContractById(contractId) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/cancel', {});
  }

  public getInvoiceList(contractId, urlParams) {
    const url = API_URL + 'contracts/' + contractId + '/customer-invoices';
    return this.http.get(url, {
      params: {
        ...urlParams
      }
    });
  }

  public getInvoiceAndAdvancePOList(contractId, urlParams) {
    const url = API_URL + 'contracts/' + contractId + '/invoices-and-advances';
    return this.http.get(url, {
      params: {
        ...urlParams
      }
    });
  }

  public getInvoicesAndAdvancesSummary(contractId, queryParams = {}) {
    return this.http.get(`${API_URL}contracts/${contractId}/invoices-and-advances-summary`, {
      params: queryParams
    });
  }

  public getInvoiceById(contractId, invoiceId, urlParams) {
    const url = API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId;
    return this.http.get(url, {
      params: {
        ...urlParams
      }
    });
  }

  public getNoteById(contractId, invoiceId, noteId, noteType = '', isEdit = false, isBoqRequired = false) {
    let url = API_URL + 'customer-credit-notes/' + noteId + '/get-credit-note/';
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      url = API_URL + 'customer-debit-notes/' + noteId + '/get-debit-note/';
    }
    const params = {};
    if (isEdit) {
      params['isEdit'] = true;
    }
    if (isBoqRequired) {
      params['isBoqRequired'] = true;
    }

    return this.http.get(url, {
      params
    });
  }

  public getReasonsForNote(urlParams, manualReasonsOnly = false) {
    return this.http.get(API_URL + 'miscellaneous-details', {
      params: {
        ...urlParams,
        manualReasonsOnly
      }
    });
  }

  public previewInvoiceNote(contractId, invoiceId, data, noteType, noteId, isEdit = false) {
    const params = {};
    if (isEdit) {
      params['isEdit'] = isEdit;
    }
    if (noteType === 'DEBIT_NOTE' || noteType === 'debitNote') {
      return this.http.post(`${API_URL}customer-debit-notes/${contractId}/preview/${noteId}`, data, { params });
    }
    return this.http.post(`${API_URL}customer-credit-notes/${contractId}/preview/${noteId}`, data, { params });

  }

  public createInvoiceById(contractId, invoiceId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/create', data);
  }

  public createNoteById(contractId, invoiceId, noteId, data, noteType?, isEdit = false) {
    const params = {};
    if (isEdit) {
      params['isEdit'] = true;
    }
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      return this.http.put(`${API_URL}customer-debit-notes/${contractId}/create/${noteId}`, data, { params });
    }
    return this.http.put(`${API_URL}customer-credit-notes/${contractId}/create/${noteId}`, data, { params });
  }

  public updateCustomerNoteById(contractId, noteId, data, noteType?, isEdit = false) {
    const params = {};
    if (isEdit) {
      params['isEdit'] = true;
    }
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      return this.http.put(`${API_URL}customer-debit-notes/${contractId}/update/${noteId}`, data, { params });
    }
    return this.http.put(`${API_URL}customer-credit-notes/${contractId}/update/${noteId}`, data, { params });
  }

  public updateNoteById(contractId, invoiceId, noteId, data, noteType?) {
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      return this.http.put(API_URL + 'contracts/' + contractId + '/customer-debit-notes/' + noteId + '/create', data);
    }
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/notes/' + noteId, data);
  }

  public submitEInvoicing(noteId, noteType?) {
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      return this.http.put(API_URL + 'customer-debit-notes/' + noteId + '/submit-e-invoicing', {});
    }
    return this.http.put(API_URL + 'customer-credit-notes/' + noteId + '/submit-e-invoicing', {});
  }

  public cancelEInvoicing(noteId, noteType?) {
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      return this.http.put(API_URL + 'customer-debit-notes/' + noteId + '/cancel-e-invoicing', {})
    }
    return this.http.put(API_URL + 'customer-credit-notes/' + noteId + '/cancel-e-invoicing', {})
  }

  public cancelInvoiceById(contractId, invoiceId) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/cancel', {});
  }

  public cancelNoteById(contractId, invoiceId, noteId) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/notes/' + noteId + '/cancel', {});
  }

  public previewInvoice(contractId, invoiceId, data, type) {
    return this.http.post(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + `/preview?invoiceType=${type}`, data);
  }

  public updateInvoice(contractId, invoiceId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId, data);
  }

  public downloadDraftPOPdf(contractId, invoiceId) {
    return this.http.get(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/download');
  }

  public downloadDraftNotePdf(contractId, invoiceId, noteId) {
    return this.http.get(`${API_URL}contracts/${contractId}/customer-invoices/${invoiceId}/notes/${noteId}/download`);
  }

  public getReferenceForPayment(contractId, invoiceId) {
    return this.http.get(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/references');
  }

  public getPayablesByContractId(contractId) {
    return this.http.get(API_URL + 'contracts/' + contractId + '/payables');
  }

  public getPayablesByCustomerId(customerId) {
    return this.http.get(API_URL + 'customers/' + customerId + '/payables');
  }

  public getContractByCustomerId(id, type) {
    return this.http.get(API_URL + `${type}/` + id + '/contracts-and-payables');
  }

  public validateMarkPaymentByContractId(contractId, data) {
    return this.http.post(API_URL + 'contracts/' + contractId + '/validate-payment-received', data);
  }

  public markPaymentReceived(contractId, data) {
    return this.http.post(API_URL + 'contracts/' + contractId + '/mark-payment-received', data);
  }

  public reviseDocument(contractId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/revise-document', data);
  }

  public uploadDocument(contractId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/upload-document', data);
  }

  public updateInvoiceCharges(contractId, invoiceId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-invoices/' + invoiceId + '/edit-charges', data);
  }

  public getAdvancePOSummaryDetails(contractId, poAdvancePaymentId) {
    return this.http.get(API_URL + 'contracts/' + contractId + '/po-advance-payments/' + poAdvancePaymentId);
  }

  public updateAdvancePOSummaryDetails(contractId, poAdvancePaymentId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/po-advance-payments/' + poAdvancePaymentId + '/edit-charges', data);
  }
  public createDraftInvoiceOld(contractId, data, type) {
    return this.http.post(API_URL + 'contracts/' + contractId + '/customer-old-invoices' + `?invoiceType=${type}`, data);
  }

  public createInvoiceOld(contractId, invoiceId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-old-invoices/' + invoiceId + '/create', data);
  }

  public updateInvoiceOldByInvoiceId(contractId, invoiceId, data) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/customer-old-invoices/' + invoiceId, data);
  }


  public getAllContracts(urlParams) {
    const url = urlParams.searchText && urlParams.searchText.length && 'all-contracts-with-search' || 'all-contracts';
    return this.http.get(API_URL + url, {
      params: {
        ...urlParams
      }
    });
  }

  public getAllContractsV2(urlParams) {

    if (urlParams && urlParams.searchText) {
      return this.http.get(API_URL + 'all-contracts-list-search', {
        params: {
          ...urlParams,
        }
      });
    } else {
      return this.http.get(API_URL + 'all-contracts-list', {
        params: {
          ...urlParams,
        }
      });
    }
  }

  public moveStageBack(contractId) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/moveStageBack', {});
  }

  public stopBilling(contractId) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/stop-billing', {});
  }

  public closeContractById(contractId) {
    return this.http.put(API_URL + 'contracts/' + contractId + '/close', {});
  }

  public getBillAllocations(contractId) {
    return this.http.get(API_URL + 'contracts/' + contractId + '/bill-allocations', {});
  }

  /**
 * assign purchase manager
 */
  public assignPurchaseManager(contractId, data) {
    return this.http.put(API_URL + `/contracts/${contractId}/assign-pm`, data);
  }

  /**
  * change scope of work
  */
  public changeScopeOfWorkandBU(contractId, data) {
    return this.http.put(API_URL + `/contracts/${contractId}/change-work-scope`, data);
  }

  /**
  * get scope of work
  */
  public getScopeOfWork() {
    return this.http.get(API_URL + `contracts/get-work-scope`);
  }


  public getScopeMatrixScopeOfWork() {
    return this.http.get(`${API_URL}contracts/scope-matrix-scope-of-work`);
  }

  /**
   *
   * @param contractId
   * @param matrixData
   */
  public saveScopeMatrixWithMatching(contractId, matrixData) {
    return this.http.post(`${API_URL}contracts/${contractId}/save-scope-matrix-with-matching-status`, matrixData);
  }

  public uploadOMSscopeMatrix(contractId, scopeMatrixFileId) {
    return this.http.put(`${API_URL}contracts/${contractId}/upload-oms-scope`, { scopeMatrixFileId });
  }

  public getProjectTypes() {
    return this.http.get(API_URL + 'all-project-types', {});
  }

  public getBusinessUnits() {
    return this.http.get(API_URL + 'get-business-units', {});
  }

  public updateBusinessUnit(businessUnitId, data) {
    return this.http.put(API_URL + 'business-unit/' + businessUnitId, data);
  }

  public getPaymentTicketApproverRoles() {
    return this.http.get(`${API_URL}business-units/payment-ticket-approver-roles`);
  }

  public getBusinessUnitById(businessUnitId: string) {
    return this.http.get(`${API_URL}business-units/${businessUnitId}`);
  }

  /**
* assign purchase manager
*/
  public getContractsForByModule(urlParams) {
    return this.http.get(API_URL + 'contracts/get-contracts-for-order-creation', {
      params: {
        ...urlParams
      }
    });
  }

  public getContractListForOrder(urlParams) {
    return this.http.get(API_URL + 'contracts/get-contract-list', {
      params: { ...urlParams }
    });
  }

  public saveContractPOC(contractId, data) {
    return this.http.put(API_URL + `/contracts/${contractId}/edit-spoc`, data);
  }

  /**
  * get enquiry no from EQS
  */
  public getEnquireNos(urlParams, data) {
    return this.http.get(environment.eqsEndPoint + 'enquiry', {
      params: {
        ...urlParams
      }
    });
  }

  // PO - Lot Realated Methods
  getUnallocatedLotsForContract(contractId, allocatedToOrder = false, isOrderAllocation = true) {
    const ZISObaseUrl = environment.zisoApiEndPoint;
    const urlParams: Params = {
      allocatedToOrder,
      isOrderAllocation
    };

    return this.http.get(`${ZISObaseUrl}contracts/${contractId}/lots`, {
      params: {
        ...urlParams
      }
    });
  }

  getAllocatedLotsForOrder(contractId, omsOrderId,
    searchText = '') {
    const ZISObaseUrl = environment.zisoApiEndPoint;
    const urlParams: Params = {
      searchText
    };

    return this.http.get(`${ZISObaseUrl}contracts/${contractId}/orders/${omsOrderId}`, {
      params: {
        ...urlParams
      }
    });
  }

  public getContractsForBOQ(urlParams) {
    return this.http.get(API_URL + 'contracts/get-contracts-for-boq-creation', {
      params: {
        ...urlParams
      }
    });
  }

  public discardDraftCustomerCreditNote(creditNoteId: string, draftId: string) {
    return this.http.put(API_URL + 'customer-credit-notes/' + creditNoteId + '/discard' , {})
  }
  public discardDraftCustomerDebitNote(debitNoteId: string, draftId: string) {
    return this.http.put(API_URL + 'customer-debit-notes/' + debitNoteId +  '/discard' , {})
  }


  public cancelCustomerCreditNote(creditNoteId) {
    return this.http.put(API_URL + 'customer-credit-notes/' + creditNoteId + '/cancel-credit-note', {})
  }

  public cancelCustomerDebitNote(debitNoteId) {
    return this.http.put(API_URL + 'customer-debit-notes/' + debitNoteId + '/cancel', {})
  }

  public eSignNote(noteId, noteType) {
    if (noteType === 'debitNote' || noteType === 'DEBIT_NOTE') {
      return this.http.put(API_URL + 'customer-debit-notes/' + noteId + '/e-sign-debit-note', {});
    }
    return this.http.put(API_URL + 'customer-credit-notes/' + noteId + '/e-sign-credit-note', {});
  }

  public getSampleUdaanExcel() {
    return this.http.get(API_URL + 'contracts/udaan-contract-template');
  }

  public uploadBulkContract(data) {
    return this.http.post(API_URL + 'contracts/bulk-upload-contract-invoices', data);
  }

  public getAllRaBillsByContractId(contractId) {
    return this.http.get(API_URL + 'contracts/' + contractId + '/raBills');
  }

  public updateCustomerInvoceRaBills(invoiceId, payload) {
    return this.http.put(API_URL + 'customer-invoices/' + invoiceId + '/map-ra-bills', payload);
  }

  public getContractsListV2(urlParams, filterQueryString) {

    if (this.gstService.selectedGST && this.gstService.selectedGST !== 'allGST') {
      urlParams['zetwerkId'] = this.gstService.selectedGST
    }

    return this.http.get(`${API_URL}contracts?${filterQueryString}`, {
      params: {
        ...urlParams,
      }
    });
  }

  public getContractFilters(params) {
    return this.http.get(`${API_URL}contracts/all-contracts-filters`, { params });
  }


  public getContractTabCount() {
    const params = {};
    if (this.gstService.selectedGST && this.gstService.selectedGST !== 'allGST') {
      params['zetwerkId'] = this.gstService.selectedGST
    }
    return this.http.get(`${API_URL}contracts/tab-count`, { params });
  }

  public mapParentOrderToContract(contractId, payload) {
    return this.http.put(`${API_URL}contracts/${contractId}/map-parent-order`, payload);
  }

  public removeParentOrder(contractId) {
    return this.http.put(`${API_URL}contracts/${contractId}/remove-parent-order`, {});
  }

  setCopiedAttributes(contractDetails) {
    this.copiedAttributes = contractDetails;
  }

  getCopiedAttributes() {
    return {
      copiedAttributes: this.copiedAttributes,
    };
  }

  public getTaxRates(payload) {
    return this.http.post(`${COMMON_MASTERS_URL}tax-rates/get-tax-rates`, payload);
  }

  public getTaxCodes(searchText,codeType = '') {
    return this.http.get(`${COMMON_MASTERS_URL}tax-codes?searchText=${searchText}&codeType=${codeType}`);
  }
  
  public getBulkTaxCodes(payload) {
    return this.http.post(`${COMMON_MASTERS_URL}tax-codes/get-bulk-tax-codes`,payload);
  }
  /**
  * get workflow req no from WORKFLOW
  */
  getWRNos(urlParams) {
    return this.http.get(environment.WREndPoint + 'workflow-request', {
      params: {
        ...urlParams
      }
    });
  }

  getDraftContractById({ contractId }) {
    return this.http.get(`${API_URL}draft-contracts/${contractId}`);
  }

  getPurchaseOrdersByContractId({ contractId }) {
    return this.http.get(`${API_URL}contracts/${contractId}/get-purchase-orders`);
  }
  public removeFileFromHistory({ contractId, payload }) {
    return this.http.put(`${API_URL}contracts/${contractId}/remove-file-history`, payload);
  }

  getActionPermissions(contractId, queryParams) {
    const path = `${API_URL}contracts/${contractId}/action-permission`;

    return this.http.get(path, { params: queryParams });
  }

  getUnmappedItems({ contractId, queryParams = {} }) {
    const path = `${API_URL}contracts/${contractId}/unmapped-dd-items`;

    return this.http.get(path, { params: queryParams });
  }

  bulkCloseContracts({fileId}) {
    return this.http.put(`${API_URL}contracts/bulk-close-contracts`, {
      fileId
    });
  }
  
  getDraftContractBySourcePO({ poNumber, isCPDraftContract }) {
    return this.http.get(`${API_URL}draft-contracts`, { 
      params: {
        poNumber,
        isCPDraftContract
      } 
    });
  }
  
  getContractPayablesDocs(contractId, queryParams = {}){
    const path = `${API_URL}contracts/${contractId}/payables-to-customer-for-contract`;
    return this.http.get(path, {params: queryParams});
  }
}
