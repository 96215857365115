import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';

@Component({
  selector: 'app-subtext-cell',
  templateUrl: './subtext-cell.component.html',
  styleUrls: ['./subtext-cell.component.scss']
})

export class SubtextCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {

  }
}
