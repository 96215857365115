<div class="confirmation-popup-container">
  <header class="header">
    <h4 class="title">{{ modalOptions?.title || '--' }}</h4>
    <img class="cursor-pointer" src="/assets/icons/close.svg" (click)="onClose()" />
  </header>

  <div class="content">
    <p class="text">{{ modalOptions?.message || '--' }}</p>
  </div>

  <footer class="footer">
    <button class="button outline" (click)="onClose()">{{ modalOptions?.cancelButton?.text }}</button>
    <button class="button"
      [ngClass]="{'delete': modalOptions?.confirmButton?.type === 'DELETE', 'confirm': modalOptions?.confirmButton?.type === 'CONFIRM'}"
      (click)="onConfirm()">{{ modalOptions?.confirmButton?.text }}</button>
  </footer>
</div>
