import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { PermissionsService } from './services/permissions.service';
import { NotificationService } from './services/notification/notification.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsGuard implements CanActivate {
  constructor(
    private _router: Router,
    private _ps: PermissionsService,
    private _notification: NotificationService
  ) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const hasPermission = this._ps.userHasPermission(route.data);
    if (!hasPermission) {
      this._notification.errorNotification(
        'You are not authorized to perform this action',
        'Unauthorized'
      );
      if(localStorage) {
        localStorage.clear();
      }
      this._router.navigate(['login']);
    }
    return hasPermission;
  }
}
