<div class="book-closure-container">

  <!-- Header -->
  <header class="header row space-between">
    <p class="title">Closure Control</p>
    <button class="button" [class.disabled]="bookClosureDetailsComponent?.isEdit" (click)="onPeriodCreate()">Create Period</button>
  </header>

  <!-- Content -->
  <div class="zetwerk-tab">

    <!-- Tabs -->
    <tabset class="tab-container">
      <tab *ngFor="let tab of tabs; let i = index;" [heading]="tab.title" (selectTab)="onTabSelect(tab)"
        (deselect)="tab.active = false" [active]="tab.active" [class.active]="tab.active"
        [class.inactive]="!tab.active">

        <!-- Filters -->
        <div class="row space-between m-12">
          <div class="row">

            <!-- Start Date -->
            <div class="date-container w-200 ml-12">
              <input type="text" placeholder="Select Start Date" class="oms-input oms-date-input" bsDatepicker
                [bsConfig]="bsConfig" [bsValue]="startDate" (bsValueChange)="onStartDateChange($event)" />
            </div>

            <!-- End Date -->
            <div class="date-container w-200 ml-12">
              <input type="text" placeholder="Select End Date" class="oms-input oms-date-input" bsDatepicker
                [bsConfig]="bsConfig" [bsValue]="endDate" (bsValueChange)="onEndDateChange($event)" />
            </div>
          </div>

          <!-- Search Box -->
          <div class="search-box">
            <span class="fa fa-search fa-search-icon"></span>
            <input type="text" class="form-control search-input" placeholder="Search" [value]="searchTextValue"
              (input)="searchText$.next($event.target.value)">
          </div>
        </div>

        <div class="grid">

          <!-- Book Closure List -->
          <div class="book-closure-list">
            <zet-list [tableConfig]="tableOptions" [filterConfig]="filterOptions" [dataProvider]="dataProvider"
            [context]="zetListContext" (rowClick)="onRowClick($event)">

            <!-- Book Closure Card -->
            <ng-template zetCardTemplate let-row>
              <app-book-closure-list-card [period]="row" [isSelected]="row?._id === selectedPeriod?._id"></app-book-closure-list-card>
            </ng-template>
          </zet-list>
          </div>

          <!-- Book Closure Details -->
          <app-book-closure-details [period]="selectedPeriod" [showSection]="showPeriodForm" (action)="onAction($event)"></app-book-closure-details>
        </div>
      </tab>
    </tabset>
  </div>
</div>
