export const  TRANSPORTATION_TERM_TYPE = [{
  value: 'Not applicable',
  label: 'Not applicable'
},
{
  value: 'Ex-Works',
  label: 'Ex-Works'
},
{
  value: 'FOR',
  label: 'FOR'
}, {
  value: 'CIF',
  label: 'CIF'
}, {
  value: 'DDP',
  label: 'DDP'
}, {
  value: 'FOB',
  label: 'FOB'
}, {
  value: 'FCA',
  label: 'FCA'
}, {
  value: 'FAS',
  label: 'FAS'
}, {
  value: 'CFR',
  label: 'CFR'
}, {
  value: 'CPT',
  label: 'CPT'
}, {
  value: 'CIP',
  label: 'CIP'
}, {
  value: 'DAP',
  label: 'DAP'
}, {
  value: 'DPU',
  label: 'DPU'
}];

export const MEASUREMENT_TERM_TYPE = [{
  value: 'As per drawing weight',
  label: 'As per drawing weight'
},
{
  value: 'As per dharam Kata weight',
  label: 'As per dharam Kata weight'
},
{
  value: 'As per drawing weight or dharam kata weight whichever is lesser',
  label: 'As per drawing weight or dharam kata weight whichever is lesser'
}];

export const MILESTONE_RETENTION = [{
  value: 'Completion of order',
  label: 'Completion of order'
},
{
  value: 'Complete material receipt at site',
  label: 'Complete material receipt at site'
},
{
  value: 'Till defect liability period from the date of last supply',
  label: 'Till defect liability period from the date of last supply'
}, {
  value: 'Till the erection completion + DLP period whichever is earlier/later',
  label: 'Till the erection completion + DLP period whichever is earlier/later'
},
{
  value: 'Till mechanical completion/successful handing over the project',
  label: 'Till mechanical completion/successful handing over the project'
},
{
  value: 'Others',
  label: 'Others'
}];

export const DELAY_PERIOD_UNIT_OPTIONS = [{
  value: 'Day',
  label: 'Day'
},
{
  value: 'Week',
  label: 'Week'
},
{
  value: 'Month',
  label: 'Month'
}];

export const MILESTONE_TYPENAME_OPTIONS = [{
  value: 'Advance',
  label: 'Advance'
},
{
  value: 'Against RM Receipt',
  label: 'Against RM Receipt'
},
{
  value: 'Before Dispatch',
  label: 'Before Dispatch'
},
{
  value: 'On Dispatch',
  label: 'On Dispatch'
},
{
  value: 'After Dispatch',
  label: 'After Dispatch'
},
{
  value: 'from GRN Date',
  label: 'from GRN Date'
},
{
  value: 'Performance Related',
  label: 'Performance Related'
},
{
  value: 'Retention',
  label: 'Retention'
},
{
  value: 'After Ground assembly / Fabrication + welding',
  label: 'After Ground assembly / Fabrication + welding'
},
{
  value: 'After Erection / Placement',
  label: 'After Erection / Placement'
},
{
  value: 'After Alignment, Bolt tightening & touch up Painting',
  label: 'After Alignment, Bolt tightening & touch up Painting'
},
{
  value: 'After Commissioning',
  label: 'After Commissioning'
},
{
  value: 'Against RA Bill',
  label: 'Against RA Bill'
}
];

export const FIELD_BY_TERM_TYPE = {
  'Advance': {
    options: [{
      value: 'Advance',
      label: 'Advance'
    },
    {
      value: 'Against ABG',
      label: 'Against ABG'
    },
    {
      value: 'Against ACG+PDC',
      label: 'Against ACG+PDC'
    }],
    fields: [],
    fieldsBySubTermType: {
      'Advance': {
        fields: ['poBasicValuePercentage', 'isValueGSTInclusive', 'advanceDays'],
        poBasicValueMessage: 'of PO basic value as advance',
        termMessage: '{{poBasicValuePercentage}} % of PO basic value as advance {{isValueGSTInclusive}} {{paymentMethod}}'
      },
      'Against ABG': {
        fields: ['poBasicValuePercentage', 'abgPercentage', 'isValueGSTInclusive', 'advanceDays'],
        poBasicValueMessage: 'of PO basic value as advance',
        termMessage: '{{poBasicValuePercentage}} % of PO basic value as advance {{isValueGSTInclusive}} {{paymentMethod}} against ABG of {{abgPercentage}} % of PO Basic value {{isValueGSTInclusive}}'
      },
      'Against ACG+PDC': {
        fields: ['poBasicValuePercentage', 'acgPercentage', 'pdcPercentage', 'isValueGSTInclusive', 'advanceDays'],
        poBasicValueMessage: 'of PO basic value as advance',
        termMessage: '{{poBasicValuePercentage}} % of PO basic value as advance {{isValueGSTInclusive}} {{paymentMethod}}, ACG of {{acgPercentage}} % of PO Basic value {{isValueGSTInclusive}} and PDC of {{pdcPercentage}} % of PO Basic value {{isValueGSTInclusive}}'
      },
      'Advance against PDC and ACG': {
        fields: ['poBasicValuePercentage', 'pdcPercentage', 'acgPercentage', 'isValueGSTInclusive', 'advancedDays'],
        poBasicValueMessage: 'of PO basic value as advance {{isValueGSTInclusive}} {{paymentMethod}}'
      }
    }
  },
  'Against RM Receipt': {
    options: [{
      value: 'Advance',
      label: 'Advance'
    },
    {
      value: 'Advance against PDC and ACG',
      label: 'Advance against PDC and ACG'
    },
    {
      value: 'Interest Bearing Advance',
      label: 'IBA'
    },
    {
      value: 'Interest Bearing Advance against PDC and ACG',
      label: 'IBA against PDC and ACG'
    }],
    fields: [],
    fieldsBySubTermType: {
      'Advance': {
        fields: ['poBasicValuePercentage', 'isValueGSTInclusive', 'advanceDays'],
        poBasicValueMessage: 'of PO basic value as advance',
        termMessage: '{{poBasicValuePercentage}} % of PO basic value as advance {{isValueGSTInclusive}} {{paymentMethod}} against RM Receipt'
      },
      'Interest Bearing Advance': {
        pickFromCmp: true,
        formGroupFunction: 'advanceIBAForm',
        fields: ['poBasicValuePercentage', 'interestPercentage', 'processingFee',
         'loanDuration', 'loanDurationUnit', 'gracePeriod', 'gracePeriodUnit', 'lateFeeChargers', 'name', 'title', 'email', 'phone', 'advanceDays'],
        termMessage: '{{poBasicValuePercentage}}% of PO basic value {{isValueGSTInclusive}} {{paymentMethod}} as Interest Bearing Advance against RM receipt @ {{interestPercentage}}% per annum'
      },
      'Interest Bearing Advance against PDC and ACG': {
        pickFromCmp: true,
        formGroupFunction: 'advanceIBAWithPDC',
        fields: ['poBasicValuePercentage', 'interestPercentage', 'processingFee', 'loanDuration', 'loanDurationUnit',
        'gracePeriod', 'gracePeriodUnit', 'lateFeeChargers', 'name', 'title', 'email', 'phone', 'pdcPercentage', 'acgPercentage', 'advanceDays'],
        termMessage: '{{poBasicValuePercentage}}% of basic value as Interest Bearing Advance {{paymentMethod}}  against RM receipt {{isValueGSTInclusive}} @ {{interestPercentage}}% per annum, ACG of {{acgPercentage}}% of PO basic value {{isValueGSTInclusive}} and PDC of {{pdcPercentage}}% of PO basic Value {{isValueGSTInclusive}}'
      },
      'Advance against PDC and ACG': {
        fields: ['poBasicValuePercentage', 'pdcPercentage', 'acgPercentage', 'isValueGSTInclusive', 'advanceDays'],
        poBasicValueMessage: 'of PO basic value as advance',
        termMessage: '{{poBasicValuePercentage}} % of PO basic value as advance against RM Receipt {{isValueGSTInclusive}} {{paymentMethod}}, ACG of {{pdcPercentage}} % of PO Basic value {{isValueGSTInclusive}} and PDC of {{acgPercentage}} % of PO Basic value {{isValueGSTInclusive}}'
      },
    }
  },
  'After Inspection': {
    options: [],
    fields: ['poBasicValuePercentage'],
    poBasicValueMessage: 'of PO basic value as advance',
    termMessage: ''
  },
  'Before Dispatch': {
    options: [{
      value: 'Before dispatch on PO basic Value',
      label: 'Before dispatch on PO basic Value'
    },
    {
      value: 'Before dispatch on PO Value',
      label: 'Before dispatch on PO Value'
    }],
    fields: [],
    fieldsBySubTermType: {
      'Before dispatch on PO basic Value': {
        fields: ['poBasicValuePercentage'],
        poBasicValueMessage: 'of PO basic value',
        termMessage: '{{poBasicValuePercentage}} % of PO basic value before dispatch {{paymentMethod}}'
      },
      'Before dispatch on PO Value': {
        fields: ['poValuePercentage'],
        poBasicValueMessage: 'of PO value',
        termMessage: '{{poValuePercentage}} % of PO value before dispatch {{paymentMethod}}'
      }
     }
  },
  'On Dispatch': {
    options: [],
    fields: ['poBasicValuePercentage', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} on dispatch {{paymentMethod}}'
  },
  'After Dispatch': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days of dispatch {{paymentMethod}}'
  },
  'from GRN Date': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days from the date of GRN {{paymentMethod}}'
  },
  'Performance Related': {
    options: [],
    fields: ['poBasicValueAgainstDocPercentage', 'poBasicValueUptoWarrantPeriodPercentage'],
    poBasicValueMessage: 'of PO basic value against PBG',
    termMessage: '{{poBasicValueAgainstDocPercentage}} % of PO basic value {{paymentMethod}} against PBG'
  },
  'Retention': {
    options: [],
    pickFromCmp: true,
    formGroupFunction: 'retentionForm',
    fields: ['paymentRetentionPercentage', 'paymentRetentionAmount', 'pbgPercentage', 'paymentRetentionDays', 'milestone', 'loanAmount'],
    termMessage: 'Retention of {{paymentRetentionPercentage}} % of basic PO value to be paid {{paymentMethod}}  {{paymentRetentionDays}} days from {{milestone}}'
  },
  'After Ground assembly / Fabrication + welding': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days of Ground assembly / Fabrication + welding {{paymentMethod}}'
  },
  'After Erection / Placement': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days of Erection / Placement {{paymentMethod}}'
  },
  'After Alignment, Bolt tightening & touch up Painting': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days of Alignment, Bolt tightening & touch up Painting {{paymentMethod}}'
  },
  'After Commissioning': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days of Commissioning {{paymentMethod}}'
  },
  'Against RA Bill': {
    options: [],
    fields: ['poBasicValuePercentage', 'creditDays', 'minimumDiscountDays', 'discountRate', 'isValueGSTInclusive'],
    poBasicValueMessage: 'of PO basic value',
    termMessage: '{{poBasicValuePercentage}} % of PO basic value {{isValueGSTInclusive}} within {{creditDays}} days of Against RA Bill {{paymentMethod}}'
  }
};

export const RETENTION_MILESTONE = [
  {
    value: 'Completion of order',
    label: 'Completion of order'
  },
  {
    value: 'Complete material receipt at site',
    label: 'Complete material receipt at site'
  },
  {
    value: 'Till defect liability period from the date of last supply',
    label: 'Till defect liability period from the date of last supply'
  }, {
    value: 'Till the erection completion + DLP period whichever is earlier/later',
    label: 'Till the erection completion + DLP period whichever is earlier/later'
  },
  {
    value: 'Till mechanical completion/successful handing over the project',
    label: 'Till mechanical completion/successful handing over the project'
  },
  {
    value: 'Others',
    label: 'Others'
  }
];

export const PAYMENT_METHODS = [
  {
    value: 'Bank Transfer',
    label: 'Bank Transfer'
  },
  {
    value: 'LC',
    label: 'LC'
  },
  {
    value: 'VFS',
    label: 'VFS'
  },
  {
    value: 'PDC',
    label: 'PDC'
  },
];

export const PaymentTermsListColumnDefs = [
  {
    'headerName': 'BU',
    'field': 'buName',
    'widthInPercentage': 35,
    'cssClass': 'bold',
  },
  {
    'headerName': 'No. of Milestones',
    'field': 'milestoneCount',
    'widthInPercentage': 25,
  },
  {
    'headerName': 'Payment Method',
    'field': 'paymentMethods',
    'widthInPercentage': 40
  },
];

export const PAYMENT_TERMS_TYPE = {
  milestone : 'MILESTONE',
  paymentMethod: 'PAYMENT_METHOD',
  documentRequired: 'DOCUMENT'
};

export const TERMS = {
  advance: {
    advance: {
      statement: ' of PO basic value as advance [isValueGSTInclusive] [paymentMethod]',
      values: ['poBasicValuePercentage']
    },
    'against abg': {
      statement: ' of PO basic value [isValueGSTInclusive] as advance [paymentMethod] against ABG of [abgPercentage]% of PO basic value [isValueGSTInclusive]',
      values: ['poBasicValuePercentage', 'abgPercentage']
    },
    'against acg+pdc': {
      statement: ' of PO basic value [isValueGSTInclusive] as advance [paymentMethod] against ACG of [acgPercentage]% and PDC of [pdcPercentage]% of PO value',
      values: ['poBasicValuePercentage', 'acgPercentage', 'pdcPercentage']
    },
  },
  'against rm receipt': {
    advance: {
      statement: ' of PO basic value as advance [isValueGSTInclusive] [paymentMethod] against RM Receipt',
      values: ['poBasicValuePercentage']
    },
    'advance against pdc and acg': {
      statement: ' of PO basic value as advance [paymentMethod] against RM Receipt [isValueGSTInclusive],  ACG of [acgPercentage]% of PO basic value [isValueGSTInclusive] and PDC of [pdcPercentage]% of PO basic Value [isValueGSTInclusive]',
      values: ['poBasicValuePercentage', 'acgPercentage', 'pdcPercentage']
    },
    'interest bearing advance': {
      statement: ' of PO basic value [isValueGSTInclusive] [paymentMethod] as Interest Bearing Advance against RM receipt @ [interestPercentage]% per annum',
      values: ['poBasicValuePercentage', 'interestPercentage']
    },
    'interest bearing advance against pdc and acg': {
      statement: ' of PO basic value [isValueGSTInclusive] as Interest Bearing Advance against RM receipt @ [interestPercentage]% per annum, ACG of [acgPercentage]% of PO basic value and PDC of [pdcPercentage]% of PO basic Value [isValueGSTInclusive]',
      values: ['poBasicValuePercentage', 'interestPercentage', 'acgPercentage', 'pdcPercentage']
    }
  },
  'before dispatch': {
    'before dispatch on po basic value': {
      statement: ' of PO basic value before dispatch [paymentMethod]',
      values: ['poBasicValuePercentage']
    },
    'before dispatch on po value': {
      statement: ' of PO value with GST before dispatch [paymentMethod]',
      values: ['poValuePercentage']
    }
  },
  'after inspection': {
    statement: '[poBasicValuePercentage]% of PO basic value after inspection [paymentMethod]',
    values: ['poBasicValuePercentage']
  },
  'on dispatch': {
    statement: '[poBasicValuePercentage]% of PO basic value on dispatch [paymentMethod]',
    values: ['poBasicValuePercentage']
  },
  'after dispatch': {
    statement: ' of PO basic value within [creditDays] days of dispatch [paymentMethod]',
    values: ['poBasicValuePercentage', 'creditDays']
  },
  'performance related': {
    statement: '[poBasicValueAgainstDocPercentage]% of PO basic value [paymentMethod] against PBG',
    values: ['poBasicValueAgainstDocPercentage', 'poBasicValueUptoWarrantPeriodPercentage']
  },
  retention: {
    statement: 'Retention of [paymentRetentionPercentage]% of basic PO value to be paid [paymentMethod] [paymentRetentionDays] days from [milestone]',
    values: ['paymentRetentionPercentage','paymentRetentionDays','milestone']
  },
  'after ground assembly / fabrication + welding': {
    statement: '[poBasicValuePercentage]% of PO basic value within [creditDays] days of Ground assembly / Fabrication + welding [paymentMethod]',
    values: ['poBasicValuePercentage', 'creditDays']
  },
  'after erection / placement': {
    statement: '[poBasicValuePercentage]% of PO basic value within [creditDays] days of Erection / Placement [paymentMethod]',
    values: ['poBasicValuePercentage', 'creditDays']
  },
  'after alignment, bolt tightening & touch up painting': {
    statement: '[poBasicValuePercentage]% of PO basic value within [creditDays] days of Alignment, Bolt tightening & touch up Painting [paymentMethod]',
    values: ['poBasicValuePercentage', 'creditDays']
  },
  'after commissioning': {
    statement: '[poBasicValuePercentage]% of PO basic value within [creditDays] days of Commissioning [paymentMethod]',
    values: ['poBasicValuePercentage', 'creditDays']
  },
  'against ra bill': {
    statement: '[poBasicValuePercentage]% of PO basic value within [creditDays] days of Against RA Bill [paymentMethod]',
    values: ['poBasicValuePercentage', 'creditDays']
  }
};

export const COMPANY_NAME_MAPPER = {
  'zetwerk-kinetix': 'Zetwerk Kinetix',
  'zetwerk-aerosystems': 'Zetwerk Aerosystems',
  'zetwerk': 'Zetwerk',
  'zetfab': 'Zetfab',
  'sharptanks': 'Sharp Tanks',
  'pragathi-inframaterials': 'Pragathi Inframaterials',
  'zetwerk-usa': 'Zetwerk',
  'pinaka': 'Pinaka',
  'zetchem': 'Zetchem',
  'terra91': "TERRA91 INTERNATIONAL PRIVATE LIMITED",
}