import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import * as Sentry from '@sentry/browser';
import { AuthGuard } from '../app/auth.guard';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_ROUTES } from './app.routing';
import { CustomerFeedbackFormComponent } from './components/customer-feedback-form/customer-feedback-form.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ErrorModalComponent } from './components/error-modal/error-modal.component';
import { FooterComponent } from './components/footer/footer.component';
import { FileViewComponent } from './components/general/file-view/file-view.component';
import { LoaderComponent } from './components/general/loader/loader.component';
import { HeaderComponent } from './components/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { CommonSharedModule } from './components/shared/common-shared.module';
import { RedirectPathComponent } from './components/shared/redirect-path/redirect-path.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SupplierFeedbackFormComponent } from './components/supplier-feedback-form/supplier-feedback-form.component';
import { ThankyouFormComponent } from './components/thankyou-form/thankyou-form.component';
import { UnderMaintainenceComponent } from './components/under-maintainence/under-maintainence.component';
import { CustomMinValidatorDirective } from './directives/custom-min-validator.directive';
import { ErrorsHandler } from './errors-handler';
import { PermissionsGuard } from './permissions.guard';
import { FormatNumberPipe } from './pipes/formatNumber.pipe';
import { AppInitService } from './services/app-init.service';
import { CustomersService } from './services/customers.service';
import { FilterService } from './services/filter.service';
import { GeneralService } from './services/general.service';
import { LoaderService } from './services/loader/loader.service';
import { NewPaymentService } from './services/new-payment.service';
import { NotificationService } from './services/notification/notification.service';
import { OmsService } from './services/oms.service';
import { PaymentsService } from './services/payments.service';
import { SocketService } from './services/socket/socket.service';
import { ValidationService } from './services/validation/validation.service';
import { WindowRef } from './services/window.service';
import { ConfirmationMessageComponent } from './components/general/confirmation-message/confirmation-message.component';
import { PaymentTicketPopupComponent } from './components/payment-ticket-popup/payment-ticket-popup.component';
import { CustomerGroupsComponent } from './components/customer-groups/customer-groups.component';
import { SupplierGroupsComponent } from './components/supplier-groups/supplier-groups.component';
import { ZetAppCoreModule, browserEnvProviders } from '@zetwerk/zetui';
import { zetAppModuleConfig } from './constants/zetapp-pkg-ui';
import { FILE_UPLOAD_CONFIG } from '@zetwerk/zetui';
import { fileUploadConfig } from "src/app/constants/payment-ticket";
import { PercentPipe } from '@angular/common';
import { HotkeyModule } from 'angular2-hotkeys';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    Sentry.captureException(error.originalError || error);
    throw error;
  }
}

export function initializeApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    LoginComponent,
    LoaderComponent,
    ErrorModalComponent,
    CustomMinValidatorDirective,
    SupplierFeedbackFormComponent,
    CustomerFeedbackFormComponent,
    ThankyouFormComponent,
    UnderMaintainenceComponent,
    FileViewComponent,
    RedirectPathComponent,
    ConfirmationMessageComponent,
    PaymentTicketPopupComponent,
    CustomerGroupsComponent,
    SupplierGroupsComponent
  ],
  imports: [
    BrowserModule,
    CommonSharedModule,
    AppRoutingModule,
    RouterModule.forRoot(APP_ROUTES, { useHash: false, relativeLinkResolution: 'legacy' }),
    RouterModule.forChild(APP_ROUTES),
    BrowserAnimationsModule, // required animations module
    ZetAppCoreModule.forRoot(zetAppModuleConfig),
    HotkeyModule.forRoot({
      cheatSheetCloseEsc: true,
      cheatSheetHotkey: `?`,
      cheatSheetDescription: `Press Shift+? to open this help menu`
    })
  ],
  providers: [
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [AppInitService],
      multi: true
    },
    PaymentsService,
    OmsService,
    AuthGuard,
    PermissionsGuard,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    CustomersService,
    LoaderService,
    {
      provide: ErrorHandler,
      useClass: ErrorsHandler
    },
    FormatNumberPipe,
    PercentPipe,
    NotificationService,
    NewPaymentService,
    ValidationService,
    SocketService,
    FilterService,
    GeneralService,
    WindowRef,
    ...browserEnvProviders,
    { provide: FILE_UPLOAD_CONFIG, useValue: fileUploadConfig }
  ],
  bootstrap: [AppComponent],
  entryComponents: [ErrorModalComponent]
})
export class AppModule { }
