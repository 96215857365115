import * as moment from 'moment';

export const ValidationDefaultErrors = {
    required: (error, controlName) => `${controlName || 'This field'} is required`,
    minlength: ({ requiredLength, actualLength }, controlName) => `${controlName || ''} Should be of length >= ${requiredLength}`,
    maxlength: ({ requiredLength, actualLength }, controlName) => `${controlName || ''} Should be of length <= ${requiredLength}`,
    min: ({ min, actual }, controlName) => `Should be > ${min}`,
    max: ({ max, actual }, controlName) => `Should be < ${max}`,
    bsDate: ({ invalid, minDate, maxDate}) => {
        const msg = [];
        msg.push(invalid ? invalid : null);
        if (maxDate) { msg.push('max allowed ' + moment(maxDate).format('DD MMM YYYY')); }
        if (minDate) { msg.push('max allowed ' + moment(minDate).format('DD MMM YYYY')); }
        return msg.join( ' | ');

     },
    pattern: ({ requiredPattern, actual}, controlName) => `${controlName || ''} - Incorrect format`,

    gstNameTaken: () => `This GST name already exist!`
};
