import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    // if ('serviceWorker' in navigator && environment.production) {
    //   navigator.serviceWorker.register('ngsw-worker.js')
    //   .then(registration => {
    //     console.log(`Service Worker registered ...`);
    // });
    // }
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        if (registrations.length) {
          for(let registration of registrations) {
            registration.unregister();
          }
        }
      });
    }
  })
  .catch(err => console.error(err));
