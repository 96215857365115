import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from "rxjs";

const API_URL = environment.apiEndPoint;


@Injectable({
  providedIn: "root",
})
export class BookClosureService {
  $firstItem: Subject<any> = new Subject<any>();
  $showPeriodDetails: Subject<any> = new Subject<any>();

  constructor(private _http: HttpClient) {}

  /**
   * Set first item value
   */
  set firstItem(value: any) {
    this.$firstItem.next(value);
  }

  /**
   * Set is empty periods value
   */
  set showPeriodDetails(value: any) {
    this.$showPeriodDetails.next(value);
  }

  /**
   * Gets all periods
   * @param params
   * @param filterQueryString
   * @returns observable
   */
  getAllPeriods(params: any = {}, filterQueryString: string = "") {
    return this._http.get(`${API_URL}/book-closure?${filterQueryString}`, {
      params,
    });
  }


  /**
   * Create period
   * @param periodDetails
   * @returns observable
   */
  public createManualPeriod(periodDetails: any) {
    return this._http.post(`${API_URL}book-closure/create-period/manual`, periodDetails);
  }

  /**
   * Update period
   * @param periodId
   * @param periodDetails
   * @returns observable
   */
  public updateManualPeriod(periodId: any, periodDetails: any) {
    return this._http.put(`${API_URL}book-closure/${periodId}`, periodDetails);
  }

  /**
   * Close Auto Period
   * @param periodDetails
   * @returns observable
   */
  public closeAutoPeriod(periodDetails: any) {
    return this._http.post(`${API_URL}book-closure/close-period/auto`, periodDetails);
  }
  
  /**
   * Get all enabled date ranges
   * @param params
   * @returns observable
   */
   getEnabledDateRanges(params) {
    return this._http.get(`${API_URL}book-closure/all-date-range`, {
      params
    });
  }
}
