
export const PAYMENT_TYPES = {
    'PAYMENT_REQUEST': 'Payment Request',
    'LOAN': 'Loan',
    'OLD_PAYMENT': 'Old Payment',
    'BILL': 'Bill',
    'CREDIT_NOTE': 'Credit Note'
};

export enum SUPPLIER_PAYMENT_TYPES {
    PAYMENT_REQUEST = 'PAYMENT_REQUEST',
    LOAN = 'LOAN',
    OLD_PAYMENT = 'OLD_PAYMENT',
    BILL = 'BILL',
    CREDIT_NOTE = 'CREDIT_NOTE'
}