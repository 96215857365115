/**
 * @typedef {Object} Measure
 * @property {string} unit - Unit of measure
 * @property {number} value - Value of the measure
 */

/**
 * @typedef {Object.<string, Measure>} MeasureTotal
 */
import {roundOff} from './index';
import * as _ from 'lodash';
export function getPoWeightSumBasedOnUnit(items) {
  const weightConst = ["KG", "MT", "G", "GRAM", "KILOGRAM", "METRIC TON"];
  const areaConst = ["SQ. METER", "M2", "SM", "SQUARE METER"];
  const lengthConst = ["MTR", "M", "KM", "INCH", "FEET", "KILOMETER", "METER"];
  const volumeConst = [
    "LITRE",
    "LTR",
    "M3",
    "CU. METER",
    "KL",
    "FT3",
    "CU. FEET",
    "CFT",
    "CUM",
    "CUBIC METER",
    "KILOLITRE",
  ];
  const packetConst = ["PKT"];
  const boxConst = ["BOX"];
  const setConst = ["SET"];
  const rollConst = ["ROLLS"];
  const pairConst = ["PAIR"];
  const noConst = ["NOS"];
  const durationConst = ["MONTHS"];
  const sum = {
    weight: {
      sum: 0,
      unit: "MT",
    },
    area: {
      sum: 0,
      unit: "m2",
    },
    length: {
      sum: 0,
      unit: "M",
    },
    volume: {
      sum: 0,
      unit: "m3",
    },
    packet: {
      sum: 0,
      unit: "PKT",
    },
    box: {
      sum: 0,
      unit: "BOX",
    },
    set: {
      sum: 0,
      unit: "SET",
    },
    roll: {
      sum: 0,
      unit: "ROLLS",
    },
    pair: {
      sum: 0,
      unit: "PAIR",
    },
    no: {
      sum: 0,
      unit: "NOS",
    },
    duration: {
      sum: 0,
      unit: "MONTHS",
    },
  };
  let totalWeightStr = "";
  items.forEach((item) => {
    const unit = item.unit.toUpperCase();
    sum.weight.sum += weightConst.includes(unit)
      ? convertAndGetWeightinMT(item.unit, item.quantity)
      : 0;
    sum.area.sum += areaConst.includes(unit) ? item.quantity : 0;
    sum.length.sum += lengthConst.includes(unit)
      ? convertLengthToMetere(item.unit, item.quantity)
      : 0;
    sum.volume.sum += volumeConst.includes(unit)
      ? convertVolumeToCubicMeter(item.unit, item.quantity)
      : 0;
    sum.packet.sum += packetConst.includes(unit) ? item.quantity : 0;
    sum.box.sum += boxConst.includes(unit) ? item.quantity : 0;
    sum.set.sum += setConst.includes(unit) ? item.quantity : 0;
    sum.roll.sum += rollConst.includes(unit) ? item.quantity : 0;
    sum.pair.sum += pairConst.includes(unit) ? item.quantity : 0;
    sum.no.sum += noConst.includes(unit) ? item.quantity : 0;
    sum.duration.sum += durationConst.includes(unit) ? item.quantity : 0;
  });
  for (const key in sum) {
    if (sum[key]["sum"] > 0) {
      totalWeightStr +=
        round(sum[key]["sum"], 2) + " " + sum[key]["unit"] + ", ";
    }
  }
  return totalWeightStr.replace(/,\s*$/, "");
}

function convertAndGetWeightinMT(unit, value = 0) {
  switch (unit.toUpperCase()) {
    case "MT":
      break;
    case "METRIC TON":
      break;
    case "KILOGRAM":
      break;
    case "KG":
      value = value / 1000;
      break;
    case "G":
      value = value / 1000000;
      break;
    default:
      value = 0;
      break;
  }
  return value;
}
function convertLengthToMetere(unit, value = 0) {
  const unitName = unit.toUpperCase();
  const unitMapper = {
    MTR: value,
    M: value,
    KM: value * 1000,
    INCH: value * 0.0254,
    FEET: value * 0.3048,
    KILOMETER: value * 1000,
    METER: value
  };
  return unitMapper[unitName] || value;
}
function convertVolumeToCubicMeter(unit, value = 0) {
  const unitName = unit.toUpperCase();
  const unitMapper = {
    LTR: value / 1000,
    CUM: value,
    CFT: value / 0.02831,
    M3: value,
    FT3: value * 0.028,
    "CU. METER": value,
    "CU. FEET": value * 0.028,
    KL: value,
    KILOGRAM: value,
    "CUBIC METER": value,
    KILOLITRE: value,
    LITRE: value/1000
  };
  return unitMapper[unitName] || value;
}

function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

class UnitConversion {
  constructor() {
    this.uoms = [];
    this.unitMap = {};
  }
  async initialize(units) {
    this.uoms = units;
    return this.refreshUOMMap();
  }
  /**
   *
   * @param {Measure} from
   * @param {string} [toUnit=null] - Unit of measure
   */
  convertToUnit(from, toUnit = null) {
    toUnit = toUnit || (this.unitMap[from.unit] && this.unitMap[from.unit].default) || from.unit;
    if (!this.unitMap[from.unit] || !this.unitMap[toUnit]) {
      return from;
      // throw new Error(`Conversion Factor for ${from.unit} to ${toUnit} is not defined`);
    }
    let conversionFactor = this.unitMap[from.unit].conversion / this.unitMap[toUnit].conversion;
    if (this.unitMap[from.unit].measurementType !== this.unitMap[toUnit].measurementType) {
      conversionFactor = 0;
    }
    return {
      value: (from.value || 0) * conversionFactor,
      unit: toUnit
    };
  }

  refreshUOMMap() {
    const uomGroupedByType = _.groupBy(this.uoms, uom => {
      return uom.measurementType;
    });
    for (const type of Object.keys(uomGroupedByType)) {
      let defaultUom = uomGroupedByType[type].filter(uom => uom.isDefault);
      defaultUom = (defaultUom.length && defaultUom[0]) || uomGroupedByType[type][0];
      for (const unit of uomGroupedByType[type]) {
        this.unitMap[unit.unit] = {
          ...unit,
          default: defaultUom.unit
        };
      }
    }
  }

  getDefaultUnit(unit) {
    return (this.unitMap[unit] && this.unitMap[unit].default) || unit;
  }

  getMeasurementType(unit) {
    return (
      this.unitMap[unit] && 
      this.unitMap[unit].measurementType && 
      this.unitMap[unit].measurementType.toLowerCase()
    ) || unit;
  }

  /**
   *
   * @param {[Measure]} items
   * @returns {MeasureTotal}
   */
  calculateTotalMeasure(items) {
    items = _.toArray(items);
    items = items.map(function mapToUnitValue(item) {
      return { value: item.value, unit: item.unit };
    });
    var totalMeasure = {};
    for (let item of items) {
      const measurementType = this.unitMap[item.unit] && this.unitMap[item.unit].measurementType.toLowerCase();
      const type = measurementType || item.unit;
      if (!!totalMeasure[type]) {
        totalMeasure[type].value += measurementType ? this.convertToUnit(item).value : item.value;
      } else {
        totalMeasure[type] = measurementType ? this.convertToUnit(item) : item;
      }
    }
    return totalMeasure;
  }

  /**
   *
   * @param {Measure} A
   * @param {Measure} B
   * @returns {Measure} returns B - A
   */
  getDifference(A, B) {
    A = this.convertToUnit(A);
    B = this.convertToUnit(B);
    if (B.unit !== A.unit) {
      return B;
    }
    return {
      value: B.value - A.value,
      unit: B.unit
    };
  }

  /**
   *
   * @param {Measure} A
   * @param {Measure} B
   * @returns {Measure} returns A+B
   */
  getSum(A, B) {
    A = this.convertToUnit(A);
    B = this.convertToUnit(B);
    if (B.unit !== A.unit) {
      return A;
    }
    return {
      value: B.value + A.value,
      unit: B.unit
    };
  }

  /**
   *
   * @param {MeasureTotal} total
   * @param {Measure} A
   * @returns {Measure} Adds A to total
   */
  getGroupedTotal(total = {}, A, quantity = 0) {
    const measureType = this.getMeasurementType(A.unit);
    if (!total[measureType]) {
      total[measureType] = {
        unit: this.getDefaultUnit(A.unit),
        value: 0,
        quantity: 0,
      };
    }
    A = this.convertToUnit(A);
    total[measureType].value += A.value;
    total[measureType].quantity += quantity;
    return total;
  }

  /**
   *
   * @param {MeasureTotal} totalMeasureA
   * @param {MeasureTotal} totalMeasureB
   * @param {Boolean} toAbs - to convert negative to positive
   */

  diffMeasureTotal(totalMeasureA, totalMeasureB, toAbs = false) {
    const differenceObj = JSON.parse(JSON.stringify(totalMeasureA));
    for (const measure in totalMeasureB) {
      if (!differenceObj[measure]) {
        continue;
      }
      differenceObj[measure].value -= totalMeasureB[measure].value;
      if (differenceObj[measure].value < 0 && toAbs) {
        differenceObj[measure].value = 0;
      }
    }
    return differenceObj;
  }

  /**
   *
   * @param {MeasureTotal} totalMeasure
   * @param {MeasureTotal} measureObject
   */

  sumMeasureTotal(totalMeasureA, totalMeasureB) {
    for (const measure in totalMeasureB) {
      if (!totalMeasureA[measure]) {
        totalMeasureA[measure] = {
          value: 0,
          unit: totalMeasureB[measure].unit
        };
      }
      totalMeasureA[measure].value += totalMeasureB[measure].value;
    }
    return totalMeasureA;
  }

  /**
   *
   * @param {MeasureTotal} totalMeasureA
   * @param {MeasureTotal} totalMeasureB
   * @returns {number}
   */
  divMeasureTotal(totalMeasureA, totalMeasureB) {
    let total = 0;
    if (totalMeasureB == null) {
      throw new Error('Cannot divide by 0');
    }
    for (const type in totalMeasureB) {
      const measureA = (totalMeasureA[type] && this.convertToUnit(totalMeasureA[type]).value) || 0;
      const measureB = (totalMeasureB[type] && this.convertToUnit(totalMeasureB[type]).value) || 1;
      total += measureA / measureB;
    }
    return Number.isNaN(total) ? 0 : total;
  }

  /**
   *
   * @param {MeasureTotal} totalMeasure
   */

  getSortedUnitValueArray(totalMeasure) {
    const sortedMeasureArray = [];
    for (const measureType of [...Object.keys(totalMeasure)]) {
      if (totalMeasure[measureType]) {
        sortedMeasureArray.push(totalMeasure[measureType]);
      }
    }
    return sortedMeasureArray;
  }

  /**
   *
   * @param {MeasureTotal} measureObject
   */

  getMeasureObjectAsString(measureObject, round=3) {
    const measureArray = [];
    const sortedMeasureArray = this.getSortedUnitValueArray(measureObject);
    for (const unitMeasure of sortedMeasureArray) {
      measureArray.push(`${roundOff(unitMeasure.value, round)} ${unitMeasure.unit}`);
    }
    return (measureArray.length && measureArray.join(', ')) || '';
  }
}
export let unitConverter = new UnitConversion();
export function initializeUnitConversion(units) {
  unitConverter.initialize(units);
};