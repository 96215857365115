<div class="modal-body status-modal">
  <button class="close-btn" (click)="close()">
    <i class="fas fa-times"></i>
  </button>
  <div class="row">
    <div class="col-12 align-content-center">
      <i *ngIf="successStatus === true" class="fas fa-check-circle green"></i>
      <i *ngIf="successStatus === false" class="fas fa-times-circle red"></i>
      <i *ngIf="successStatus !== true && successStatus !== false" class="fas fa-exclamation-circle orange"></i>
    </div>
    <div class="col-12 align-content-center">
      <div class="message" [innerHTML]="message"></div>
    </div>
  </div>
</div>