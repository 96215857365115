<div *ngIf="params?.value?.component !== 'finance-approval'" (click)="emitAction($event)" class="pointer">
  <img *ngIf="params?.value?.src" [src]="params?.value?.src" />
  <span class="action font-12-bold" *ngIf="!params?.value?.defaultText">{{params?.value?.actionText}}</span>
  <span class="font-12-bold" *ngIf="params?.value?.defaultText" title="{{params?.value?.defaultText}}">
    Error Occurred
    <i class="fas fa-info-circle"></i>
  </span>
</div>

<div *ngIf="params?.value?.component === 'finance-approval'" class="flex pointer finance-approval">
  <div *ngFor="let action of params?.value?.actionsData">
    <button
      (click)="emitAction($event, action?.buttonAction)"
      [ngClass]="action?.buttonClass"
      *ngIf="params?.value?.hasPermission"
    >
      {{ action?.buttonText }}
    </button>
  </div>
</div>
