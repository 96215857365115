import { decodeEntities } from 'src/app/utils';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencySymbolDecode'
})
export class CurrencySymbolDecodePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value) {
      return '₹';
    }
    return decodeEntities(value);
  }

}
