<form class="supplier-feedback-container" #customerFeedbackForm="ngForm" id="supplierD"
  (ngSubmit)="submit(customerFeedbackForm)">
  <div class="logo">
    <img src="/assets/zetwerk-logo.svg" alt="">
  </div>
  <div class="dispatch_feedback">
    Dispatch Feedback
  </div>
  <div class="dispatch_feedback_description">Please give your feedback for the dispatch done on {{feedbackDetails?.invoiceDetails?.invoiceDate | date: 'MMM d, y'}} against invoice: {{feedbackDetails?.invoiceDetails?.invoiceNumber}} for {{feedbackDetails?.contractDetails?.contractNumber}} {{feedbackDetails?.contractDetails?.name}}. Your feedback is valuable to us and will help us serve your better.</div>

  <fieldset ngModelGroup="feedbackForm">
    <div class="dispatch" #qualityDispatch id="qualityDispatch"
      [ngClass]="{'opac': showId['qualityDispatch']}">
      <div class="question-title row">How would you rate the quality of the materials received in this dispatch?</div>
      <div class="content emoji-container">
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityRatingsOfMaterials=== 'Very dissatisfied'}"
            (click)="select('qualityRatingsOfMaterials', 'Very dissatisfied')">
            <img src="/assets/emojis/Very-Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityRatingsOfMaterials=== 'Very dissatisfied'"
              class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Very Dissatisfied</div>
          </div>
        </div>
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityRatingsOfMaterials=== 'Dissatisfied'}"
            (click)="select('qualityRatingsOfMaterials', 'Dissatisfied')">
            <img src="/assets/emojis/Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityRatingsOfMaterials=== 'Dissatisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Dissatisfied</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityRatingsOfMaterials=== 'Neither'}"
            (click)="select('qualityRatingsOfMaterials', 'Neither')">
            <img src="/assets/emojis/Neither.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityRatingsOfMaterials=== 'Neither'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Neither</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityRatingsOfMaterials=== 'Satisfied'}"
            (click)="select('qualityRatingsOfMaterials', 'Satisfied')">
            <img src="/assets/emojis/Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityRatingsOfMaterials=== 'Satisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Satisfied</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityRatingsOfMaterials=== 'Very satisfied'}"
            (click)="select('qualityRatingsOfMaterials', 'Very satisfied')">
            <img src="/assets/emojis/Very-Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityRatingsOfMaterials=== 'Very satisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Very Satisfied</div>
          </div>
        </div>

      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" [disabled]="feedbackForm.qualityRatingsOfMaterials === ''"
          (click)="scrollTo($event, 'btnClick')" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" #nps  [ngClass]="{'opac': showId['nps']}" id="nps">
      <div class="question-title row">Would you want to work with us again?</div>
      <div class="content emoji-container">
        <div class="rating-container">
          <div class="emoji"
            [ngClass]="{'selected': feedbackForm.likenessToWorkWithCompanyInFuture=== 'Definitely not'}"
            (click)="select('likenessToWorkWithCompanyInFuture', 'Definitely not')">
            <img src="/assets/emojis/Very-Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.likenessToWorkWithCompanyInFuture=== 'Definitely not'"
              class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Definitely Not</div>
          </div>
        </div>
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.likenessToWorkWithCompanyInFuture=== 'Probably not'}"
            (click)="select('likenessToWorkWithCompanyInFuture', 'Probably not')">
            <img src="/assets/emojis/Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.likenessToWorkWithCompanyInFuture=== 'Probably not'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Probably Not</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.likenessToWorkWithCompanyInFuture=== 'Possibly'}"
            (click)="select('likenessToWorkWithCompanyInFuture', 'Possibly')">
            <img src="/assets/emojis/Neither.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.likenessToWorkWithCompanyInFuture=== 'Possibly'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Possibly</div>
          </div>

        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.likenessToWorkWithCompanyInFuture === 'Very probably'}"
            (click)="select('likenessToWorkWithCompanyInFuture', 'Very probably')">
            <img src="/assets/emojis/Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.likenessToWorkWithCompanyInFuture=== 'Very probably'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Very probably</div>
          </div>

        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.likenessToWorkWithCompanyInFuture=== 'Definitely'}"
            (click)="select('likenessToWorkWithCompanyInFuture', 'Definitely')">
            <img src="/assets/emojis/Very-Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.likenessToWorkWithCompanyInFuture=== 'Definitely'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Definitely</div>
          </div>

        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event)"
          [disabled]="!feedbackForm.likenessToWorkWithCompanyInFuture" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['improve']}" #qualityWork id="improve"
      ngModelGroup="pointsOfImprovement" (click)="scrollTo($event,
      'isDivClick')">
      <div class="row">What would you want to improve?</div>
      <div class="content">
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.pointsOfImprovement.communication}">
          <div class="material-checkbox">
            <!-- <input type="checkbox" name="Poor Work Man Ship" id="checkbox" [(ngModel)]="feedbackForm.rating.isPoorWorkManship"> -->
            <input type="checkbox" name="Poor Work Man Ship" id="communication"
              [(ngModel)]="feedbackForm.pointsOfImprovement.communication"
              [required]="checkIsRequired(customerFeedbackForm, 'pointsOfImprovement')">
            <label for="communication">Communication</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.pointsOfImprovement.deliveryTimeLines}">
          <div class="material-checkbox">
            <input type="checkbox" name="deliveryTimeLines" [(ngModel)]="feedbackForm.pointsOfImprovement.deliveryTimeLines"
              id="deliveryTimeLines" [required]="checkIsRequired(customerFeedbackForm, 'pointsOfImprovement')">
            <label for="deliveryTimeLines">Delivery timelines</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.pointsOfImprovement.qualityOfWork}">
          <div class="material-checkbox">
            <input type="checkbox" name="qualityOfWork" id="qualityOfWork"
              [(ngModel)]="feedbackForm.pointsOfImprovement.qualityOfWork"
              [required]="checkIsRequired(customerFeedbackForm, 'pointsOfImprovement')">
            <label for="qualityOfWork">Quality of work</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.pointsOfImprovement.progressUpdates}">
          <div class="material-checkbox">
            <input type="checkbox" name="progressUpdates" id="progressUpdates"
              [(ngModel)]="feedbackForm.pointsOfImprovement.progressUpdates"
              [required]="checkIsRequired(customerFeedbackForm, 'pointsOfImprovement')">
            <label for="progressUpdates">Progress updates</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="checkIsDisabled('pointsOfImprovement')" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" id="remarks" [ngClass]="{'opac': showId['remarks']}">
      <div class="row">Remarks</div>
      <div class="row">
        <textarea name="remarks" id="" cols="30" rows="10" [(ngModel)]="feedbackForm.remarks"></textarea>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick', 'end')"
          [disabled]="feedbackForm.remarks === ''" type="button">Ok</button>
      </div>
    </div>
  </fieldset>

  <div class="submit-container dispatch" id="submit">
    <div class="btn-container">
      <button class="btn oms-btn-primary bg"
        [disabled]="!customerFeedbackForm.valid || (!feedbackForm['qualityRatingsOfMaterials'] ||
        (!feedbackForm['likenessToWorkWithCompanyInFuture']))">Submit</button>
    </div>
  </div>
</form>
