import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-confirmation-popup-v2",
  templateUrl: "./confirmation-popup-v2.component.html",
  styleUrls: ["./confirmation-popup-v2.component.scss"],
})
export class ConfirmationPopupV2Component implements OnInit {
  @Input() modalOptions: any = {};
  @Output() action: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onClose() {
    this.action.emit({ type: "CLOSE" });
  }

  onConfirm() {
    this.action.emit({ type: "CONFIRM" });
  }
}
