<nav class="navbar header-navbar row">

  <div>
    <div>
      <div class="row logo-wrapper justify-content-between pb-2">
          <img class="logo" [src]=getClassAndImgLink().logo />
          <!-- <img class="logo" src="/assets/zetwerk-logo.svg" /> -->
          <select
          type="text"
          class="oms-input dropdown-icon"
          [ngClass]="getClassAndImgLink().icon"
          [formControl]="companyType"
          name="companyType"
          (change)="companyChange(companyType)"
          >
          <option value="" disabled>Switch Company</option>
          <option *ngFor="let item of companyDetails" [ngValue]="item.companyDetails.slug">{{
              item.companyDetails.name | uppercase }}</option>
        </select>
      </div>
      <div class="profile-div pb-2">
        <select
          type="text"
          class="oms-input pt14"
          [ngClass]=" gstShow ? 'gst-dropdown-hide' : 'gst-dropdown' "
          [class]="getClassAndImgLink().bgClass"
          [formControl]="gstType"
          name="gstType"
          (change)="gstChange(gstType)"
        >
          <option value="" disabled>Switch GST</option>
          <option *ngFor="let item of gstList" [ngValue]="item._id">{{
              item.gstName }}</option>
        </select>
        <span class="dropdowntext">Currently you are in</span>
      </div>

    </div>
  </div>


</nav>
