import { Injectable } from '@angular/core';
import { OmsService } from './oms.service';

@Injectable({
  providedIn: 'root'
})
export class TenantService {
  selectedTenantId: string;
  constructor(private omsService: OmsService) { }

  getTenantId() {
    return this.selectedTenantId;
  }

  setTenantId() {
    const companies = this.omsService.getUserCompany() || [];
    const selectedCompanySlug = this.omsService.getSelectedCompany();

    const selectedCompany = companies.find((company) => company.slug === selectedCompanySlug);

    this.selectedTenantId = selectedCompany?.companyDetails?.tenantId;
    localStorage.setItem('tenantId', this.selectedTenantId);
  }
}
