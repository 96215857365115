import { Injectable } from '@angular/core';
import { OmsService } from './oms.service';
import { PermissionsService } from './permissions.service';
import { GSTservice } from './gst.service';
import { Location } from '@angular/common';
import { TenantService } from './tenant.service';
import { FeatureFlagService } from './feature-flag.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

interface CompanyObj {
  companySelected: boolean;
  companyDetails: [];
}

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private _oms: OmsService,
    private _ps: PermissionsService,
    private _gst: GSTservice,
    private _location: Location,
    private tenantService: TenantService,
    private featureFlagService: FeatureFlagService,
    private router: Router,
  ) { }


  private companySelected = new BehaviorSubject<CompanyObj>({ companySelected: false, companyDetails: [] });
  companyDetails: any;
  companySelected$ = this.companySelected.asObservable();
  extractUserRoles(roleDetails) {
    if (!roleDetails || roleDetails.length === 0) {
      return '';
    }

    return roleDetails.map(role => role['name']).join(',');
  }

  setPermissions() {
    if (this.companyDetails && this.companyDetails.length > 1) {
      console.log('I am inside app init service');
      this.companySelected.next({ companySelected: true, companyDetails: this.companyDetails });
    } else {
      this._oms.setSelectedCompany(this.companyDetails[0].companyDetails.slug);
      this.tenantService.setTenantId();
      this._oms.setSelectedCompanySlug(this.companyDetails[0].companyDetails.slug);
      const gstData = this._gst.gstData.then(null);
    }
  }

  init() {
    return new Promise<void>((resolve, reject) => {
      this._oms.setIsChannelPartner();
      if (this._oms.getIsChannelPartner()) {
        this.setPermissionAndCompany().then(() => {
          resolve();
        });
      } else {
        this._oms.verifyCookie().subscribe((result: any) => {
          if (!result) {
            this.router.navigate(['/login']);
            resolve();
            return;
          }
          const response = result.data;
          const { email, firstName, lastName, image } = response['userObject'];
          const name = firstName + ' ' + lastName;
          this._oms.setUserName(name);
          this._oms.setUserImage(image);
          this._oms.setUserEmail(email);

          this._oms.setToken(response['tokens']['zetAuthToken']);
          this._oms.setUserRole(this.extractUserRoles(response['userObject']['roleDetails']));
          this.companyDetails = response['userObject']['companies'];
          this._oms.setUserCompany(this.companyDetails);
          this._oms.setUserId(response['userObject']['_id']);
          this.setPermissions();
          this.setPermissionAndCompany().then(() => {
            resolve();
          });
        }, error => {
          resolve();
          this.router.navigate(['/login']);
        });
      }
    });
  }

  setPermissionAndCompany() {
    return new Promise<void>((resolve) => {
      let company = this._location.prepareExternalUrl(this._location.path());
      company = this._oms.getSelectedCompany() || company && company.split('/')[2];
      const userCompanies = this._oms.getUserCompany() || [];
      company = userCompanies.findIndex(userCompany => userCompany.slug === company) >= 0 ? company : this._oms.getSelectedCompanySlug();
      this._oms.setSelectedCompany(company || '');
      this.tenantService.setTenantId();
      if (this._oms.getAuthToken() && this._oms.getSelectedCompany()) {
        this._ps.getUserPermissions().subscribe(response => {
          if (response['success']) {
            this._ps.setPermissions(response['data']['permissions']);
            this.featureFlagService.setFeatureFlags(response['data']['featureFlags']);
          }
          const gstData = this._gst.gstData.then(null);
          Promise.all([gstData]).then(() => {
            resolve();
          });
        });
        this._oms.getUnits().subscribe(response => {
          if (response['success']) {
            this._oms.loadUnitConvertor(response['data'])
          }
        });
      } else {
        resolve();
      }
    });
  }
}