import { Component, isDevMode, OnInit } from '@angular/core';
import {
  environment
} from '../../../environments/environment';
import * as Sentry from '@sentry/browser';
import { OmsService } from '../../services/oms.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  isDevelopment = false;
  public hideDevBar = environment['hideDevBar'];
  public env = environment['env'];

  constructor(private omsService: OmsService) { }

  ngOnInit() {

    if (environment.production) {
      const userEmail = this.omsService.getUserEmail();
      Sentry.init({
        dsn: 'https://3333206148d2431bbe280694a010ceaf@sentry.io/1353663'
      });
      Sentry.configureScope(scope => {
        scope.setUser({
          email: userEmail
        });
      });
    }

    if (!this.hideDevBar) {
      this.isDevelopment = isDevMode();
    }
  }

}
