export enum ColumnType {
  LINK = 'LINK',
  PROGRESS = 'PROGRESS',
  STATUS_LABEL = 'STATUS_LABEL',
  QUANTITY = 'QUANTITY',
  UNIT_RATE = 'UNIT_RATE',
  QUANTITY_WITH_UNIT_FROM_ITEM = 'QUANTITY_WITH_UNIT_FROM_ITEM', // this will pick the unit field from the items and uses that unit
  RATE_WITH_UNIT_FROM_ITEM = 'RATE_WITH_UNIT_FROM_ITEM', // this will pick the unit field from the items and uses that unit
  DATE = 'DATE',
  CURRENCY_TEXT = 'CURRENCY_TEXT',
  CONTRACT_CUSTOMER = 'CONTRACT_CUSTOMER',
  WITH_SUPTEXT_SUBTEXT = 'WITH_SUPTEXT_SUBTEXT', // this text will look like PO#5363563 -  • 125MT and below that
  // Balaji Rajshekhar Works
  WITH_SUB_TEXT = 'WITH_SUB_TEXT', // this is for something if we have First field is above and 2nd Field is below
  WITH_DATE_SUB_TEXT = 'WITH_DATE_SUB_TEXT', // this is for something if we have First field is above and 2nd Field below is DATE
  // this is used if we need to display multiple fields in a single line with a subtext
  WITH_SUB_TEXT_MULTIPLE_FIELDS = 'WITH_SUB_TEXT_MULTIPLE_FIELDS',
  S_NO = 'S_NO', // this is for rendering the s.no based on ngFor index
  LIGHT_TEXT = 'LIGHT_TEXT',
  PENDING_DATE_SUBTEXT = 'PENDING_DATE_SUBTEXT',
  TAX = 'TAX',
  WITH_SUB_TEXT_SUP_DATETIME = 'WITH_SUB_TEXT_SUP_DATETIME',
  PR_STATUS_LABEL = 'PR_STATUS_LABEL',
  WITH_SUBTEXT_NUMBER = 'WITH_SUBTEXT_NUMBER',
  PAYMENT_TYPE = 'PAYMENT_TYPE',
  CHECKBOX = 'CHECKBOX',
  ACTION = 'ACTION',
  CHARGES_TOOLTIP = 'CHARGES_TOOLTIP',
  WITH_SUB_TEXT_LINK = 'WITH_SUB_TEXT_LINK',
  NAVIGATION_LINK = 'NAVIGATION_LINK',
  WITH_SUB_TEXT_LABEL = 'WITH_SUB_TEXT_LABEL',
}
