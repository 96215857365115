<div class="zetwerk-login-wrapper" *ngIf="!companySelect && !isChannelPartner">
  <div class="center">
    <img src="/assets/logo_blue.png" class="login-logo" />
    <div class="login-block">
      <p class="login-signin-p">Sign In to</p>
      <p class="login-signin-p1">Order Management System</p>
    </div>
    <zet-google-login-btn (login)="onLogin($event)"></zet-google-login-btn>
  </div>
</div>

<!-- Email and OTP based login page, this is special added for channel partner user login -->
<div
  *ngIf="!companySelect && isChannelPartner"
  class="channel-partner-login-wrapper"
>
  <img
    src="./../../../assets/zetwerk_logo_login.svg"
    class="zetwerk-logo"
    alt="Zetwerk"
  />
  <img
    src="./../../../assets/login_illustrations.svg"
    class="illustrations-logo"
    alt="Zetwerk"
  />
  <zet-login
    [type]="loginType.EMAIL_OTP"
    (login)="onChannelPartnerLogin($event)"
  ></zet-login>
</div>

<div class="company-wrapper" *ngIf="companySelect">
  <div class="company-card">
    <div class="company-header">Select Company</div>
    <div
      *ngFor="let item of companyDetails; let i = index"
      (click)="onChangeCompany(item?.companyDetails?.slug, item)"
      class="company row justify-content-between"
      [class.selected-company]="item?.isSelected"
    >
      <div
        class="company-name"
        [ngClass]="
          item?.isSelected ? 'company-name-selected' : 'company-name-unselected'
        "
      >
        {{ item?.companyDetails?.name }}
      </div>
      <div class="point" [class.green-point]="item?.isSelected"></div>
      <div *ngIf="!item?.isSelected">
        <i class="arrow-color fas fa-arrow-right"></i>
      </div>
    </div>
  </div>
</div>
