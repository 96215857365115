export const STATUS_MAP = {
    CREATED: 'Contract Created',
    CLOSED: 'Contract Closed',
    CANCELLED: 'Contract Cancelled',
    LOI_CREATED: 'LOI Received',
    LOI_REVISED: 'LOI Revised',
    DRAFT_PO_CREATED: 'Draft PO Received',
    DRAFT_PO_REVISED: 'Draft PO Revised',
    PO_CREATED: 'PO Received',
    PO_REVISED: 'PO Revised',
    PO_ACKNOWLEDGED: 'PO Acknowledged',
    BILLING_STARTED: 'Billing Started',
    ORDER_RELEASED: 'Order Released',
    BILLING_STOPPED: 'Billing Stopped',
    REVISION_REQUESTED: 'CN Under Revision'
};
