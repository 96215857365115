import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';

@Component({
  selector: 'app-common-cell',
  templateUrl: './common-cell.component.html',
  styleUrls: ['./common-cell.component.scss']
})
export class CommonCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {
  }
}
