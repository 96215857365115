import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationPopupV2Component } from "src/app/components/general/confirmation-popup-v2/confirmation-popup-v2.component";
import { BsModalService } from "ngx-bootstrap/modal";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ContractService } from 'src/app/services/contract.service';
import { BUSINESS_UNIT, BU_SCOPES, ENTITY, ENTITY_SCOPES,AUTO } from 'src/app/constants/book-closure';
import { ValidationService } from 'src/app/services/validation/validation.service';
import * as moment from 'moment';
import { BookClosureService } from 'src/app/services/book-closure.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { Router, ActivatedRoute } from '@angular/router';
import { deepCopy } from 'src/app/utils/index';

@Component({
  selector: 'app-book-closure-details',
  templateUrl: './book-closure-details.component.html',
  styleUrls: ['./book-closure-details.component.scss'],
  providers: [DatePipe]
})
export class BookClosureDetailsComponent implements OnInit {
  @Input() period: any = {};
  @Input() showSection: boolean = true;
  @Output() action: EventEmitter<any> = new EventEmitter<any>();
  isEdit: Boolean = false;
  isAutoPeriod: Boolean = false;
  public periodForm: FormGroup;
  isDetailsPresent: Boolean = false;
  businessUnits: any = [];
  buScopes = Object.values(BU_SCOPES);
  entityScopes = Object.values(ENTITY_SCOPES);
  levels: any = [];
  currentSelectedBuId: any = '';
  isAllScopesForAllBuSelected: Boolean = false;
  isAllScopesForCurrentBuSelected: Boolean = false;
  startDateError: String = '';
  endDateError: String = '';
  validityError: String = '';
  scopesError: String = '';
  BUSINESS_UNIT_TYPE = 'BU';
  activeTab = '';

  constructor(
    private _modalService: BsModalService,
    private _formBuilder: FormBuilder,
    private _datePipe: DatePipe,
    private contractService: ContractService,
    private _validate: ValidationService,
    private bookClosureService: BookClosureService,
    private notificationService: NotificationService,
    private _router: Router,
    private _activateRoute: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._activateRoute.params.subscribe((params: any) => {
      this.activeTab = params?.status;
    });
  }

  ngOnChanges() {
    this.initialize();
  }

  initialize() {
    this.isDetailsPresent = this.period && this.period.periodNumber ? true : false;
    this.isEdit = !this.isDetailsPresent;
    this.isAutoPeriod = this.period?.type === AUTO ? true : false;
    this.createPeriodForm();
    this.getAllBusinessUnits();
    this.patchFormValues();
    this.patchLevels();
  }

  /**
   * Creates the form for book closure period
   */
  createPeriodForm() {
    this.periodForm = this._formBuilder.group({
      startDate: [{value: null, disabled: this.isDetailsPresent}, [Validators.required]],
      endDate: [{value: null, disabled: this.isDetailsPresent}, [Validators.required]],
      validityDate: [{value: null, disabled: this.isDetailsPresent}, [Validators.required]],
      validityTime: [{value: null, disabled: this.isDetailsPresent}, [Validators.required]],
      validTill: [{value: null, disabled: this.isDetailsPresent}]
    });
  }

  /**
   * Patches the form values for book closure
   */
  patchFormValues() {
    if(this.period && this.period.periodNumber) {
      this.periodForm.patchValue({
        startDate: this.period.startDate.split('T')[0],
        endDate: this.period.endDate.split('T')[0],
        validityDate: this.period.validUntil.split('T')[0],
        validityTime: this.period.validUntil.split('T')[1].slice(0, -5),
        validTill: this.period.validUntil.slice(0, -5)
      });
      this.periodForm.updateValueAndValidity();
    }
  }

  /**
   * Fetches all the existing business units
   */
  getAllBusinessUnits() {
    this.contractService.getBusinessUnits().subscribe((res:any) => {
      this.businessUnits = res.data || [];
      this.businessUnits.sort((a,b) => a.name.localeCompare(b.name));
      this.currentSelectedBuId = this.businessUnits[0]._id;
      this.setLevels(this.businessUnits);
      this.onBuSelect(this.businessUnits[0]);
    });
  }

  /**
   * Toggles the isEdit boolean flag
   */
  onEditPeriod() {
    this.isEdit = !this.isEdit;
  }

  /**
   * Check for scopes validity
   * @returns boolean
   */
  isValidScopes() {
    const isValid = this.levels.some((level: any) => level?.scopes?.length);
    if (!isValid) {
      this.scopesError = 'Select atleast one business unit and one scope';
    } else {
      this.scopesError = '';
    }
    return isValid;
  }

  /**
   * Creates/Updates a Period
   */
  onSavePeriod() {
    let periodFormData = this.periodForm.getRawValue();
    const date = moment().toDate();

    const checkValidityDate = moment(date).add(7, 'days').toDate();

    let current_time = moment(date).format('HH:mm:ss');
    let startDate = moment(periodFormData.startDate + ' ' + current_time).toDate();

    let endDate = moment(periodFormData.endDate).endOf('day').toDate();

    let validUntil = moment(periodFormData.validityDate + ' ' + periodFormData.validityTime).toDate();

    this.startDateError = this.endDateError = this.validityError = '';

    if(!this.periodForm.invalid) {
      if(startDate > endDate) {
        this.startDateError = 'Period start date cannot be greater than the end date';
      }
      else if(startDate > date) {
        this.startDateError = 'Manual period can only be created for past dates';
      }
      else if(endDate > date) {
        this.endDateError = 'Manual period can only be created for past dates';
      }
      else if(validUntil < date) {
        this.validityError = 'Period Validity should be a future date';
      }
      else if(validUntil > checkValidityDate && !this.isAutoPeriod) {
        this.validityError = 'Period Validity cannot be 7 days greater than that of the creation date';
      } else if (this.isValidScopes()) {
        this.resetErrors();
        periodFormData.levels = this.levels;
        if(!this.isDetailsPresent) {
          return this.bookClosureService.createManualPeriod(periodFormData).subscribe((response) => {
            if (response['success']) {
              this.notificationService.successNotification('Manual Period Created Successfully', 'Success');
              this.period = response['data'];
              this.initialize();
              this.isEdit = false;
              this.action.emit({ type: 'SUCCESS' });
            } else {
              this.notificationService.errorNotification(`'Manual Period Creation Failed`, 'Error');
            }
          });
        } else {
          this.resetErrors();
          periodFormData.levels = this.levels;
          periodFormData.status = this.period.status;
          return this.bookClosureService.updateManualPeriod(this.period._id, periodFormData).subscribe((response) => {
            if (response['success']) {
              this.notificationService.successNotification('Manual Period Updated Successfully', 'Success');
              this.period = response['data'];
              this.initialize();
              this.isEdit = false;
              this.action.emit({ type: 'SUCCESS' });
            } else {
              this.notificationService.errorNotification(`'Manual Period Updation Failed`, 'Error');
            }
          });
        }
      }
    } else {
      this._validate.validateAllFormFields(this.periodForm);
    }
  }

  /**
   * Sets levels on Entity and BU Level
   */
  setLevels(businessUnits) {
    this.levels = [];
    businessUnits.forEach(businessUnit => {
      this.levels.push({
        level: 'BUSINESS_UNIT',
        businessUnitId: businessUnit._id,
        scopes: []
      });
    });
    this.levels.push({
      level: 'ENTITY',
      businessUnitId: null,
      scopes: []
    });
    this.patchLevels();
    this.setScopesCountForBU();
  }

  /**
   * Patches the levels data if available
   */
  patchLevels() {
    if(this.isDetailsPresent) {
      this.period.levels.forEach(periodLevel => {
        let matchingLevel = this.levels.find(level => level.businessUnitId === periodLevel.businessUnitId);
        if(matchingLevel)
          matchingLevel.scopes = deepCopy(periodLevel.scopes);
      });
    }
  }

  /**
   * Set selected scopes count
   */
  setScopesCountForBU() {
    this.levels.forEach((level: any) => {
      const businessUnit = this.businessUnits.find((businessUnit: any) => businessUnit._id === level.businessUnitId);
      if (!businessUnit) return;
      businessUnit.scopesCount = level?.scopes?.length;
    })
  }

  checkIfAllScopesForCurrentBuSelected(currentLevel) {
    this.isAllScopesForCurrentBuSelected = currentLevel.scopes.length === this.buScopes.length;
  }

  /**
   * Handle on click of BU
   */
  onBuSelect(businessUnit) {
    this.currentSelectedBuId = businessUnit._id;
    let currentLevel = this.levels.find(level => level.businessUnitId === this.currentSelectedBuId);
    this.checkIfAllScopesForCurrentBuSelected(currentLevel)
  }

  /**
   * Enables all the scopes for every BU combination
   */
  selectAllScopesForAllBu() {
    this.levels.forEach(level => {
      if (level.level === ENTITY) return;
      if(level.level === BUSINESS_UNIT && !this.isAllScopesForAllBuSelected)
        level.scopes = Object.keys(BU_SCOPES);
      else if(this.isAllScopesForAllBuSelected)
        level.scopes = [];
    });
    this.isAllScopesForAllBuSelected = !this.isAllScopesForAllBuSelected;
    this.isAllScopesForCurrentBuSelected = this.isAllScopesForAllBuSelected;
    this.isValidScopes();
    this.setScopesCountForBU();
  }

  /**
   * Enables all the scopes for current selected BU
   */
  selectAllScopesForCurrentBu() {
    let currentLevelIndex = this.levels.findIndex(level => level.businessUnitId === this.currentSelectedBuId);
    if(!this.isAllScopesForCurrentBuSelected) {
      this.levels[currentLevelIndex].scopes = Object.keys(BU_SCOPES);
    } else {
      this.levels[currentLevelIndex].scopes = [];
    }
    this.isAllScopesForCurrentBuSelected = !this.isAllScopesForCurrentBuSelected;
    this.isAllScopesForAllBuSelected = this.isAllScopesForCurrentBuSelected;
    this.isValidScopes();
    this.setScopesCountForBU();
  }

  /**
   * Toggles the scopes for current selected BU
   */
  toggleScope(scope, type) {
    if(type === this.BUSINESS_UNIT_TYPE) {
      let currentLevel = this.levels.find(level => level.businessUnitId === this.currentSelectedBuId);
      let scopeKey = Object.keys(BU_SCOPES).find(key => BU_SCOPES[key] === scope);
      if(currentLevel.scopes.includes(scopeKey)) {
        currentLevel.scopes.splice(currentLevel.scopes.indexOf(scopeKey), 1);
      } else {
        currentLevel.scopes.push(scopeKey);
      }
      this.checkIfAllScopesForCurrentBuSelected(currentLevel)
    } else {
      let currentLevel = this.levels.find(level => !level.businessUnitId);
      let scopeKey = Object.keys(ENTITY_SCOPES).find(key => ENTITY_SCOPES[key] === scope);
      if(currentLevel.scopes.includes(scopeKey)) {
        currentLevel.scopes.splice(currentLevel.scopes.indexOf(scopeKey), 1);
      } else {
        currentLevel.scopes.push(scopeKey);
      }
    }
    this.isValidScopes();
    this.setScopesCountForBU();
  }

  /**
   * Returns a boolean value, indicating if scope is checked for a given BU
   */
  isScopeChecked(scope, type) {
    const isBusinessUnit = type === this.BUSINESS_UNIT_TYPE;
    const SCOPES = isBusinessUnit ? BU_SCOPES : ENTITY_SCOPES;

    const currentLevel = this.levels.find((level: any) => {
      return isBusinessUnit ? level.businessUnitId === this.currentSelectedBuId : !level.businessUnitId;
    });
    const scopeKey = Object.keys(SCOPES).find(key => SCOPES[key] === scope);
    return currentLevel?.scopes.includes(scopeKey);
  }


  /**
   * Reverts any modifications done on period edit
   */
   onCancelPeriod() {
    const initialState = {
      modalOptions: {
        title: "Cancel Period Changes",
        message:
          "Are you sure you want to Cancel the changes made to the period? This action cannot be undone.",
        confirmButton: { text: "Yes", type:"DELETE" },
        cancelButton: { text: "No" },
      },
    };
    const deletePopupRef = this._modalService.show(
      ConfirmationPopupV2Component,
      {
        initialState,
        ignoreBackdropClick: true,
        class: "modal-middle",
      }
    );
    deletePopupRef.content.action.subscribe((response) => {
      if (response?.type === "CLOSE") {
        deletePopupRef.hide();
      } else {
        deletePopupRef.hide();
        this.isEdit = !this.isEdit;
        this.initialize();
        this.action.emit({ type: 'CANCEL' });
        this.scopesError = "";
        this.resetErrors();
      }
    });
  }

  /**
   * Reset errors
   */
  resetErrors() {
    this.startDateError = "";
    this.endDateError = "";
    this.validityError = "";
  }

  /**
   * Closes a Period
   */
   onClosePeriod() {
    const initialState = {
      modalOptions: {
        title: "Close Period",
        message:
          "Are you sure you want to close this period? After closing you will not be able to post transactions for this period.",
        confirmButton: { text: "Close", type:"DELETE" },
        cancelButton: { text: "Cancel" },
      },
    };
    const deletePopupRef = this._modalService.show(
      ConfirmationPopupV2Component,
      {
        initialState,
        ignoreBackdropClick: true,
        class: "modal-middle",
      }
    );
    deletePopupRef.content.action.subscribe((response) => {
      if (response?.type === "CLOSE") {
        deletePopupRef.hide();
      } else {
        deletePopupRef.hide();
        let periodFormData = this.periodForm.getRawValue();
        periodFormData.levels = this.levels;
        periodFormData.status = 'CLOSED';
        this._router.navigate(['settings', 'book-closure', periodFormData.status]);
        if(this.isAutoPeriod){
          return this.bookClosureService.closeAutoPeriod({id:this.period._id,...periodFormData}).subscribe((response) => {
            if (response['success']) {
              this.notificationService.successNotification('Initiated Auto Period Closure,System Will Notify On Completion!', 'Success');
              this.period = response['data'];
              this.initialize();
              this.isEdit = false;
            } else {
              this.notificationService.errorNotification(`'Closing Auto Period Failed`, 'Error');
            }
          });
        }else{
          return this.bookClosureService.updateManualPeriod(this.period._id, periodFormData).subscribe((response) => {
            if (response['success']) {
              this.notificationService.successNotification('Manual Period Closed Successfully', 'Success');
              this.period = response['data'];
              this.initialize();
              this.isEdit = false;
            } else {
              this.notificationService.errorNotification(`'Closing Manual Period Failed`, 'Error');
            }
          });
        }
        
      }
    });
  }

}
