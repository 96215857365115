<div class="row">
  <div>
    {{ params?.value?.percent }} %&nbsp;-&nbsp;
  </div>
  <div [innerHTML]="params?.value?.currencySymbol" class="currency">
  </div>
  <div>
    {{ params?.value?.amount && transform(params?.value?.amount) }}
  </div>
</div>
