import { IndianCurrencyPipe } from './pipe/indian-currency.pipe';
import { CurrencySymbolDecodePipe } from './pipe/currency-symbol-decode.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableComponent } from './component/data-table/data-table.component';
import { FooterComponent } from './component/footer/footer.component';
import { HeaderComponent } from './component/header/header.component';
import { BodyComponent } from './component/body/body.component';
import { DatatabeColumnComponent } from './component/header/datatabe-column/datatabe-column.component';
import { DataTableColumnDirective } from './directive/column.directive';
import { DataTableColumnHeaderDirective } from './directive/column-header.directive';
import { BodyCellComponent } from './component/body/body-cell/body-cell.component';
import { BodyWrapperComponent } from './component/body/body-wrapper/body-wrapper.component';
import { PaginationComponent } from './component/pagination/pagination.component';
import { FormsModule } from '@angular/forms';
import { SearchInputComponent } from './component/search-input/search-input.component';
import { StatusComponent } from '../../../components/general/status/status.component';
import { DatePendingPipe } from './pipe/date-pending.pipe';
import { EllipsifyMeDirective } from 'src/app/directives/ellipsify-me.directive';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FormatNumberPipe } from './pipe/formatNumber.pipe';


@NgModule({
  imports: [CommonModule, FormsModule,TooltipModule],
  declarations: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    DataTableComponent,
    DatatabeColumnComponent,
    DataTableColumnDirective,
    DataTableColumnHeaderDirective,
    BodyWrapperComponent,
    BodyCellComponent,
    PaginationComponent,
    SearchInputComponent,
    StatusComponent,
    IndianCurrencyPipe,
    CurrencySymbolDecodePipe,
    DatePendingPipe,
    EllipsifyMeDirective,
    FormatNumberPipe
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    BodyComponent,
    DataTableComponent,
    DatatabeColumnComponent,
    DataTableColumnDirective,
    DataTableColumnHeaderDirective,
    BodyWrapperComponent,
    BodyComponent,
    PaginationComponent,
    SearchInputComponent,
    StatusComponent,
    EllipsifyMeDirective
  ],
  providers: [],
})
export class ZetwerkDatatableModule {
}

