import { of, Observable, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { environment } from './../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LABELS } from '../constants/labels';
import { SequenceNumbers } from '../models/gst-details';
import { SAMPLE_SEQUENCE_NUMS } from '../constants/sample-sequence-nums';
import { deepCopy } from '../utils';
import * as _ from 'lodash-es';

const API_URL = environment.apiEndPoint;


@Injectable({
  providedIn: 'root'
})
export class GSTservice {

  selectedGSTSubj: Subject<string> = new ReplaySubject<string>(1);
  _gstData: any;
  gstHash: any;
  selectedGST;
  constructor(
    private http: HttpClient
  ) {
    if (!this.selectedGST) {
      this.selectedGST = 'allGST';
    }
  }

  public getGstConfigServer(urlParams = {}) {
    const selectFields = 'name gst gstName state docNoInfix addresses addressesV2 export_invoice_data cleartax_owner_id';
    return this.http.get(API_URL + 'zetwerk-gst/config-list', {
      params: {
        ...urlParams,
        selectFields
      }
    });
  }
  public getGSTconfigList(): Observable<any> {
    // return this.http.get(API_URL + 'zetwerk-gst/config-list');
    return new Observable((ob) => {
      ob.next(deepCopy(this._gstData));
    });
  }
  /**
   *
   * @param gstNo
   * return gst details for gstNo
   */
  public getZwGSTdetails(gstNo): Observable<any> {
    return this.http.get(API_URL + 'zetwerk-gst-details/' + gstNo);
  }

  public getZwGSTdetailsByGSTNumber(gstNo) {
    return this.http.get(API_URL + `zetwerk-gst-details/${gstNo}/details`);
  }


  public getRandom(noOfDigits) {
    return Math.floor(Math.random() * Math.pow(10, noOfDigits));
  }

  public zwGSTnameExist(gstName) {
    return this.http.get(API_URL + 'zetwerk-gst-name-exist/' + gstName);
  }

  public saveGSTdetails(inputData) {
    return this.http.post(API_URL + 'zetwerk-gst/create', inputData);
  }

  public getSequenceNumbers(): SequenceNumbers {
    // return this.http.get(API_URL + 'zetwerk-gst/sequence-numbers');
    return SAMPLE_SEQUENCE_NUMS;
  }

  /**
   *
   * @param sequenceNumbers
   * @param prefix
   * @param includeClass //whether to add class for style(colors N all) purpose
   */
  public getSequenceFormat(sequenceNumbers: SequenceNumbers, prefix: string = '', includeClass = true) {

    const className = 'doc-infix ' + (prefix || '').toLowerCase();
    const injectString = includeClass ? `<span class="${className}">${prefix}</span>` : prefix;

    const sequenceFormats = {
      contractNo: {
        label: LABELS.contractNo,
        value: (prefix && sequenceNumbers) ? `CN-${injectString}${sequenceNumbers.contractNo}` : `-`
      },
      orderNo: {
        label: LABELS.orderNo,
        value: (prefix && sequenceNumbers) ? `OD-${injectString}${sequenceNumbers.orderNo}` : `-`
      },
      poNo: {
        label: LABELS.poNo,
        value: (prefix && sequenceNumbers) ? `ZET-${injectString}-${sequenceNumbers.poNo}` : `-`
      },
      creditNoteNo: {
        label: LABELS.creditNoteNo,
        value: (prefix && sequenceNumbers) ? `CRS-${injectString}${sequenceNumbers.creditNoteNo}` : `-`
      },
      debitNoteNo: {
        label: LABELS.debitNoteNo,
        value: (prefix && sequenceNumbers) ? `DRS-${injectString}${sequenceNumbers.debitNoteNo}` : `-`
      },
      paymentRequestId: {
        label: LABELS.paymentRequestId,
        value: (prefix && sequenceNumbers) ? `PR-${injectString}${sequenceNumbers.paymentRequestId}` : `-`
      },
      loanNo: {
        label: LABELS.loanNo,
        value: (prefix && sequenceNumbers) ? `L-${injectString}${sequenceNumbers.loanNo}` : `-`
      },
      invoiceNo: {
        label: LABELS.invoiceNo,
        value: (prefix && sequenceNumbers) ? `${injectString}/IN/${sequenceNumbers.invoiceNo}` : `-`
      },
      proformaInvoiceNo: {
        label: LABELS.proformaInvoiceNo,
        value: (prefix && sequenceNumbers) ? `${injectString}/PI/${sequenceNumbers.proformaInvoiceNo}` : `-`
      }
    };

    return sequenceFormats;
  }


  gstSelection(gstId) {
    this.selectedGST = gstId;
    // this.selectedGSTSubj.next(gstId);
  }



  /**
   *
   * @param gstNo
   * return gst details for gstNo for Supplier
   */
  public getSupplierGSTdetails(supplierId, gstNo): Observable<any> {
    return this.http.get(`${API_URL}suppliers/${supplierId}/gst-details/${gstNo}`);
  }

  public get gstData() {
    return new Promise((resolve, reject) => {
      this.getGstConfigServer().subscribe((res: any) => {
        if (res.success) {
          this._gstData = deepCopy(res);
          this.gstHash = _.keyBy(res.data, '_id');
          resolve(res);
        }
      }, (err) => {
        reject(err);
      });
    });
  }

  public getGstList() {
    if (this._gstData) {
      return this._gstData.data;
    }
  }

  public getGSTReconData({ payload }) {
    return this.http.post(`${API_URL}reports/gstr2a-recon`, payload);
  }

  getZetwerkGstDetails(urlParams) {
    return this.http.get(API_URL + 'zetwerk-gst/config-list', {
      params: {
        ...urlParams
      }
    });
  }

}



