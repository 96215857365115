import { FY_YEAR } from './dates';
import { SequenceNumbers } from '../models/gst-details';

export const SAMPLE_SEQUENCE_NUMS: SequenceNumbers = {
    contractNo: `001`,
    orderNo:  '001',
    poNo: 'SUPM7' + '1-1',
    creditNoteNo:  `001`,
    debitNoteNo:  `001`,
    paymentRequestId:  `002`,
    loanNo:  `002`,
    invoiceNo: `${FY_YEAR.from % 100}${FY_YEAR.to % 100}/01132`,
    proformaInvoiceNo: `247/${FY_YEAR.from % 100}-${FY_YEAR.to % 100}`
};

