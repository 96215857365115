import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ICellCmp } from '@zetwerk/zet-list';
import { BsModalService } from 'ngx-bootstrap/modal';
import { InvoiceDiscountingService } from 'src/app/services/invoice-discounting.service';
import { NotificationService } from 'src/app/services/notification/notification.service';
import { RouteReloadService } from 'src/app/services/route-reload.service';
import { ValidationService } from 'src/app/services/validation/validation.service';

@Component({
  selector: 'app-reject-cell',
  templateUrl: './reject-cell.component.html',
  styleUrls: ['./reject-cell.component.scss']
})
export class RejectCellComponent implements ICellCmp {

  params;
  columnDef;
  element;
  public rejectInvoiceDiscountingModalRef;
  rejectInvoiceDiscountingForm: FormGroup;


  constructor(private _router: Router,private _fb: FormBuilder, private _validationService: ValidationService,
   private _modalService: BsModalService, private _invoiceDiscountingService: InvoiceDiscountingService,
   private _notificationService: NotificationService, private _routeReload: RouteReloadService) { }

  init(params: any) {
    this.loadForm();
  }

  navigateToClickedLink(templateRef) {
    this.rejectInvoiceDiscountingModalRef = this._modalService.show(templateRef, { class: 'modal-dialog-centered',
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

  loadForm() {
    this.rejectInvoiceDiscountingForm = this._fb.group({
      rejectReason: [null, [Validators.required]]
    });
  }

  reject() {
    if (!this.rejectInvoiceDiscountingForm.valid) {
      this._validationService.validateAllFormFields(this.rejectInvoiceDiscountingForm);
      return;
    }
    this._invoiceDiscountingService.rejectInvoiceDiscount({ payload: this.rejectInvoiceDiscountingForm.getRawValue(), invoiceDiscountingId: this.params.value._id}).subscribe(result => {
      this._notificationService.successNotification('Invoice Discounting Rejected', 'Success');
      this._routeReload.handleForceDataUpdate();
      this.rejectInvoiceDiscountingModalRef.hide();
    });
  }

}
