import { OptionModel } from 'src/app/models/tab-model';
import { PAYMENT_TYPES } from './payment-type';
import { ColumnType } from '../components/shared/data-table/model/column-type';

export enum STATUS_LIST {
  PAYMENT_DONE = 'PAYMENT_DONE',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PROCESSING = 'PROCESSING',
  PENDING = 'PENDING',
  PAYMENT_REJECTED = 'PAYMENT_REJECTED'
}

export enum TAB_LIST {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED'
}

export const TAB_LIST_LABEL  = {
  APPROVED: <OptionModel> {value: TAB_LIST.APPROVED, label: 'APPROVED'},
  PENDING: <OptionModel> {value: TAB_LIST.PENDING, label: 'PENDING'},
  REJECTED: <OptionModel> {value: TAB_LIST.REJECTED, label: 'REJECTED'}
};

export const STATUS_MAP = {
  PAYMENT_DONE: 'Transfered',
  PAYMENT_FAILED: 'Failed',
  PROCESSING: 'Processing',
  PAYMENT_REJECTED: 'Rejected',
  PENDING: 'Pending',
  LC_REPAYMENT_DONE: 'LC Repayment Done'
};

const actionConfig = [
  {
    conditions: {
      'status': 'PAYMENT_DONE_Transfered',
      'showSecondaryMarkPaid': true,
    },
    options: [
      {
        name: 'Mark as Paid(Secondary)',
        value: 'SECONDARY'
      },
      {
        name: 'Cancel Cheque',
        value: 'CANCEL_CHEQUE'
      }
    ]
  },
  {
    conditions: {
      'status': 'PROCESSING_Processing',
      'isSecondaryAccountPayment': true
    },
    options: [
      {
        name: 'Mark as Paid(Primary)',
        value: 'PRIMARY'
      },
      {
        name: 'Mark as Paid(Secondary)',
        value: 'SECONDARY',
        disabled: true
      },
      {
        name: 'Cancel Cheque',
        value: 'CANCEL_CHEQUE'
      }
    ]
  },
  {
    conditions: {
      'status': 'PROCESSING_Processing',
    },
    options: [
      {
        name: 'Mark as Paid',
        value: 'PRIMARY'
      },
      {
        name: 'Cancel Cheque',
        value: 'CANCEL_CHEQUE'
      }
    ]
  }
];

const searchActionConfig = [
  {
    conditions: {
      'status': 'PAYMENT_DONE_Transfered',
      'showSecondaryMarkPaid': true,
      'transactionType':'PDC'

    },
    options: [
      {
        name: 'Mark as Paid(Secondary)',
        value: 'SECONDARY'
      },
      {
        name: 'Cancel Cheque',
        value: 'CANCEL_CHEQUE'
      }
    ]
  },
  {
    conditions: {
      'status': 'PROCESSING_Processing',
      'isSecondaryAccountPayment': true,
      'transactionType':'PDC'
    },
    options: [
      {
        name: 'Mark as Paid(Primary)',
        value: 'PRIMARY'
      },
      {
        name: 'Mark as Paid(Secondary)',
        value: 'SECONDARY',
        disabled: true
      },
      {
        name: 'Cancel Cheque',
        value: 'CANCEL_CHEQUE'
      }
    ]
  },
  {
    conditions: {
      'status': 'PROCESSING_Processing',
      'transactionType':'PDC'
    },
    options: [
      {
        name: 'Mark as Paid',
        value: 'PRIMARY'
      },
      {
        name: 'Cancel Cheque',
        value: 'CANCEL_CHEQUE'
      }
    ]
  },
  {
    conditions: {
      'status': 'PAYMENT_DONE_Transfered',
      'transactionType':'LC'
    },
    options: [
      {
        name: 'Mark LC Repayment',
        value: 'LC_REPAID'
      }
    ]
  }
];

const lcActionConfig = [
  {
    conditions: {
      'status': 'PAYMENT_DONE_Transfered',

    },
    options: [
      {
        name: 'Mark LC Repayment',
        value: 'LC_REPAID'
      }
    ]
  }
];
export const bankTransactionColumnDefs = [{
    'name': 'Supplier Name',
    'fieldName': 'supplierName',
    'width': '13%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'supplierLocation'
  }, {
    'name': 'Order',
    'fieldName': 'orderNumber',
    'width': '9%',
  }, {
    'name': 'Type',
    'fieldName': 'paymentType',
    'width': '10%',
    'columnType': ColumnType.PAYMENT_TYPE,
    'paymentMap': PAYMENT_TYPES,
  }, {
    'name': 'Date',
    'fieldName': 'paymentDate',
    'width': '9%',
    'columnType': ColumnType.DATE
  },
  {
    'name': 'Amount',
    'fieldName': 'amount',
    'width': '10%',
    'columnType': ColumnType.CURRENCY_TEXT,
  },
  {
    'name': 'Paid To',
    'fieldName': 'payeeName',
    'width': '15%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'payeeLocation'
  },  {
    'name': 'UTR',
    'fieldName': 'utrNumber',
    'width': '12%',
  },  {
    'name': 'Bank',
    'fieldName': 'paymentBankName',
    'width': '10%',
  },  {
    'name': 'Status',
    'fieldName': 'status',
    'width': '10%',
    'columnType': ColumnType.PR_STATUS_LABEL,
    'statusMap': STATUS_MAP
  }];

  export const lcTransactionColumnDefs = [{
    'name': 'Supplier Name',
    'fieldName': 'supplierName',
    'width': '14%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'supplierLocation'
  }, {
    'name': 'Order',
    'fieldName': 'orderNumber',
    'width': '9%',
  }, {
    'name': 'Type',
    'fieldName': 'paymentType',
    'width': '7%',
    'columnType': ColumnType.PAYMENT_TYPE,
    'paymentMap': PAYMENT_TYPES,
  }, {
    'name': 'Payment Date',
    'fieldName': 'paymentDate',
    'width': '11%',
    'columnType': ColumnType.DATE,
  },
  {
    'name': 'Amount',
    'fieldName': 'amount',
    'width': '11%',
    'columnType': ColumnType.CURRENCY_TEXT,
  },
  {
    'name': 'Doc No./Boe No.',
    'fieldName': 'docNumber',
    'width': '9%',
    'subFieldName': 'boeNumber',
    'columnType': ColumnType.WITH_SUB_TEXT,
  },
  {
    'name': 'Paid To',
    'fieldName': 'payeeName',
    'width': '12%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'payeeLocation'
  },  
  {
    'name': 'LC No.',
    'fieldName': 'lcNumber',
    'width': '12%',
    'columnType': ColumnType.WITH_SUBTEXT_NUMBER,
    'subFieldName': 'referenceDate'
  }, {
    'name': 'Status',
    'fieldName': 'status',
    'width': '10%',
    'columnType': ColumnType.PR_STATUS_LABEL,
    'statusMap': STATUS_MAP
  },
  {
    'name': '',
    'width': '4%',
    'fieldName': 'status',
    'columnType': ColumnType.ACTION,
    'actionConfig': lcActionConfig
    }];

  export const financeTransactionColumnDefs = [{
    'name': 'Supplier Name',
    'fieldName': 'supplierName',
    'width': '15%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'supplierLocation'
  }, {
    'name': 'Order',
    'fieldName': 'orderNumber',
    'width': '9%'
  }, {
    'name': 'Type',
    'fieldName': 'paymentType',
    'width': '10%',
    'columnType': ColumnType.PAYMENT_TYPE,
    'paymentMap': PAYMENT_TYPES,
  }, {
    'name': 'Date',
    'fieldName': 'paymentDate',
    'width': '9%',
    'columnType': ColumnType.DATE
  },
  {
    'name': 'Amount',
    'fieldName': 'amount',
    'width': '10%',
    'columnType': ColumnType.CURRENCY_TEXT
  },
  {
    'name': 'Paid To',
    'fieldName': 'payeeName',
    'width': '15%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'payeeLocation'
  },  {
    'name': 'UTR',
    'fieldName': 'utrNumber',
    'width': '12%',
  },  {
    'name': 'Financer',
    'fieldName': 'financerName',
    'width': '10%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'utrNumber'
  },  {
    'name': 'Status',
    'fieldName': 'status',
    'width': '10%',
    'columnType': ColumnType.PR_STATUS_LABEL,
    'statusMap': STATUS_MAP,
    'filter': 'ColumnFilter',
  }];

  export const pdcTransactionColumnDefs = [{
    'name': 'Supplier Name',
    'fieldName': 'supplierName',
    'width': '14%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'supplierLocation'
  }, {
    'name': 'Order',
    'fieldName': 'orderNumber',
    'width': '8%'
  }, {
    'name': 'Type',
    'fieldName': 'paymentType',
    'width': '10%',
    'columnType': ColumnType.PAYMENT_TYPE,
    'paymentMap': PAYMENT_TYPES
  }, {
    'name': 'Date',
    'fieldName': 'paymentDate',
    'width': '9%',
    'columnType': ColumnType.DATE
  },
  {
    'name': 'Amount',
    'fieldName': 'amount',
    'width': '10%',
    'columnType': ColumnType.CURRENCY_TEXT,
  },
  {
    'name': 'Paid To',
    'fieldName': 'payeeName',
    'width': '14%',
    'columnType': ColumnType.WITH_SUB_TEXT,
    'subFieldName': 'payeeLocation'
  },  {
    'name': 'Cheque No.',
    'fieldName': 'paymentChequeNumber',
    'width': '10%',
    'columnType': ColumnType.WITH_SUBTEXT_NUMBER,
    'subFieldName': 'referenceDate',
    'anotherFieldName': 'paymentBankName'
  },  {
    'name': 'Bank',
    'fieldName': 'paymentBankName',
    'width': '7%'
    },
    {
    'name': 'Status',
    'fieldName': 'status',
    'width': '8%',
    'columnType': ColumnType.PR_STATUS_LABEL,
    'statusMap': STATUS_MAP
    },
  {
    'name': '',
    'width': '5%',
    'fieldName': 'status',
    'columnType': ColumnType.ACTION,
    'actionConfig': actionConfig
    }];
  
  export const financierRepaymentColumnDefs = [
    {
      'name': 'Financier',
      'fieldName': 'financerName',
      'width': '8%',
    },
    {
      'name': 'Type of Repayment',
      'fieldName': 'financerRepaymentType',
      'width': '11%',
    },
    {
      'name': 'Date',
      'fieldName': 'paymentDate',
      'width': '8%',
      'columnType': ColumnType.DATE
    },
    {
      'name': 'No. of payments',
      'fieldName': 'numberOfPayments',
      'width': '10%',
      'columnType': ColumnType.WITH_SUB_TEXT_LINK,
      'linkText': 'View Payments',
      'subFieldName': 'financierReceiptId',
      'align': 'left',
      'actionType': 'NAVIGATE_TO_PAYMENTS',
      'fontSize': '12px',
      'fontWeight': '500',
    },
    {
      'name': 'Amount',
      'fieldName': 'amount',
      'width': '10%',
      'columnType': ColumnType.CURRENCY_TEXT,
    },
    {
      'name': 'Account Details',
      'fieldLabel': 'A/C',
      'fieldName': 'accountNo',
      'width': '13%',
      'columnType': ColumnType.WITH_SUB_TEXT_LABEL,
      'subFieldName': 'ifscCode',
      'subFieldLabel': 'IFSC',
    },
    {
      'name': 'Mode of payment',
      'fieldName': 'paymentMode',
      'width': '9%',
      'columnType': ColumnType.WITH_SUB_TEXT,
      'subFieldName': 'paymentBankName'
    },
    {
      'name': 'Reason for Rejection',
      'fieldName': 'rejectReason',
      'width': '12%',
    },
    {
      'name': 'UTR',
      'fieldName': 'utrNumber',
      'width': '12%',
    },
    {
      'name': 'Status',
      'fieldName': 'status',
      'width': '10%',
      'columnType': ColumnType.PR_STATUS_LABEL,
      'statusMap': STATUS_MAP,
      'filter': 'ColumnFilter',
    },
    {
      'name': '',
      'fieldName': 'financierReceiptId',
      'width': '8%',
      'columnType': ColumnType.NAVIGATION_LINK,
      'linkText': 'View Receipt',
      'actionType': 'NAVIGATE_TO_RECEIPT',
      'fontSize': '14px',
      'fontWeight': '500',
    }
  ];

    export const searchTransactionColumnDefs = [
      // {
      //   'name': '',
      //   'checkbox': true,
      //   'width': 2,
      //   'columnType': 'select',
      //   'fieldName': 'status',
      //   'type': 'pending' // Need to change latter once akshay says
      // },
      {
      'name': 'Supplier Name',
      'fieldName': 'supplierName',
      'width': '10%',
      'columnType': ColumnType.WITH_SUB_TEXT,
      'subFieldName': 'supplierLocation'
    }, {
      'name': 'Order',
      'fieldName': 'orderNumber',
      'width': '6%'
    }, {
      'name': 'Type',
      'fieldName': 'paymentType',
      'width': '5%',
      'columnType': ColumnType.PAYMENT_TYPE,
      'paymentMap': PAYMENT_TYPES,
    }, {
      'name': 'Payment Date',
      'fieldName': 'paymentDate',
      'width': '8%',
      'columnType': ColumnType.DATE
    },
    {
      'name': 'Amount',
      'fieldName': 'amount',
      'width': '7%',
      'columnType': ColumnType.CURRENCY_TEXT,
    },
    {
      'name': 'Paid To',
      'fieldName': 'payeeName',
      'width': '10%',
      'columnType': ColumnType.WITH_SUB_TEXT,
      'subFieldName': 'payeeLocation'
      },
      {
        'name': 'Type',
        'fieldName': 'transactionType',
        'columnType': ColumnType.WITH_SUB_TEXT,
        'width': '5%',
        'subFieldName':'utrNumber'
      }, {
      'name': 'Cheque No.',
      'fieldName': 'paymentChequeNumber',
      'width': '10%',
    },  {
      'name': 'Financer',
      'fieldName': 'financerName',
      'width': '6%',
    },  {
      'name': 'LC No.',
      'fieldName': 'lcNumber',
      'width': '10%',
    }, 
    {
      'name': 'Doc No./Boe No',
      'fieldName': 'docNumber',
      'width': '9%',
      'subFieldName': 'boeNumber',
      'columnType': ColumnType.WITH_SUB_TEXT,
    }, {
      'name': 'Status',
      'fieldName': 'status',
      'width': '9%',
      'columnType': ColumnType.PR_STATUS_LABEL,
      'statusMap': STATUS_MAP,
      },
      {
      'name': '',
      'width': '5%',
      'fieldName': 'status',
      'columnType': ColumnType.ACTION,
      'actionConfig': searchActionConfig
    }];

  export const TRANSACTION_TABS = {
    tabType: [{
      name: TAB_LIST.APPROVED,
      title: 'Approved',
      countKey: 'totalApprovedTransactions',
      active: true
    }, {
      name: TAB_LIST.PENDING,
      title: 'Pending',
      countKey: 'totalPendingTransactions'
    }, {
      name: TAB_LIST.REJECTED,
      title: 'Rejected',
      countKey: 'totalRejectedTransactions'
    }],
    transactionType: [{
      name: 'BANK',
      title: 'Bank Transaction',
      countKey: 'totalBankTransactions',
      active: true
    },
    {
      name: 'LC',
      title: 'LC Transaction',
      countKey: 'totalLCTransactions',
      active: true
    },
    {
      name: 'FINANCER',
      title: 'Financer Transaction',
      countKey: 'totalFinancierTransactions',
      active: true
    },
    {
      name: 'PDC',
      title: 'PDC Transaction',
      countKey: 'totalPDCTransctions'
    },
    {
      name: 'FINANCER_REPAYMENT',
      title: 'Financier Repayment',
      countKey: 'totalFinancierRepayments'
    }
  ]
  };

  export enum FUND_TRANSFER_TYPE {
    NEFT = 'NEFT',
    IMPS = 'IMPS',
    RTGS = 'RTGS'
  }


  export const PAYMENT_OTP_RESEND_TIME = 60;