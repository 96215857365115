import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { STATUS_MAP } from './status'
@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss']
})
export class StatusCellComponent implements ICellCmp {
  params;
  columnDef;
  element;
  statusMap = STATUS_MAP;
  constructor() { }

  init(params: any) {
  }

}
