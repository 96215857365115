<form class="supplier-feedback-container" #supplierFeedbackForm="ngForm" id="supplierD"
  (ngSubmit)="submit(supplierFeedbackForm)">
  <div class="logo">
    <img src="/assets/zetwerk-logo.svg" alt="">
  </div>
  <div class="dispatch_feedback">
    Feedback for {{feedbackDetails?.orderDetails?.supplierInformation?.basicInformation?.name}}
  </div>
  <div class="dispatch_feedback_description">Please give your feedback for the dispatch done on
    {{feedbackDetails?.billDetails?.billDate | date: 'MMM d, y'}}
    against {{feedbackDetails?.billDetails?.billNumber}}
    from {{feedbackDetails?.orderDetails?.supplierInformation?.basicInformation?.name}} for
    {{feedbackDetails?.orderDetails?.contractDetails?.contractNumber}} of
    {{feedbackDetails?.orderDetails?.contractDetails?.customerDetails?.name}}. Your feedback is
    valuable to us and will help us serve your better.</div>

  <fieldset ngModelGroup="feedbackForm">
    <div class="dispatch" #qualityDispatch id="qualityDispatch"
      [ngClass]="{'opac': showId['qualityDispatch']}">
      <div class="question-title row">How was the quality of work for this dispatch?</div>
      <div class="content emoji-container">
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityOfWork=== 'Very dissatisfied'}"
            (click)="select('qualityOfWork', 'Very dissatisfied')">
            <img src="/assets/emojis/Very-Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityOfWork=== 'Very dissatisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Very Dissatisfied</div>
          </div>
        </div>
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityOfWork=== 'Dissatisfied'}"
            (click)="select('qualityOfWork', 'Dissatisfied')">
            <img src="/assets/emojis/Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityOfWork=== 'Dissatisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Dissatisfied</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityOfWork=== 'Neither'}"
            (click)="select('qualityOfWork', 'Neither')">
            <img src="/assets/emojis/Neither.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityOfWork=== 'Neither'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Neither</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityOfWork=== 'Satisfied'}"
            (click)="select('qualityOfWork', 'Satisfied')">
            <img src="/assets/emojis/Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityOfWork=== 'Satisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Satisfied</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.qualityOfWork=== 'Very satisfied'}"
            (click)="select('qualityOfWork', 'Very satisfied')">
            <img src="/assets/emojis/Very-Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.qualityOfWork=== 'Very satisfied'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Very Satisfied</div>
          </div>
        </div>
      <div class="error" *ngIf="showError">This field is required.</div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" [disabled]="feedbackForm.qualityOfWork === ''"
          (click)="scrollTo($event, 'btnClick')" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['qualityWork']}" #qualityWork id="qualityWork"
      ngModelGroup="reasonForPoorQuality" *ngIf="checkIsDissatisfied()" (click)="scrollTo($event,
      'isDivClick')">
      <div class="row">What is the reason for Poor quality?</div>
      <div class="content">
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.reasonForPoorQuality.isPoorWorkManship}">
          <div class="material-checkbox">
            <input type="checkbox" name="isPoorWorkManship" id="pmship"
              [(ngModel)]="feedbackForm.reasonForPoorQuality.isPoorWorkManship"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForPoorQuality')">
            <label for="pmship">Poor workmanship</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.reasonForPoorQuality.technicallyNotCapable}">
          <div class="material-checkbox">
            <input type="checkbox" name="technicallyNotCapable"
              [(ngModel)]="feedbackForm.reasonForPoorQuality.technicallyNotCapable"
            id="technicallyNotCapable" [required]="checkIsRequired(supplierFeedbackForm, 'reasonForPoorQuality')">
            <label for="technicallyNotCapable">Technically not capable</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.reasonForPoorQuality.deliveryTimePressue}">
          <div class="material-checkbox">
            <input type="checkbox" name="deliveryTimePressure" id="deliveryTimePressure"
              [(ngModel)]="feedbackForm.reasonForPoorQuality.deliveryTimePressue"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForPoorQuality')">
            <label for="deliveryTimePressure">Delivery
              time pressure (Tight Timelines)</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.reasonForPoorQuality.requiredMachinesAvailable}">
          <div class="material-checkbox">
            <input type="checkbox" name="requiredMachinesAvailable" id="requiredMachinesAvailable"
              [(ngModel)]="feedbackForm.reasonForPoorQuality.requiredMachinesAvailable"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForPoorQuality')">
            <label for="requiredMachinesAvailable">Required Machines unavailable</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="checkIsDisabled('reasonForPoorQuality')" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['dispatchTime']}" #dispatchTime id="dispatchTime"
      (click)="scrollTo($event, 'isDivClick')">
      <div class="row">Was the dispatch done on time?</div>
      <div class="content">
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.dispatchDoneOnTime}">
          <div class="material-radio">
            <input type="radio" name="dispatchDoneOnTime" [value]="true" [(ngModel)]="feedbackForm.dispatchDoneOnTime"
              id="dispatchDone" (change)="checkIsDispatchDone()" required>
            <label for="dispatchDone">Yes</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.dispatchDoneOnTime == false}">
          <div class="material-radio">
            <input type="radio" name="dispatchDoneOnTime" [value]="false" id="no"
              [(ngModel)]="feedbackForm.dispatchDoneOnTime" (change)="checkIsDispatchDone()"
              required>
            <label for="no">No</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="feedbackForm.dispatchDoneOnTime === undefined" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['reasonOfDelay']}" #reasonOfDelay id="reasonOfDelay"
      ngModelGroup="reasonForDispatchDelay" *ngIf="feedbackForm.dispatchDoneOnTime == false "
      (click)="scrollTo($event,
      'isDivClick')">
      <div class="row">What was the reason for delay in dispatch?</div>
      <div class="content">
        <div class=" row">
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.isPaymentIssueWithLabour}">
            <div class="material-checkbox">
              <input type="checkbox" name="isPaymentIssueWithLabour" id="pl"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.isPaymentIssueWithLabour"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="pl">Payment issues with labour</label>
            </div>
          </div>
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.isPaymentIssueWithZetwerk}">
            <div class="material-checkbox">
              <input type="checkbox" name="isPaymentIssueWithZetwerk" id="pz"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.isPaymentIssueWithZetwerk"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="pz" appCompanyDisplayName>Payment issues with Zetwerk</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.truckAvailability}">
            <div class="material-checkbox">
              <input type="checkbox" name="truckAvailability" id="ta"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.truckAvailability"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="ta">Truck
                availability</label>
            </div>
          </div>
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.labourAvailability}">
            <div class="material-checkbox">
              <input type="checkbox" name="labourAvailability" id="la"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.labourAvailability"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="la">Labour availability</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 sub-item" [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.planningError}">
            <div class="material-checkbox">
              <input type="checkbox" name="planningError" id="pe"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.planningError"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="pe">Planning error</label>
            </div>
          </div>
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.delayInReceiveingDrawings}">
            <div class="material-checkbox">
              <input type="checkbox" name="delayInReceiveingDrawings" id="drd"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.delayInReceiveingDrawings"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="drd">Delay in receiving drawings</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.delayInDrawingClarification}">
            <div class="material-checkbox">
              <input type="checkbox" name="delayInDrawingClarification" id="drc"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.delayInDrawingClarification"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="drc">Delay in drawing clarification</label>
            </div>
          </div>
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.delayInApprovalCustomer}">
            <div class="material-checkbox">
              <input type="checkbox" name="delayInApprovalCustomer"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.delayInApprovalCustomer" id="dps"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="dps">Delay in approvals from customer</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6 sub-item"
            [ngClass]="{'item-selected': feedbackForm.reasonForDispatchDelay.delayInRawMaterialProcurement}">
            <div class="material-checkbox">
              <input type="checkbox" name="delayInRawMaterialProcurement"
                [(ngModel)]="feedbackForm.reasonForDispatchDelay.delayInRawMaterialProcurement" id="drp"
                [required]="checkIsRequired(supplierFeedbackForm, 'reasonForDispatchDelay')">
              <label for="drp">Delay in raw material procurement</label>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="checkIsDisabled('reasonForDispatchDelay')" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['paymentIssue']}" #paymentIssue id="paymentIssue"
       *ngIf="feedbackForm.reasonForDispatchDelay.isPaymentIssueWithLabour"
      (click)="scrollTo($event, 'isDivClick')">
      <div class="row">How frequent would you have issues with the payment to labour?</div>
      <div class="content">
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfPaymentIssueWithLabour === 'Very rare'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfPaymentIssueWithLabour" value="Very rare" id="pvr"
              [(ngModel)]="feedbackForm.frequencyOfPaymentIssueWithLabour" required>
            <label for="pvr">Very
              Rare</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfPaymentIssueWithLabour === 'Once a week'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfPaymentIssueWithLabour" value="Once a week" id="pow"
              [(ngModel)]="feedbackForm.frequencyOfPaymentIssueWithLabour" required>
            <label for="pow">Once a
              week</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfPaymentIssueWithLabour === 'Few times a month'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfPaymentIssueWithLabour" value="Few times a month" id="pfm"
              [(ngModel)]="feedbackForm.frequencyOfPaymentIssueWithLabour" required>
            <label for="pfm">Few times a month</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfPaymentIssueWithLabour === 'Almost daily'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfPaymentIssueWithLabour" value="Almost daily" id="pad"
              [(ngModel)]="feedbackForm.frequencyOfPaymentIssueWithLabour" required>
            <label for="pad">Almost daily</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="!feedbackForm.frequencyOfPaymentIssueWithLabour" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['labour']}" #labour id="labour"
      *ngIf="feedbackForm.reasonForDispatchDelay.labourAvailability" (click)="scrollTo($event, 'isDivClick')">
      <div class="row">How frequent would you have issues with the labour availability with this supplier?</div>
      <div class="content">
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfLabourUnavailability === 'Very Rare'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfLabourUnavailability" value="Very rare"
              [(ngModel)]="feedbackForm.frequencyOfLabourUnavailability" id="lvr" required>
            <label for="lvr">Very
              rare</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfLabourUnavailability === 'Once a week'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfLabourUnavailability" value="Once a week"
              [(ngModel)]="feedbackForm.frequencyOfLabourUnavailability" id="low" required>
            <label for="low">Once a week</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfLabourUnavailability === 'Few times a month'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfLabourUnavailability" value="Few times a month"
              [(ngModel)]="feedbackForm.frequencyOfLabourUnavailability" id="lfm" required>
            <label for="lfm">Few times a month</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.frequencyOfLabourUnavailability === 'Almost daily'}">
          <div class="material-radio">
            <input type="radio" name="frequencyOfLabourUnavailability" value="Almost daily"
              [(ngModel)]="feedbackForm.frequencyOfLabourUnavailability" id="lad" required>
            <label for="lad">Almost daily</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="!feedbackForm.frequencyOfLabourUnavailability" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['planning']}" #planning id="planning"
      *ngIf="feedbackForm.reasonForDispatchDelay.planningError" (click)="scrollTo($event, 'isDivClick')">
      <div class="row">How elaborate is the planning done for the project by the supplier</div>
      <div class="content">
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.planningDoneForProject === 'Detailed Plan with all dispatches
          planned'}">
          <div class="material-radio">
            <input type="radio" name="planningDoneForProject" value="Detailed Plan with all dispatches planned" id="pr"
              [(ngModel)]="feedbackForm.planningDoneForProject" required>
            <label for="pr">Detailed
              Plan with all
              dispatches planned</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.planningDoneForProject === 'Only next action is planned'}">
          <div class="material-radio">
            <input type="radio" name="planningDoneForProject" value="Only next action is planned" id="onap"
              [(ngModel)]="feedbackForm.planningDoneForProject" required>
            <label for="onap">Only
              next action is planned</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.planningDoneForProject === 'No Plan'}">
          <div class="material-radio">
            <input type="radio" name="planningDoneForProject" value="No Plan" id="np"
              [(ngModel)]="feedbackForm.planningDoneForProject" required>
            <label for="np">No Plan</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="!feedbackForm.planningDoneForProject" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['procureMent']}" #procureMent id="procureMent"
      ngModelGroup="reasonForProcurementDelay" *ngIf="feedbackForm.reasonForDispatchDelay.delayInRawMaterialProcurement"
      (click)="scrollTo($event, 'isDivClick')">
      <div class="row">What was the reason for delay in procurement of raw material?</div>
      <div class="content">
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.reasonForProcurementDelay.isFinanceIssue}">
          <div class="material-checkbox">
            <input type="checkbox" name="isFinanceIssue" id="fi"
              [(ngModel)]="feedbackForm.reasonForProcurementDelay.isFinanceIssue"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForProcurementDelay')">
            <label for="fi">Finance issues</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.reasonForProcurementDelay.isRawMaterialAvailable}">
          <div class="material-checkbox">
            <input type="checkbox" name="isRawMaterialAvailable" id="rma"
              [(ngModel)]="feedbackForm.reasonForProcurementDelay.isRawMaterialAvailable"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForProcurementDelay')">
            <label for="rma">Raw material availability</label>
          </div>
        </div>
        <div class="item row" [ngClass]="{'item-selected': feedbackForm.reasonForProcurementDelay.rawMaterialSupplierIssue}">
          <div class="material-checkbox">
            <input type="checkbox" name="rawMaterialSupplierIssue" id="rmsi"
              [(ngModel)]="feedbackForm.reasonForProcurementDelay.rawMaterialSupplierIssue"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForProcurementDelay')">
            <label for="rmsi">Raw material supplier issue</label>
          </div>
        </div>
        <div class="item row">
          <div class="material-checkbox">
            <input type="checkbox" name="dispatchIssue" id="di"
              [(ngModel)]="feedbackForm.reasonForProcurementDelay.dispatchIssue"
              [required]="checkIsRequired(supplierFeedbackForm, 'reasonForProcurementDelay')">
            <label for="di">Dispatch Issue</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="checkIsDisabled('reasonForProcurementDelay')" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" [ngClass]="{'opac': showId['response']}" #response id="response"
      (click)="scrollTo($event, 'isDivClick')">
      <div class="row">How responsive is the supplier to complaints and corrections requested?</div>
      <div class="content">
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.supplierResponsivenessToComplaints === 'Immediately responds in any mode of
        communication'}">
        <div class="material-radio">
          <input type="radio" name="supplierResponsivenessToComplaints"
            value="Immediately responds in any mode of communication (Email or call)" id="irmac"
            [(ngModel)]="feedbackForm.supplierResponsivenessToComplaints" required>
          <label for="irmac">Immediately responds in any
            mode of communication (Email or call)</label>
        </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.supplierResponsivenessToComplaints === 'Only responds to call'}">
          <div class="material-radio">
            <input type="radio" name="supplierResponsivenessToComplaints" value="Only responds to call" id="orc"
              [(ngModel)]="feedbackForm.supplierResponsivenessToComplaints" required>
            <label for="orc">Only responds to call</label>
          </div>
        </div>
        <div class="item row"
          [ngClass]="{'item-selected': feedbackForm.supplierResponsivenessToComplaints === 'Needs regular followup'}">
          <div class="material-radio">
            <input type="radio" name="supplierResponsivenessToComplaints" value="Needs regular followup" id="nrf"
              [(ngModel)]="feedbackForm.supplierResponsivenessToComplaints" required>
            <label for="nrf">Needs regular followup</label>
          </div>
        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event, 'btnClick')"
          [disabled]="!feedbackForm.supplierResponsivenessToComplaints" type="button">Ok</button>
      </div>
    </div>
    <div class="dispatch" #nps [ngClass]="{'opac': showId['nps']}" id="nps">
      <div class="question-title row">Would you want to work with the supplier again?</div>
      <div class="content emoji-container">
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.wouldWorkWithSupplierInFuture=== 'Definitely not'}"
            (click)="select('wouldWorkWithSupplierInFuture', 'Definitely not')">
            <img src="/assets/emojis/Very-Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.wouldWorkWithSupplierInFuture=== 'Definitely not'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Definitely Not</div>
          </div>
        </div>
        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.wouldWorkWithSupplierInFuture=== 'Probably not'}"
            (click)="select('wouldWorkWithSupplierInFuture', 'Probably not')">
            <img src="/assets/emojis/Dissatisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.wouldWorkWithSupplierInFuture=== 'Probably not'"
              class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Probably Not</div>
          </div>
        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.wouldWorkWithSupplierInFuture=== 'Possibly'}"
            (click)="select('wouldWorkWithSupplierInFuture', 'Possibly')">
            <img src="/assets/emojis/Neither.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.wouldWorkWithSupplierInFuture=== 'Possibly'" class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Possibly</div>
          </div>

        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.wouldWorkWithSupplierInFuture === 'Very Probably'}"
            (click)="select('wouldWorkWithSupplierInFuture', 'Very Probably')">
            <img src="/assets/emojis/Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.wouldWorkWithSupplierInFuture=== 'Very Probably'"
              class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Very probably</div>
          </div>

        </div>

        <div class="rating-container">
          <div class="emoji" [ngClass]="{'selected': feedbackForm.wouldWorkWithSupplierInFuture=== 'Definitely'}"
            (click)="select('wouldWorkWithSupplierInFuture', 'Definitely')">
            <img src="/assets/emojis/Very-Satisfied.svg" height="40" width="40" />
            <i *ngIf="feedbackForm.wouldWorkWithSupplierInFuture=== 'Definitely'"
              class="fa fa-check-square selectedIcon"
              aria-hidden="true"></i>
            <div class="text">Definitely</div>
          </div>

        </div>
      </div>
      <div class="btn-container">
        <button class="btn oms-btn-primary md" (click)="scrollTo($event)"
          [disabled]="!feedbackForm.wouldWorkWithSupplierInFuture" type="button">Ok</button>
      </div>
    </div>
  </fieldset>
  <div class="submit-container dispatch" id="submit">
    <div class="btn-container">
      <button class="btn oms-btn-primary bg"
        [disabled]="!supplierFeedbackForm.valid || (!feedbackForm['qualityOfWork'] ||
        (!feedbackForm['wouldWorkWithSupplierInFuture']))">Submit</button>
    </div>
  </div>
</form>
