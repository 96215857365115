import { Component, OnInit } from '@angular/core';
import { ICellCmp } from '@zetwerk/zet-list';
import { formatToInr } from 'src/app/utils';

@Component({
  selector: 'app-subtext-currency-cell',
  templateUrl: './subtext-currency-cell.component.html',
  styleUrls: ['./subtext-currency-cell.component.scss']
})
export class SubtextCurrencyCellComponent implements ICellCmp {

  params;
  columnDef;
  element;

  constructor() { }

  init(params: any) {
  }

  transform(value: number): string {
    if (!value) {
      return;
    }
    return formatToInr(value);

  }

}
