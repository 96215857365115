import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import { RedirectToPageConst } from 'src/app/constants/validatorRegex';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit {
  @Input() route: any;
  constructor(
    public bsModalRef: BsModalRef, 
    private router: Router,
    private modalService: BsModalService
    ) { }

  list: any[] = [];
  ngOnInit() {
  }

  onClickOk() {
    this.bsModalRef.hide();
    const customerNotePreviewFromFinance = new RegExp(RedirectToPageConst.CUSTOMER_NOTE_FROM_FINANCE);
    const customerNotePreviewFromInvoice = new RegExp(RedirectToPageConst.CUSTOMER_NOTE_FROM_INVOICE);
    const taxInvoicePreviewFromInvoice = new RegExp(RedirectToPageConst.TAX_INVOICE_FROM_INVOICE);
    const taxInvoicePreviewFromContract = new RegExp(RedirectToPageConst.TAX_INVOICE_FROM_CONTRACT);
    const customerNotePreviewFromFinanceWithIsEdit = new RegExp(RedirectToPageConst.CUSTOMER_NOTEFROM_FINANCE_WITH_EDIT);
    const customerNotePreviewFromInvoiceWithIsEdit = new RegExp(RedirectToPageConst.CUSTOMER_NOTEFROM_INVOICE_WITH_EDIT);
    const custmerNotePreviewFromContractDetails = new RegExp(RedirectToPageConst.CUSTOMER_NOTE_FROM_CONTRACT);
    const customerReversalNote = new RegExp(RedirectToPageConst.CUSTOMER_REVERSAL_NOTE);
    const customerReversalNoteWithEdit = new RegExp(RedirectToPageConst.CUSTOMER_REVERSAL_NOTE_WITH_EDIT);

    if (this.route && this.route.url && (
      customerNotePreviewFromFinance.test(this.route.url) || 
      customerNotePreviewFromInvoice.test(this.route.url) || 
      customerNotePreviewFromFinanceWithIsEdit.test(this.route.url) || 
      customerNotePreviewFromInvoiceWithIsEdit.test(this.route.url) || 
      custmerNotePreviewFromContractDetails.test(this.route.url) ||
      customerReversalNote.test(this.route.url) ||
      customerReversalNoteWithEdit.test(this.route.url))) {
      let { noteType, origin, isEdit } = this.route.currentUrlTree.queryParams;
      let urlSplit = this.route.url.split('/');
      if (urlSplit[4] === 'creditNote') {
        noteType = noteType ? noteType : 'CREDIT_NOTE'
      } else if (urlSplit[4] === 'debitNote') {
        noteType = noteType ? noteType : 'DEBIT_NOTE'
      }

      if (origin === 'creditNote' || origin === 'debitNote') {
        let contractId = urlSplit[5];
        let reversedNoteId = urlSplit[7];
        let noteId = urlSplit[9];
        this.navigateToEditReversalNote({contractId, reversedNoteId, noteId, noteType, origin, isEdit});
      } else {
        let contractId = urlSplit[5];
        let invoiceId = urlSplit[7];
        let noteId = urlSplit[9];
        this.navigateToEditNote({contractId, invoiceId, noteId, noteType, origin, isEdit});
      }
      
    } else if (this.route && this.route.url && (taxInvoicePreviewFromInvoice.test(this.route.url) || taxInvoicePreviewFromContract.test(this.route.url))) {
      let urlSplit = this.route.url.split('/');
      this.navigateToEditInvoice({contractId: urlSplit[4], invoiceId: urlSplit[6]});
    }
  }

  navigateToEditInvoice({contractId, invoiceId}) {
    this.router.navigate(['contract', contractId, 'invoice', invoiceId], {});
  }

  navigateToEditReversalNote({contractId, reversedNoteId, noteId, noteType, origin, isEdit}) {
    if (origin === 'creditNote') {
      this.router.navigate(['contract', contractId, 'reversedCreditNote', reversedNoteId, 'note', noteId],
          { queryParams: { noteType, origin, isEdit } });
        return;
    } else {
      this.router.navigate(['contract', contractId, 'reversedDebitNote', reversedNoteId, 'note', noteId],
          { queryParams: { noteType, origin, isEdit } });
        return;
    }
  }

  navigateToEditNote({contractId, invoiceId, noteId, noteType, origin, isEdit}) {
    if (origin !== 'finance') {
      this.router.navigate(
        ['contract', contractId, 'invoice', invoiceId, 'note', noteId ],
        { queryParams: { noteType, origin, isEdit } }
      );
    } else {
      this.router.navigate(
        ['finance', noteType === 'CREDIT_NOTE' ? 'creditNote' : 'debitNote', 'contract', contractId, 'invoice', invoiceId, 'note', noteId],
        { queryParams: { noteType, origin, isEdit} }
      );
    }
  }

}
