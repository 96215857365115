import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import { FilterGridComponent } from './filter-grid.component';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule
  ],
  declarations: [FilterGridComponent],
  exports: [NgSelectModule, FilterGridComponent]
})
export class FilterGridModule { }
