export const FieldRegExConstant = {
  GREATER_THAN_ZERO: '^s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$',
  ALLOW_ZERO: '^s*(?=.*[0-9])\\d*(?:\\.\\d{1,2})?\\s*$',
  ALLOW_TWO_DIGIT_WITH_TWO_DECIMAL_PLACES: '^([0-9]|[1-9][0-9])(\\.[0-9][0-9]?)?$',
  PAYEE_NAME_NOT_ALLOWED_CHARACTERS: '^[^\&]+$',
  EQUAL_TO_HUNDRED: '^100$',
  GSTIN_NO: '\\d{2}[A-Za-z]{5}\\d{4}[A-Za-z]{1}[A-Za-z\\d]{1}[ZSDCEBzsdcebA-Ja-j\\d]{1}[A-Za-z\\d]{1}',
  ONLY_INTEGER: '\\d{1,}$',
  ALLOW_NUMBER_WITH_TWO_DECIMAL_PLACES: '^-?s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$',
  ALLOW_NUMBER_WITH_TWO_DECIMAL_PLACES_INCLUDING_ZERO: '^-?s*(?=.*[0-9])\\d*(?:\\.\\d{1,2})?\\s*$',
  ALLOW_NUMBER_WITH_THREE_DECIMAL_PLACES: '^-?s*(?=.*[1-9])\\d*(?:\\.\\d{1,3})?\\s*$',
  ALLOW_NUMBER_WITH_THREE_DECIMAL_PLACES_WITH_POSTIVE_NUMBERS: '^s*(?=.*[1-9])\\d*(?:\\.\\d{1,3})?\\s*$',
  ALLOW_NUMBER_WITH_THREE_DECIMAL_PLACES_INCLUDING_ZERO: '^-?s*(?=.*[0-9])\\d*(?:\\.\\d{1,3})?\\s*$',
  NON_ZERO: '^s*(?=.*[1-9])\\d*(?:\\.\\d{1,5})?\\s*$',
  HSN_CODE: '^[0-9]{4,8}$',
  INTEGER_WITH_ZERO: '\\d{0,}$',
  NON_ZERO_WITH_NO_DECIMALS: '^[1-9][0-9]*$',
  ALLOW_NUMBER_WITH_TWO_DECIMAL_PLACES_WITH_POSTIVE_NUMBERS: '^s*(?=.*[1-9])\\d*(?:\\.\\d{1,2})?\\s*$',
  ALLOW_NUMBER_WITH_TWO_DECIMAL_PLACES_INCLUDING_ZERO_WITH_POSTIVE_NUMBERS: '^s*(?=.*[0-9])\\d*(?:\\.\\d{1,2})?\\s*$',
  ALLOW_INPUT_WITH_DASH_SLASH_AND_ALPHANUMERIC: '^[a-zA-Z1-9][a-zA-Z0-9-/]{0,}$',
  ALLOW_NUMBER_WITH_THREE_DECIMAL_PLACES_INCLUDING_ZERO_WITH_POSTIVE_NUMBERS: '^s*(?=.*[0-9])\\d*(?:\\.\\d{1,3})?\\s*$',
  ALLOW_ALL_CHARACTERS_WITH_MAX_FIFTY_LENGTH: '^.{1,50}$'
};

export const RedirectToPageConst = {
  TAX_INVOICE_FROM_INVOICE: "^\\/company\\/zetwerk\\/finance\\/[a-zA-Z0-9]{24}\\/invoice\\/[a-zA-Z0-9]{24}\\/preview\\?origin\\=finance$",
  TAX_INVOICE_FROM_CONTRACT: "^\\/company\\/zetwerk\\/contract\\/[a-zA-Z0-9]{24}\\/invoice\\/[a-zA-Z0-9]{24}\\/preview$",
  CUSTOMER_NOTE_FROM_FINANCE: "^\\/company\\/zetwerk\\/finance\\/(debitNote|creditNote)\\/[a-zA-Z0-9]{24}\\/invoice\\/([a-zA-Z0-9]{24}|undefined)\\/note\\/[a-zA-Z0-9]{24}\\/preview(\\?origin\\=finance\\&noteType\\=(DEBIT_NOTE|CREDIT_NOTE))?$",
  CUSTOMER_NOTE_FROM_INVOICE: "^\\/company\\/zetwerk\/contract\\/(creditNote|debitNote)\\/[a-zA-Z0-9]{24}\\/invoice\\/[a-zA-Z0-9]{24}\\/note\\/[a-zA-Z0-9]{24}\\/preview$",
  CUSTOMER_NOTEFROM_FINANCE_WITH_EDIT: "^\\/company\\/zetwerk\\/finance\\/(debitNote|creditNote)\\/[a-zA-Z0-9]{24}\\/invoice\\/([a-zA-Z0-9]{24}|undefined)\\/note\\/[a-zA-Z0-9]{24}\\/preview(\\?origin\\=finance\\&noteType\\=(DEBIT_NOTE|CREDIT_NOTE))\\&isEdit\\=(true|false)?$",
  CUSTOMER_NOTEFROM_INVOICE_WITH_EDIT: "^\\/company\\/zetwerk\/contract\\/(creditNote|debitNote)\\/[a-zA-Z0-9]{24}\\/invoice\\/[a-zA-Z0-9]{24}\\/note\\/[a-zA-Z0-9]{24}\\/preview\\?isEdit\\=(true|false)$",
  CUSTOMER_NOTE_FROM_CONTRACT: "^\\/company\\/zetwerk\\/contract\\/(debitNote|creditNote)\\/[a-zA-Z0-9]{24}\\/invoice\\/([a-zA-Z0-9]{24}|undefined)\\/note\\/([a-zA-Z0-9]{24}|undefined)\\/preview(\\?origin\\=contract\\&noteType\\=(DEBIT_NOTE|CREDIT_NOTE)(\\&isEdit\=(true|false))?)?$",
  CUSTOMER_REVERSAL_NOTE_WITH_EDIT: "^\\/company\\/zetwerk\/contract\\/(creditNote|debitNote)\\/[a-zA-Z0-9]{24}\\/(reversedCreditNote|reversedDebitNote)\\/[a-zA-Z0-9]{24}\\/note\\/[a-zA-Z0-9]{24}\\/preview\\?isEdit\\=(true|false)$",
  CUSTOMER_REVERSAL_NOTE: "^\\/company\\/zetwerk\/contract\\/(creditNote|debitNote)\\/[a-zA-Z0-9]{24}\\/(reversedCreditNote|reversedDebitNote)\\/[a-zA-Z0-9]{24}\\/note\\/[a-zA-Z0-9]{24}\\/preview(\\?origin\\=(creditNote|debitNote)\\&noteType\\=(DEBIT_NOTE|CREDIT_NOTE)(\\&isEdit\=(true|false))?)?$",
};
export const VALIDATION_REGEX = {
  GST: /\d{2}[A-Z]{5}\d{4}[A-Z]{1}[A-Z\d]{1}[Z]{1}[A-Z\d]{1}/,
  YEAR: /^(18|19|20)\d{2}$/, // 1800-2099
  EMAIL: /^([\w-\.\+W]+@([\w-]+\.)+[\w-]{2,11})?$/,
  CONTACT_NUMBER: /^(\+\d{1,3}[- ]?)?\d{10}$/,
  NAME: /^[a-zA-Z ]{2,60}$/, // 2-60 words
  PAN: /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/,
  TAN: /^([a-zA-Z]){4}([0-9]){5}([a-zA-Z]){1}?$/,
  ACCOUNT_NO: /^[0-9]{1,20}$/,
  UAN: /^[0-9]{12}$/,
  IFSC: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  SWIFT: /^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/,
  PAYEENAME: /^[a-zA-Z0-9 ]{2,60}$/,
  AADHAAR :  /^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$/
};

