import { Component } from "@angular/core";
import { ICellCmp } from "@zetwerk/zet-list";
import { STATUS_MAPPER } from "src/app/constants/bank-transaction";

@Component({
  selector: "app-transaction-number-cell",
  templateUrl: "./transaction-number-cell.component.html",
  styleUrls: ["./transaction-number-cell.component.scss"],
})
export class TransactionNumberCellComponent implements ICellCmp {
  params: any;
  columnDef: any;
  element: any;

  status: any = {};

  constructor() {}

  /**
   * Inititalizes status and showStatus
   * @param params
   */
  init(params: any) {
    const { status } = params?.value || {};
    this.status = STATUS_MAPPER.find(
      (mapper: any) => mapper?.status === status
    );
  }
}
