import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import {
  OmsService
} from '../../services/oms.service';
import { FormControl } from '@angular/forms';
import {
  matchPattern
} from 'url-matcher';
import { GSTservice } from '../../services/gst.service';
import { GST_MENU_ALL } from 'src/app/constants/gst';
import { UsersService } from 'src/app/services/users.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmationPopupComponent } from '../general/confirmation-popup/confirmation-popup.component';
import { RouteReloadService } from 'src/app/services/route-reload.service';
import { TenantService } from 'src/app/services/tenant.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { FeatureFlagService } from 'src/app/services/feature-flag.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  public userName: String = '';
  public userImage: String = '';
  checkin = 0;
  gstConfigList: any = [];
  gstShow = false;
  public gstType = new FormControl();
  public companyType = new FormControl();
  gstList = [];
  companyDetails = [];
  slug: any;
  pageExitModalRef: BsModalRef;
  isChannelPartner = false;

  constructor(
    private router: Router,
    private _omsService: OmsService,
    private _gstService: GSTservice,
    private userService: UsersService,
    private activatedRoute: ActivatedRoute,
    private modalService: BsModalService,
    private _reload: RouteReloadService,
    private tenantService: TenantService,
    private _ps: PermissionsService,
    private featureFlagService: FeatureFlagService,
  ) {
    this.routeEvent(this.router);
  }

  routeEvent(router: Router) {
    router.events.subscribe(e => {
      if (e instanceof NavigationEnd) {
        const urlMatch = e.urlAfterRedirects;
        if (urlMatch &&
          (
            matchPattern('gst/:type', urlMatch) ||
            matchPattern('finance/letterofCredit/:idortype', urlMatch) ||
            matchPattern('finance/transaction/*', urlMatch) ||
            matchPattern('finance/bankReceipt/:idortype', urlMatch) ||
            matchPattern('finance/:type/contract/:cid/invoice/:invid/note', urlMatch) ||
            matchPattern('finance/:type/note/:id/note-details', urlMatch) ||
            matchPattern('finance/:type/contract/:cid/invoice', urlMatch) ||
            matchPattern('finance/:type/invoice/:id/invoice-summary', urlMatch) ||
            matchPattern('users/view', urlMatch) ||
            matchPattern('metrics/view', urlMatch) ||
            matchPattern('suppliers/:supplierId', urlMatch) ||
            matchPattern('loan/:loanId/transaction', urlMatch) ||
            matchPattern('loan/:loanId/payment', urlMatch) ||
            matchPattern('loan/:loanId/ledger', urlMatch) ||
            matchPattern('loan/:loanId/agreement', urlMatch) ||
            matchPattern('loan/:loanId/details', urlMatch) ||
            matchPattern('payment/:paymentId/finance', urlMatch) ||
            matchPattern('payment/:paymentId/payments', urlMatch) ||
            matchPattern('payment/:paymentId/payment-tickets', urlMatch) ||
            matchPattern('payment/:paymentId/documents', urlMatch) ||
            matchPattern('payment/:paymentId/details', urlMatch) ||
            matchPattern('order/creditNote/:creditNoteId/details', urlMatch) ||
            matchPattern('order/creditNote/:creditNoteId/timeline', urlMatch) ||
            matchPattern('order/creditNote/:creditNoteId/payments', urlMatch) ||
            matchPattern('order/creditNote/:creditNoteId/transaction', urlMatch) ||
            matchPattern('order/debitNote/:debitNoteId/details', urlMatch) ||
            matchPattern('order/:orderId/note', urlMatch) ||
            matchPattern('bills/:billId/finance', urlMatch) ||
            matchPattern('bills/:billId/payments', urlMatch) ||
            matchPattern('bills/:billId/payment-tickets', urlMatch) ||
            matchPattern('bills/:billId/documents', urlMatch) ||
            matchPattern('bills/:billId/timeline', urlMatch) ||
            matchPattern('bills/:billId/details', urlMatch) ||
            matchPattern('order/:orderId/note', urlMatch) ||
            matchPattern('order/:orderId/finance', urlMatch) ||
            matchPattern('order/:orderId/ledger', urlMatch) ||
            matchPattern('order/:orderId/bills', urlMatch) ||
            matchPattern('order/:orderId/order-details', urlMatch) ||
            matchPattern('customers/:customer', urlMatch) ||
            matchPattern('contract/:contractId/revise', urlMatch) ||
            matchPattern('contract/:contractId/SPOC', urlMatch) ||
            matchPattern('contract/:contractId/finance', urlMatch) ||
            matchPattern('contract/:contractId/order', urlMatch) ||
            matchPattern('contract/:contractId/invoices', urlMatch) ||
            matchPattern('contract/:contractId/terms', urlMatch) ||
            matchPattern('contract/:contractId/items', urlMatch) ||
            matchPattern('contract/:contractId/details', urlMatch) ||
            matchPattern('order/production-order/:orderId/order-details', urlMatch) ||
            matchPattern('order/production-order/:orderId/invoice-request', urlMatch) ||
            matchPattern('finance/invoice-request/:invoiceId/:type', urlMatch) ||
            matchPattern('boq', urlMatch)
          )
        ) {
          this.gstShow = true;
          this.gstType.disable();

        } else {
          this.gstShow = false;
          this.gstType.enable();

        }

        if (urlMatch && ('/contract/view/REVISED' === urlMatch)
        ) {
          this.gstShow = false;
          this.gstType.enable();
        }

        if (urlMatch) {
          this.contractCreateCheck(urlMatch);
        }

      }
    });
  }

  contractCreateCheck(url) {
    if ('/contract/create' === url) {
      this.gstList = [];
      this.gstList.push({ _id: GST_MENU_ALL.value, gstName: GST_MENU_ALL.label });
    } else {
      this.getGSTconfigList();
    }
  }

  getGSTconfigList() {
    this._gstService.getGSTconfigList().subscribe(_res => {
      this.gstList = _res['data'] || [];
      this.gstList.unshift({ _id: GST_MENU_ALL.value, gstName: GST_MENU_ALL.label });
    });
  }


  ngOnInit() {
    this.isChannelPartner = this._omsService.getIsChannelPartner();
    if (this.router && this.router.url) {
      this.contractCreateCheck(this.router.url);
    }
    this.slug = this.activatedRoute.snapshot.params['slug'];
    this.userService.companySelection(this.slug);
    // gst set
    const type = localStorage.getItem('gst') || GST_MENU_ALL.value;
    this.gstType.setValue(type);
    this._gstService.gstSelection(type);
    this.userName = this._omsService.getUserName();
    this.userImage = this._omsService.getUserImage();
    this.getAllCompany();
    this.companyType.setValue(this.slug);
    this.getClassAndImgLink();
  }

  ngDoCheck() {
    this.slug = this.activatedRoute.snapshot.params['slug'];
  }

  gstChange(gstValue) {
    if (gstValue && gstValue.value) {
      this._gstService.gstSelection(gstValue.value);
      localStorage.setItem('gst', gstValue.value);
      this._reload.handleForceDataUpdate();
    }
  }

  async companyChange(company) {
    const confirmationData = {
      title: 'Alert ',
      message: 'Are you sure want to switch company?',
      confirmationButton: 'Yes, Switch',
      cancelButton: 'No',
      isAction: true
    };

    this.pageExitModalRef = this.modalService.show(ConfirmationPopupComponent, { initialState: confirmationData, class: 'modal-middle' });
    this.pageExitModalRef.content.action.subscribe(async (status) => {
      if (status) {
        this.userService.companySelection(company.value);
        this._omsService.setSelectedCompany(company.value);
        this.tenantService.setTenantId();
        await this._gstService.gstData;
        this.getGSTconfigList();
        this.gstType.setValue(GST_MENU_ALL.value);
        this._gstService.gstSelection(GST_MENU_ALL.value);
        localStorage.setItem('gst', GST_MENU_ALL.value);

        this._ps.getUserPermissions().subscribe(response => {
          if (response['success']) {
            this.featureFlagService.setFeatureFlags(response['data']['featureFlags']);
            this.router.navigate([`/company/${company.value}/contract/view/OPEN`]);
          }
        });
        
      } else {
        const slug = this._omsService.getSelectedCompany();
        if (company.value !== slug) {
          this.companyType.setValue(slug);
        }
      }
    });
    this.getClassAndImgLink();
  }

  getAllCompany() {
    this.companyDetails = this._omsService.getUserCompany();
  }

  getClassAndImgLink() {
    const defaultSlug = 'zetwerk';
    return {
      logo: `/assets/${this.slug || defaultSlug}_logo.svg`,
      icon:  `${this.isChannelPartner ? 'channel-partner' : this.slug || defaultSlug}-color`,
      bgClass: `${this.isChannelPartner ? 'channel-partner' : this.slug || defaultSlug}-select`
    };
  }

}
