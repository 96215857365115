<div class="preview-container">
  <div>
    <div class="modal-header">
      <h4 id="dialog-sizes-name1" class="modal-title pull-left">Preview
        <span class="file-name"> {{ fileName }}</span>
      </h4>
      <i class="pt-2 far fa-times-circle modal-hide-i mouse-pointer" (click)="modalRef.hide()"></i>
    </div>
    <div *ngIf="showNavigation" class="arrows previous" (click)="handleNavigation('previous')"><i class="fas fa-arrow-circle-left"></i></div>
    <div *ngIf="showNavigation" class="arrows next" (click)="handleNavigation('next')"><i class="fas fa-arrow-circle-right"></i></div>
    <div class="modal-body pdf-preview-wrapper">
      <pdf-viewer [src]="fileUrl" [original-size]="false" *ngIf="fileMimeType === 'application/pdf'"></pdf-viewer>

      <ngx-image-viewer [src]="[list[0]]" [config]="{
            btnShow: {
              zoomIn: true,
              zoomOut: true,
              rotateClockwise: false,
              rotateCounterClockwise: false,
              next: false,
              prev: false
            }}"
        *ngIf="list[1]=== 'image/jpeg' || list[1] === 'image/png' "></ngx-image-viewer>

    </div>
  </div>
</div>
