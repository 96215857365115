import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RouteReloadService {

  constructor(
    private _router: Router
  ) { }

  handleForceDataUpdate() {
    const currentUrl = `${this._router.url}`;
    this._router
      .navigateByUrl(`under-maintenance`, { skipLocationChange: true })
      .then(() => {
        this._router.navigate([currentUrl]);
      });
  }
}
