<div *ngIf="!params?.value?.statuses; else multipleStatuses"
  [ngClass]="params?.value?.class ? params?.value?.class : 'status-label'">
  {{ params?.value?.statusMap[params?.value?.status] || '-' }}
</div>

<ng-template #multipleStatuses>
  <div *ngFor="let status of params?.value?.statuses">
    <span class="pl-1 pr-1" [ngClass]="status?.class ? status?.class : 'status-label'">
      {{ params?.value?.statusMap[status?.status] || '-' }}
    </span>
  </div>
</ng-template>