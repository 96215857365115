import * as moment from 'moment';
import { stringify } from 'qs';
import {
  StateCodes
} from '../services/StateCodes';
import { Subscription } from 'rxjs';
import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ElementRef, QueryList } from '@angular/core';
import { CHARGE_TYPES } from '../constants/charge-types';
import { FieldRegExConstant } from '../constants/validatorRegex';
import { FIELD_BY_TERM_TYPE,MILESTONE_RETENTION, } from 'src/app/constants/payment-terms';
import { STATUS_MAPPER } from "../constants/workflow/progressMapper";
import { customDates } from 'src/app/constants/dates'
import { CONTRACT_STATUS } from 'src/app/constants/contract';
import { ORDER_STATUS } from 'src/app/constants/order';
import * as _ from "lodash-es";
import { DEBIT_PAYMENT_MARKING_MAPPER } from 'src/app/constants/bank-transaction';
import { environment } from 'src/environments/environment';
import { ACCOUNT_SUB_TYPES_VENDOR_GROUPID_MAPPER } from 'src/app/components/shared/indirect-receipt/Data/Definition';
const ECO_NF_BUS = ['econf', 'zetchem-ecosystem-non-ferrous'];
import { PAYMENT_MODES, RECEIPTS_STATUS_ENUM, TRANSACTION_TYPES_IN_INVOICE } from '../constants/oms-receipts';
import { ConfirmationPopupComponent } from '../components/general/confirmation-popup/confirmation-popup.component';
import { ENTITY_TYPES, ENTITY_REFS, STATUSES } from 'src/app/constants/document-verification';
import { ADVANCE_DOCS } from '../constants/payment-request';


export const convertCommaSeperatedStringToNumber = function (value) {
  if (typeof value === 'number') {
    return value;
  }

  if (typeof value === 'string') {
    return Number(value.replace(/,\s?/g, ''));
  }
};

export const deepCopy = function (object) {
  return JSON.parse(JSON.stringify(object));
};

export const sortLogs = (logs : any)=> {
  return logs.sort(
    (a: any, b: any): any =>
      new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
  );
}

export const convertToEodIST = function (date) {

  return moment(date)
    .endOf('day')
    .utcOffset(330)
    .format();
};

export const sortBy = function (prop) {
  return function (a, b) {
    if (a[prop] > b[prop]) {
      return 1;
    } else if (a[prop] < b[prop]) {
      return -1;
    }
    return 0;
  };
};


export const decodeGSTNumber = function (gstNumber) {
  gstNumber = gstNumber.trim();
  const gst_state_code = gstNumber.substring(0, 2);
  const gst_pan = gstNumber.substring(2, 12);
  let gst_state = '';
  for (const item of StateCodes) {
    if (item.code === parseInt(gst_state_code, 10)) {
      gst_state = item.name;
      return {
        name: item.name,
        pan: gst_pan,
        code: item.code
      };
    }
  }
};

export const decodeEntities = function (encodedString) {
  //The code &#128; corresponds to the ISO-10646 character \u0080,
  // which is a non-printable control character.
  if (encodedString === '&#128;') {
    encodedString = '&#8364;';
  }
  const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
  const translate = {
    'nbsp': ' ',
    'amp': '&',
    'quot': '\"',
    'lt': '<',
    'gt': '>'
  };
  return encodedString.replace(translate_re, function (match, entity) {
    return translate[entity];
  }).replace(/&#(\d+);/gi, function (match, numStr) {
    const num = parseInt(numStr, 10);
    return String.fromCharCode(num);
  });
};


export const downloadFile = function (filename, text) {
  const element = document.createElement('a');
  element.setAttribute('href', filename);
  element.setAttribute('download', text);
  element.click();
};


export const getTaxedPoValue = function (data, orderValue = false) {
  let poValue = 0;
  let totalPoValue = 0;
  const tax = calculateWeightedTax(data.items);
  if (data.poTaxIncluded) {
    totalPoValue = calculatePoValueWithTax(data.items);
  } else {
    poValue = calculatePoValueWithTax(data.items);
    totalPoValue = poValue;
  }
  let taxCalculated: any = '';
  let poValueCalculated: any;
  poValue = calculatePoValueWithoutTax(data.items);
  if (!data.poTaxIncluded) {
    taxCalculated = calculateWeightedTax(data.items);
    if (tax === 0 && !data.poDisplayTaxColumn) {
      // If field is order value and tax is zero then return povalue and not empty
      if (orderValue) {
        poValueCalculated = poValue;
      } else {
        poValueCalculated = '';
      }
    } else {
      poValueCalculated = totalPoValue;
    }
    if (data.poTax && data.poTax >= 0) {
      taxCalculated = data.poTax;
      poValueCalculated = poValue + (poValue * (taxCalculated / 100));
    }
  } else {
    poValueCalculated = totalPoValue;
  }
  return poValueCalculated;
};

export const calculatePoValueWithoutTax = function (items) {
  let preTax = 0;
  if(items && items.length > 0){
    items.map((item) => {
      preTax = preTax + (item.quantity * item.unitRate);
    });
  }
  return preTax;
};

export const calculatePoValueWithTax = function (items) {
  let postTax = 0;
  items.map((item) => {
    postTax = postTax + item.amount;
  });
  return postTax;
};

export const calculateTotalValueWithTolerance = function (items, tolerancePercentage) {
  let totalValueWithTolerance = 0;
  items.map((item) => {
    let toleranceQuantity = roundOff((item.quantity * tolerancePercentage)/100);
    let itemAmountWithoutTax = (item.quantity + toleranceQuantity) * item.unitRate;
    totalValueWithTolerance += (((item.tax /100) * itemAmountWithoutTax) + itemAmountWithoutTax);
  });
  return totalValueWithTolerance;
};

export const calculatePoValueWithSgstCgst = function (items) {
  let postTax = 0;
  items.map((item) => {
    postTax = postTax + (item.quantity * item.unitRate) + (((item.quantity * item.unitRate) * (item.sgst + item.cgst)) / 100);
  });
  return postTax;
};

export const calculatePoValueWithSgst = function (items) {
  let postTax = 0;
  items.map((item) => {
    postTax = postTax + (item.quantity * item.unitRate) + (((item.quantity * item.unitRate) * item.sgst) / 100);
  });
  return postTax;
};

export const calculatePoValueWithCgst = function (items) {
  let postTax = 0;
  items.map((item) => {
    postTax = postTax + (item.quantity * item.unitRate) + (((item.quantity * item.unitRate) * item.cgst) / 100);
  });
  return postTax;
};

export const calculatePoValueWithIgst = function (items) {
  let postTax = 0;
  items.map((item) => {
    postTax = postTax + (item.quantity * item.unitRate) + (((item.quantity * item.unitRate) * item.igst) / 100);
  });
  return postTax;
};
export const calculatePoValueWithCess = function (items) {
  let postTax = 0;
  if(items && items.length > 0){
    items.map((item) => {
      item.cessAmount = item.cessAmount? item.cessAmount : 0;
      postTax = postTax + (item.quantity * item.unitRate) + item.cessAmount;
    });
  }
  return postTax;
};

export const calculateWeightedSgst = function (items) {
  const preTax = calculatePoValueWithoutTax(items);
  const postTax = calculatePoValueWithSgst(items);
  const percentage = ((postTax / preTax) - 1) * 100;
  return percentage;
};

export const calculateWeightedCgst = function (items) {
  const preTax = calculatePoValueWithoutTax(items);
  const postTax = calculatePoValueWithCgst(items);
  const percentage = ((postTax / preTax) - 1) * 100;
  return percentage;
};

export const calculateWeightedIgst = function (items) {
  const preTax = calculatePoValueWithoutTax(items);
  const postTax = calculatePoValueWithIgst(items);
  const percentage = ((postTax / preTax) - 1) * 100;
  return percentage;
};
export const calculateWeightedCess = function(items){
  const preTax = calculatePoValueWithoutTax(items);
  const postTax = calculatePoValueWithCess(items);
  const percentage = ((postTax / preTax) - 1) * 100;
  return percentage;
}
export const calculateWeightedTax = function (items): any {
  const preTax = calculatePoValueWithoutTax(items);
  const postTax = calculatePoValueWithTax(items);
  const percetage = ((postTax / preTax) - 1) * 100;
  return percetage;
};

export function getAmountDueToday(paymentSchedule) {
  let dueAmounts = paymentSchedule.filter(schedule => {
    return moment(schedule.dueDate).subtract(1, 'days').toDate() <= new Date();
  });
  return dueAmounts.reduce((sum, schedule) => {
    return sum + schedule.totalAmountDue;
  }, 0);
}

export function  getAmountPaidExcludingCharges(charges, amountPaid) {
  let totalChargesSumExcludingLd = charges
  .filter(charge => charge.typeOfCharge !== 'LD')
  .reduce((sum, charge) => sum + charge.amount, 0);

  return amountPaid - totalChargesSumExcludingLd;
}

export const setDataInLocalStorage = function (key, value) {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getDataFromLocalStorage = function (key) {
  const data = localStorage.getItem(key);
  if (data !== null) {
    return JSON.parse(data);
  }
};
export const removeDataFromLocalStorage = function (key) {
  localStorage.removeItem(key);
};

export const scrollToError = function (selector) {
  const firstElementWithError = document.querySelector(
    selector
  );

  if (firstElementWithError) {
    firstElementWithError.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest'
    });
  }
};


export const capitalizeString = function (text) {
  return text.toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export const checkNested = function (obj /*, level1, level2, ... levelN*/) {
  const args = Array.prototype.slice.call(arguments, 1);
  for (let i = 0; i < args.length; i++) {
    if (!obj || !obj.hasOwnProperty(args[i])) {
      return false;
    }
    obj = obj[args[i]];
  }
  return true;
};


export const removeAllPropertyOfNullvalue = function (object) {
  if (object) {
    Object
      .entries(object)
      .forEach(([k, v]) => {
        if (v && typeof v === 'object') {
          removeAllPropertyOfNullvalue(v);
        }
        if (v && typeof v === 'object' && !(v instanceof Array) && !Object.keys(v).length || v === null || v === undefined || v === '') {
          if (Array.isArray(object)) {
            object.splice(Number(k), 1);
          } else {
            delete object[k];
          }
        }
      });
  }
  return object;
};

export function getErrorMessageForTooltip(item, defaultMessage) {
  if (!item) {
    return;
  }

  let tooltip = '';

  if (item.bankRequestStatusCode) {
    tooltip = tooltip + 'Code:  ' + item.bankRequestStatusCode + ', ';
  }
  if (item.bankRequestStatusDescription) {
    tooltip = tooltip + 'Message: ' + item.bankRequestStatusDescription + ', ';
  }
  if (item.bankTransactionStatusCode) {
    tooltip = tooltip + 'Status Code: ' + item.bankTransactionStatusCode + ', ';
  }
  if (item.bankTransactionStatusDescription) {
    tooltip = tooltip + 'Status Message: ' + item.bankTransactionStatusDescription;
  }

  if (tooltip === '') {
    tooltip = defaultMessage;
  }
  return tooltip;
}

export function searchInArray(list, searchtext) {

  if (!searchtext) {
    return list;
  }

  if (!list || !Array.isArray(list)) {
    return [];
  }
  searchtext = searchtext.toLowerCase().trim();
  const result = list.filter((item: any) => {
    // const stringifiedItem = JSON.stringify(item);
    return ((item.UTR || item.urnNo).toLowerCase().includes(searchtext)) ||
      (item.orderDetails && item.orderDetails.orderNumber.toLowerCase().includes(searchtext)) ||
      (item.billDetails && item.billDetails.billNumber.toLowerCase().includes(searchtext)) ||
      (item.supplierCreditNoteDetails && item.supplierCreditNoteDetails.noteNumber.toLowerCase().includes(searchtext) ||
        (item.supplierInformation && item.supplierInformation.basicInformation.name.toLowerCase().includes(searchtext)) ||
        (item.salesInvoiceDiscountingDetails && (item.salesInvoiceDiscountingDetails.contractNumber || '').toLowerCase().includes(searchtext)) ||
        (item.salesInvoiceDiscountingDetails && (item.salesInvoiceDiscountingDetails.invoiceNumber || '').toLowerCase().includes(searchtext)) ||
        (item.salesInvoiceDiscountingDetails && item.salesInvoiceDiscountingDetails.customerDetails && (item.salesInvoiceDiscountingDetails.customerDetails.name || '').toLowerCase().includes(searchtext))
      );
    // return stringifiedItem.toLowerCase().includes(searchtext);
  });

  return result;
}


export function destroySubs(_subsList: Subscription | Subscription[]): boolean {

  const subsList = [].concat(_subsList || []);

  subsList.forEach(subscription => {
    if (subscription) {
      subscription.unsubscribe();
    }
  });
  return true;

}

export function camelToTitleCase(inpString: string): string | null {
  inpString = inpString || '';
  inpString = inpString.replace(/([A-Z])/g, ' $1');
  const converted = inpString.charAt(0).toUpperCase() + inpString.slice(1);

  return converted;

}


export function isEqual(input: string, toMatch: string, ignoreCase = true) {

  if (input && toMatch && (typeof input === 'string')) {
    return ignoreCase ? input.toLowerCase() === toMatch.toLowerCase() : false;
  }
  return false;

}


export function compareValues(inpValue: any, toCompareValue: number, operator: string) {
  try {
    switch (operator) {
      case 'gt':
        return inpValue > toCompareValue;
      case 'gte':
        return inpValue >= toCompareValue;
      case 'lt':
        return inpValue < toCompareValue;
      case 'lte':
        return inpValue <= toCompareValue;
      default:
        return false;
    }

  } catch (e) {
    return false;
  }

}

export function gt(inpValue: any, toCompareValue: number, equalityMatch = false) {
  return compareValues(inpValue, toCompareValue, equalityMatch ? 'gte' : 'gt');
}

export function getScopeOfWork(scopeOfWork) {
  if (scopeOfWork) {
    const scopeOfWorkArray = [];
    scopeOfWork.map(data => {
      scopeOfWorkArray.push(data);
    });
    return scopeOfWorkArray.join(' , ');
  }
  return;
}

export function _decimalUpto(value: number, fractionalDigit: number) {
  if (value && !isNaN(value)) {
    return +value.toFixed(fractionalDigit);
  }
  return value;
}

export function searchInLotArray(list, searchtext) {

  if (!searchtext) {
    return list;
  }

  if (!list || !Array.isArray(list)) {
    return [];
  }
  searchtext = searchtext.toLowerCase().trim();
  const result = list.filter((item: any) => {
    return (item.lotIdName.toLowerCase().includes(searchtext)) ||
      (item.lotNumber.toLowerCase().includes(searchtext));
  });

  return result;
}

export function flattenChildren(items, parentId, parentName, ancestors = [], path = [], flatLineItems, serialNumber) {
  let itemAncestors = new Array();
  if (parentId) {
    itemAncestors = [...ancestors].concat(parentId);
  } else {
    itemAncestors = [...ancestors].concat(serialNumber);
  }

  let itemPath = new Array();
  itemPath = [...path].concat(parentName);
  const itemChildren = [];
  items.forEach(item => {
    const itemId = item._id;
    const itemName = item.name;
    let children = [];
    if (item.children && item.children.length) {
      const itemAncestorsCopy = [...itemAncestors];
      const itemPathCopy = [...itemPath];
      children = flattenChildren(item.children, itemId, itemName, itemAncestorsCopy, itemPathCopy, flatLineItems, item.serialNumber);
    }

    flatLineItems.push({
      ...item,
      _id: itemId,
      children,
      ancestors: itemAncestors,
      path: itemPath
    });

    if (itemId) {
      itemChildren.push(itemId);
    } else {
      itemChildren.push(item.serialNumber);
    }
  });

  return itemChildren;
}

export function flattenLineItems(data) {
  const flatLineItems = new Array();

  data.forEach(item => {

    const ancestors = [];
    const path = [];
    let children = [];
    const parentId = item._id;
    const parentName = item.name;

    if (item.children && item.children.length) {
      children = flattenChildren(item.children, parentId, parentName, ancestors, path, flatLineItems, item.serialNumber);
    }
    flatLineItems.push({
      ...item,
      _id: parentId,
      children,
      ancestors,
      path
    });
  }
  );

  // console.log(flatLineItems);
  return flatLineItems;
}

export function formatToInr(x: any) {
  x = x.toString();
  let afterPoint = '';
  if (x.indexOf('.') > 0) {
    afterPoint = x.substring(x.indexOf('.'), x.length);
    afterPoint = afterPoint.substring(0, 3);
  }
  x = Math.floor(x);
  x = x.toString();
  let lastThree = x.substring(x.length - 3);
  const otherNumbers = x.substring(0, x.length - 3);
  if (otherNumbers != '') {
    lastThree = ',' + lastThree;
  }

  return (
    otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, ',') + lastThree + afterPoint
  );
}


export const markFormGroupTouched = (formGroup: FormGroup) => {
  (<any>Object).values(formGroup.controls).forEach(control => {
    control.markAsTouched();

    if (control.controls) {
      markFormGroupTouched(control);
    }
  });
};

export function twoObjectsKeyValueDiff(obj1, obj2) {
  const result = {};
  if (Object.is(obj1, obj2)) {
    return undefined;
  }
  if (!obj2 || typeof obj2 !== 'object') {
    return obj2;
  }
  Object.keys(obj1 || {})
    .concat(Object.keys(obj2 || {}))
    .forEach((key) => {
      if (obj2[key] !== obj1[key] && !Object.is(obj1[key], obj2[key])) {
        result[key] = obj2[key];
      }
      if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
        const value = twoObjectsKeyValueDiff(obj1[key], obj2[key]);
        if (value !== undefined) {
          result[key] = value;
        }
      }
    });
  return result;
}

export function checkIsFormInValid(form: FormGroup) {
  if (!form.valid) {
    const firstInvalidControl = document.querySelector(
      '.ng-invalid'
    );
    if (firstInvalidControl) {
      firstInvalidControl.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
    return true;
  }
  return false;
}

export function getChargeTypeName(type) {
  const charge = CHARGE_TYPES.find(item => item.chargeType === type);
  return charge ? charge.name : type;
}

export function convertToWords(num, appendOnly = true) {
  num = num.toString().split('.')[0];
  const a = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen '
  ];
  const b = [
    '',
    '',
    'Twenty',
    'Thirty',
    'Forty',
    'Fifty',
    'Sixty',
    'Seventy',
    'Eighty',
    'Ninety'
  ];
  if ((num = num.toString()).length > 10) { return 'overflow'; }
  const n = ('0000000000' + num)
    .substr(-10)
    .match(/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  if (!n) { return; }
  let str = '';
  str +=
    Number(n[1]) !== 0
      ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Hundred '
      : '';
  str +=
    Number(n[2]) !== 0
      ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Crore '
      : Number(n[1]) !== 0
        ? 'Crore '
        : '';
  str +=
    Number(n[3]) !== 0
      ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Lakh '
      : '';
  str +=
    Number(n[4]) !== 0
      ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Thousand '
      : '';
  str +=
    Number(n[5]) !== 0
      ? (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Hundred '
      : '';
  str +=
    Number(n[6]) !== 0
      ? (str !== '' ? 'and ' : '') +
      (a[Number(n[6])] || b[n[6][0]] + ' ' + a[n[6][1]]) +
      ''
      : '';
  
    let convertedStr = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();

    // Append "only" to the end of the string if specified
    convertedStr += appendOnly ? " only " : "";

    return convertedStr;

}

export function checkUpToThreeDecimalPlaces(formControl: FormControl) {
  if (formControl.value === null || formControl.value === 0) {
    return null;
  }
  const regexp = new RegExp(FieldRegExConstant.ALLOW_NUMBER_WITH_THREE_DECIMAL_PLACES);
  return regexp.test(formControl.value) ? null : { checkUpToThreeDecimalPlaces: { valid: false } };
}

export function roundOff(value: Number, decimals = 3) {
  if (countDecimals(value) > decimals) {
    return Number(value.toFixed(decimals));
  }
  return Number(value);
}

export function countDecimals(value) {
  if (Math.floor(value.valueOf()) === value.valueOf()) { return 0; }
  return value.toString().split('.')[1].length || 0;
}

export function nonZeroValidator() {
  return (field: AbstractControl): ValidationErrors | null => {
    return field.value > 0
      ? null
      : { maxValue: 'Unit rate should be greater than zero' };
  };
}



function generateTreeFromRoot(root, flatLineItems) {
  const itemObj = { ...root };
  if (root.children && root.children.length) {
    const myChildren = new Array();
    root.children.forEach(child => {
      const matchIndex = flatLineItems.findIndex((ele) => ele._id === child || ele.serialNumber === child);
      if (matchIndex > -1) {
        const newRoot = generateTreeFromRoot(flatLineItems[matchIndex], flatLineItems);
        myChildren.push(newRoot);
      }
    }
    );

    return { ...itemObj, childrenDetails: myChildren };
  } else {
    const returning = { ...itemObj, childrenDetails: [] };
    return returning;
  }
}

export function generateTreeFromFlatItems(flatLineItems, treeStructure = []) {
  const roots = flatLineItems.filter((item) => !item.ancestors || (item.ancestors && !item.ancestors.length));
  roots.forEach(root => {
    const tree = generateTreeFromRoot(root, flatLineItems);
    // if (tree && tree.childrenDetails) {
    root.childrenDetails = tree.childrenDetails;
    // }
    treeStructure.push(root);
  });

  return treeStructure;
}

export function checkConversionRateInputRequired(firstCurrencyDetails, secondCurrencyDetails) {
  return firstCurrencyDetails && secondCurrencyDetails && firstCurrencyDetails._id !== secondCurrencyDetails._id;
}

export function getTotalValue(noteDetails) {
  let totalValue = noteDetails.items.reduce((sum, item) => {
    return sum + item.amount;
  }, 0);
  return noteDetails.isTcsApplicable ? (totalValue + (noteDetails.tcsRate * totalValue) / 100) : totalValue;
}

export function getCompanyCurrencyDetails() {
  let slug = JSON.parse(localStorage.getItem('slug'));
  let companyDetails = JSON.parse(localStorage.getItem('company'));
  let currentCompany = companyDetails.find(company => slug === company.slug);
  return currentCompany.companyDetails.baseCurrencyDetails;
}

export const shouldShowTaxColumn = ({ docCurrencyDetails, companyCurrency }) => {
  const { currencyCode } = docCurrencyDetails;
  const { currencyCode: companyCurrencyCode } = companyCurrency;
  if (currencyCode !== 'INR') {
    return true;
  }
  if (currencyCode === 'INR' && currencyCode === companyCurrencyCode) {
    return false;
  }
}

export function getConvertedCurrencyValue({currentCurrencyValue, conversionRateToBaseCurrency = 1, conversionRateToCompanyCurrency = 1, ouputCurrencyConversionRateToCompany = 1}) {
  let valueInBaseCurrency = currentCurrencyValue * conversionRateToBaseCurrency;
  let valueInCompanyCurrency = valueInBaseCurrency * conversionRateToCompanyCurrency;
  let valueInOutputCurrency = valueInCompanyCurrency / ouputCurrencyConversionRateToCompany;
  return roundOff(valueInOutputCurrency);
}

export function getFinancialYearInDateRange(startDate, endDate = new Date()) {
  startDate = new Date(startDate)
  const data = [];
  let startFY = getFinancialYear(startDate);
  const currentYear = moment(startDate).year();
  const startDateStartofDay = new Date(new Date(startDate).setHours(0, 0, 0, 0));
  const eystartofDay = new Date(startDateStartofDay.setFullYear(startFY + 1, 2, 31));
  let date = new Date(eystartofDay.setHours(23, 59, 59, 999));
  data.push({ startYear: startFY, endYear: startFY + 1, startDate: new Date(startDate.setHours(0, 0, 0, 0)), endDate: date });
  while (date < endDate) {
    const fyStartDate = new Date(new Date(new Date(date).setMonth(3, 1)).setHours(0, 0, 0, 0));
    startFY = getFinancialYear(fyStartDate);
    date = new Date(new Date(new Date(fyStartDate).setFullYear(startFY + 1, 2, 31)).setHours(23, 59, 59, 999));
    data.push({ startYear: startFY, endYear: startFY + 1, startDate: fyStartDate, endDate: date > endDate && new Date(new Date(endDate).setHours(23, 59, 59, 999)) || date });
  }
  return data;
}
function getFinancialYear(billDate) {
  let currentYear = moment(billDate).year();
  const currentMonth = moment(billDate).month();
  if (currentMonth < 3) {
    currentYear -= 1;
  }
  return currentYear;
}

export const capitalize = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getContractCurrencyId = (contract) => {
  if (contract.poDetails && contract.poDetails.currencyId) {
    return contract.poDetails.currencyId;
  }
  if (contract.draftPODetails && contract.draftPODetails.currencyId) {
    return contract.draftPODetails.currencyId;
  }
  if (contract.loiDetails && contract.loiDetails.currencyId) {
    return contract.loiDetails.currencyId;
  }

  return;
}

export const dateForNewPicker = (date = new Date()) => {
  date = date && new Date(date) || new Date();
  return date.toJSON().split('T')[0];
};

export const isNullOrUndefined = (value: any) => {
  if (value === null) {
    return true;
  }
  if (value === undefined) {
    return true;
  }
  return false;
}

export const clearFormArray = (formArray: FormArray) => {
  while (formArray.length !== 0) {
    formArray.removeAt(0)
  }
}

export function getTermsAndConditionsForPO(purchaseOrder) {
  let termsArray = [];
  if (purchaseOrder.isPOToleranceEnabled) {
    termsArray.push({
      name: 'Tolerance Terms',
      include: true,
      descriptions: [
        `Tolerance of ${purchaseOrder.poToleranceSign}${(purchaseOrder.poTolerancePercentage)}% is applicable on each of the PO line item quantity`
      ]
    });
  }

  for (let term of JSON.parse(JSON.stringify(purchaseOrder.terms))) {
    let termObj = {};
    termObj['name'] = term.name;
    termObj['include'] = term.include;
    let descriptions = [];

    if (term.termTypes && term.termTypes.length > 0) {
      for (let termType of term.termTypes) {
        let statement = getStatement(termType);
        if (statement) {
          descriptions.push(statement);
        }
      }
    }

    if (term.name === 'Add Payment Terms' && term.include) {
      if (term.description) {
        descriptions.push(term.description);
      }
      if(term.isRetentionApplicable){
        descriptions.push(getRetentionTermsSentence({paymentTerms: term}));
      }
      if (term.isLcApplicable) {
        descriptions.push(getLcTermsSentence({paymentTerms: term}));
      }
      if (term.isDiscountApplicable) {
        descriptions.push(getDiscountTermSentence({ paymentTerms: term}));
      }

      let beforeDispatchTerm = (term.termTypes || []).find((termType) => (termType.subTypeName && termType.subTypeName.toLowerCase() === 'before dispatch on po value') || termType.isValueGSTInclusive);

      if (!beforeDispatchTerm) {
        descriptions.push('GST amount to be paid on submission and acceptance of original invoice as per the due date of invoice.');
      }
    } else if (term.name === 'Add Transportation Terms' && term.include) {
      if (term.termTypes && term.termTypes.length) {
        for (let termType1 of term.termTypes || []) {
          if (termType1 && termType1 !== 'Not applicable') {
            if (term && term.description) {
              descriptions.push(`${termType1.typeName}. ${term.description}`);
            } else {
              descriptions.push(`${termType1.typeName}.`);
            }
          } else if (termType1 && termType1 !== 'Not applicable' && term.description) {
            descriptions.push(`${term.description}`);
          } else {
            descriptions.push('Not applicable');
          }
        }
      } else {
        let termType = term.termType || '';
        if (termType && termType !== 'Not applicable') {
          if (term && term.description) {
            descriptions.push(`${termType}. ${term.description}`);
          } else {
            descriptions.push(`${termType}.`);
          }
        } else if (termType && termType !== 'Not applicable' && term.description) {
          descriptions.push(`${term.description}`);
        } else {
          descriptions.push('Not applicable');
        }
      }

    } else if (term.name === 'Add Delivery Terms' && term.include) {
      if (term.deliveryDate) {
        descriptions.push(`Complete material to be delivered by ${moment(term.deliveryDate).format('DD/MM/YYYY')}. `);
      }
      if (term.description) {
        descriptions.push(`${term.description}`);
      }
    } else if (term.name === 'Add Late Delivery Terms' && term.include) {
      if (term.poValuePercentage && term.delayPeriodUnit && term.maximumDelayPeriod) {
        descriptions.push(`Late delivery charges of ${term.poValuePercentage}% of PO basic value per every ${term.delayPeriodUnit} of delay upto a maximum of ${term.maximumDelayPeriod}%.`);
        descriptions.push('This will be applicable on the un-dispatched value of the PO.');
      } else if (term.poValuePercentage && term.maximumDelayPeriod) {
        descriptions.push(`Late delivery charges of ${term.poValuePercentage}% of PO basic value upto a maximum of ${term.maximumDelayPeriod}%.`);
        descriptions.push('This will be applicable on the un-dispatched value of the PO.');
      } else if (term.poValuePercentage) {
        descriptions.push(`Late delivery charges of ${term.poValuePercentage }% of PO basic value.`);
        descriptions.push('This will be applicable on the un-dispatched value of the PO.');
      }
      if (term.description) {
        descriptions.push(term.description);
      }
    } else if (term.name === 'Add Measurement Terms' && term.include) {
      if (term.termTypes && term.termTypes.length) {
        descriptions.push(getTypeName({termTypes: term.termTypes}));
      } else {
        descriptions.push(`${term.termType}`);
      }
    } else if (term.include) {
      descriptions.push(term.description);
    }

    termObj = { ...term };
    termObj['descriptions'] = descriptions;
    termsArray.push(termObj);
  }
  return termsArray;
}

function getTypeName({termTypes = []}) {
  let finalTermType = '';
  for (let termType of termTypes) {
    finalTermType += termType.typeName + ' ';
  }
  return finalTermType;
}

function getMilestoneForRetention(terms) {
  if (terms && terms.milestone) {
    const milestone = terms.milestone === 'Others' ? terms.otherMilestoneCondition : MILESTONE_RETENTION[terms.milestone.toLowerCase()];
    return milestone;
  }
  else {
    return '';
  }
}

 function getRetentionTermsSentence({ paymentTerms }) {
  if (paymentTerms) {
    const milestone = getMilestoneForRetention(paymentTerms);
    return `Retention of ${paymentTerms.paymentRetentionPercentage}% of basic PO values to be paid ${paymentTerms.paymentRetentionDays} days from ${milestone}`;
  }
}

function getLcTermsSentence({ paymentTerms }) {
  if (
    paymentTerms &&
    paymentTerms.hasOwnProperty('lcUsanceDays') &&
    paymentTerms.hasOwnProperty('supplierUsanceDays') &&
    paymentTerms.hasOwnProperty('zetwerkUsanceDays')
  ) {
    return `Payment to be made via Letter of Credit with ${paymentTerms.lcUsanceDays} days usance days (${paymentTerms.zetwerkUsanceDays} days in Zetwerk scope, ${paymentTerms.supplierUsanceDays} days in Supplier's scope)`;
  }
}

function getDiscountTermSentence({ paymentTerms }) {
  if (
    paymentTerms &&
    paymentTerms.hasOwnProperty('discountCreditDays') &&
    paymentTerms.hasOwnProperty('discountRate')
  ) {
    return `The supplier can avail the bill discounting facility from a mutually agreed third party financier and avail the payment on/after the ${converNumberToOrdinalNumbers(paymentTerms.discountCreditDays)} day of dispatch @${paymentTerms.discountRate}% per month interest borne by supplier`;
  }
}

function converNumberToOrdinalNumbers(number) {
  const j = number % 10,
    k = number % 100;
  if (j == 1 && k != 11) {
    return number + 'st';
  }
  if (j == 2 && k != 12) {
    return number + 'nd';
  }
  if (j == 3 && k != 13) {
    return number + 'rd';
  }
  return number + 'th';
}

function getStatement(termType) {
  const typeName = termType.typeName && termType.typeName.toLowerCase();
  const subTypeName = termType.subTypeName && termType.subTypeName.toLowerCase();
  let termMessage = '';
  if (typeName && subTypeName) {
    termMessage =
          (FIELD_BY_TERM_TYPE[typeName] &&
            FIELD_BY_TERM_TYPE[typeName][subTypeName] &&
            FIELD_BY_TERM_TYPE[typeName][subTypeName][
              'statement'
            ]) ||
          '';
  } else if (typeName && !subTypeName) {
    termMessage =
      (FIELD_BY_TERM_TYPE[typeName] &&
        FIELD_BY_TERM_TYPE[typeName]['statement']) ||
      '';
    if(termType.minimumDiscountDays && termType.discountRate) {
      termMessage += `. Bill discounting can be availed after ${termType.minimumDiscountDays} days at ${termType.discountRate}% interest borne by the supplier.`;
    }
  }
  if (termMessage) {
    termMessage = termMessage.replace(/\[(.*?)\]/g, (a, b) => {
      if (b === 'isValueGSTInclusive') {
        return '';
      }
      if (b === 'paymentMethod') {
        return getPaymentMethodText(termType);
      }
      return termType[b] || 0;
    });
    if (termType.isValueGSTInclusive) {
      termMessage = termMessage.replace('basic', 'taxed');
    }
  }
  return termMessage;
}

function getPaymentMethodText(item) {
  const { paymentMethod = '' } = item;
  if (!paymentMethod) {
    return '';
  }
  if (paymentMethod === 'Bank Transfer') {
    return 'via Bank Transfer';
  }
  if (paymentMethod === 'LC') {
    return  `via  LC, ${item.lcUsanceDays || 0} Usance days (Zetwerk scope ${item.zetwerkUsanceDays || 0} days, supplier scope ${item.supplierUsanceDays} days)`;
  }
  if (paymentMethod === 'VFS') {
    return `via VFS (Credit days ${item.discountCreditDays || 0} days, Discounting Charge ${item.discountRate || 0}%)`;
  }
  if (paymentMethod === 'PDC') {
    return 'via PDC';
  }
}

export function isValidAddress({ isHighSeaSale, isExportOrder, exportType, billingAddressV2, shippingAddressesV2  }) {
  let isValidAddress = true;
  let errorMessages = [];
  if(!isHighSeaSale) {
    if(isExportOrder) {
      if(exportType && exportType.includes('Export')) {
        // Outside India Address
        if(billingAddressV2 && billingAddressV2.country && billingAddressV2.country.name === 'India') {
          isValidAddress = false;
          errorMessages.push('Billing Address should be outside India')
        }
        isValidAddress = handleSezAddress({address:billingAddressV2,isValidAddress,errorMessages, addressType:'Billing Address' });
        (shippingAddressesV2 || []).forEach(shippingAddressV2 => {
          if(shippingAddressV2 && shippingAddressV2.country && shippingAddressV2.country.name === 'India') {
            isValidAddress = false;
            errorMessages.push(`Shipping Address (${shippingAddressV2.name}) should be outside India`)
          }
          isValidAddress = handleSezAddress({address:shippingAddressV2,isValidAddress,errorMessages, addressType:`Shipping Address (${shippingAddressV2.name})` });
        })
      }
      else if(exportType && exportType.includes('SEZ')) {
        // SEZ Address
        if(billingAddressV2 && billingAddressV2.country && billingAddressV2.epzCode !== 'SEZ') {
          isValidAddress = false;
          errorMessages.push('Billing Address should be SEZ address')
        }
      }
    }
    else {
      // Domestic Address
      if(billingAddressV2 && billingAddressV2.country && billingAddressV2.country.name !== 'India') {
        isValidAddress = false;
        errorMessages.push('Billing Address should be in India')
      }
      isValidAddress = handleSezAddress({address:billingAddressV2,isValidAddress,errorMessages, addressType:'Billing Address' });
      (shippingAddressesV2 || []).forEach(shippingAddressV2 => {
        if(shippingAddressV2 && shippingAddressV2.country && shippingAddressV2.country.name !== 'India') {
          isValidAddress = false;
          errorMessages.push(`Shipping Address (${shippingAddressV2.name}) should be in India`)
        }
        isValidAddress = handleSezAddress({address:shippingAddressV2,isValidAddress,errorMessages, addressType:`Shipping Address (${shippingAddressV2.name})` });
      })
    }
  }

  return { isValidAddress, errorMessage: errorMessages.join(', ') };
}

export function groupFilterQueryString(filterQueryString) {
  if (!filterQueryString || !filterQueryString.length) {
    return '';
  }
  const filterQueryKeyValues = {};
  let joinedfilterQueryString = '';

  const filterQueryValue = filterQueryString.split('&');
  for (const query of filterQueryValue) {
    const [key, value] = query.split('=');
    if (!filterQueryKeyValues[key]) {
      filterQueryKeyValues[key] = [];
    }
    filterQueryKeyValues[key].push(value);
  }

  for (const query in filterQueryKeyValues) {
    if(joinedfilterQueryString.length) {
      joinedfilterQueryString += '&'
    }
    joinedfilterQueryString += (`${query}=${filterQueryKeyValues[query].join(',')}`)
  }
  return joinedfilterQueryString;
}

export function isFinancialDimensionAvailableFn (data) {
  let isFinancialDimensionAvailaleFlag = false;
  if(data.segment && data.segment && data.businessUnit && data.businessUnit){
    isFinancialDimensionAvailaleFlag = true;
  }
  return isFinancialDimensionAvailaleFlag;
}

export function getFinacialDimensionData(data){
  let financialDimension = {
    segment: data.segment && data.segment,
    businessUnit: data.businessUnit && data.businessUnit,
    subBusinessUnit: data.subBusinessUnit && data.subBusinessUnit,
    region: data.region && data.region,
    factory: data.factory && data.factory,
    contract: data.contract && data.contract
  };
  return financialDimension;
}

export function isBillFlowNew({ items }) {
  if (Array.isArray(items) && items.length > 0 && items[0].itemCode && items[0].taxRateType) {
    return true;
  }
  return false;
}

export function isNewItemFlow({ items }) {
  if (Array.isArray(items) && items.length > 0 && items[0].itemCode && items[0].taxRateType) {
    return true;
  }
  return false;
}

/**
 * Finds item from items array based on given searchText
 * @param items
 * @param searchText
 * @returns item
 */
export function findItem(items: any[], itemKey: string, searchText: string = '') {
  if (!items || !itemKey || !searchText) return null;
  return items.find((item: any) => item[itemKey]?.toLowerCase().includes(searchText?.toLowerCase()));
}

export function getTaxTypeForSales(data) {
    let isIntegratedTax = false;
    const { billFromAddress, billToAddress, salesParameters } = data;
    const { isExport,exportType } = salesParameters;
    if (isExport) {
      if(exportType && exportType.toLowerCase().includes('sez')){
        return isIntegratedTax = true;
      }
      return isIntegratedTax = true;
    }
    if (billToAddress?.country?.name?.toLowerCase() !== 'india') {
      return isIntegratedTax = true;
    }
    const isInterStateTransaction = billFromAddress?.state?.name && billToAddress?.state?.name && (billFromAddress?.state?.name.toLowerCase() !== billToAddress?.state?.name.toLowerCase());
    if (isInterStateTransaction) {
      return isIntegratedTax = true;
    }
    return isIntegratedTax;
}

export function setDataForButtonPermissions({ workflowRequest }: any) {
  let userMap = {
    createdBy: '',
    approvers: [],
    sendForApproval: []
  }
  switch(workflowRequest?.status) {
    case STATUS_MAPPER.PENDING: {
      userMap = {
        createdBy: workflowRequest?.createdBy,
        approvers: workflowRequest?.approverIds || [],
        sendForApproval: []
      }
      return userMap;
    }
    case STATUS_MAPPER.APPROVED: {
      userMap = {
        createdBy: workflowRequest?.createdBy,
        approvers: [],
        sendForApproval: []
      }
      return userMap;
    }
    case STATUS_MAPPER.ON_HOLD: {
      userMap = {
        createdBy: workflowRequest?.createdBy,
        approvers: workflowRequest?.approverIds || [],
        sendForApproval: workflowRequest?.approverIds || []
      }
      return userMap;
    }
    case STATUS_MAPPER.REJECTED: {
      userMap = {
        createdBy: workflowRequest?.createdBy,
        approvers:  [],
        sendForApproval: workflowRequest?.approverIds || []
      }
      return userMap;
    }
    default: {
      userMap = {
        createdBy: workflowRequest?.createdBy,
        approvers: [],
        sendForApproval: workflowRequest?.createdBy && [workflowRequest?.createdBy] || []
      }
      return userMap;
    }
  }
}

export function transformUOMData({data}) {
  if(data?.length > 0){
    data.forEach(uom => {
      uom.name = uom.unit;
      uom.unit = uom.symbol;
      if(!uom?.conversion){
        uom.conversion = 1;
      }
    });
  }
}

export function getUnitToPatch({ mergedUOMs, unit }) {
  return (mergedUOMs.map(uom => uom.symbol)).includes(unit) ? unit : null;
}

export const isErrorPresentInForm = function (selector) {
  const firstElementWithError = document.querySelector(
    selector
  );

  if (firstElementWithError) {
    return true;
  }
  return false;
};

export function transformDirectIndirectUOMs ({directUOMs = [], indirectUOMs = []}) {
  let result = [];
  let unitSet = new Set();
  for (const uom of directUOMs) {
    if (uom.symbol && uom.unit && uom._id && !unitSet.has(uom.symbol)) {
      result.push({
        symbol:uom.symbol,
        unit: uom.unit,
        _id: uom._id
      });
      unitSet.add(uom.symbol);
    }
  }
  for (const uom of indirectUOMs) {
    if (uom.to && uom._id && !unitSet.has(uom.to)) {
      result.push({
        symbol: uom.to,
        unit: uom.to,
        _id: uom._id
      });
      unitSet.add(uom.symbol);
    }
  }
  return result;
}

export function isCessAllowed(contract,einvoiceSubType = null) {
  const exportType = contract?.exportType?.toLowerCase();
  if(einvoiceSubType && einvoiceSubType.toLowerCase() === 'expwop' && exportType === 'export with gst payment') return false;
  if(einvoiceSubType && einvoiceSubType.toLowerCase() === 'expwp' && exportType === 'export without gst payment') return true;
  if(einvoiceSubType && einvoiceSubType.toLowerCase() === 'sezwop' && exportType === 'sez with gst payment') return false;
  if(einvoiceSubType && einvoiceSubType.toLowerCase() === 'sezwp' && exportType === 'sez without gst payment') return true;
  if (
    exportType === 'export without gst payment' ||
    exportType === 'sez without gst payment' ||
    contract?.isHighSeaSale
  ) {
    return false;
  }
  return true;
}


function getMetaConfigTaxRateKey(isRcm) {
  if(isRcm){
    return 'rcmTaxRateTypes';
  }
  return 'nonRcmTaxRateTypes';
}

export function getMetaConfigTaxRates({ poData, metaReason, isKeyExistsCheck = false  }){
  let taxRate;
  let taxRateKey = getMetaConfigTaxRateKey(poData.isRcm);

  if(!metaReason[taxRateKey]) return;

  if(poData.isMerchantOrder){
    taxRate = metaReason[taxRateKey]['isMerchantOrder'];
  }else if(poData.importType === 'Import of Goods' || poData.importType === 'Import of Services'){
    taxRate = metaReason[taxRateKey]['importOfGoodsAndServices'];
  }else if(poData.importType === 'Import from SEZ'){
    taxRate = metaReason[taxRateKey]['importFromSez'];
  }else{
    taxRate = metaReason[taxRateKey]['B2BB2C'];
  }

  if(isKeyExistsCheck){
    return taxRate;
  }

  return taxRate?.length > 0 ? taxRate : null;
}
// Handle contracts address where export type is not sez & address is sez
export function handleSezAddress({ address,isValidAddress,errorMessages,addressType }){
  if (address && address.epzCode && address.epzCode.toLowerCase() === 'sez') {
    isValidAddress = false;
    errorMessages.push(` ${addressType} should not be SEZ address`)
  }
  return isValidAddress;
}

export function isNewTdsFlow({ isTDSApplicable }) {
  if (isTDSApplicable === null || typeof isTDSApplicable === 'undefined') {
    return false;
  }
  return true;
}

export function tranformTdsRateForDropdown({ applicableTdsRates }) {
  if(!applicableTdsRates || !applicableTdsRates.length) {
    return [];
  }
  let dropdownData = [];
  for(let applicableTdsRate of applicableTdsRates) {
    const data = {
      header: applicableTdsRate.isDefaultTds? 'Default TDS': 'Other TDS',
      data: {
        label: `${applicableTdsRate.tdsRate}% | ${applicableTdsRate.tdsGroup}`,
        value: applicableTdsRate
      }
    }
    data.header === 'Default TDS' ? dropdownData.unshift(data) : dropdownData.push(data);

  }
  return dropdownData;
}

export function userListAdditionalInfoDataTransForm({ additionalInfo, isRemoveLabelAndValue = false }){

  if (additionalInfo['saleSPOC']) {
    additionalInfo = isRemoveLabelAndValue ? removeLabelAndValueToAdditionalInfo('saleSPOC', additionalInfo) : addLabelAndValueToAdditionalInfo('saleSPOC', additionalInfo);
  }
  if (additionalInfo['purchaseSPOC']) {
    additionalInfo = isRemoveLabelAndValue ? removeLabelAndValueToAdditionalInfo('purchaseSPOC', additionalInfo) : addLabelAndValueToAdditionalInfo('purchaseSPOC', additionalInfo);
  }
  if (additionalInfo['stateHeads']) {
    additionalInfo = isRemoveLabelAndValue ? removeLabelAndValueToAdditionalInfo('stateHeads', additionalInfo) : addLabelAndValueToAdditionalInfo('stateHeads', additionalInfo);
  }
  if (additionalInfo['l1accowner']) {
    additionalInfo = isRemoveLabelAndValue ? removeLabelAndValueToAdditionalInfo('l1accowner', additionalInfo) : addLabelAndValueToAdditionalInfo('l1accowner', additionalInfo);
  }
  if (additionalInfo['l2accowner']) {
    additionalInfo = isRemoveLabelAndValue ? removeLabelAndValueToAdditionalInfo('l2accowner', additionalInfo) : addLabelAndValueToAdditionalInfo('l2accowner', additionalInfo);
  }

  return additionalInfo;
}

export function addLabelAndValueToAdditionalInfo(SPOC, additionalInfo){
  if(!additionalInfo[SPOC].hasOwnProperty('label') && !additionalInfo[SPOC].hasOwnProperty('value')){
    additionalInfo[SPOC] = {
      label: additionalInfo[SPOC].name,
      value: {
        userId: additionalInfo[SPOC].userId,
        email: additionalInfo[SPOC].email,
        name: additionalInfo[SPOC].name,
      }
    };
  }
  return additionalInfo;
}

export function removeLabelAndValueToAdditionalInfo(SPOC, additionalInfo){
  if(additionalInfo[SPOC].hasOwnProperty('label') && additionalInfo[SPOC].hasOwnProperty('value')){
    additionalInfo[SPOC] = {
      ...additionalInfo[SPOC].value
    }
  }
  return additionalInfo;
}

export function isMSDAllowedDate(documentDate){
  let isMsdAllowedDate = true;
  if(documentDate){
    const cutoffDate = moment(customDates.eInvoicingOnBookClosure).toDate();
    documentDate = moment(documentDate).toDate();
    if(documentDate < cutoffDate){
      isMsdAllowedDate = false;
    }
  }
  return isMsdAllowedDate;
}


/** Returns if edit or cancel is allowed for invoice , customer CN/DN
 * Check if for the document Date is below isMSDAllowedDate & !sentToMSD & e-invoicing failed
 * or
 * if document is sent to send to msd
 * @param {} document : will have isSentToMSD key
 * @param {} key     : name of key having document Date
 * @returns
*/
export function isEditCancelAllowed(document,key){
  if (!document?.isSentToMSD && !document.isSubmittedForEInvoicing && !isMSDAllowedDate(document[key])) {
    return false;
  } else if (document?.isSentToMSD) {
    return false;
  }
  return true;
}

/** 
  * @description returns for dd so, if there is a change in items length or any item's quantity or unit has been updated
  * @returns isDDSORevisedFlag: Boolean, contractStatusForDDSORevision
*/
export function isDDSORevised({ contract, purchaseOrderDetails }) {
  const contractStatusForDDSORevision = [CONTRACT_STATUS.PO_REVISED, 'REVISION_REQUESTED']
  let isDDSORevisedFlag = false;
  if (contract?.isDirectDelivery &&
    isNewItemFlow({ items:contract?.items }) &&
    isNewItemFlow({ items:purchaseOrderDetails?.items }) &&
    purchaseOrderDetails?.isDirectDelivery
  ) {
    const contractItems = contract.items;
    const purchaseOrderItems = purchaseOrderDetails.items;

    // Checking if the number of purchase order items is greater than the contract items
    if (purchaseOrderItems.length > contractItems.length) {
      isDDSORevisedFlag = true;
    } else {
      for (const purchaseOrderItem of purchaseOrderItems) {
        const contractItem = contractItems.find(item => item._id?.toString() === purchaseOrderItem.contractLineItemId?.toString());
        if (!contractItem) {
          isDDSORevisedFlag = true;
        } else if (contractItem) {
          // If a corresponding contract item is found, check for any differences in certain fields
          if (purchaseOrderItem.quantity != contractItem.quantity || purchaseOrderItem.itemCode != contractItem.itemCode ||
            purchaseOrderItem.unit != contractItem.unit) {
            purchaseOrderItem.soItemStatus = 'REVISED';
            isDDSORevisedFlag = true;
          }
        }
      }
    }
  }
  return { isDDSORevisedFlag, contractStatusForDDSORevision };
}

export function getDate(date) {
  return new Date(date).toJSON().split('T')[0];
}

export function getDaysDiff(date1: Date, date2: Date) {
  return Math.abs(moment(date1).diff(moment(date2), 'days'));
}

export function validateTDSData (data) {
  if((!data?.tdsGroup || (isNaN(Number(data?.tdsRate))) || (data?.tdsRate === null) || (data?.tdsRate !== 0 && !data?.erpTdsGroup))){
    return false;
  }
  return true;
}

/**
 * Checks if SO's Export type is Export with gst payment or SEZ with  gst payment, 
 *  or Invoice's e-invoicing sub type is expwp or sezwp
 * then 0% tax rate type should not be present
 * 1st line -> for old cases, this validations is not required
 * @param {FormGroup} form : form data
 * @param {String} exportType
 * @returns {Boolean} and update Form by adding new control
 */
export function validateTaxRateForExportAndSEZ(form: FormGroup, data) {
  if (data?._id && !data.disableZeroTaxRate) return false;
  const formData = form.getRawValue();
  const allowedExportTypesForZeroTaxRate = ['export with gst payment', 'sez with gst payment'];
  const allowedEInvoiceSubTypesForZeroTaxRate =  ['expwp', 'sezwp'];;
  const isCheckRequired = allowedExportTypesForZeroTaxRate.includes(formData?.exportType?.toLowerCase()) || allowedEInvoiceSubTypesForZeroTaxRate.includes(formData?.einvoiceSubType?.toLowerCase())
  const items = formData?.items;
  let isInvalid = false;
  if (items.length > 0) {
    items.forEach((item, elementIndex) => {
      if ((item.taxRateType === '0 %') && isCheckRequired) {
        isInvalid = true;
        form?.controls?.items['controls'][elementIndex]['controls'].taxRateType.setErrors({ 'isInvalidTaxRate': true });
      } else if (form?.controls?.items['controls'][elementIndex]['controls'].x?.hasError('isInvalidTaxRate')) {
        delete form?.controls?.items['controls'][elementIndex]['controls'].taxRateType?.controls.errors['isInvalidTaxRate'];
      }
    });
  }
  form.updateValueAndValidity();
  if (isInvalid) return true;
  return false;
}

/**
 * Checks if Unit Rate Validation is required or not
 * @param item
 * @param orderDetails
 * @param purchaseOrderDetails
 * @returns {Boolean}
 */
export function isUnitRateValidationRequiredForDoc(item, orderDetails, purchaseOrderDetails) {
  if (orderDetails?.isUnitRateValidationRequired || (purchaseOrderDetails && item &&
    !(item.allocations && item.allocations.length))) {
    return true;
  }
  return false;
}

export function getItemMasterWidgetData({categoryType, data, companyId, buId, subBuId, bizType, isDD, allowSingleSelection = false, contract = {}}){

  const isDummy = isOnlyDummyItemsAllowed(data, categoryType, contract, bizType);

  const itemMasterData = {
    categoryType,
    selectedItems: [],
    transactionName: 'OMS',
    companyId,
    isDummy,
    filterContext: isDummy ? undefined : {
      companyId,
      buId,
      subBuId,
      bizType,
      isDD
    },
    allowSingleSelection
  };
  return itemMasterData;
}


/**
* for contracts/POs created before new item master changes related to selection of items went live(FIN-30),
* only dummy items selection will be allowed
* @returns boolean
*/
export function isOnlyDummyItemsAllowed(data, categoryType, contract, bizType) {
  if (categoryType === 'PROCUREMENT') {
    return isOnlyDummyItemAllowedInPO(data, contract, bizType)
  }
  if ((data?._id || data?.isSubCn) && !data?.hasNewItem) {
    return true
  }
  return false;
}

export function isOnlyDummyItemAllowedInPO(data, contract,bizType) {
  const isKeyExists = data && data.hasOwnProperty('hasNewItem');
  const isKeyExistsinSO = contract && contract.hasOwnProperty('hasNewItem');
  if(!bizType){ // for old Order w/o SO where businessType key is not present
    return true;
  } else if(isKeyExists && isKeyExistsinSO){ // new SO & new POs
    return !data.hasNewItem;
  } else if(data?._id && (!isKeyExists || !data?.hasNewItem) && data?.status !== 'DRAFT'){ // old SO & new PO or old Order w/o SO
    return true;
  } else if(!contract?._id){ // order w/o SO
    return false;
  } else if(contract?._id){ // order with SO, inherit key from SO
    return contract?.hasNewItem? false : true;
  }
  return true;
}

export function setToEndOfDay(date) {
  date.setHours(23, 59, 59, 999);
}

/**
   * Checks if e-invoice subtype is export with payment,
   * then show download with gst or not-gst buttons
   * @returns {Boolean}
   */
export function showDownloadWithGSTBtn(einvoiceSubType) {
  if (einvoiceSubType?.toLowerCase() === 'expwp') {
    return true;
  }
  return false;
}

/** Checks if order/contract or any entity belongs Eco system no ferrous business unit
 * @param businessUnitSlug
 * @returns {Boolean}
 */
export function isEntityEcosystemNonFerrous(entity) {
  const businessUnitSlug = _.get(entity, 'businessUnitDetails.slug', '') || _.get(entity, 'contractDetails.businessUnitDetails.slug', '' ) || _.get(entity, 'orderDetails.businessUnitDetails.slug', '');
  return ECO_NF_BUS.includes(businessUnitSlug);
}

/**
 * checks If All Items HaveSame UOM
 * @param items
 * @returns {Boolean}
 */
export function checkIfAllItemsHaveSameUOM(items) {
  return (_.uniqBy(items, 'unit')).length === 1;
}

/**
 * get Total Quantity and unit of items
 * @param items
 * @returns { totalQuantity, unit }
 */
export function getAllItemsWeight(items) {
  const sumWeight = {
    totalQuantity: 0,
    unit: ''
  }
  if (!items || (items && items.length === 0)) return sumWeight;
  sumWeight['totalQuantity'] =  _decimalUpto(items.reduce((sum, item) => sum + (item.quantity || 0), 0), 2);
  sumWeight['unit'] = items && items.length > 0 && items[0].unit || ''
  return sumWeight;
}

export function handleInvalidHsnCode(noteCreateForm,hsnCodesMapper){
  let items = noteCreateForm['controls'].items['controls'] as FormArray;
  for (let i = 0; i < noteCreateForm.getRawValue().items.length; i++) {
    let eachItem = (items.at(i) as FormGroup);
    // eachItem.controls?.isHsnCodeInValid.enable();
    if(hsnCodesMapper[eachItem?.getRawValue()?.hsnCode]){
      // eachItem.controls?.isHsnCodeInValid.patchValue(true);
      eachItem.controls?.isHsnCodeInValid.setErrors({ isInvalid: true });
    } else {
      eachItem.controls?.isHsnCodeInValid?.setErrors(null);
    }
  }
  noteCreateForm.updateValueAndValidity();
  return noteCreateForm;
}

/**
 * Finds addressToSearch is present in addresses or not
 * @param {Array} addresses - array of address to be checked from
 * @param {Object} addressToSearch - adress object which we are comparing
 * @returns - address object 
 *          - empty Object if addressToSearch not found in addresses
 */
export function findCorrespondingAddressUsingObj({ addresses, addressToSearch }) {
  const name = removeSpecialCharacters(addressToSearch.name);
  const city = removeSpecialCharacters(addressToSearch.city);
  const state = removeSpecialCharacters(addressToSearch.state);
  const country = removeSpecialCharacters(addressToSearch.country);
  const pinCode = removeSpecialCharacters(addressToSearch.pincode);
  const street = removeSpecialCharacters(addressToSearch.street);
  const isExist = addresses.find((address) => {
    const addressCopy = deepCopy(address);
    if (city === removeSpecialCharacters(address.city) &&
      name === removeSpecialCharacters(address.name) &&
      state === removeSpecialCharacters(address.state) &&
      country === removeSpecialCharacters(address.country) &&
      pinCode === removeSpecialCharacters(address.pincode) &&
      street === removeSpecialCharacters(address.street)
    ) {
      return addressCopy;
    }
  });
  return isExist;
}

export function removeSpecialCharacters(data) {
  const fixedData = JSON.stringify(data)?.toLowerCase()?.replace(/[^A-Z0-9]/ig, '');
  return fixedData;
}
/**
 * Returns query-string that can be used to apply
 * inital filters in invoice list
 * @param {object} paramsObj
 * expected structure of paramsObj-
 * { 
 *   <filter-key>: [
 *     { 
 *       <name-used-in-invoice-filters>: <identifier-in-invoice-filters> 
 *     }
 *   ],
 *   ...more filters
 * } 
 *   eg. { businessUnit: [{ Roads: 'asdferwee' }] }
*/
export function filtersForInvoiceList(paramsObj) {
  if (!paramsObj) return;

  const queryString =  stringify({
    pageNumber: '1',
    filterValues: paramsObj
  });
  
  return { preFilters: queryString };
}

/**
 * This function checks if the bill linked to old flow and Billing-stopped order
 * @param { Object } billDetails
 * @returns { Boolean }
*/
export function isBillLinkedToV1BillStoppedOrClosedOrder(billDetails) {
  const { purchaseOrderDetails = {}, orderDetails = {} } = billDetails;
  return _.get(purchaseOrderDetails, 'items', []).length > 0 
    && !isBillFlowNew({ items: purchaseOrderDetails.items }) 
    && [ORDER_STATUS.BILLING_STOPPED, ORDER_STATUS.CLOSED].includes(_.get(orderDetails, 'status', ''));
}

/**
 * add proofOfAccount & proofOfAccountDetails to secondary bank account details object
 * @param secondayBankAccounts
*/
export function addProofOfAccountToSecondaryBankAccountDetails(secondayBankAccounts = []){
  if(secondayBankAccounts.length === 0){
    return;
  }
  secondayBankAccounts.forEach(acc => {
    if(acc.proofOfAccountDetails){
      const uri = acc?.proofOfAccountDetails?.src;
      const encodedUri = encodeURI(uri).replace(/[/]/g, "/").replace(/[,]/g, "%2C");
      acc.proofOfAccountDetails = {
        ...acc.proofOfAccountDetails,
        src: `https://${environment.supplierMicroserviceBucketName}.s3-ap-south-1.amazonaws.com${uri}`
      }
    }
  });
}

export function setSelectedSecondaryBankAccountIndex(secondaryBankDetails = [],accountNo = '', selectedSecondaryAccountIndex , acTypeSelected = '') {
  const secondaryBankAccountIndex = secondaryBankDetails.findIndex(bankAccount => bankAccount.accountNo == accountNo);
  if (secondaryBankAccountIndex > -1) {
    selectedSecondaryAccountIndex = secondaryBankAccountIndex;
    acTypeSelected = 'SECONDARY';
  }
  return {selectedSecondaryAccountIndex, acTypeSelected };
}

/**
 * Reset secondary account selection
 * @param secondayBankAccounts
*/
export function inactivateSecondaryAccountSelection(secondaryBankAccounts = []) {
  return secondaryBankAccounts.map(account => {
    return {
      ...account,
      active: false
    }
  });
}

/**
 * construct message for debit transaction marking type &
 * check if pop-up should be shown or not
 * @param markingType : Debit transaction's marking type
 * @param financePayments : finance-payments of debit transaction
 * @returns - { showPopUp, message }
*/
export function getMessageForReverseTransaction(markingType, financePayments) {
  let message = '';
  switch(markingType) {
    case DEBIT_PAYMENT_MARKING_MAPPER.PDC:
      message = `<div>You are reversing a payment which has been mapped to a bank debit transaction. Please reverse the mapping first to reverse the PDC payment. Click on continue to proceed. You will be redirect to the debit transaction page.</div>`;
      break;

    case DEBIT_PAYMENT_MARKING_MAPPER.AUTO:
      message = `<div>You are reversing a payment which has been auto mapped to a bank debit transaction. Please reverse the mapping first to reverse the Bank payment. Click on continue to proceed. You will be redirect to the debit transaction page.</div>`;
      break;

    case DEBIT_PAYMENT_MARKING_MAPPER.ADVANCE:
      message = `<div>You are reversing a payment which has been assigned to multiple orders. Click on continue to proceed. You will be redirect to the debit transaction page.</div>`;
      break;

    case DEBIT_PAYMENT_MARKING_MAPPER.MULTI_VFS:
    case DEBIT_PAYMENT_MARKING_MAPPER.VFS:
      message = `<div>You are reversing a re-payment which has been done via recipt marking. Click on continue to proceed. You will be redirect to the debit transaction page.</div>`;
      break;

    default:
      message = '';
  }
  const showPopUp = isPopUpRequired(markingType,financePayments)
  return { showPopUp, message };
}

/**
 * for debit transaction marking type, check if pop-up should be shown or not
 * @param markingType : Debit transaction's marking type
 * @param financePayments : finance-payments of debit transaction
 * @returns - showPopUp
*/
export function isPopUpRequired(markingType,financePayments){
  let showPopUp = false;
  if (markingType === DEBIT_PAYMENT_MARKING_MAPPER.PDC && financePayments?.length > 0) {
    showPopUp = true;
  } else if (markingType === DEBIT_PAYMENT_MARKING_MAPPER.AUTO && financePayments?.length > 0) {
    showPopUp = true;
  } else if (markingType === DEBIT_PAYMENT_MARKING_MAPPER.ADVANCE && financePayments?.length > 1) {
    showPopUp = true;
  } else if ((markingType === DEBIT_PAYMENT_MARKING_MAPPER.VFS || markingType === DEBIT_PAYMENT_MARKING_MAPPER.MULTI_VFS)) {
    showPopUp = true;
  }
  return showPopUp;
}

/*
 * Get Vendor Group Id
 * @param company, @param sourceObj
*/
export function getVendorGroupId(company, vendor,vendorType, vendorSubType) {
  const VendorGroupsForD365 = {
    Domestic: 'TP-Dom-OM',
    Intrntl: 'TP-Intl',
    MSME_Domes: 'TP-Dom-M',
    InterCo: 'TPI-Dom-OM',
    InterCoIntl: 'TPI-Intl',
    IntraCo: 'TP-Intra',
    'Creditor VFS': 'TP-Cr-VFS',
    'Creditor LC': 'TP-Cr-LC'
  };
  if (ACCOUNT_SUB_TYPES_VENDOR_GROUPID_MAPPER[vendorSubType] || ACCOUNT_SUB_TYPES_VENDOR_GROUPID_MAPPER[vendorType]){
    return ACCOUNT_SUB_TYPES_VENDOR_GROUPID_MAPPER[vendorSubType] || ACCOUNT_SUB_TYPES_VENDOR_GROUPID_MAPPER[vendorType];
  }
  let vendorGroupId = VendorGroupsForD365[vendor?.supplierNationality];
  if (company?.companyRelation === 'Intra') {
    vendorGroupId = VendorGroupsForD365['IntraCo'];
  }
  if (company?.companyRelation === 'Inter') {
    if (vendor.supplierNationality === 'Intrntl') {
      vendorGroupId = VendorGroupsForD365['InterCoIntl'];
    } else {
      vendorGroupId = VendorGroupsForD365['InterCo'];
    }
  }
  if (!vendorGroupId) {
    vendorGroupId = vendor.supplierNationality;
  }
  return vendorGroupId;
}

/**
 * Get Customer Group Id
 * @param company, @param sourceObj
*/

export function getCustomerGroupId(company, customer) {
  const CustomerGroupsForD365 = {
    Domestic: 'TR-Dom-TP',
    International: 'TR-Intl-TP',
    Retail: 'TR-Dom-TP',
    InterCompany: 'TRI-Dom',
    InterCompanyInternational: 'TRI-Intl',
    IntraCompany: 'TR-Intra'
  };
  let customerGroupId = CustomerGroupsForD365[customer?.customerType];
  if (company?.companyRelation === 'Intra') {
    customerGroupId = CustomerGroupsForD365['IntraCompany'];
  }
  if (company?.companyRelation === 'Inter') {
    if (customer.customerType === 'International') {
      customerGroupId = CustomerGroupsForD365['InterCompanyInternational'];
    } else {
      customerGroupId = CustomerGroupsForD365['InterCompany'];
    }
  }
  if (!customerGroupId) {
    customerGroupId = customer.customerType;
  }
  return customerGroupId;
}

export function showDeleteAllocationBtn(transactionItem) {
  let isShowBtn = false;
  const { transactionType, transactionDetails } = transactionItem;
  switch (transactionType) {
    case TRANSACTION_TYPES_IN_INVOICE.AR_AP_ADJUSTMENTS:
      // for AR/AP
      if (transactionItem?.paymentReceivedGroupId && transactionItem?.bankReceiptDetails?.status === RECEIPTS_STATUS_ENUM.OMS_ALLOCATED) {
        isShowBtn = true;
      }
      break;
    case TRANSACTION_TYPES_IN_INVOICE.PAYMENT_RECEIVED:
      if (transactionDetails?.paymentReceivedGroupId &&
          transactionDetails?.paymentReceivedGroupDetails?.status === RECEIPTS_STATUS_ENUM.OMS_ALLOCATED) {
        // for TDS Receivable
        if (transactionDetails?.paymentMode === PAYMENT_MODES.TDS_RECEIVABLE) {
          isShowBtn = true;

        // for direct
        } else if (transactionDetails?.paymentReceivedGroupDetails?.bankTransactionId) {
          isShowBtn = true;
        }
      }
      break;
    }
  return isShowBtn;
}

export function getIndianCurrencyObject(currencies) {
  return _.find(currencies, ['currencyCode', 'INR']) || null;
}

export function isARAPCreatedNote(noteDetails) {
  return !!noteDetails?.paymentReceivedDetails?.paymentReceivedGroupId;
}

/**
 * Scrolls to a specific item in a list of elements.
 * @param queryList - The list of elements to scroll within.
 * @param index - The index of the item to scroll to.
 */
export function scrollToItem(queryList: QueryList<ElementRef>, index: number): void {
  const listItem = queryList.toArray()[index];
  if (listItem) {
    listItem.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
}

/**
 * Compares two values for equality, with special handling for strings and numbers.
 * @param input - The first value to compare.
 * @param toMatch - The second value to compare.
 * @returns True if the values are equal, false otherwise.
 */
export function areEquals(input: any, toMatch: any): boolean {
  if ((typeof input === typeof toMatch) && input && toMatch) {
    switch (typeof input) {
      case 'string': {
        return input === toMatch;
      };
      case 'number': {
        return (Math.abs(roundOff(input) - roundOff(toMatch)) < 1);
      };
      default:
        return false;
    }
  }
  return false;
}

/**
   * Transforms bill verification form data and bill data into a structured payload format.
   * 
   * This function combines questions and items(line items) from the bill verification form data, 
   * then transforms the combined data into a structured format suitable for payload.
   * The resulting payload includes various metadata and transformed questionnaire data.
   * 
   * @param {Object} param0 - The input parameters.
   * @param {Object} param0.billVerificationFormData - The form data containing questions and items.
   * @param {Object} param0.billData - The bill data including id, document name, file id, status, and meta data.
   * @returns {Object} - The transformed payload data.
   */
export function transformBillVerificationFormDataForPayload({
  billVerificationFormData,
  billData,
}) {

  const allQuestionsData = [
    ...(billVerificationFormData?.questionnaireFormData?.questions || []),
    ...(billVerificationFormData?.questionnaireFormData?.headerErrors || []),
  ];

  let transformedData: any = {
    entityType: ENTITY_TYPES.BILL,
    entityRef: ENTITY_REFS.BILL,
    entityId: billData?.id,
    documentName: billData?.documentName,
    docFileId: billData?.docFileId,
    status: billData?.status,
    questionnaire: getTransformedQuestionnaire(allQuestionsData, 'questions'),
    lineItemQuestions: getTransformedQuestionnaire(billVerificationFormData.items,'items'),
    additionalInfo: billVerificationFormData?.questionnaireFormData?.additionalInfo,
    metaData: billData.metaData,
  };
  return transformedData;
}

/**
 * Transforms an array of question data objects into a structured format for questionnaire data.
 * 
 * This function checks if the input is an array, and if so, maps each question object to a new format 
 * containing detailed information and validation attributes.
 * 
 * @param {Array} questionsData - The array of question data objects to be transformed.
 * @returns {Array} - The transformed array of question data objects.
 */
interface CommonProperties {
  details: {
    title?: any;
    displayValue: {
      displayType?: any;
      value?: any;
    };
    link: {
      url?: any;
    };
  };
  questionType?: any;
  priority?: any;
  validationErrorMessage?: any;
  validationType?: any;
  validationSubType?: any;
  applicable?: any;
  lineItemId?: any;
}

interface ItemsTypeProperties {
  validatedItems?: any[];
}

interface QuestionTypeProperties {
  validity?: any[];
}

type TransformedQuestion = CommonProperties & (ItemsTypeProperties | QuestionTypeProperties);

export function getTransformedQuestionnaire(questionsData: any[], type: string): TransformedQuestion[] {
  if (!Array.isArray(questionsData)) {
    return [];
  }
  return questionsData.map((question) => {
    // Transform the question object
    const commonProperties: CommonProperties = {
      details: {
        title: question?.details?.title,
        displayValue: {
          displayType: question?.details?.displayType,
          value: question?.details?.displayValue,
        },
        link: {
          url: question?.details?.link,
        },
      },
      questionType: question.questionType,
      priority: question.priority,
      validationErrorMessage: question.validationError,
      validationType: question.type || question.validationType,
      ...(question.subType ? { validationSubType: question.subType } : {}),
      ...(question.questionType ? { questionType: question.questionType } : {}),
      applicable: question?.applicable,
      ...(question.lineItemId ? { lineItemId: question.lineItemId } : {}),
    };

    const itemsTypeProperties: ItemsTypeProperties = type === "items" ? {
      validatedItems: question.validatedItems && question.validatedItems.length > 0 ? question.validatedItems : undefined,
    } : {};

    const questionTypeProperties: QuestionTypeProperties = type !== "items" ? {
      validity: question.validity && Object.keys(question.validity).length > 0 ? question.validity : undefined,
    } : {};

    const transformedQuestion: TransformedQuestion = {
      ...commonProperties,
      ...itemsTypeProperties,
      ...questionTypeProperties,
    };

    if ('validity' in transformedQuestion && transformedQuestion.validity) {
      Object.keys(transformedQuestion.validity).forEach((key) => {
        if (key === 'MANUAL' && transformedQuestion.validity[key] === 'DEFAULT') {
          transformedQuestion.validity[key] = null;
        }
      });
    }

    return transformedQuestion;
  });
}


/**
 * Calculates metadata summary for bill verification form data.
 *
 * Processes questionnaire questions, line items, and additional info to update metadata counts
 * for validation errors categorized by type (manual/auto) and priority (high/low risk).
 *
 * @param {Object} verificationFormData - The form data containing questionnaire questions, line items, and additional info.
 * @returns {Object} - Metadata summary of validation errors.
 * @returns {Object} manualValidation - Metadata for manual validation errors.
 * @returns {number} manualValidation.highRiskCount - Count of high-risk manual validation errors.
 * @returns {number} manualValidation.lowRiskCount - Count of low-risk manual validation errors.
 * @returns {Object} autoValidation - Metadata for auto validation errors.
 * @returns {number} autoValidation.highRiskCount - Count of high-risk auto validation errors.
 * @returns {number} autoValidation.lowRiskCount - Count of low-risk auto validation errors.
 */
export function getVerificationSummaryMetaData(verificationFormData) {
  const metaData = {
    manualValidation: {
      highRiskCount: 0,
      lowRiskCount: 0,
    },
    autoValidation: {
      QR: { highRiskCount: 0, lowRiskCount: 0 },
      IRN: { highRiskCount: 0, lowRiskCount: 0 }
    },
  };

  const { HIGHRISK, LOWRISK } = {
    HIGHRISK: 'highRiskCount',
    LOWRISK: 'lowRiskCount'
  };

  const transformLineItemQuestions = (lineItemQuestions) => {
    return lineItemQuestions.flatMap(question => {
      const { validatedItems } = question;

      if (validatedItems && validatedItems.length > 0) {
        return validatedItems.map(item => ({
          ...question, 
          itemId: item.itemId,
          validity: item.validity,
          lineNumber: item.lineNumber
        }));
      } else {
        return question;
      }
    });
  };

  const updateMetaDataCount = (riskType, priority, isValid, item, type) => {
    const countCategory = priority === "HIGH" ? HIGHRISK : LOWRISK;

    if (isValid === false && isValid !== null) {
      const hasError = item?.infoType
        ? item.errorMessage
        : item.validationError?.[type]?.errorMessage;

      if (hasError) {
        if (type === "MANUAL") {
          metaData[riskType][countCategory]++;
        } else {
          metaData.autoValidation[type][countCategory]++;
        }
      }
    }
  };

  const processItems = (items) => {
    items.forEach(item => {
      const { priority, isValid, validity = {} } = item;

      if (item.infoType === "CHECK" || item.inputRequired) {
        const riskType = "manualValidation"; 
        if (priority?.MANUAL && isValid === false) {
          updateMetaDataCount(riskType, priority.MANUAL, isValid, item, "MANUAL");
        }
      } else {
        for (const type in validity) {
          const isValidForType = validity[type];
          const riskType = type === "MANUAL" ? "manualValidation" : "autoValidation";

          if (priority?.[type] && (isValidForType === false || isValidForType === true)) {
            updateMetaDataCount(riskType, priority[type], isValidForType, item, type);
          }
        }
      }
    });
  };

  const processQuestions = (questions) => {
    questions.forEach(question => {
      if (question.applicable) {
        const { validity = {}, priority } = question;

        for (const type in validity) {
          const isValid = validity[type];
          const riskType = type === "MANUAL" ? "manualValidation" : "autoValidation";

          if (priority?.[type] && (isValid === false || isValid === true)) {
            updateMetaDataCount(riskType, priority[type], isValid, question, type);
          }
        }
      }
    });
  };

  // Process questions
  if (verificationFormData?.questionnaireFormData?.questions?.length) {
    processQuestions(verificationFormData.questionnaireFormData.questions);
  }

  // Process items
  if (verificationFormData?.items?.length) {
    const transformedItems = transformLineItemQuestions(verificationFormData?.items || []);
    processItems(transformedItems);
  }

  // Process additionalInfo
  if (verificationFormData?.questionnaireFormData?.additionalInfo?.length) {
    processItems(verificationFormData.questionnaireFormData.additionalInfo);
  }

  // Process headerErrors
  if (verificationFormData?.questionnaireFormData?.headerErrors?.length) {
    processQuestions(verificationFormData.questionnaireFormData.headerErrors);
  }

  return metaData;
}

/**
 * Transforms bill verification form data into a structured summary of validation errors.
 *
 * @param {Object} billVerificationFormData - Form data containing questionnaire questions,
 *                                           line items, and additional info.
 * @returns {Object} - Structured summary object with categorized validation errors
 *                    and metadata counts.
 */
export function transformDataForVerificationSummary(billVerificationFormData) {
  const verificationSummary = {
    manualValidation: {
      highRisk: [],
      lowRisk: [],
    },
    autoValidation: {
      QR: {
        highRisk: [],
        lowRisk: [],
      },
      IRN: {
        highRisk: [],
        lowRisk: [],
      },
    },
    meta: {
      manualValidation: {
        highRiskCount: 0,
        lowRiskCount: 0,
      },
      autoValidation: {
        QR: {
          highRiskCount: 0,
          lowRiskCount: 0,
        },
        IRN: {
          highRiskCount: 0,
          lowRiskCount: 0,
        },
      },
    },
  };

  const addRiskLevel = (validationCategory, subCategory, riskPriority, validationErrorMessage) => {
    const riskCategory = riskPriority === "HIGH" ? "highRisk" : "lowRisk";
    const countCategory = riskPriority === "HIGH" ? "highRiskCount" : "lowRiskCount";

    if (subCategory) {
      verificationSummary[validationCategory][subCategory][riskCategory].push(validationErrorMessage);
      verificationSummary.meta[validationCategory][subCategory][countCategory]++;
    } else {
      verificationSummary[validationCategory][riskCategory].push(validationErrorMessage);
      verificationSummary.meta[validationCategory][countCategory]++;
    }
  };

  const transformLineItemQuestions = (lineItemQuestions) => {
    return lineItemQuestions.flatMap(question => {
      const { validatedItems } = question;
      if (validatedItems && validatedItems.length > 0) {
        return validatedItems.map(item => ({
          ...question, 
          itemId: item.itemId,
          validity: item.validity,
          lineNumber: item.lineNumber
        }));
      } else {
        return question;
      }
    });
  };

  const processVerificationData = (data, type) => {
    data?.forEach(question => {
        const { validity, priority, validationErrorMessage, validationError } = question;
        if(validity && priority){
        if (Object.keys(validity)?.length) {
          Object.keys(validity)?.forEach(key => {
            if (validity[key] === false && validity[key] !== null && (validationErrorMessage?.[key] || validationError?.[key]) && priority[key]) {
              const riskPriority = priority[key];
              const errorMessage = validationErrorMessage?.[key]?.errorMessage || validationError?.[key]?.errorMessage;
              
              switch (key) {
                case "MANUAL":
                  addRiskLevel("manualValidation", null, riskPriority, errorMessage);
                  break;
                case "QR":
                  addRiskLevel("autoValidation", "QR", riskPriority, errorMessage);
                  break;
                case "IRN":
                  addRiskLevel("autoValidation", "IRN", riskPriority, errorMessage);
                  break;
                default:
                  console.warn(`Unknown key - ${key}`);
              }
            }
          });
        }
      }
    });
  };

  const itemsData = transformLineItemQuestions(billVerificationFormData?.items || []);
  const questionHeaderData = [
    ...(billVerificationFormData?.questionnaireFormData?.questions || []),
    ...(billVerificationFormData?.questionnaireFormData?.headerErrors || []),
  ];

  processVerificationData(itemsData, 'items');
  processVerificationData(questionHeaderData, 'questionHeader');

  billVerificationFormData?.questionnaireFormData?.additionalInfo?.forEach(item => {
    if (item.infoType === "CHECK" || item.inputRequired) {
      const { isValid, priority, errorMessage } = item;

      if (isValid === false && priority.MANUAL) {
        const riskPriority = priority.MANUAL;
        addRiskLevel("manualValidation", null, riskPriority, errorMessage);
      }
    }
  });

  return verificationSummary;
}

/**
 * Calculates the Taxable amount proportional to the grossAmount 
 * for the artefact (Bill / Payment Request)
 * Taxable amount = Gross amount - all taxes (GST, CESS, TCS)
 * 
 * Use this function only for POs where poCategory is available
 * @returns taxable amount
 */
export function calculateTaxableAmount(artefactDetails, grossAmount = 0) {
  return (calculateTaxableFactor(artefactDetails) * grossAmount) || 0;
}

/**
 * Calculates the Taxable factor of the artefact
 * Taxable Factor = (taxable value / total value of artefact)
 * 
 * Use this function only for POs where poCategory is available
 * @returns taxable amount
 */
export function calculateTaxableFactor(artefactDetails) {
  if (artefactDetails?.paymentRequestNumber) {
    return (artefactDetails.taxableValue / artefactDetails.totalAmount) || 0;
  }
  else if (artefactDetails?.billNumber) {
    return (artefactDetails.value / artefactDetails.totalValue) || 0;
  }
  else if (artefactDetails?.noteNumber) {
    const totalTaxableValue = artefactDetails?.items?.reduce((total, item) => {
      return total + (item.quantity * item.unitRate);
    }, 0);
    const totalValueOfNote = getTotalValue(artefactDetails);
    return (totalTaxableValue / totalValueOfNote) || 0;
  }
}

/**
 * Calculates the GST amount proportional to the grossAmount
 * for the artefact (Bill / Payment Request)
 * 
 * Use this function only for POs where poCategory is available
 * @returns GST amount
 */
export function calculateApplicableGST(artefactDetails, grossAmount = 0) {
  let applicableGST = 0;
  const taxableAmount = calculateTaxableAmount(artefactDetails, grossAmount);
  //If payment request, the source of truth for the GST value is the entire GST amount captured in payment request
  if (artefactDetails?.paymentRequestNumber) {
    applicableGST = ((artefactDetails.gstTaxValue / artefactDetails.totalAmount) * grossAmount) || 0;
  }
  //For bills, a partial payment ticket can be created. Therefore calculating GST applicable on the amount in the ticket timeline form
  else if (artefactDetails?.billNumber) {
    applicableGST = artefactDetails.igst || artefactDetails.igst === 0 
      ? (taxableAmount * artefactDetails.igst / 100) 
      : (taxableAmount * ((artefactDetails.cgst + artefactDetails.sgst) || 0) / 100);
  }
  return roundOff(applicableGST);
}

/**
 * Based on artefact type, calculates the applicable (porportional) CESS
 * @returns CESS amount
 */
export function calculateApplicableCESS(artefactDetails, grossAmount = 0) {
  let applicableCESS = 0;
  const taxableAmount = calculateTaxableAmount(artefactDetails, grossAmount);
  //There is no CESS in Payment Requests
  if (artefactDetails?.paymentRequestNumber) {
    applicableCESS = 0;
  }
  //For bills, calculate the proportional CESS amount based on proportional taxable amount
  else if (artefactDetails?.billNumber) {
    const cessPercentage = calculateWeightedCess(artefactDetails.items);
    applicableCESS = roundOff(((taxableAmount * cessPercentage) / 100) || 0);
  }
  return applicableCESS;
}

/**
 * Based on artefact type, calculates the applicable (porportional) TCS
 * @returns TCS amount
*/
export function calculateApplicableTCS(artefactDetails, grossAmount = 0) {
  let applicableTCS = 0;
  //For bills, calculate the proportional CESS amount based on proportional taxable amount + GST + CESS.
  if (artefactDetails?.billNumber && artefactDetails.tcsRate) {
    const tcsPercentage = ((artefactDetails.tcsRate / (artefactDetails.totalValue - artefactDetails.tcsRate)) * 100) || 0;
    // TCS is calculated on (Taxable amount + GST + CESS)
    const amountIncludingGSTCESS = calculateTaxableAmount(artefactDetails, grossAmount) 
      + calculateApplicableGST(artefactDetails, grossAmount) 
      + calculateApplicableCESS(artefactDetails, grossAmount);
    
    applicableTCS = roundOff((amountIncludingGSTCESS * tcsPercentage) / 100);
  }
  else if (artefactDetails?.paymentRequestNumber) {
    const tcsAmount = artefactDetails?.documents?.find(doc => doc.documentType === ADVANCE_DOCS.PROFORMA_INVOICE.name)?.tcsRate ?? 0;
    applicableTCS = ((tcsAmount / artefactDetails.totalAmount) * grossAmount) || 0;
  }
  return applicableTCS;
}

/**
 * Calculates the TDS amount for Paid After Ded Interest modality
 * when payment is being initiated against a Payment Request
 * TDS is only calculated on the taxable amount for PO Category PRs
 * and for non PO Category PRs it is calculated on the entire gross amount
 * 
 * For more info on the formula and examples refer Jira BSNG-746
 * 
 * @param amountToBePaid - Cash paid to vendor (not including TDS and interest)
 * @param tdsRate - TDS Rate of the payment being initiated
 * @param artefactDetails - Bill / PR / Supplier CN details
 * @returns {number} TDS amount
 */
export function calculateTDSForPaidAfterDedInterest(amountToBePaid, tdsRate, artefactDetails) {
  let amountToApplyTDSOn;
  if (artefactDetails?.purchaseOrderDetails?.poCategory) {
    const taxableFactor = calculateTaxableFactor(artefactDetails) || 0;
    const amountToBeAdjustedInArtefact = +((amountToBePaid * 100) / (100 - ((tdsRate * taxableFactor) || 0))).toFixed(3);
    amountToApplyTDSOn = +(amountToBeAdjustedInArtefact * taxableFactor).toFixed(3);
  } else {
    amountToApplyTDSOn = +((amountToBePaid * 100) / (100 - (tdsRate || 0))).toFixed(3);
  }
  return +(((tdsRate || 0) * amountToApplyTDSOn) / 100).toFixed(3);
}
