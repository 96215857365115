export const StateCodes = [
  { id: 1, name: 'JAMMU AND KASHMIR', code: 1, nameForErp: 'Jammu and Kashmir', shortCode: 'JK', new: true },
  { id: 2, name: 'HIMACHAL PRADESH	', code: 2, nameForErp: 'Himachal Pradesh', shortCode: 'HP', new: true },
  { id: 3, name: 'PUNJAB', code: 3, nameForErp: 'Punjab', shortCode: 'PB', new: true },
  { id: 4, name: 'CHANDIGARH', code: 4, nameForErp: 'Chandigarh', shortCode: 'CH', new: true },
  { id: 5, name: 'UTTARAKHAND', code: 5, nameForErp: 'Uttarakhand', shortCode: 'UT', new: true },
  { id: 6, name: 'HARYANA', code: 6, nameForErp: 'Haryana', shortCode: 'HR', new: true },
  { id: 7, name: 'DELHI', code: 7, nameForErp: 'Delhi', shortCode: 'DL', new: true },
  { id: 8, name: 'RAJASTHAN', code: 8, nameForErp: 'Rajasthan', shortCode: 'RJ', new: true },
  { id: 9, name: 'UTTAR PRADESH	', code: 9, nameForErp: 'Uttar Pradesh', shortCode: 'UP', new: true },
  { id: 10, name: 'BIHAR', code: 10, nameForErp: 'Bihar', shortCode: 'BR', new: true },
  { id: 11, name: 'SIKKIM', code: 11, nameForErp: 'Sikkim', shortCode: 'SK', new: true },
  { id: 12, name: 'ARUNACHAL PRADESH', code: 12, nameForErp: 'Arunachal Pradesh', shortCode: 'AR', new: true },
  { id: 13, name: 'NAGALAND', code: 13, nameForErp: 'Nagaland', shortCode: 'NL', new: true },
  { id: 14, name: 'MANIPUR', code: 14, nameForErp: 'Manipur', shortCode: 'MN', new: true },
  { id: 15, name: 'MIZORAM', code: 15, nameForErp: 'Mizoram', shortCode: 'MZ', new: true },
  { id: 16, name: 'TRIPURA', code: 16, nameForErp: 'Tripura', shortCode: 'TR', new: true },
  { id: 17, name: 'MEGHALAYA', code: 17, nameForErp: 'Meghalaya', shortCode: 'ML', new: true },
  { id: 18, name: 'ASSAM', code: 18, nameForErp: 'Assam', shortCode: 'AS', new: true },
  { id: 19, name: 'WEST BENGAL', code: 19, nameForErp: 'West Bengal', shortCode: 'WB', new: true },
  { id: 20, name: 'JHARKHAND', code: 20, nameForErp: 'Jharkhand', shortCode: 'JH', new: true },
  { id: 21, name: 'ODISHA', code: 21, nameForErp: 'Odisha', shortCode: 'OR', new: true },
  { id: 22, name: 'CHATTISGARH', code: 22, nameForErp: 'Chhattisgarh', shortCode: 'CT', new: true },
  { id: 23, name: 'MADHYA PRADESH', code: 23, nameForErp: 'Madhya Pradesh', shortCode: 'MP', new: true },
  { id: 24, name: 'GUJARAT', code: 24, nameForErp: 'Gujarat', shortCode: 'GJ', new: true },
  { id: 25, name: 'DAMAN AND DIU', code: 25, nameForErp: 'Daman & Diu', shortCode: 'DH', new: false },
  { id: 26, name: 'DADRA AND NAGAR HAVELI', code: 26, nameForErp: 'Dadra & Nagar Haveli', shortCode: 'DH', new: false },
  { id: 27, name: 'MAHARASHTRA', code: 27, nameForErp: 'Maharashtra', shortCode: 'MH', new: true },
  { id: 28, name: 'ANDHRA PRADESH', code: 37, nameForErp: 'Andhra Pradesh', shortCode: 'AP', new: true },
  { id: 29, name: 'KARNATAKA', code: 29, nameForErp: 'Karnataka', shortCode: 'KA', new: true },
  { id: 30, name: 'GOA', code: 30, nameForErp: 'Goa', shortCode: 'GA', new: true },
  { id: 31, name: 'LAKSHWADEEP', code: 31, nameForErp: 'Lakshadweep', shortCode: 'LD', new: true },
  { id: 32, name: 'KERALA', code: 32, nameForErp: 'Kerala', shortCode: 'KL', new: true },
  { id: 33, name: 'TAMIL NADU', code: 33, nameForErp: 'Tamil Nadu', shortCode: 'TN', new: true },
  { id: 34, name: 'PUDUCHERRY', code: 34, nameForErp: 'Puducherry', shortCode: 'PY', new: true },
  { id: 35, name: 'ANDAMAN AND NICOBAR ISLANDS', code: 35, nameForErp: 'Andaman and Nicobar Islands', shortCode: 'AN', new: true },
  { id: 36, name: 'TELANGANA', code: 36, nameForErp: 'Telangana', shortCode: 'TG', new: true },
  { id: 37, name: 'ANDHRA PRADESH (NEW)', code: 37, nameForErp: 'Andhra Pradesh', shortCode: 'AP', new: false },
  { id: 38, name: 'LADAKH', code: 38, nameForErp: 'Ladakh', shortCode: 'LA', new: true },
  { id: 39, name: 'JAMMU AND KASHMIR', code: 38, nameForErp: 'Jammu and Kashmir', shortCode: 'JK', new: false },
  { id: 40, name: 'DAMAN AND DIU & DADRA AND NAGAR HAVELI', code: 26, nameForErp: 'Dadra & Nagar Haveli & Daman & Diu', shortCode: 'DH', new: true },
  { id: 41, name: 'OTHER TERRITORY', code: 97, nameForErp: 'Other Territory', shortCode: 'OTHER', new: true }
];