import { Pipe, PipeTransform } from "@angular/core";

const padding = "000000";


@Pipe({ name: "numberFormatter" })
export class FormatNumberPipe implements PipeTransform {
  private decimal_separator:string;
  private thousands_separator:string;
  private suffix:string;

  constructor(){
    this.suffix='';
    this.decimal_separator='.';
    this.thousands_separator = ',';
  }
  transform(value: number, args?: string, key?: boolean): any {
    if (! isNaN(value) && value) {
      //var output = Number(input).toLocaleString('en-IN');   <-- This method is not working fine in all browsers!
      const toDecimalValue = this.getTwoDecimal(value);
      if (toDecimalValue) {
        const result = toDecimalValue.toString().split('.');
        let lastThree = result[0].substring(result[0].length - 3);
        const otherNumbers = result[0].substring(0, result[0].length - 3);
        if (otherNumbers != '' && otherNumbers !== '-')
          lastThree = ',' + lastThree;
        let output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, this.thousands_separator) + lastThree;
        if (args === '&#36;') {
          output = result[0].replace(/\B(?=(\d{3})+(?!\d))/g, this.thousands_separator);
        }
        if (args === '&#8377;') {
          output = otherNumbers.replace(/\B(?=(\d{2})+(?!\d))/g, this.thousands_separator) + lastThree;
        }

        if (result.length > 1) {
          output += "." + result[1];
        }

        if (key) {
          return output;
        }
        return args + output;
      } else {
      return args + 0;
      }
    }
    return args + 0;

  }

  parse(value: string, fractionSize: number = 2): string {
    let [ integer, fraction = "" ] = (value || "")
      .replace(this.suffix, "")
      .split(this.decimal_separator);

    integer = integer.replace(new RegExp(this.thousands_separator, "g"), "");

    fraction = parseInt(fraction, 10) > 0 && fractionSize > 0
      ? this.decimal_separator + (fraction + padding).substring(0, fractionSize)
      : "";
    return integer + fraction;
  }

  getTwoDecimal(number) {
    if (!Number(number)) {
      return;
    }
    if (Number(number) % 1 === 0) {
      return Number(number);
    }
    return Number(Number(number).toFixed(2));
  }


}
