export enum LABELS {
    contractNo = 'Contract No.',
    orderNo = 'Order No.',
    poNo = 'PO No.',
    creditNoteNo = 'Credit Note No.',
    debitNoteNo = 'Debit Note No.',
    paymentRequestId = 'Payment Request ID',
    loanNo = 'Loan No.',
    invoiceNo = 'Invoice No.',
    proformaInvoiceNo = 'PI No.'
}